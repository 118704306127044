import BaseService from "./BaseService";
import { APP } from "../../configs/apis";
class APPService extends BaseService {
  /**
   * get requested appointment data
   * @param {object} params
   */
  getRequestedAppointment(params: any) {
    return this.client
      .get(APP.REQUESTED_APPOINTMENT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * Request appointment
   * Method: POST
   * @param {*} params
   */
  postAppointmentRequest(params: any) {
    return this.client
      .post(APP.APPOINTMENT_REQUEST, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get provider assign for appointment
   * @param {object} params
   */
  getProviderAssignForAppointment(params: any) {
    return this.client
      .get(APP.PROVIDER_ASSIGN_APPOINTMENT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * do confirm requested appointment
   * @param {object} data
   */
  confirmRequestedAppointment(data: any, requestedAppointmentId: any) {
    const url = APP.CONFIRM_REQUESTED_APPOINTMENT({
      requested_app_id: requestedAppointmentId
    });
    return this.client
      .put(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get all appointment
   * @param {object} params
   */
  getAllAppointment(params: any) {
    return this.client
      .get(APP.APPOINTMENT_ALL, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get appointment data
   * @param {object} params
   */
  getAppointment(params: any) {
    return this.client
      .get(APP.APPOINTMENT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get appointment with id
   * @param {*} appointmentId
   */
  getAppointmentWithId(appointmentId: any) {
    const url = APP.APPOINTMENT_ID({ app_id: appointmentId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * delete appointment with id
   * @param {*} appointmentId
   */
  deleteAppointmentWithId(appointmentId: any, params: any) {
    const url = APP.APPOINTMENT_ID({ app_id: appointmentId });
    return this.client
      .delete(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  cancelAppointment(appointmentId: any, params: any) {
    const url = APP.CANCEL_APPOINTMENT({ app_id: appointmentId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * update appointment with id
   * Method: PUT
   * @param {*} appointmentId
   */
  putAppointmentWithId(appointmentId: any, params: any) {
    const url = APP.APPOINTMENT_ID_REQUEST({ app_id: appointmentId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postponeAppointmentWithId(appointmentId: any, params: any) {
    const url = APP.APPOINTMENT_ID({ app_id: appointmentId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * print appointment
   * @param {object} appointmentId
   */
  printAppointment(appointmentId: any) {
    const url = APP.PRINT_APPOINTMENT({ app_id: appointmentId });
    return this.client
      .put(url, { language: "TH" })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putAppointmentPrintWithId(appointmentId: any, params: any) {
    const url = appointmentId ? APP.PRINT_APPOINTMENT({ app_id: appointmentId }) : APP.PRINT_APPOINTMENT_WITHOUT_ID;

    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putAppointmentWithIdConfirm(appointmentId: any, params: any) {
    const url = APP.APPOINTMENT_ID_CONFIRM({ app_id: appointmentId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putAppointmentWithIdCancel(appointmentId: any, params: any) {
    const url = APP.CANCEL_APPOINTMENT({ app_id: appointmentId })
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get doctor work schedule for division
   * @param {division} params
   */
  getDoctorWorkAtDivision(params: any) {
    return this.client
      .get(APP.DOCTOR_WORK_AT_DIV, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDoctorNote(params: any) {
    return this.client
      .get(APP.DOCTOR_NOTE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDoctorNoteGroup(params: any) {
    return this.client
      .get(APP.DOCTOR_NOTE_GROUP, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDoctorScheduleAppointment(params: any) {
    return this.client
      .get(APP.DOCTOR_SCHEDULE_APPOINTMENT_LIST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default APPService;
