
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from 'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* ['APIView'] */
/* params:  */
/* data: 'application' */
const EmailAuthRegisterViewM:
  {
    post: ({ params, data, apiToken, extra }:
      {
        params?: any,
        data?: {[key: string]: any},
        apiToken?: any,
        extra?: any
      }) => any,
  } =
{
  post: async ({ params, data, apiToken, extra
  }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
      base.client.defaults.headers["content-type"] = "multipart/form-data"
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(data || {})) {
      formData.append(key, value)
    }

    const result = await to(base.client.post(`${HOST}/email_auth/register/`,
      formData,
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default EmailAuthRegisterViewM

