import ChatService from "./ChatService";
import { to, setStateAsync } from "../react-lib/utils";

export default class ChatManager {
    getChatChannel = async ({apiToken, userId, search}) => {
        let headers = {}
        let params = {user_type: "PATIENT"};
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        if (userId) {
            params.user = userId;
        }
        if (search) {
            params.search = search;
        }
        const chatService = new ChatService();
        const [error, response] = await to(chatService.getChatChannel({ headers, params }));
        return [response, error];
    }

    getLasterChatChannel = async ({apiToken, userId}) => {
        let headers = {}
        let params = {};
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        if (userId) {
            params.user_id = userId;
        }
        const chatService = new ChatService();
        const [error, response] = await to(chatService.getLasterChatChannel({ headers, params }));
        return [response, error];
    }
}