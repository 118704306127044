import { USER, PRX } from "./url";
import BaseService from "../react-lib/apis/services/BaseService";

class UserService extends BaseService {
  getUserProfile({ headers, params } = {}) {
    return this.client
      .get(USER.USER_PROFILE, { headers, params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  patchUserProfile({ headers, data } = {}) {
    return this.client
      .patch(USER.USER_PROFILE, data, { headers })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  patchMyProfile({ apiToken, options, data } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .patch(USER.USER_MY_PROFILE, data, options)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  
  postDeleteUser({headers, data} = {}) {
    return this.client.post(USER.DELETE_ACCOUNT, data, {headers})
        .then(this.handleResponse)
        .catch(this.throwErrorMessage);
  } 
}

export default UserService;
