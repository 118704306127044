// Note1: setProps เมื่อ setState ทั้งก้อนทำให้ ทุกส่วน render 
// โดยปกติ setState ส่วน PureComponent (Class) จะ shallow compare แลัวไม่ render 
// หรือ function component ที่ หุ้มด้วย React.memo ไม่ render  ดังนั้น 
// ซึ่งการ mutate state object และ setState กลับไปทั้งก้อน จะทำให้ 
// ที่ให้ PureComponent หรือ React.memo Render ทั้งหมด 
// ทำให้ Performance drop ลงมาก เพราะต้อง render ทั้งหน้า 

// ถ้าจะทำให้ performance render ดีขึ้น ต้องแก้ โดย setState ด้วย object ใหม่ ไม่ mutate state object โดยตรง 
// จึงจะทำให้ react ไม่ render ส่วนที่ไม่จำเป็น และ performance ดีขึ้น 
// โดย กำลังแก้อยู่ โดย
// SetPropSeparate มันยังมี bug กับ CUdent บางมุมอยู่ ยังไม่ได้ดูต่อ จึงต้องกลับไปใช้ setProp แรก 

// และ ระลึกว่า SetProp มันจำเป็น render ทุกชิ้น เพราะ เราไม่รู้ว่า ส่วนไหน ค่าไหน ใน state Object ที่เปลี่ยน และ กระทบกับ Component ไหน 
// จึง ห้าม ใช้ PureComponent หรือ หุ้ม ด้วย React.memo เพื่อให้ Component render ทุกครั้งที่ setProp และไปเรียก setState ( Performance ตกมาก )

// Note2: เมื่อ setProps ทำการ mutate Object state จะทำให้ function componentDidUpdate แล้วเปรียบเทียบ prevState กับ current State จะทำงานผิดพลาดเนื่องจาก
// prevState จะถูกแก้ไขค่า เป็น Current State ทันที 
// ดังนั้น ทำให้เรา ไม่สามารถจับ ความแตกต่าง ว่า State ก่อนหน้า กับ State ปัจจุบัน ได้
//  ทำให้เขียน logic ถ้า State ตัวนี้ มีค่า 10 เปลี่ยนเป็น ค่า 11 เท่านั้น ถึง จะทำงาน แบบนี้ จะเขียนไม่ได้ เพราะเมื่อตรวจความแตกต่างจะเห็นว่า 
// prevState คือ 11 แล้ว Current State ก็เป็น 11 

// Note2.1 ดังนั้น แก้ อาการ Note 2 ได้โดยใช้  controller.setState({ x: y }) แทน setProps จะจับ diff ได้ 


const SetProp = (context: any, keyPrefix: string, callback?: any) => {
  return (keySuffix: string, value: any, callback?: any) => {
    const keys = keyPrefix.trim() === "" ? keySuffix.split(".")
      : keyPrefix.split(".").concat(keySuffix.split("."));
    // console.log(" SetProp keys:", keys, value , context.state );
    let state = context.state;
    let stateRef = state;

    for (var i=0; i < keys.length; i++) {
      if (i < keys.length - 1) {
        if (!Object.keys(stateRef).includes(keys[i]) || 
            stateRef[keys[i]] === null ||
            typeof stateRef[keys[i]] === "undefined") {
          stateRef[keys[i]] = {};
        }
        stateRef = stateRef[keys[i]];
      } else {
        stateRef[keys[i]] = value;
      }
    }

    if (callback) {
      context.setState(state, callback);
    } else {
      context.setState(state);
    }
  };
};



// To make sure react will render Version 1 (Obsolete)
export const SetPropNewObj = (context: any, keyPrefix: string, callback?: any) => {
  return (keySuffix: string, value: any, callback?: any) => {
    const keys = keyPrefix.trim() === "" ? keySuffix.split(".")
      : keyPrefix.split(".").concat(keySuffix.split("."));
    // console.log("SetPropNewObj keys:", keys,  value , context.state )
    let state = Object.assign({}, context.state ); 
    let stateRef = state;

    // Condition for setState with new object
    if (keys.length > 0) {

      if (!Object.keys(stateRef).includes(keys[0])) {
        state[keys[0]] = {};
      }

      let prepare = state[keys[0]];
      if (prepare?.constructor == Object) {
        let newValue = Object.assign({}, prepare);
        state[keys[0]] = newValue; // <<--- new Obj
      } else if ( prepare?.constructor == Array) {
        state[keys[0]] = [...prepare];
      }
    }

    for (var i=0; i < keys.length; i++) {
      if (i < keys.length - 1) {
        if (!Object.keys(stateRef).includes(keys[i])) {
          stateRef[keys[i]] = {};
        }
        stateRef = stateRef[keys[i]];
      } else {
        stateRef[keys[i]] = value;
      }
    }

    if (callback) {
      context.setState(state, callback);
    } else {
      context.setState(state);
    }
  };
};

// To make sure react will render with React.memo Version 2
export const SetPropSeparate = (context: any, keyPrefix: string, callback?: any) => {
  return (keySuffix: string, value: any, callback?: any) => {
    // console.log('SetPropSeparate value: ', value);
    // console.log("SetPropSeparate keySuffix:", keySuffix)
    var keys = keyPrefix.trim() === "" ? keySuffix.split(".")
      : keyPrefix.split(".").concat(keySuffix.split("."));
    // console.log("SetPropSeparate keys: ", keys)
      
    let firstKey = keys.shift() || "";
    let head;
    if (context.state?.[firstKey]?.length) {
      head = Object.assign([], context.state[firstKey]); 
    } else {
      head = Object.assign({}, context.state[firstKey]);
    }
    let stateRef = head;

    if ( keys.length > 0) {
      // other Key
      for (var i=0; i < keys.length; i++) {
        if (i < keys.length - 1) {
          if (!Object.keys(stateRef).includes(keys[i])) {
            stateRef[keys[i]] = {};
          }
          stateRef = stateRef[keys[i]];
        } else {
          stateRef[keys[i]] = value;
        }
      }
    } else {
      // only have first key 
      head = value;
    }
    
    // console.log("SetPropSeparate stateRef, firstKey: ", stateRef, firstKey )
    // console.log("SetPropSeparate head: ", head )
    if (callback) {
      context.setState({ [firstKey]: head }, callback);
    } else {
      context.setState({[firstKey]: head });
    }
  };
};

export default SetProp;