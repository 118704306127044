
const settings = {
  CHANNEL_ID: "1653989204",
  CHANNEL_SECRET: "cda56c30cfca219c83bdbbbe634a6576",

  APPLE_ID_CLIENT_ID: "app.ishealth",
  APPLE_ID_REDIRECT_URI: document.location.origin + "/apple-callback",
  // APPLE_ID_REDIRECT_URI: "https://accounts.thevcgroup.com/apple-callback/patient-ishealth/"
};

export default settings;
