import React, { useMemo } from "react";
// CSS
import { Button, Dimmer, Loader } from "semantic-ui-react";

// Interface
import {
  Event as MobEvent,
  State as MobState,
} from "../../../../patient-lib/MobTransformInterface";

// UI
import TFHead from "./TFHead";
import { SpecifyHN } from "./TFRegister";
import ModMessage from "./ModMessage";

type TFEditHNProps = {
  onEvent: (e: MobEvent) => any;
  history: any;
} & Pick<
  MobState,
  "loadingMainTransform" | "successMessage" | "errorMessage" | "registerInfo"
>;

const initialHeight = window.innerHeight;

const TFEditHN: React.FunctionComponent<TFEditHNProps> = (props) => {
  const contentHeight = useMemo(() => {
    return `${(initialHeight * 63) / 100}px`;
  }, []);

  /* ----------------------- Handle ----------------------- */
  const handleLeftIconClick = () => {
    props.history.goBack();
  };

  const handleUpdatePatientProfile = () => {
    props.onEvent({
      message: "UpdatePatientProfile",
      params: { history: props.history, current: "hn" },
    });
  };

  return (
    <div className="tf-register">
      <Dimmer
        active={props.loadingMainTransform}
        inverted
        style={{ zIndex: 1100 }}
      >
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <TFHead title={"กรุณาระบุ HN"} leftIconClick={handleLeftIconClick} />

      <div className="tf-main">
        <SpecifyHN
          {...props}
          onSubmit={handleUpdatePatientProfile}
          height={contentHeight}
        />
        <Button
          fluid
          className="button-submit-primary"
          onClick={handleUpdatePatientProfile}
        >
          บันทึก
        </Button>
      </div>

      <ModMessage
        onEvent={props.onEvent}
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
      />
    </div>
  );
};

TFEditHN.defaultProps = {
  onEvent: () => {},
  history: {},
} as TFEditHNProps;

export default TFEditHN;
