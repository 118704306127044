import React, { useState, useEffect, useCallback } from "react";
import {
  Header,
  Card,
  Button,
  Segment,
  Modal,
  Dimmer,
  Loader,
  Form
} from "semantic-ui-react";
import ErrorMessage from "../../common/ErrorMessage";
import moment from "moment";
import { useHistory } from "react-router";
import Cookies from "js-cookie";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";

// Controller
import QueueController from "../../QUE/QueueController"

// Manager
import QUEManager from "../../../apis/manager/QUEManager"
import PRXManager from "../../../apis/manager/PRXManager"

// Common

type VCAppointmentViewProps = {
  apiToken: string
  header: any
  patientData: any;

  // Callback
  noApiToken: () => void;
  goToVaccineAppointmentSlot: (data: {
    divisionId: number,
    divisionName: string,
    year: number,
    month: string;
    appointmentId: number;
  }) => void

  // Controller
  queueController: QueueController & {
    queManager: QUEManager,
    prxManager: PRXManager
  }
} & WrappedComponentProps

type ModConfirmType = {
  open: boolean;
  data?: any;
  error?: any;
}

const CLASSIC_BLUE = "#004884";

const initialModConfirm: ModConfirmType = {
  open: false,
  data: null,
  error: null
};

const styles = {
  card: {
    width: "100%",
    fontSize: "15px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    lineHeight: "1.5em"
  },
  button: {
    backgroundColor: "#01b2ac", color: "white", padding: "15px 0px",
  }
};

const regexDate = /(\d+)\/(\d+)\/(\d+)/i


const VCAppointmentView = (props: VCAppointmentViewProps) => {
  const history = useHistory();
  // Loading
  const [isLoading, setIsLoading] = useState(false)

  // Open
  const [openModConfirm, setOpenModConfirm] = useState<ModConfirmType>(initialModConfirm);

  // List
  const [patientAppointmentList, setPatientAppointmentList] = useState({
    vaccine_covid: [],
    telemed: []
  })

  const [error, setError] = useState(null)

  useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      props.noApiToken();
    }
    if (props.patientData.id) {
      getListAppointment()
    }
  }, [props.patientData.id])

  const formatDate = useCallback(
    (date) => {
      return moment((date || "").replace(regexDate, "$3-$2-$1"))
    },
    [],
  )

  const getListAppointment = async () => {
    setIsLoading(true)
    const [
      response,
      error,
      network
    ] = await props.queueController.listPatientAppointment({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      patientId: props.patientData?.id
    });

    // const appointmentList = MOCK_DATA.items
    const appointmentList = response?.items?.[0] ? response.items : MOCK_DATA.items

    const groupProviderType = {
      vaccine_covid: [],
      telemed: []
    }
    for (const list of appointmentList) {
      console.log(list)
      if (list?.display_info?.provider_type_category === "") {
        groupProviderType.telemed.push(list)
      } else if (list?.display_info?.provider_type_category === "covid") {
        groupProviderType.vaccine_covid.push(list)
      } else if (list?.display_info?.provider_type_category === "covid_vaccine") {
        groupProviderType.vaccine_covid.push(list)
      }
    }

    setIsLoading(false)
    setPatientAppointmentList(groupProviderType)
    // let newArr: PatientAppointment[] = response.items.sort(
    //   (
    //     a: { display_info: { start_serial: number } },
    //     b: { display_info: { start_serial: number } }
    //   ) => {
    //     if (!a.display_info && !b.display_info) return 0;
    //     else if (!a.display_info) return -1;
    //     else if (!b.display_info) return 1;
    //     else
    //       return a.display_info.start_serial - b.display_info.start_serial;
    //   }
    // );

  }


  const handleClickCardCovid = (item: any) => {
    setOpenModConfirm({
      open: true,
      data: item
    });
  };

  const handleCloseModConfirm = (error = null) => {
    if (error) {
      setError(error)
    } else {
      setError(null)
      setOpenModConfirm({ open: false, data: null });
      if (props.patientData.id) {
        getListAppointment()
      }
    }
  };

  const handleGoToAppointment = () => {
    const { data } = openModConfirm
    if (data?.display_info) {
      const { display_info } = data
      props.goToVaccineAppointmentSlot({
        divisionId: data.division,
        divisionName: data.division_name,
        year: +formatDate(display_info?.start_datetime).format("YYYY") - 543,
        month: formatDate(display_info?.start_datetime).format("MM"),
        appointmentId: data.id
      });
      handleCloseModConfirm()
    }

  };

  const handleCancel = async () => {
    const { data } = openModConfirm
    var error = null
    if (data?.display_info) {
      console.log(data);
      const { display_info } = data
      const [response, error] = await props.controller.prxManager.deleteVaccineServiceBlock({
        apiToken:props.apiToken ? props.apiToken : Cookies.get("apiToken"),
        slot: data.division_service_block
      });
      console.log(response);
      console.log(error);
      handleCloseModConfirm(error);
    }

  }

  return (
    <>
      {props.header}
      <div style={{ padding: "15px", paddingTop: "15px" }}>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Header as='h3' style={{ color: CLASSIC_BLUE }}>นัดหมายฉีดวัคซีน COVID-19</Header>
        {
          patientAppointmentList.vaccine_covid[0] ?
            patientAppointmentList.vaccine_covid.map((item, index) =>
              <Card
                key={"card-covid" + index}
                style={styles.card}
                onClick={() => handleClickCardCovid(item)}
              >
                <Card.Content>
                  <div>
                    <label style={{ fontWeight: "bold" }}>วันที่ : </label>  {formatDate(item?.display_info?.start_datetime).format("DD MMMM YYYY")}
                  </div>
                  <div>
                    <label style={{ fontWeight: "bold" }}>ช่วงเวลา :
                    </label>  {formatDate(item?.display_info?.start_datetime).format("HH:mm")}
                    - {formatDate(item?.display_info?.end_datetime).format("HH:mm")} น.
                  </div>

                  <p style={{ color: "#267CAD", fontWeight: "bold", margin: "15px 0 25px" }}>เปลี่ยนแปลงนัดหมาย</p>
                  <p style={{ color: "#FF4D4D", fontWeight: "bold" }}>กรุณามาตามเวลานัดหมาย เพื่อลดความแออัดของสถานที่</p>
                </Card.Content>
              </Card>) :
            <div
              style={{ marginTop: "25px", display: "flex", justifyContent: "center" }}>
              <div style={{ background: "#f6f7f7", padding: "13px" }}>
                ไม่พบนัดหมาย
              </div>
            </div>

        }
      </div>
      <div style={{ padding: "15px", paddingBottom: "35px" }}>
        <Header as='h3' style={{ color: CLASSIC_BLUE }}>นัดหมายพบแพทย์ผ่าน Telemed</Header>
        {
          patientAppointmentList.telemed[0] ? patientAppointmentList.telemed.map((item, index) => <Card
            key={"card-telemed" + index}
            style={styles.card}
          >
            <Card.Content>
              <div>
                <label style={{ fontWeight: "bold" }}>วันที่ : </label>  {formatDate(item?.display_info?.start_datetime).format("DD MMMM YYYY")}
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>เวลา : </label>  {formatDate(item?.display_info?.start_datetime).format("HH:mm")} น.
              </div>
              <div>
                <label style={{ fontWeight: "bold" }}>แพทย์ : </label>  {item?.display_info?.provider_name}
              </div>
            </Card.Content>
          </Card>

          ) :
            <div
              style={{ marginTop: "25px", display: "flex", justifyContent: "center" }}>
              <div style={{ background: "#f6f7f7", padding: "13px" }}>
                ไม่พบนัดหมาย
              </div>
            </div>
        }
      </div>

      <Modal
        open={openModConfirm.open}
        closeOnDimmerClick={true}
        onClose={() => handleCloseModConfirm()}
      >
        <Segment style={{ backgroundColor: "#2185d0", color: "white" }}>{"แจ้งเตือน!!!"}</Segment>
        <Segment padded align='center' style={{
          border: 0,
          boxShadow: "none",
          lineHeight: "1.5em"
        }}>
          <Form error={error ? true : false}>
            <ErrorMessage error={error} />
          </Form>
          <div style={{ display: "grid", gridTemplateColumns: "45% 10% 45%", padding: "10px 0 10px" }}>
            <Button
              fluid
              style={{ ...styles.button }}
              onClick={handleGoToAppointment}
            >
              {"เลื่อนนัดหมาย"}
            </Button>
            <div></div>
            <Button
              fluid
              style={{ ...styles.button, backgroundColor: "#EB001B" }}
              onClick={handleCancel}
            >
              {"ยกเลิก"}
            </Button>
          </div>
        </Segment>

      </Modal>

    </>
  );
};

const MOCK_DATA = {
  "total": 63,
  "next": null,
  "previous": null,
  "items": [
    {
      "id": 26,
      "patient_first_name": "คุณขวัญใจ",
      "patient_last_name": "มหาชน",
      "division_name": "ศัลยกรรม",
      "display_info": {
        "id": 475,
        "division_name": "ศัลยกรรม",
        "provider_name": "พญ. ชาดา บินกาเมน",
        "provider_type_category": "",
        "employee": 44932,
        "employee_type": "DOCTOR",
        "start_datetime": "15/05/2563T08:00:00+0700",
        "end_datetime": "15/05/2563T16:00:00+0700",
        "start_serial": 118084,
        "end_serial": 118116,
        "status": 3,
        "capacity": 12,
        "division": 11091,
        "provider": 40
      },
      "estimate_service_at": "2020-05-15T08:00:00+07:00",
      "description": "นัดปรึกษาแพทย์ออนไลน์",
      "seq": 1,
      "status": 2,
      "estimated_at": null,
      "created": "2020-05-14T11:52:53.052536Z",
      "updated": "2020-05-15T08:59:44.219698Z",
      "patient": 181,
      "division": 11091,
      "provider": null,
      "division_service_block": 475
    },
    {
      "id": 83,
      "patient_first_name": "พรรุ่ง",
      "patient_last_name": "อังวิทยาธร",
      "division_name": "ทันตกรรม",
      "display_info": {
        "id": 3555,
        "division_name": "ทันตกรรม",
        "provider_name": "ศตายุ ฐาปนาเวท",
        "provider_type_category": "",
        "employee": 44943,
        "employee_type": "NURSE",
        "start_datetime": "11/06/2563T08:00:00+0700",
        "end_datetime": "11/06/2563T16:00:00+0700",
        "start_serial": 120676,
        "end_serial": 120708,
        "status": 3,
        "capacity": 3,
        "division": 11070,
        "provider": 51
      },
      "estimate_service_at": null,
      "description": "นัดปรึกษาแพทย์ออนไลน์",
      "seq": -1,
      "status": 1,
      "estimated_at": null,
      "created": "2020-06-01T06:52:50.819201Z",
      "updated": "2020-06-11T08:31:13.764676Z",
      "patient": 401,
      "division": 11070,
      "provider": null,
      "division_service_block": 3555
    },
    {
      "id": 84,
      "patient_first_name": "สุพัตรา",
      "patient_last_name": "วันเพ็ญ",
      "division_name": "ทันตกรรม",
      "display_info": {
        "id": 3542,
        "division_name": "ทันตกรรม",
        "provider_name": "ศศธร อาจสูงเนิน",
        "provider_type_category": "covid",
        "employee": 44944,
        "employee_type": "DOCTOR",
        "start_datetime": "01/06/2563T08:00:00+0700",
        "end_datetime": "01/06/2563T16:00:00+0700",
        "start_serial": 119716,
        "end_serial": 119748,
        "status": 3,
        "capacity": 12,
        "division": 11070,
        "provider": 52
      },
      "estimate_service_at": null,
      "description": "นัดปรึกษาแพทย์ออนไลน์",
      "seq": -1,
      "status": 1,
      "estimated_at": null,
      "created": "2020-06-01T08:54:32.277450Z",
      "updated": "2020-06-01T08:55:03.884415Z",
      "patient": 234,
      "division": 11070,
      "provider": null,
      "division_service_block": 3542
    },
  ]
}

export default React.memo(injectIntl(VCAppointmentView));

