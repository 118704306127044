import React from "react";
import { Segment, Form } from "semantic-ui-react";
import PropTypes from "prop-types";

const PatientIdentification = (props: any) => {
  return (
    <Segment>
      <Form>
        <Form.Group inline>
          <Form.Button circular size="mini" icon="user" />
          <Form.Input
            label="HN"
            readOnly
            width={3}
            value={props.patientInfo?.hn ? props.patientInfo.hn : ""}
          />
          <Form.Input
            label="ชื่อผู้ป่วย"
            readOnly
            width={6}
            value={
              props.patientInfo?.full_name_th
                ? props.patientInfo.full_name_th
                : ""
            }
          />
          <Form.Input
            label="เพศ"
            readOnly
            width={2}
            value={props.patientInfo?.gender ? props.patientInfo.gender : ""}
          />
          <Form.Input
            label="เกิด"
            readOnly
            width={3}
            value={
              props.patientInfo?.birthdate ? props.patientInfo.birthdate : ""
            }
          />
          <Form.Input
            label="อายุ"
            readOnly
            width={2}
            value={props.patientInfo?.age ? props.patientInfo.age : ""}
          />
        </Form.Group>
      </Form>
    </Segment>
  );
};

PatientIdentification.defaultProps = {
  patientInfo: {},
};

PatientIdentification.propTypes = {
  patientInfo: PropTypes.object,
};

export default React.memo(PatientIdentification);
