import { waiting } from "./../react-lib/apps/ER/StandingOrderHandler";
import WasmController from "../react-lib/frameworks/WasmController";
import moment from "moment";
// @ts-ignore
import Cookies from "js-cookie";

// APIs
import RegisterAPI from "../issara-sdk/apis/RegisterAPI_users";
import RequestToken from "../issara-sdk/apis/RequestTokenAPI_users";
import UserProfileAPI from "../issara-sdk/apis/UserProfileAPI_users";
import UserProfileAPIM from "../issara-sdk/apis/UserProfileAPI_usersM";
import MyProxyPatientView from "../issara-sdk/apis/MyProxyPatientView_apps_PRX";
import EmailAuthSendLoginView from "../issara-sdk/apis/EmailAuthSendLoginViewM";
import EmailAuthRegisterView from "../issara-sdk/apis/EmailAuthRegisterViewM";
import ProxyPatientHasDivisionAdminList from "../issara-sdk/apis/ProxyPatientHasDivisionAdminList_apps_PRX";
import CheckActivateView from "../issara-sdk/apis/CheckActivateView_apps_PRX";
import EmailActivateView from "../issara-sdk/apis/EmailActivateView_apps_PRX";
import ResetPasswordAPIView from "../issara-sdk/apis/ResetPasswordAPIView_users";
import CheckAvailableAPIView from "../issara-sdk/apis/CheckAvailableAPIView_usersM";
import ShippingOrderFinish from "../issara-sdk/apis/ShippingOrderFinish_apps_PRX";
import ShippingOrderPerformedList from "../issara-sdk/apis/ShippingOrderPerformedList_apps_PRX";
import AvailableVaccineDivisionServiceBlockSerializer from "../issara-sdk/apis/AvailableVaccineDivisionServiceBlockList_apps_PRX";
import SelectDivisionServiceBlock from "../issara-sdk/apis/SelectDivisionServiceBlockView_apps_PRX";
import DivisionScheduleTimeList from "issara-sdk/apis/DivisionScheduleTimeList_apps_QUE";
import DoctorDivisionServiceBlockSchedule from "issara-sdk/apis/DoctorDivisionServiceBlockSchedule_apps_QUE";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import DoctorList from "issara-sdk/apis/DoctorList_core";
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import ProxyPatientDetail from "issara-sdk/apis/ProxyPatientDetail_apps_PRX";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import ProxyPatientEmergencyContactView from "issara-sdk/apis/ProxyPatientEmergencyContactView_apps_PRX";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import MobileDeviceSignOutAPI from "issara-sdk/apis/MobileDeviceSignOutAPI_users"

// @ts-ignore
import Fingerprint2 from "fingerprintjs2";
import {
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
} from "../react-lib/apps/IsHealth/Common/push-notifications";

// Utils
import {
  dateToStringWithoutTimeBE,
  formatADtoBEString,
  formatDateToYYYYMMDD,
} from "../react-lib/utils/dateUtils";
// Utils
import { adToBe } from "../react-lib/utils";
import CONFIG from "../config/config";

export type State = {
  theme?: string;
  projectName?: string;
  device_id?: string | number;
  subscription?: any;
  apiToken?: string;
  userId?: string | number;
  loggedin?: boolean;
  language?: "en-US" | "th-TH";
  login?: boolean;
  profile?: boolean;
  openVideoCallModal?: false;
  videoCallRoom?: "";

  // Data
  inputErrorLogin?: {
    username: boolean;
    password: boolean;
    message?: string;
  };
  viewIndexRegister?: number;
  registerInfo?: Partial<{
    username: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    birthdate: string;
    hn: string;
    hidePassword: boolean;
    hideConfirmPassword: boolean;
    error: Partial<{
      username: any;
      password: any;
      confirmPassword: any;
      firstName: any;
      lastName: any;
      birthdate: any;
      hn: any;
      message: string;
      viewIndex?: number;
    }>;
  }>;
  inputEmail?: string;
  confirmShipping?: any;
  shippingList?: any;
  getdivision?: any;
  setQRData?: any;
  divisionServiceBlockList?: any;
  divisionServiceBlockConfirm?: any;
  inputEmailError?: boolean;
  signInByEmailError?: boolean;
  firstRegistration?: boolean;
  inputEmailRequest?: string;
  openModCheckEmail?: boolean;
  changePasswordData?: {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    error: {
      currentPassword: any;
      newPassword: any;
      confirmNewPassword: any;
      message: any;
    };
  };
  requestTokenDetail?: { [_: string]: any };
  userProfile?: { [_: string]: any };
  openDiagRuleSearch?: boolean;
  slideRouteRemember?: any[];
  slideDuration?: number;
  previousPathname?: string;
  disabledScrollIntoView?: boolean;
  openModVerify?: {
    type?: "check_email" | "not_available" | "staff" | "no_hn"; //กรุณาตรวจสอบอีเมล, ไม่สามารถใช้งาน HN นี้ได้, กรุณาติดต่อเจ้าหน้าที่, ยังไม่ระบุ HN
    open?: boolean;
  };
  isMount?: boolean;
  // Loading
  loadingMainTransform?: boolean;
  // Error
  errorMessage?: any;
  // Success
  successMessage?: any;
  modMessageType?:
    | "SEND_TO_EMAIL"
    | "REGIS_KYC"
    | "RESET_PASSWORD"
    | {
        type: "SEND_TO_EMAIL" | "REGIS_KYC" | "RESET_PASSWORD";
        message: any;
      }
    | null;
  initialInnerScreen?: {
    height: number;
    width: number;
  };
  setDevice?: Partial<{
    device_id: number;
    device_type: string;
    device_name: string;
    device_token: string;
    device_api_version: string;
    application: string;
  }> | null;
  // ChatList
  chatSearchValue?: string;
  chatLoading?: boolean;
  channelList?: any[];
  chatDivision?: number;
  patientData?: any;
  chatName?: string;
  backToApp?: boolean;
  targetForm?: any;
  targetDiagRule?: any;
  openChatBox?: boolean;
  openSettingMod?: boolean;
  openLogoutMod?: boolean;
  profileStepperIndex?: number;
  openAllergyProfile?: boolean;
  openDeleteMod?: boolean;
  deviceId?: string;
  userSubscription?: any;
  appointmentData?: any;
  divisionList?: any;
  doctorList?: any;
  patientAppointmentList?: any;
  timeDivision?: any[];
  createAppointment?: {
    divisionScheduleTimeList?: any;
    doctorDivisionServiceBlockSchedule?: any;
    userProfile?: any;
  };
  userProfileData?: any;
};

export const StateInitial: State = {
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  // language: "th-TH",

  // Data
  inputErrorLogin: {
    username: false,
    password: false,
    message: "",
  },
  viewIndexRegister: 0,
  registerInfo: {
    username: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    birthdate: "",
    hn: "",
    hidePassword: true,
    hideConfirmPassword: true,
    error: {
      username: null,
      password: null,
      confirmPassword: null,
      firstName: null,
      lastName: null,
      birthdate: null,
      hn: null,
      message: "",
      viewIndex: 0,
    },
  },
  inputEmailError: false,
  signInByEmailError: false,
  firstRegistration: false,
  inputEmailRequest: "",
  openModCheckEmail: false,
  changePasswordData: {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    error: {
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      message: "",
    },
  },
  requestTokenDetail: {},
  userProfile: {},
  openDiagRuleSearch: false,
  slideRouteRemember: [],
  openModVerify: {},
  initialInnerScreen: {
    height: window.innerHeight,
    width: window.innerWidth,
  },
  setDevice: null,
  isMount: false,
  // Loading
  loadingMainTransform: false,
  // Error
  errorMessage: null,
  // Success
  successMessage: null,
  modMessageType: null,
  slideDuration: 225,
  // ChatList
  chatSearchValue: "",
  chatLoading: false,
  channelList: [],
  chatDivision: 0,
  patientData: {},
  chatName: "Unknown",
  backToApp: false,
  openChatBox: false,
  openSettingMod: false,
  openLogoutMod: false,
  profileStepperIndex: 0,
  openAllergyProfile: false,
  openDeleteMod: false,
  appointmentData: {},
  divisionList: [],
  doctorList: [],
  patientAppointmentList: {},
  timeDivision: [],
  createAppointment: {
    divisionScheduleTimeList: [],
    doctorDivisionServiceBlockSchedule: [],
    userProfile: "",
  },
  userProfileData: {},
};

export type Event =
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  // Setter
  | { message: "HandleSetViewIndexRegister"; params: { index: number } }
  | {
      message: "HandleSetValueRegisterInfo";
      params: { key: string; value: any };
    }
  | { message: "HandleSetInputEmail"; params: { value: any } }
  | { message: "HandleSetInputEmailRequest"; params: { value: any } }
  | { message: "HandleSetOpenModCheckEmail"; params: { value: boolean } }
  | {
      message: "HandleSetValueChangePassword";
      params: { key: string; value: any };
    }
  | {
      message: "HandleSetOpenModVerify";
      params: {
        type?: "check_email" | "not_available" | "staff";
        open?: boolean;
      };
    }
  | {
      message: "HandleNextViewRegister";
      params: {
        current: "username" | "password" | "hn" | "info";
        next: string;
        history: any;
        data?: any;
        onSuccess?: (data: any) => any;
      };
    }
  | {
      message: "HandleSetInitialInnerScreen";
      params: { height: number; width: number };
    }
  // Sign
  | {
      message: "HandleSignIn";
      params: {
        username: string;
        password: string;
        deviceId?: any;
        userSubscription?: any;
        history: any;
      };
    }
  | { message: "HandleSignOut"; params: {} }
  | { message: "HandleSignInByEmail"; params: { history: any } }
  | { message: "HandleEmailRequest"; params: { email: string } }
  | {
      message: "HandleConfirmChangePassword";
      params: { history: any; data?: any };
    }
  // GET
  | { message: "GetPatientDetail"; params: {} }
  // Update
  | {
      message: "UpdatePatientProfile";
      params: { history: any; current: "hn" | "info" };
    }
  // Handle
  | {
      message: "HandleGoBackSignInEmail";
      params: { index: number; search?: string };
    }
  | {
      message: "HandleGoBackVerify";
      params: { history: any };
    }
  | { message: "HandleCloseMessage"; params: {} }
  | {
      message: "HandleRouteChange";
      params: {
        action: "PUSH" | "POP" | "REPLACE";
        currentPathname: string;
        nextPathname: string;
        location: any;
        history: any;
      };
    }
  | {
      message: "HandleShippingOrder";
      params: { encounter: number; shipping: number };
    }
  | {
      message: "HandleShippingOderlist";
      params: { patient: number };
    }
  | {
      message: "HandleSelectDivisionServiceBlock";
      params: { division: number; shipping: number };
    }
  | {
      message: "HandlePostDivisionServiceBlock";
      params: {};
    }
  | { message: "HandleVerifyByEmail"; params: { hn: string } }
  | { message: "HandleGoToEditProfile"; params: { history: any } }
  | { message: "HandleGoToEditHN"; params: { history: any } }
  | { message: "HandleOpenLinkEKYC"; params: {} }
  | { message: "HandleDivisionScheduleTimeList"; params: {} }
  | { message: "HandleDoctorDivisionServiceBlockSchedule"; params: {} }
  | { message: "HandleGetTimeSlotByDate"; params: { selectDate?: string } }
  | { message: "HandleDivisionList"; params: {} }
  | { message: "HandleDoctorList"; params: {} }
  | { message: "HandleGetUserProfile"; params: {} }
  | { meseage: "HandleCreateAppointment"; params: {} }
  | { meseage: "HandleGetAppointment"; params: {} }
  | { meseage: "HandleUpdateAppointment"; params: {} }
  | { meseage: "HandleUpdateProfileImage"; params: {} };

export type Data = {};

export const DataInitial = {};

const MOR_COMPANY = "mor.company";

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {
  setTimeout(() => {
    controller.setState({ isMount: true });
  }, 500);
};

export const DidUpdate: Handler = async (controller, params) => {};

/* TODO ------------------------- API ------------------------ */
export const PostRegisterAPI: Handler = async (controller, params) => {
  const [res, error] = await RegisterAPI.post({
    data: params,
  });
  return [res, error];
};

export const HandlePostDivisionServiceBlock: Handler = async (
  controller,
  params
) => {
  const { apiToken } = controller.getState();

  const [res, error] = await SelectDivisionServiceBlock.post({
    apiToken: apiToken,
    data: {
      division_service_block: params.division,
      shipping_order: params.shipping,
    },
  });

  if (error) {
    controller.setState({
      errorMessage: error,
    });
    controller.setState({ divisionServiceBlockConfirm: null });
    return;
  }

  if (res) {
    if (res.patient_appointment !== null) {
      controller.setState({ divisionServiceBlockConfirm: res });
      return;
    } else {
      controller.setState({ divisionServiceBlockConfirm: null });
      return;
    }
  }
};

export const HandleSelectDivisionServiceBlock: Handler = async (
  controller,
  params
) => {
  const { apiToken } = controller.getState();
  const [res, error] =
    await AvailableVaccineDivisionServiceBlockSerializer.list({
      apiToken: apiToken,
    });

  if (error) {
    controller.setState({
      errorMessage: error,
    });
    return;
  }

  if (res) {
    controller.setState({ divisionServiceBlockList: res });
    return;
  }
};

export const HandleShippingOrder: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const [res, error] = await ShippingOrderFinish.post({
    apiToken: apiToken,
    data: {
      encounter: params.encounter,
      shipping: params.shipping,
    },
  });
  if (error) {
    controller.setState({
      errorMessage: error,
    });
    controller.setState({ confirmShipping: error });
    return;
  }
  if (res) {
    controller.setState({ confirmShipping: res });
    return;
  }
};

export const HandleShippingOderlist: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const [res, error] = await ShippingOrderPerformedList.list({
    apiToken: apiToken,
    params: params,
  });

  if (error) {
    controller.setState({
      errorMessage: error,
    });
    return;
  }

  if (res) {
    controller.setState({ shippingList: res.items });
    return;
  } else {
    controller.setState({ shippingList: null });
    return;
  }
};

export const PostRequestTokenAPI: Handler = async (controller, params) => {
  const { setDevice } = controller.getState();
  let deviceDetail: any = "{}";

  if (typeof window.MobNative !== "undefined") {
    deviceDetail = window.MobNative?.getDevice?.();
  } else if (typeof window.iosNative !== "undefined") {
    if (setDevice?.device_token) {
      deviceDetail = JSON.stringify(setDevice || {});
    } else {
      deviceDetail = window.iosNative?.getDevice?.();
      return [{ waiting_set_device: true }, null];
    }
  }

  if (setDevice?.device_token) {
    controller.setState({ setDevice: null });
  }

  const device = JSON.parse(deviceDetail || "{}");

  const [res, error] = await RequestToken.post({
    data: {
      device_api_version: "20211219",
      application: device?.application || "ishealth",
      ...params,
      ...(device || {}),
    },
  });
  return [res, error];
};

export const GetUserProfile: Handler = async (controller, params) => {
  const [res, error] = await UserProfileAPI.retrieve({
    apiToken: params.apiToken,
  });

  return [res, error];
};

export const PostEmailAuthSendLogin: Handler = async (controller, params) => {
  const [res, error] = await EmailAuthSendLoginView.post({
    data: {
      email: params.email,
      callback: `${CONFIG.HOST_EMAIL_LOGIN}/email-callback/`,
    },
  });

  return [res, error];
};

/* TODO ----------------------- Setter ----------------------- */
export const HandleSetViewIndexRegister: Handler = (controller, params) => {
  if (params.index >= 0) {
    controller.setState({ viewIndexRegister: params.index });
  } else {
    clearRegisterInfo(controller);
    controller.setState({ firstRegistration: false });
  }
};

export const HandleSetValueRegisterInfo: Handler = (controller, params) => {
  const { registerInfo } = controller.getState();
  const { key, value } = params;

  if (registerInfo) {
    (registerInfo as any)[key] = value;
  }

  controller.setState({ registerInfo: { ...(registerInfo || ({} as any)) } });
};

export const HandleSetValueChangePassword: Handler = (controller, params) => {
  const { changePasswordData } = controller.getState();
  const { key, value } = params;

  if (changePasswordData) {
    (changePasswordData as any)[key] = value;
  }

  controller.setState({
    changePasswordData: { ...(changePasswordData || ({} as any)) },
  });
};

export const HandleSetInputEmail: Handler = (controller, params) => {
  controller.setState({ inputEmail: params.value });
};

export const HandleSetInputEmailRequest: Handler = (controller, params) => {
  controller.setState({ inputEmailRequest: params.value });
};

export const HandleSetOpenModCheckEmail: Handler = (controller, params) => {
  controller.setState({ openModCheckEmail: params.value });
};

export const HandleSetOpenModVerify: Handler = (controller, params) => {
  controller.setState({ openModVerify: params });
};

export const HandleSetInitialInnerScreen: Handler = (controller, params) => {
  controller.setState({ initialInnerScreen: params });
};

export const HandleNextViewRegister: Handler = async (
  controller,
  params: Extract<Event, { message: "HandleNextViewRegister" }>["params"]
) => {
  const { viewIndexRegister, registerInfo } = controller.getState();

  const { current, next, history, onSuccess } = params;

  if (!registerInfo) {
    return;
  }

  let isError = false;
  const required = {
    username: {
      input: ["username"],
      check: {
        prefix: ["A-Z", "a-z", "0-9"],
        minLength: 6,
        message:
          "ชื่อผู้ใช้ที่ท่านกำหนด ไม่ตรงตามเงื่อนไข<br/>กรุณากำหนดใหม่อีกครั้ง",
      },
    },
    password: {
      input: ["password", "confirmPassword"],
      check: {
        prefix: ["A-Z", "a-z", "0-9"],
        minLength: 8,
        message:
          "รหัสผ่านที่ท่านกำหนด ไม่ตรงตามเงื่อนไข<br/>กรุณากำหนดใหม่อีกครั้ง",
      },
    },
    info: {
      input: ["firstName", "lastName", "birthdate"],
      check: { prefix: [] },
    },
    hn: {
      input: ["hn"],
      check: {
        prefix: ["0-9"],
        length: 7,
      },
    },
  };

  registerInfo.error = {} as any;
  required[current].input.map((key: string) => {
    const value: string = (registerInfo as any)[key];
    const check = required[current].check
      ? checkValidInput({ ...required[current].check, text: value })
      : { valid: true };

    if (!value || !check.valid) {
      (registerInfo as any)["error"][key] = true;
      isError = true;
      if (registerInfo.error) {
        if (check.message) {
          registerInfo.error.message = check.message;
        }
        registerInfo.error.viewIndex = viewIndexRegister;
      }
    }

    if (
      key === "birthdate" &&
      !checkValidBirthdate(value) &&
      registerInfo.error
    ) {
      (registerInfo as any)["error"][key] = true;
      registerInfo.error.message = "กรุณาระบุวันเกิดให้ถูกต้อง";
      registerInfo.error.viewIndex = viewIndexRegister;
      isError = true;
    }
  });

  if (current === "password" && !registerInfo?.error?.password) {
    const username = registerInfo?.username
      ?.replace(/\./g, "\\.")
      ?.replace(/\-/g, "\\-");
    const regex = new RegExp(`[${username || ""}]+`, "g");
    const comparePassword =
      registerInfo?.password !== registerInfo?.confirmPassword;
    const intersectUsername =
      (registerInfo?.password?.length || 0) -
        (registerInfo?.password?.match(regex)?.[0]?.length || 0) <=
      0;

    if (comparePassword && registerInfo.error) {
      registerInfo.error.message = "รหัสผ่านไม่ตรงกัน";
      registerInfo.error.viewIndex = viewIndexRegister;
      isError = true;
    } else if (intersectUsername && registerInfo.error) {
      registerInfo.error.message =
        "รหัสผ่านนี้คล้ายกับ ชื่อผู้ใช้งาน มากเกินไป";
      registerInfo.error.viewIndex = viewIndexRegister;
      isError = true;
    }
  }
  console.log(isError);

  if (isError) {
    controller.setState({ registerInfo: { ...registerInfo } });
    return;
  }

  switch (current) {
    case "username":
      HandleUsernamePageManage(controller, { history, next });
      break;
    case "password":
      HandlePasswordPageManage(controller, { history, next });
      break;
    case "info":
      HandleInfoPageManage(controller, { history, next, onSuccess });
      break;
    case "hn":
      HandleHnPageManage(controller, { history, next, onSuccess });
      break;
    default:
      history.push(`${history.location.pathname}?screen=${next}`);
      controller.setState({ viewIndexRegister: (viewIndexRegister || 0) + 1 });
      break;
  }
};
const HandleUsernamePageManage: Handler = async (
  controller,
  params: { history: any; next: string }
) => {
  controller.setState({ loadingMainTransform: true });
  const { registerInfo, viewIndexRegister } = controller.getState();
  const { history, next } = params;

  if (!registerInfo) {
    return;
  }

  const [res, error] = await CheckAvailableAPIView.post({
    data: {
      username: window.btoa(`${registerInfo?.username}_${MOR_COMPANY}`),
    },
  });

  controller.setState({ loadingMainTransform: false });

  if (error) {
    controller.setState({ errorMessage: error });
  }

  if (!res.available) {
    registerInfo.error = {};
    registerInfo.error.message = "USERNAME_AVAILABLE";
    registerInfo.error.viewIndex = viewIndexRegister;
    controller.setState({ registerInfo: { ...registerInfo } });
  } else {
    history.push(`${history.location.pathname}?screen=${next}`);
    controller.setState({ viewIndexRegister: (viewIndexRegister || 0) + 1 });
  }
};

const HandlePasswordPageManage: Handler = async (
  controller,
  params: { history: any; next: string }
) => {
  controller.setState({ loadingMainTransform: true });
  const { registerInfo, viewIndexRegister } = controller.getState();
  const { history, next } = params;

  if (!registerInfo) {
    return;
  }

  let { username, password, confirmPassword } = registerInfo;
  username = `${username}@${MOR_COMPANY}`;

  // register
  const [res, error] = await PostRegisterAPI(controller, {
    confirm_password: confirmPassword,
    email: username,
    password: password,
  });

  const login = { username, password };
  let [resToken, errToken]: [any, any] = [null, null];

  if (error) {
    // ถ้ามีชื่อผู้ใช้นี้อยู่แล้ว
    if ("email" in error) {
      // get token เพื่อใช้ update profile
      [resToken, errToken] = await PostRequestTokenAPI(controller, login);
      if (resToken?.waiting_set_device) {
        return;
      }
    } else {
      controller.setState({
        loadingMainTransform: false,
        errorMessage: error,
      });
      return;
    }
  } else {
    // get token เพื่อใช้ update profile
    [resToken, errToken] = await PostRequestTokenAPI(controller, login);
    if (resToken?.waiting_set_device) {
      return;
    }
  }

  if (errToken) {
    controller.setState({
      loadingMainTransform: false,
      errorMessage: errToken,
    });
    return;
  }

  // get user profile
  const [resProfile, errProfile] = await GetUserProfile(controller, {
    apiToken: resToken?.token,
  });

  // if (!resProfile?.first_name || !resProfile.last_name || !resProfile.dob) {
  registerInfo.firstName = resProfile.first_name;
  registerInfo.lastName = resProfile.last_name;
  registerInfo.birthdate = adToBe(resProfile.dob, "YYYY-MM-DD");
  registerInfo.hn = JSON.parse(resProfile?.extra || "{}")?.hn || "";
  controller.setState({ firstRegistration: true });
  // } else {
  // registerInfo.hn = JSON.parse(resProfile?.extra || "{}")?.hn || "";
  // controller.setState({ firstRegistration: false });
  // }

  controller.setState({
    loadingMainTransform: false,
    requestTokenDetail: resToken || {},
    registerInfo: { ...registerInfo },
    userProfile: resProfile || {},
  });
  history.push(`${history.location.pathname}?screen=${next}`);
  controller.setState({ viewIndexRegister: (viewIndexRegister || 0) + 1 });
};

const HandleInfoPageManage: Handler = async (
  controller,
  params: { history: any; next: string; onSuccess?: (data: any) => any }
) => {
  const {
    registerInfo,
    viewIndexRegister,
    signInByEmailError,
    requestTokenDetail,
  } = controller.getState();
  const { history, next } = params;

  if (!registerInfo) {
    return;
  }
  // หากเป็น flow ลงทะเบียนปกติ ไม่ไช่เป็นการลงทะเบียนผ่าน email
  if (!signInByEmailError) {
    controller.setState({ loadingMainTransform: true });

    // อัพเดตข้อมูลผู้ใช้
    const [resProfile, errorProfile] = await UserProfileAPI.patch({
      apiToken: requestTokenDetail?.token,
      data: {
        first_name: registerInfo.firstName,
        last_name: registerInfo.lastName,
        dob: formatDateToYYYYMMDD(registerInfo.birthdate, "-", true),
      },
    });
    controller.setState({ loadingMainTransform: false });

    if (errorProfile) {
      controller.setState({ errorMessage: errorProfile });
      return;
    }
    if (params.onSuccess) {
      params.onSuccess?.(resProfile);
      return;
    }
  }
  history.push(`${history.location.pathname}?screen=${next}`);
  controller.setState({ viewIndexRegister: (viewIndexRegister || 0) + 1 });
};

const HandleHnPageManage: Handler = async (
  controller,
  params: { history: any; next: string; onSuccess?: (data: any) => any }
) => {
  const { registerInfo, signInByEmailError, requestTokenDetail, inputEmail } =
    controller.getState();
  const { history, next } = params;

  if (!registerInfo) {
    return;
  }
  // หากเป็น flow ลงทะเบียนปกติ ไม่ไช่เป็นการลงทะเบียนผ่าน email
  if (!signInByEmailError) {
    controller.setState({ loadingMainTransform: true });
    // const [resDivision, errDivision] =
    //   await ProxyPatientHasDivisionAdminList.list({
    //     apiToken: requestTokenDetail?.token,
    //   });
    // const findHn = resDivision?.items?.find(
    //   (item: any) => item.hn === registerInfo.hn
    // );

    // if ((findHn && findHn?.proxy_patient !== userProfile?.patient)) {
    //   controller.setState({
    //     loadingMainTransform: false,
    //     errorMessage: "HN_NOT_AVAILABLE",
    //   });
    //   return;
    // }

    // อัพเดต hn
    const [res, error] = await UserProfileAPI.patch({
      apiToken: requestTokenDetail?.token,
      data: {
        extra: JSON.stringify({
          hn: registerInfo?.hn,
        }),
      },
    });

    controller.setState({
      loadingMainTransform: false,
    });

    if (error) {
      controller.setState({
        errorMessage: error,
      });
      return;
    }

    if (params.onSuccess) {
      params.onSuccess?.(res);
      return;
    }
    // get user profile
    // const [resProfile, errProfile] = await GetUserProfile(controller, {
    //   apiToken: requestTokenDetail?.token,
    // });
    controller.setState({
      userProfile: res,
    });

    history.push(`/tuh-transform/verify-identity`);
  } else {
    // หากเป็น flow ลงทะเบียนผ่าน email
    controller.setState({ loadingMainTransform: true });
    const [res, error] = await EmailAuthRegisterView.post({
      data: {
        email: inputEmail,
        first_name: registerInfo.firstName,
        last_name: registerInfo.lastName,
        dob: formatDateToYYYYMMDD(registerInfo.birthdate, "-", true),
        hn: registerInfo.hn,
        mode: "tuh",
      },
    });

    controller.setState({ loadingMainTransform: false });

    if (res?.detail === "created") {
      const [res, error] = await PostEmailAuthSendLogin(controller, {
        email: inputEmail,
      });
      controller.setState({
        modMessageType: "SEND_TO_EMAIL",
      });
    } else if (res?.detail?.includes("registered with KYC")) {
      controller.setState({
        modMessageType: "REGIS_KYC",
      });
    } else {
      controller.setState({
        errorMessage: error || res,
      });
    }
  }
};

export const HandleConfirmChangePassword: Handler = (controller, params) => {
  const { changePasswordData } = controller.getState();
  const { history } = params;

  if (!changePasswordData) {
    return;
  }

  let isError = false;
  const required = {
    input: ["currentPassword", "newPassword", "confirmNewPassword"],
    check: {
      prefix: ["0-9", "a-z", "A-Z"],
      minLength: 8,
    },
  };

  (changePasswordData as any).error = {};
  required.input.map((key: string) => {
    const value: string = (changePasswordData as any)[key];
    const check =
      required.check && key !== "currentPassword"
        ? checkValidInput({ ...required.check, text: value })
        : { valid: true };
    if (!value || !check.valid) {
      (changePasswordData as any)["error"][key] = true;
      isError = true;
    }
  });

  if (
    changePasswordData?.newPassword !== changePasswordData?.confirmNewPassword
  ) {
    changePasswordData.error.message = "รหัสผ่านไม่ตรงกัน";
    isError = true;
  }

  if (isError) {
    controller.setState({ changePasswordData: { ...changePasswordData } });
    return;
  }

  console.log(history);
};

export const HandleSignInByEmail: Handler = async (controller, params) => {
  const { inputEmail } = controller.getState();
  const { history } = params;

  if (!inputEmail) {
    controller.setState({ inputEmailError: true });
    return;
  }

  controller.setState({ inputEmailError: false, loadingMainTransform: true });
  console.log(inputEmail);

  const [res, error] = await PostEmailAuthSendLogin(controller, {
    email: inputEmail,
  });

  controller.setState({ loadingMainTransform: false });

  if (error) {
    if (error.detail === "not found") {
      controller.setState({ signInByEmailError: true, viewIndexRegister: 0 });
      history.push(`${history.location.pathname}?screen=${"info"}`);
    } else {
      controller.setState({
        errorMessage: error,
      });
    }
  } else {
    controller.setState({
      modMessageType: "SEND_TO_EMAIL",
    });
  }
};

const clearRegisterInfo: Handler = (controller, params) => {
  controller.setState({
    registerInfo: { ...StateInitial.registerInfo, username: "" },
  });
};

const getBrowserName = () => {
  const nAgt = navigator.userAgent;

  // In Opera, the true version is after "Opera" or after "Version"
  if (nAgt.indexOf("Opera") !== -1) {
    return "Opera";
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if (nAgt.indexOf("MSIE") !== -1) {
    return "Microsoft Internet Explorer";
  }
  // In Chrome, the true version is after "Chrome"
  else if (nAgt.indexOf("Chrome") !== -1) {
    return "Chrome";
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if (nAgt.indexOf("Safari") !== -1) {
    return "Safari";
  }
  // In Firefox, the true version is after "Firefox"
  else if (nAgt.indexOf("Firefox") !== -1) {
    return "Firefox";
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else {
    return navigator.appName;
  }
};

const getOSName = () => {
  const nAgt = navigator.userAgent;
  let os = "Unknown OS";
  const clientStrings = [
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows ME", r: /Windows ME/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Linux", r: /(Linux|X11)/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (let id in clientStrings) {
    let cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }
  return os;
};

export const HandleGoBackSignInEmail: Handler = (
  controller,
  params: Extract<Event, { message: "HandleGoBackSignInEmail" }>["params"]
) => {
  const { signInByEmailError } = controller.getState();
  const { index } = params;

  const searchParams = new URLSearchParams(window.location.search);

  if (!signInByEmailError) {
    controller.setState({ inputEmailError: false, inputEmail: "" });
  } else if (signInByEmailError && searchParams.toString() === "") {
    clearRegisterInfo(controller, {});
    controller.setState({ signInByEmailError: false });
  } else if (signInByEmailError) {
    controller.setState({ viewIndexRegister: index });
  }
};

export const HandleEmailRequest: Handler = async (controller, params) => {
  controller.setState({ loadingMainTransform: true });
  const [res, error] = await ResetPasswordAPIView.post({
    data: { email: params.email },
  });

  controller.setState({ loadingMainTransform: false });

  if (error) {
    controller.setState({ errorMessage: error });
  } else {
    controller.setState({
      modMessageType: {
        type: "RESET_PASSWORD",
        message: res.message,
      },
    });
  }
};

export const LoginIssara: Handler = async (controller, params) => {
  console.log("LoginIssara", params);

  let { username, password, deviceId, userSubscription, history } = params;

  controller.setState({ loadingMainTransform: true });

  username = `${username}@${MOR_COMPANY}`;

  // let data: any = { username, password };
  const data = {
    username,
    password,
    device_id: deviceId,
    device_type: "webpush",
    device_name: `${getBrowserName()} on ${getOSName()}`,
    device_token: userSubscription ? JSON.stringify(userSubscription) : "",
    application: "ishealth",
  };

  if (userSubscription) {
    data.device_token = JSON.stringify(userSubscription);
  }

  const [resToken, errToken] = await PostRequestTokenAPI(controller, data);
  if (resToken?.waiting_set_device) {
    return;
  }

  if (errToken) {
    controller.setState({
      loggedin: false,
      inputErrorLogin: {
        username: true,
        password: true,
        message:
          "ชื่อผู้ใช้งานหรือรหัสผ่านของท่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
      },
      loadingMainTransform: false,
    });
    return;
  }

  let [resCheck, errCheck]: any = [null, null, null];

  if (CONFIG.LOGIN_NEED_HN) {
    [resCheck, errCheck] = await CheckActivateView.get({
      apiToken: resToken?.token,
    });
  }

  if (errCheck && errCheck?.detail !== "Please set your HN") {
    controller.setState({
      loadingMainTransform: false,
      errorMessage: errCheck,
    });
    return;
  }

  const [resProfile, errProfile] = await GetUserProfile(controller, {
    apiToken: resToken.token,
  });

  console.log("resProfile: ", resProfile);
  controller.setState({
    userProfile: resProfile || {},
    loadingMainTransform: false,
    inputErrorLogin: { username: false, password: false },
  });

  if (!resCheck?.hn && CONFIG.LOGIN_NEED_HN) {
    controller.setState({
      requestTokenDetail: resToken,
    });
    history.push(`/tuh-transform/verify-identity`);
    console.log("return ");
    return;
  }
  console.log("handleLoginSuccess: ", resToken);

  handleLoginSuccess(controller, { response: resToken, history });

  LoginFirebase(controller, { username: params.username });
};

export const LoginFirebase: Handler = (controller, params) => {
  // console.log(" LoginFirebase")
  controller.functions
    .httpsCallable("createTokenFromUser")(params.username)
    .then((result: any) => {
      // console.log("after createTokenFromUser Token", result.data);
      controller.app
        .auth()
        .signInWithCustomToken(result.data)
        .then((result: any) => {
          console.log("signInWithCustomToken sucess");

          // TODO: ตรงนี้ ถ้าไม่ใช้ firebase แล้ว ต้องย้ายกลับไป function login issara
          controller.setState({ loggedin: true });
        })
        .catch(function (error: any) {
          console.log("SiginWithCustomToken error");

          // TODO: ตรงนี้ ถ้าไม่ใช้ firebase แล้ว ต้องย้ายกลับไป function login issara
          controller.setState({ loggedin: true });
        });
      // console.log(" SiginWithCustomToken !!!")
    })
    .catch((error: any) => {
      console.log(error);
    });
};

const handleLoginSuccess: Handler = async (controller, params) => {
  const { response, history } = params;

  console.log(" handleLoginSuccess ");
  controller.cookies.set("apiToken", response?.token);
  controller.cookies.set("division_id", response?.division);
  controller.apiToken = response?.token;
  controller.cookies.set("userId", response?.user_id);

  if (response?.token && response?.user_id) {
    // IOS
    if (window.iosNative?.storeUserId) {
      console.log("Call window.iosNative?.storeUserId", response?.user_id);
      window.iosNative?.storeUserId(response?.user_id?.toString());
    }
    if (window.iosNative?.storeApiToken) {
      console.log("Call window.iosNative?.storeApiToken", response?.token);
      window.iosNative?.storeApiToken(response?.token?.toString());
    }

    // Android
    if (window.MobNative?.storeUserId) {
      console.log("Call window.MobNative?.storeUserId", response?.user_id);
      window.MobNative?.storeUserId(response?.user_id?.toString());
    }
    if (window.MobNative?.storeApiToken) {
      console.log("Call window.MobNative?.storeApiToken", response?.token);
      window.MobNative?.storeApiToken(response?.token?.toString());
    }
  }

  console.log("controller.setState: ", controller.setState);
  controller.setState(
    {
      login: true,
      apiToken: response.token,
      requestTokenDetail: response,
      userId: response?.user_id,
    },
    () => {
      let state = controller.getState();

      console.log("callback state.apiToken: ", state.apiToken);
      console.log("response.token: ", response.token);
      let oaChannelId = Cookies.get("oaChannelId");

      //Initial Path after Login
      if (response.token && response?.user_id) {
        let formURL = Cookies.get("form");
        if (formURL !== null && formURL !== undefined) {
          Cookies.remove("form", { path: "/" });
          history.replace({ pathname: "/" });
          history.push({
            pathname: formURL,
          });
          console.log("handleLoginSuccess Goto fromURL ?", formURL);
        } else if (oaChannelId) {
          console.log("handleLoginSuccess Goto /OA/ ?", oaChannelId);
          history.push("/OA/" + oaChannelId);
          Cookies.remove("oaChannelId", { path: "/" });
        } else {
          console.log(" handleLoginSuccess push /");
          history.push("/");
        }
      }
    }
  );

  console.log("response.token: ", response.token);
  let oaChannelId = Cookies.get("oaChannelId");

  // Initial Path after Login
  if (response.token && response?.user_id) {
    let formURL = Cookies.get("form");
    if (formURL !== null && formURL !== undefined) {
      Cookies.remove("form", { path: "/" });
      history.replace({ pathname: "/" });
      history.push({
        pathname: formURL,
      });
      console.log("handleLoginSuccess Goto fromURL ?", formURL);
    } else if (oaChannelId) {
      console.log("handleLoginSuccess Goto /OA/ ?", oaChannelId);
      history.push("/OA/" + oaChannelId);
      Cookies.remove("oaChannelId", { path: "/" });
    } else {
      console.log(" handleLoginSuccess push /");
      history.push({
        pathname: "/",
        state: {
          login: true,
          apiToken: response.token,
          requestTokenDetail: response,
          userId: response?.user_id,
        },
      });
    }
  }
};

/* TODO ------------------------- GET ------------------------ */
export const GetPatientDetail: Handler = async (controller, params) => {
  const [res, error] = await MyProxyPatientView.retrieve({
    apiToken: controller.cookies.get("apiToken"),
  });

  controller.setState({ patientData: res || {} });
};

/* TODO ----------------------- UPDATE ----------------------- */
export const UpdatePatientProfile: Handler = async (controller, params) => {
  HandleNextViewRegister(controller, {
    current: params.current,
    history: params.history,
    onSuccess: (data: any) => {
      controller.setState({
        successMessage: "บันทึกสำเร็จ",
        userProfile: data,
      });
    },
  });
};

export const HandleGoBackVerify: Handler = (controller, params) => {
  if (params.history) {
    params.history.push("/tuh-transform/login");
  }
  clearRegisterInfo(controller);
  controller.setState({
    viewIndexRegister: 0,
    userProfile: {},
    requestTokenDetail: {},
  });
};

export const HandleCloseMessage: Handler = (controller, params) => {
  controller.setState({
    errorMessage: null,
    successMessage: null,
    modMessageType: null,
  });
};

export const HandleVerifyByEmail: Handler = async (controller, params) => {
  controller.setState({ loadingMainTransform: true });
  const { requestTokenDetail } = controller.getState();

  const [res, error] = await EmailActivateView.post({
    apiToken: requestTokenDetail?.token,
    data: {},
  });

  controller.setState({ loadingMainTransform: false });

  if (error) {
    if (error?.status === 200) {
      controller.setState({
        openModVerify: { type: "not_available", open: true },
      });
    } else if (error?.detail === "No hn assigned") {
      controller.setState({
        openModVerify: { type: "no_hn", open: true },
      });
    } else {
      controller.setState({ errorMessage: error?.detail });
    }
  } else {
    controller.setState({
      openModVerify: {
        type: "check_email",
        open: true,
      },
    });
  }
};

export const HandleGoToEditProfile: Handler = (controller, params) => {
  const { userProfile } = controller.getState();
  controller.setState({
    registerInfo: {
      firstName: userProfile?.first_name || "",
      lastName: userProfile?.last_name || "",
      birthdate: formatADtoBEString(userProfile?.dob),
    },
  });
  params.history.push("/tuh-transform/edit-patient-profile");
};

export const HandleGoToEditHN: Handler = (controller, params) => {
  const { userProfile } = controller.getState();
  controller.setState({
    registerInfo: {
      hn: JSON.parse(userProfile?.extra || "{}")?.hn || "",
    },
  });
  params.history.push("/tuh-transform/edit-patient-hn");
};

export const HandleOpenLinkEKYC: Handler = (controller, params) => {
  const url = "https://ekyc.lbsconsultant.com:8080/tu-ekyc/";

  if (typeof window.MobNative !== "undefined") {
    window.MobNative?.openExternalBrowser?.(url);
  } else if (typeof window.iosNative !== "undefined") {
    window.iosNative?.openExternalBrowser?.(url);
  } else {
    window.open(url, "noopener");
  }
};

export const HandleDivisionScheduleTimeList: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  const { apiToken } = controller.getState();

  const [r, e, n] = await DivisionScheduleTimeList.list({
    params: {
      division: params?.divisionID,
      selected_date: params?.selectDate,
    },
    apiToken: apiToken,
  });

  const [res, error] = await UserProfileAPI.retrieve({
    apiToken: apiToken,
  });

  controller.setState({
    createAppointment: {
      ...state?.createAppointment,
      divisionScheduleTimeList: r?.items,
      userProfile: res,
    },
    appointmentData: {
      ...state.appointmentData,
      personPhone: res?.ecp_phone_no,
      personLastName: res?.ecp_last_name,
      personName: res?.ecp_first_name,
    },
  });
};

export const HandleDoctorDivisionServiceBlockSchedule: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  const { apiToken } = controller.getState();

  const [r, e, n] = await DoctorDivisionServiceBlockSchedule.list({
    params: {
      division: params?.divisionID,
      doctor: params?.doctorID,
      month: params?.month,
      year: params?.year,
      is_telemed: params?.is_telemed,
    },
    apiToken: apiToken,
  });

  controller.setState({
    createAppointment: {
      ...state?.createAppointment,
      doctorDivisionServiceBlockSchedule: r?.items,
    },
  });
};

export const HandleGetTimeSlotByDate: Handler = async (controller, params) => {
  const { apiToken, createAppointment, userProfile, appointmentData } =
    controller.getState();
  const [response, error, network]: any = await PatientAppointmentView.list({
    apiToken: apiToken,
    params: {
      status: 1,
      start_date: params.selectDate
        ? dateToStringWithoutTimeBE(params.selectDate)
        : "",
      end_date: params.selectDate
        ? dateToStringWithoutTimeBE(params.selectDate)
        : "",
    },
  });
  // console.log("HandleGetTimeSlotByDate: ", response)
  let reservedTimeByDSB: any = {};
  response?.items
    ?.filter((item: any) => userProfile?.patient !== item.patient)
    .forEach((item: any) => {
      //  console.log("reservedTimeByDSB: ", reservedTimeByDSB, item.estimated_at_iso)
      if (!(item.division_service_block in reservedTimeByDSB)) {
        reservedTimeByDSB[item.division_service_block] = [
          moment(item.estimated_at_iso).format("HH:mm"),
        ];
      } else {
        reservedTimeByDSB[item.division_service_block].push(
          moment(item.estimated_at_iso).format("HH:mm")
        );
      }
    });
  let reservedTimeByPatient =
    response?.items
      ?.filter(
        (item: any) =>
          userProfile?.patient === item.patient &&
          !(
            appointmentData.division_service_block ===
              item.division_service_block &&
            moment(item.estimated_at_iso).format("HH:mm") === params?.selectTime
          )
      )
      .map((item: any) => moment(item.estimated_at_iso).format("HH:mm")) || [];

  // console.log("reservedTimeByPatient: ", reservedTimeByPatient, "reservedTimeByDSB", reservedTimeByDSB)
  let dataTime: any[] = [];
  let dataTimeAndDoctorDSB: any[] = [];

  createAppointment?.doctorDivisionServiceBlockSchedule?.forEach(
    (item: any) => {
      if (
        moment(item?.start_datetime_iso, "YYYY-MM-DD").format("YYYY-MM-DD") ===
        params.selectDate
      ) {
        //set text for date time
        let textTime = `${moment(item?.start_datetime_iso).format(
          "HH:mm"
        )} - ${moment(item?.end_datetime_iso).format("HH:mm")}`;
        if (dataTime.length === 0) {
          dataTime.push(textTime);
          dataTimeAndDoctorDSB.push({ periodTime: textTime, doctorDSB: [] });
        } else if (dataTime.length !== 0 && !dataTime.includes(textTime)) {
          dataTime.push(textTime);
          dataTimeAndDoctorDSB.push({ periodTime: textTime, doctorDSB: [] });
        }

        let indOfDataTime = dataTime.indexOf(textTime);

        // set selectable time
        let timeData =
          ((parseFloat(moment(item?.end_datetime_iso).format("H")) -
            parseFloat(moment(item?.start_datetime_iso).format("H"))) /
            item?.slot_length) *
          60;
        let timeValue: any[] = [];
        for (let i = 0; i < timeData; i++) {
          let time = moment(item?.start_datetime_iso)
            .add(item?.slot_length * i, "minutes")
            .format("HH:mm");
          if (
            !reservedTimeByDSB?.[item.id]?.includes(time) &&
            !reservedTimeByPatient?.includes(time)
          ) {
            timeValue.push(time);
          }
        }

        dataTimeAndDoctorDSB[indOfDataTime]["doctorDSB"].push({
          ...item,
          timeValue: timeValue,
        });
      }
    }
  );

  controller.setState({
    timeDivision: dataTimeAndDoctorDSB,
  });
};

export const HandleDoctorList: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();

  const [r, e, n] = await DoctorList.list({
    apiToken: apiToken,
    params: {
      ...(params?.search ? { name_code: params.search } : {}),
      ...(params?.isTelemed ? { is_telemed: true } : {}),
    },
  });

  controller.setState({
    doctorList: r?.items,
  });
};

export const HandleDivisionList: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();

  const [r, e, n] = await DivisionList.list({
    params: {
      ...(params?.only_have_dsb ? { only_have_dsb: params.only_have_dsb } : {}),
      // limit: 99999,
      // for_order_div: true,
    },
    apiToken: apiToken,
  });

  controller.setState({
    divisionList: r?.items,
  });
};

export const HandleGetUserProfile: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const [res, error] = await UserProfileAPI.retrieve({
    apiToken: apiToken,
  });

  controller.setState({
    userProfile: res ? res : {},
  });
};

export const HandleCreateAppointment: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  const [r, e, n] = await PatientAppointmentView.create({
    data: {
      division: state.appointmentData?.doctor?.division,
      patient: state.patientData?.id,
      division_service_block: state.appointmentData?.doctor?.id,
      order_note: state.appointmentData?.detail,
      estimated_at_iso: params?.date,
      estimated_duration: state.appointmentData?.doctor?.slot_length,
      repetition_note: "",
    },
    apiToken: apiToken,
  });

  const [response, error] = await ProxyPatientEmergencyContactView.post({
    data: {
      ecp_first_name: state.appointmentData?.personName || "",
      ecp_last_name: state.appointmentData?.personLastName || "",
      ecp_phone_no: state.appointmentData?.personPhone || "",
    },
    apiToken: apiToken,
  });

  if (r && response) {
    controller.setState({
      successMessage: "บันทึกสำเร็จ",
    });
  } else {
    controller.setState({
      errorMessage: "บันทึกไม่สำเร็จ",
    });
  }
};

export const HandleGetAppointment: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  // console.log("HandleGetAppointment: ", params)
  const [r, e, n] = await PatientAppointmentUpdate.retrieve({
    pk: params?.id,
    apiToken: apiToken,
  });

  if (r && r.display_info?.provider_object_id) {
    const doctorInfo = await DoctorDetail.retrieve({
      pk: r.display_info.provider_object_id,
      apiToken: apiToken,
    });

    await HandleDoctorDivisionServiceBlockSchedule(controller, {
      doctorID: r?.display_info?.provider_object_id,
      month: moment(r.estimated_at_iso).format("M"),
      year: moment(r.estimated_at_iso).format("YYYY"),
      is_telemed: r.is_telemed,
    });

    return controller.setState({
      appointmentData: {
        ...state.appointmentData,
        time: moment(r.estimated_at_iso).format("HH:mm"),
        selectDate: moment(r?.estimated_at_iso).format("YYYY-MM-DD"),
        data: { ...r, display_info: { ...r.display_info, ...doctorInfo?.[0] } },
      },
    });
  }

  // const [response, error] = await ProxyPatientDetail.retrieve({
  //   pk: params?.id,
  //   apiToken: apiToken,
  // });

  // const [res, errors] = await UserProfileAPI.retrieve({
  //   apiToken: apiToken,
  // });

  controller.setState({
    appointmentData: {
      ...state.appointmentData,
      data: r,
    },
  });
};

export const HandleUpdateAppointment: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  const [r, e, n] = await PatientAppointmentUpdate.patch({
    pk: state.appointmentData?.data?.id,
    data: {
      division: state.appointmentData?.doctor?.division,
      patient: state.patientData?.id,
      division_service_block: state.appointmentData?.doctor?.id,
      order_note: state.appointmentData?.data?.order_note,
      estimated_at_iso: params?.date,
      estimated_duration: state.appointmentData?.doctor?.slot_length,
      repetition_note: "",
    },
    apiToken: apiToken,
  });

  const [response, error] = await ProxyPatientEmergencyContactView.post({
    data: {
      ecp_first_name: state.appointmentData?.personName || "",
      ecp_last_name: state.appointmentData?.personLastName || "",
      ecp_phone_no: state.appointmentData?.personPhone || "",
    },
    apiToken: apiToken,
  });

  if (r && response) {
    controller.setState({
      successMessage: "บันทึกสำเร็จ",
    });
  } else {
    controller.setState({
      errorMessage: "บันทึกไม่สำเร็จ",
    });
  }
};

export const HandleCancelAppointment: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  const [r, e, n] = await PatientAppointmentUpdate.patch({
    pk: params?.id,
    data: {
      status: 5,
    },
    apiToken: apiToken,
  });

  if (r) {
    const [response, error, network]: any = await PatientAppointmentView.list({
      apiToken: apiToken,
      params: {
        patient_id: params?.patientId,
        status: params?.viewIndex === 1 ? 8 : null,
      },
    });

    controller.setState({
      patientAppointmentList: response,
    });
  }
};

export const HandlePatientAppointmentView: Handler = async (
  controller,
  params
) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  const [response, error, network]: any = await PatientAppointmentView.list({
    apiToken: apiToken,
    params: {
      patient_id: params?.patientId,
      status: params?.viewIndex === 1 ? 8 : null,
    },
  });

  controller.setState({
    patientAppointmentList: response,
  });
};

export const HandleUpdateProfileImage: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  const [res, error] = await UserProfileAPIM.update({
    apiToken: apiToken,
    data: {
      image: params?.image,
    },
  });

  controller.setState({
    userProfileData: res,
  });
};

const BOTTOM_MENU = [
  "/profile",
  "/chat",
  "/appointment",
  "/login",
  "/shippinglist",
];

const handleSlideRoute = ({
  translate,
  childNodes,
  isDuration,
}: {
  translate: number;
  childNodes: any;
  isDuration: boolean;
}) => {
  const duration = 350;

  const isForward = translate === 100;
  const ceil = isForward ? 0 : 1;
  const floor = isForward ? 1 : 0;

  childNodes[0].style.cssText = `transform: translateX(0%); transition: none;`;
  if (childNodes[1]) {
    childNodes[1].style.cssText = `transform: translateX(0%); transition: none;`;
  }

  setTimeout(() => {
    if (!childNodes[1]) {
      return;
    }

    if (!isDuration) {
      setStopDuration(childNodes, floor, ceil);
      return;
    }

    childNodes[
      ceil
    ].style.cssText = `transition: none; transform: translateX(${translate}%);`;
    childNodes[ceil].classList.add("ceil", isForward ? "t-100" : "t-0");

    childNodes[
      floor
    ].style.cssText = `transition: none; transform: translateX(${
      isForward ? 0 : -20
    }%);`;
    childNodes[floor].classList.add("floor", isForward ? "t-0" : "t-100");

    const delay = 15;

    setTimeout(() => {
      if (!childNodes[1]) {
        clearPosition(childNodes);
        return;
      }

      childNodes[ceil].classList.add("position");
      childNodes[ceil].style.cssText = `transition: transform ${
        duration * 0.9
      }ms ease; transform: translateX(${isForward ? 0 : 100}%);`;

      childNodes[floor].style.transition = `transform ${duration}ms ease`;
      childNodes[floor].style.transform = `translateX(${isForward ? -20 : 0}%)`;

      clearPosition(childNodes);
    }, delay);
  });

  const setStopDuration = (childNodes: any, floor: number, ceil: number) => {
    childNodes[
      floor
    ].style.cssText = `transform: translateX(0%); transition: none;`;
    childNodes[
      ceil
    ].style.cssText = `opacity: 0; transform: translateX(100%); transition: none;`;
    clearPosition(childNodes);
  };

  const clearPosition = (childNodes: any) => {
    const timer = () => {
      if (!childNodes[1]) {
        clearInterval(interval);
        childNodes[0].style.cssText = ``;
        childNodes[0].classList.remove(
          "position",
          "ceil",
          "floor",
          "t-0",
          "t-100"
        );
      }
    };

    var interval = setInterval(timer);
  };
};

const handleSlideRouteRemember: Handler = (
  controller,
  params: Extract<Event, { message: "HandleRouteChange" }>["params"]
) => {
  const { slideRouteRemember } = controller.getState();
  const { currentPathname, nextPathname, action } = params;

  const current: string = getPathname(currentPathname);
  const next: string = getPathname(nextPathname);
  let isDuration = isSlideDuration(currentPathname, nextPathname);
  let cloneArray = [...(slideRouteRemember || [])];

  const slide: any = document.querySelector(".slide-routes.slide");
  const childNodes = slide?.childNodes;

  const backExit = () => {
    handleSlideRoute({ translate: 0, childNodes, isDuration });
  };

  const forwardExit = () => {
    handleSlideRoute({
      translate: 100,
      childNodes,
      isDuration,
    });
  };

  if (["PUSH", "REPLACE"].includes(action)) {
    const nextFindIndex = cloneArray.findIndex((item) => item.key === next);
    const currentFindIndex = cloneArray.findIndex(
      (item) => item.key === current
    );

    if (nextFindIndex !== -1) {
      cloneArray = cloneArray.filter((_, index) => index < nextFindIndex);
    } else if (currentFindIndex !== -1) {
      cloneArray = cloneArray.filter((_, index) => index < currentFindIndex);
    } else {
      cloneArray = [...cloneArray, { key: current }];
    }

    if (
      BOTTOM_MENU.includes(next) ||
      (nextFindIndex !== -1 && !BOTTOM_MENU.includes(current))
    ) {
      backExit();
    } else {
      forwardExit();
    }
  } else if (action === "POP") {
    backExit();
    cloneArray = cloneArray.filter((item: any) => item.key !== next);
  }
  controller.setState({
    slideRouteRemember: cloneArray,
    previousPathname: current,
  });
};
// จัดการ state เมื่อ goback
const handleActionGoBack: Handler = (
  controller,
  params: Extract<Event, { message: "HandleRouteChange" }>["params"]
) => {
  const state = controller.getState();
  const current: string = getPathname(params.currentPathname);
  const next: string = getPathname(params.nextPathname);
  const apiToken = Cookies.get("apiToken");
  console.log("saika ", params, current, next);

  switch (current) {
    case "/register":
      const index = (state.viewIndexRegister || 0) - 1;
      HandleSetViewIndexRegister(controller, { index });
      break;
    case "/sign-in-email":
      const idx = (state.viewIndexRegister || 0) - 1;
      HandleGoBackSignInEmail(controller, {
        index: idx,
        search: params.location.search,
      });
      break;
    case "/verify-identity":
      if (next === "/login") {
        HandleGoBackVerify(controller, {});
      } else if (next === "/register") {
        controller.setState({ viewIndexRegister: 3 });
      }
      break;
    case "/login":
      if (!apiToken) {
        params.history.replace("/login");
      }
      break;
    default:
      break;
  }

  if (
    ((current === "/chat" && next === "/chat") || next === "/login") &&
    apiToken
  ) {
    // Ios
     if (window.iosNative?.dismissWebView) {
      window.iosNative?.dismissWebView();
    }

    // Android
    if (window.MobNative?.dismissWebView) {
      window.MobNative?.dismissWebView();
    }
  }
};

export const HandleRouteChange: Handler = async (
  controller,
  params: Extract<Event, { message: "HandleRouteChange" }>["params"]
) => {
  handleSlideRouteRemember(controller, params);
  if (params.action === "POP") {
    handleActionGoBack(controller, params);
  }
};

// Utils
const checkValidInput = (params: {
  text: string;
  prefix: string[];
  minLength?: number;
  length?: number;
  message?: string;
}): { valid: boolean; message?: any } => {
  const {
    text = "",
    prefix = [],
    minLength = 0,
    length = 0,
    message = "",
  } = params;
  if (minLength && text.length < minLength) {
    return { valid: false, message };
  }

  if (length && text.length !== length) {
    return { valid: false, message };
  }

  let errorKey: string = "";

  const validAll = prefix.every((value) => {
    const regex = new RegExp(`[${value}]`, "g");
    const test = regex.test(text);
    if (!errorKey && !test) {
      errorKey = value;
    }
    return test;
  });

  return { valid: validAll, message };
};

const checkValidBirthdate = (value: string) => {
  const reg = /\d{2}\/\d{2}\/\d{4}/g;

  if (!reg.test(value)) {
    return false;
  }

  const formatDate = value.replace(
    /(\d{2})\/(\d{2})\/(\d{4})/g,
    (...res) => `${+(res?.[3] || 0) - 543}-${res?.[2]}-${res?.[1]}`
  );

  const momentToAdd = (moment: moment.Moment) => {
    return moment.format("YYYY-MM-DD");
  };

  return moment(formatDate).isBetween(
    momentToAdd(moment().add(-130, "years")),
    momentToAdd(moment())
  );
};

export const getPathname = (path: string) => {
  const reg = /(\?.*)|(\/tuh-transform)/g;
  let split: string[] = path.replace(reg, "").split("/") || [];

  try {
    if (!split[split.length - 1]) {
      split.splice(-1, 1);
    }
  } catch (error) {
    split = [];
  }

  path = split
    .map((item, index) => (index >= 2 ? `ID${index - 1}` : item))
    .join("/");
  if (path === "") {
    path = "/Chat";
  }
  return path.toLowerCase();
};

export const isSlideDuration = (
  currentPathname: string,
  nextPathname: string
) => {
  let isDuration = true;
  const current: string = getPathname(currentPathname);
  const next: string = getPathname(nextPathname);

  if (BOTTOM_MENU.includes(current) && BOTTOM_MENU.includes(next)) {
    isDuration = false;
  }

  return isDuration;
};

export const SignOutIssara: Handler = async (controller, params)  => {

  const [res, error] = await MobileDeviceSignOutAPI.get({
    apiToken: Cookies.get("apiToken")
  });

}
