import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dimmer } from "semantic-ui-react";
import CONFIG from "config/config";

export const Main = (props: any) => {
  const history = useHistory();

  const [iframeHeight, setIframeHeight] = useState(480);
  const [iframeWidth, setIframeWidth] = useState(640);
  const [visible, setVisible] = useState(true);
  const [videoCallStartPip, setVideoCallStartPip] = useState(false);

  useEffect(() => {
    setVisible(props.openVideoCallModal);
  }, [props.openVideoCallModal]);

  const handleCloseVideoCall = ({ sendEndMessage = true }) => {
    console.log("MainVideoCall handle close video call");
    // if (sendEndMessage) {
    //   setTimeout(() => {
    //     sendMessage({
    //       contentType: "end_call",
    //       content: "", // `${CONFIG.WEB_RTC}/${props.match.params.chatChannelId}/?name=${name}`
    //     });
    //   }, 1000);
    // }
    
    // props.setProp("openVideoCallModal", false);
    setVisible(false);
    setVideoCallStartPip(false);
    setIframeHeight(1);
    setIframeWidth(1);
    props.onCloseVideoCall();
  };

  const handleIframeResize = () => {
    setTimeout(() => {
      setIframeHeight(window.innerHeight - 100);
      setIframeWidth(window.innerWidth - 10);
      setVisible(true);
    }, 500);
  };

  const handleIframeMessage = (msg: any) => {
    console.log(msg);
    try {
      let jsonMsg = JSON.parse(msg.data);
      if (jsonMsg.message === "end_call") {
        handleCloseVideoCall({ sendEndMessage: false });
      } else if (jsonMsg.message === "start_pip") {
        setTimeout(() => {
          setIframeHeight(1);
          setIframeWidth(1);
          setVideoCallStartPip(true);
          // setVisible(false);
        }, 500);
      } else if (jsonMsg.message === "stop_pip") {
        setVideoCallStartPip(false);
        handleIframeResize();
      }
    } catch (e) {
      console.log("ignore this message");
    }
  };

  const onLoadIfrm = () => {
    window.addEventListener("resize", handleIframeResize);
    window.addEventListener("message", handleIframeMessage);
    if (visible) handleIframeResize();
  };

  return (
    <div>
      {props.openVideoCallModal && (
        <Dimmer
          inverted
          active={visible}
          size="fullscreen"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.0)",
            width: videoCallStartPip ? "0px" : "100%",
            height: videoCallStartPip ? "0px" : "100%",
          }}
          className={{}}
        >
          <iframe
            frameBorder={0}
            onLoad={onLoadIfrm}
            scrolling="no"
            allow="microphone; camera; *;"
            src={`${CONFIG.WEB_RTC}/${props.videoCallRoom}/?name=${props.username}&isPatient=${props.isPatient}`}
            // src={`http://localhost:3001/${props.videoCallRoom}`}
            width={videoCallStartPip ? "0px" : iframeWidth}
            height={videoCallStartPip ? "0px" : iframeHeight}
          />
        </Dimmer>
      )}
    </div>
  );
};
