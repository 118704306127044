const AddTablePreSedationEN = {
  unbreakable: true,
  table: {
    widths: ["20%", "20%", "60%"],
    body: [
      [
        {
          text: "Detail of anesthetic technique",
          fontSize: 15,
          bold: true,
          colSpan: 3,
        },
        {},
        {},
      ],
      [
        {
          text: "General Anesthesia",
          fontSize: 15,
          border: [true, true, true, false],
        },
        {
          text: "Expected Result",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, false],
        },
        {
          text: "Technique",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, true],
        },
        {
          text: "Risks (include but not limitted to)",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "Sedation",
          fontSize: 15,
          border: [true, true, true, false],
        },
        {
          text: "Expected Result",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, false],
        },
        {
          text: "Technique",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, true],
        },
        {
          text: "Risks (include but not limitted to)",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "Spinal and/ or Epidural Analgesia/ Anesthesia",
          fontSize: 15,
          border: [true, true, true, false],
        },
        {
          text: "Expected Result",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, false],
        },
        {
          text: "Technique",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, true],
        },
        {
          text: "Risks (include but bot limitted to)",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "Major/ Minor Nerve Block",
          fontSize: 15,
          border: [true, true, true, false],
        },
        {
          text: "Expected Result",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, false],
        },
        {
          text: "Technique",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, true],
        },
        {
          text: "Risks (include but not limitted to)",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "Monitored anesthesia care",
          fontSize: 15,
          border: [true, true, true, false],
        },
        {
          text: "Expected Result",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, false],
        },
        {
          text: "Technique",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, true],
        },
        {
          text: "Risks (include but not limitted to)",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 15,
          colSpan: 3,
          border: [true, true, true, false],
        },
        {},
        {},
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 15,
          colSpan: 3,
          border: [true, false, true, false],
        },
        {},
        {},
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 15,
          colSpan: 3,
          border: [true, false, true, true],
        },
        {},
        {},
      ],
    ],
  },
};

export default AddTablePreSedationEN;
