import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router";
import EmailLoginManager from "./EmailLoginManager";

import Fingerprint2 from "fingerprintjs2";
import {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription
} from "../push-notifications";
import * as serviceWorker from "../../../../../serviceWorker";

const Callback = props => {

    const manager = new EmailLoginManager()
    const params = new URLSearchParams(useLocation().search)
    const accessToken = params.get('access_token')
    const email = params.get('email')
    let [loading, setLoading] = useState(true)
    let [data, setData] = useState({})
    let [message, setMessage] = useState('')
    
    const requestToken = async () => {
        let _device_id = null;
        let _subscription = null;
        let result:any = await getFingerPrint();
        if (result.device_id && result.subscription) {
          _device_id = result.device_id;
          _subscription = result.subscription;
        }

        try {
            let ret = await manager.getTokenFromAccount(email, accessToken, _subscription, _device_id) // add deviceId, subscription
            setMessage('')
            console.log(ret)
            setData(ret)
            setLoading(false)
            props.onLoginSuccess(ret)
        } catch (e) {
            setMessage(JSON.stringify(e.response.data))
            setLoading(false)
            props.onLoginFailed()
        }
    }

    useEffect(() => {
        requestToken()
    }, [])

    const getFingerPrint = async () => {
        //* #59563 - แก้เพื่อให้สามารถ register ได้ โดยที่ ไม่เจอ serviceWorker ใน browser
        if (!navigator.serviceWorker) {
          return { device_id: "", subscription: "" };
        }

        let promise = new Promise(function(resolve, reject) {
          navigator.serviceWorker
            .getRegistrations()
            .then(registrations => {
              console.log("check service worker", registrations);
              if (registrations.length <= 0) {
                console.log("Force regist service worker again !!!");
                serviceWorker.register();
                registerServiceWorker();
              }
            })
            .then(() => {
              var options = {};
              Fingerprint2.getPromise(options).then((components:any) => {
                var values = components.map(function(component:any) {
                  return component.value;
                });
                var device_id = Fingerprint2.x64hash128(values.join(""), 31);
                console.log("LCB Fingerprint id:", device_id);
                // Get subscription endpoint object
                createNotificationSubscription()
                  .then(subscription => {
                    console.log("LCB Success get notification subscription..");
                    console.log(subscription);
                    resolve({ device_id, subscription });
                  })
                  .catch(err => {
                    console.log("LCB error getSubScriptionObejct");
                    console.error(
                      "Couldn't create the notification subscription",
                      err,
                      "name:",
                      err.name,
                      "message:",
                      err.message,
                      "code:",
                      err.code
                    );
                    reject(err);
                  });
              });
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        });
    
        return promise;
      };

    return (
      <></>
    )
}

export default Callback;