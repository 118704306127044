export default function toPDFMakeData(props: any) {
  return {
    pageOrientation: `portrait`,
    pageSize: `A4`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    content: [
      {
        color: ``,
        decoration: ``,
        alignment: `center`,
        preserveLeadingSpaces: true,
        bold: `true`,
        fontSize: `18`,
        text: `Consent for Narcotic Use In Chronic Non-Cancer Pain`,
        pageBreak: ``,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
      },
      {
        decorationStyle: ``,
        color: ``,
        text: ` `,
        width: `auto`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        decoration: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            bold: false,
            decoration: ``,
            color: ``,
            width: `auto`,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `I (Mr./Mrs./Ms.) `,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            text: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            width: 5,
            color: ``,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                decorationColor: ``,
                width: `auto`,
                bold: false,
                fontSize: 15,
                decorationStyle: ``,
                margin: [25, 0, 0, -15],
                alignment: `left`,
                color: ``,
                text: props.items?.formatPatient,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decoration: ``,
              },
              {
                decoration: ``,
                decorationStyle: ``,
                fontSize: 15,
                bold: false,
                width: `auto`,
                decorationColor: ``,
                pageBreak: ``,
                color: ``,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                text: `.................................................................`,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            text: `I confirm that I have been informed by Dr.     `,
          },
          {
            color: ``,
            width: 5,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                color: ``,
                bold: false,
                text: props.items?.formatDoctor,
                alignment: `center`,
                preserveLeadingSpaces: true,
                decoration: ``,
                decorationColor: ``,
                width: `auto`,
                fontSize: 15,
                decorationStyle: ``,
                pageBreak: ``,
              },
              {
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                width: `auto`,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                fontSize: 15,
                decorationColor: ``,
                text: `.............................................................................................`,
                color: ``,
                bold: false,
                decoration: ``,
              },
            ],
          },
          {
            fontSize: 15,
            text: `that my symptom is`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            bold: false,
            width: `auto`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        decoration: ``,
        pageBreak: ``,
        alignment: `left`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        color: ``,
        text: `chronic non-cancer pain, if untreated. The symptom does not disappear and may lead to more severe pain. `,
        width: `auto`,
        bold: false,
        decorationColor: ``,
      },
      {
        decoration: ``,
        text: `The physician has explained me about the proposed terms, conditions and alternative  I have discussed about the likelihood of success, which depends on my conditions and other factors, as well as possible problems related to recovery.`,
        bold: false,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        fontSize: 15,
        color: ``,
        alignment: `left`,
        width: `auto`,
        decorationColor: ``,
        decorationStyle: ``,
      },
      {
        alignment: `left`,
        width: `auto`,
        decorationColor: ``,
        decorationStyle: ``,
        fontSize: 15,
        color: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        bold: false,
        decoration: ``,
        pageBreak: ``,
      },
      {
        columns: [
          {
            width: `auto`,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            text: `I understand well and agree to receive the pain treatment with narcotics: .........................................................................`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
          },
        ],
      },
      {
        fontSize: 15,
        bold: false,
        width: `auto`,
        alignment: `left`,
        pageBreak: ``,
        decorationStyle: ``,
        color: ``,
        preserveLeadingSpaces: true,
        text: `I acknowledge that I have to follow these terms and conditions strictly and I acknowledge as follow:`,
        decorationColor: ``,
        decoration: ``,
      },
      {
        bold: false,
        color: ``,
        pageBreak: ``,
        alignment: `left`,
        fontSize: 15,
        decoration: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        text: ` `,
        decorationColor: ``,
      },
      {
        decoration: ``,
        preserveLeadingSpaces: true,
        bold: false,
        fontSize: 15,
        text: `1. My treatment goal is improvement of function with my pain. If narcotics injection are needed, my physician will use the appropriate dose to control my pain.`,
        width: `auto`,
        alignment: `left`,
        decorationColor: ``,
        decorationStyle: ``,
        color: ``,
        pageBreak: ``,
      },
      {
        decoration: ``,
        text: ` `,
        pageBreak: ``,
        decorationStyle: ``,
        decorationColor: ``,
        color: ``,
        fontSize: 15,
        alignment: `left`,
        preserveLeadingSpaces: true,
        bold: false,
        width: `auto`,
      },
      {
        pageBreak: ``,
        fontSize: 15,
        bold: false,
        decorationStyle: ``,
        color: ``,
        width: `auto`,
        decoration: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        text: `2. I understand that Sapiens Hospital may not provide “walk-in appointments” for pain treatment with narcotics. If new medical problems arise or acute pain while under care of the pain treatment, I will address those with my attending physician.`,
        alignment: `left`,
      },
      {
        decorationStyle: ``,
        pageBreak: ``,
        fontSize: 15,
        decorationColor: ``,
        color: ``,
        width: `auto`,
        decoration: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        text: ` `,
        bold: false,
      },
      {
        bold: false,
        pageBreak: ``,
        alignment: `left`,
        color: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        width: `auto`,
        decoration: ``,
        text: `3. I understand that the common side effects of narcotic therapy include nausea. constipation, sweating and itchiness of the skin. Drowsiness may occur when starting narcotics therapy or when Increasing the dosage. I agree to refrain from driving a motor vehicle or operating dangerous machinery until such drowsiness disappears.`,
        fontSize: 15,
        decorationStyle: ``,
      },
      {
        bold: false,
        text: ` `,
        width: `auto`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decoration: ``,
        pageBreak: ``,
        decorationColor: ``,
        color: ``,
        decorationStyle: ``,
        alignment: `left`,
      },
      {
        decorationStyle: ``,
        text: `4. I understand that there is a risk that I may become addicted to the narcotic medication I am being prescribed. As such. my physician may require that I have additional tests and/or see a specialist in addiction should a concern about addiction arise during my treatment.`,
        width: `auto`,
        decorationColor: ``,
        bold: false,
        color: ``,
        fontSize: 15,
        decoration: ``,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
      },
      {
        alignment: `left`,
        pageBreak: ``,
        bold: false,
        decorationColor: ``,
        text: ` `,
        width: `auto`,
        decoration: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        color: ``,
        decorationStyle: ``,
      },
      {
        fontSize: 15,
        color: ``,
        decorationStyle: ``,
        decoration: ``,
        pageBreak: ``,
        width: `auto`,
        text: `5. I understand that the use of any mood-modifying substances. such as tranquilizers, sleeping pills, alcohol or illegal drugs (such as cannabis, cocaine, heroin or hallucinogens) can cause adverse effects or interface with narcotic therapy. Therefore ,I agree to retrain from the use of all of these substances without first discussing it with my physician.`,
        decorationColor: ``,
        alignment: `left`,
        bold: false,
        preserveLeadingSpaces: true,
      },
      {
        decorationStyle: ``,
        decoration: ``,
        fontSize: 15,
        pageBreak: ``,
        color: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        text: ` `,
        bold: false,
        decorationColor: ``,
        width: `auto`,
      },
      {
        bold: false,
        fontSize: 15,
        width: `auto`,
        decoration: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        alignment: `left`,
        color: ``,
        text: `6. I hereby agree that my physician has the authority to disclose the prescribing information In my patient file to other health care professionals when it is deemed medically necessary according to the physician’s judgment.`,
        decorationStyle: ``,
        pageBreak: ``,
      },
      {
        decoration: ``,
        decorationStyle: ``,
        fontSize: 15,
        width: `auto`,
        text: `I will do as follows:`,
        pageBreak: `before`,
        bold: false,
        decorationColor: ``,
        color: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
      },
      {
        pageBreak: ``,
        text: ` `,
        alignment: `left`,
        decorationColor: ``,
        decorationStyle: ``,
        bold: false,
        width: `auto`,
        decoration: ``,
        preserveLeadingSpaces: true,
        color: ``,
        fontSize: 15,
      },
      {
        fontSize: 15,
        width: `auto`,
        decorationStyle: ``,
        pageBreak: ``,
        decorationColor: ``,
        text: `1. I will be responsible to keep my appointments, including referrals made for me to see other specialists, such as physiatrists and psychiatrists.`,
        bold: false,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decoration: ``,
        color: ``,
      },
      {
        fontSize: 15,
        preserveLeadingSpaces: true,
        alignment: `left`,
        pageBreak: ``,
        color: ``,
        bold: false,
        decoration: ``,
        width: `auto`,
        decorationStyle: ``,
        text: ` `,
        decorationColor: ``,
      },
      {
        decoration: ``,
        alignment: `left`,
        pageBreak: ``,
        color: ``,
        width: `auto`,
        decorationStyle: ``,
        text: `2. I will disclose  all the medication I take to my physician. Medications and Treatment Plan must be taken strictly as prescribed. I will disclose to my physician for any non-compliance on my part`,
        fontSize: 15,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        bold: false,
      },
      {
        width: `auto`,
        text: ` `,
        color: ``,
        decorationColor: ``,
        pageBreak: ``,
        alignment: `left`,
        fontSize: 15,
        decorationStyle: ``,
        bold: false,
        preserveLeadingSpaces: true,
        decoration: ``,
      },
      {
        color: ``,
        alignment: `left`,
        bold: false,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        pageBreak: ``,
        fontSize: 15,
        decorationStyle: ``,
        text: `3. I have to see only my attending physician for the pain treatment. In case of an emergency that I have to see other physicians. The next visit I have to see my attending physician, Otherwise , I have to be barred from the treatments at Sapiens Hospital.`,
        decoration: ``,
      },
      {
        decoration: ``,
        decorationStyle: ``,
        bold: false,
        text: ` `,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        color: ``,
        pageBreak: ``,
        alignment: `left`,
        fontSize: 15,
        width: `auto`,
      },
      {
        color: ``,
        pageBreak: ``,
        decorationColor: ``,
        text: `4. I will take narcotic pain medication form Sapiens Hospital only at the close and frequency prescribed by my physician, I will not take any pain medication from other sources neither will I hoard nor discard medication.`,
        decoration: ``,
        width: `auto`,
        alignment: `left`,
        bold: false,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
      },
      {
        text: ` `,
        decorationColor: ``,
        bold: false,
        alignment: `left`,
        decoration: ``,
        fontSize: 15,
        decorationStyle: ``,
        width: `auto`,
        color: ``,
        pageBreak: ` `,
        preserveLeadingSpaces: true,
      },
      {
        bold: false,
        pageBreak: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
        decoration: ``,
        fontSize: 15,
        width: `auto`,
        text: `5. I will not use illegal drugs/narcotics nor use another person’s medication.`,
      },
      {
        text: ` `,
        pageBreak: ``,
        decorationColor: ``,
        alignment: `left`,
        color: ``,
        decorationStyle: ``,
        fontSize: 15,
        bold: false,
        preserveLeadingSpaces: true,
        decoration: ``,
        width: `auto`,
      },
      {
        decorationStyle: ``,
        alignment: `left`,
        text: `6. I will be responsible for the secure storage of my medication at all times. I agree not to provide my prescribed pain medication to any other persons.`,
        decorationColor: ``,
        bold: false,
        width: `auto`,
        fontSize: 15,
        decoration: ``,
        pageBreak: ``,
        color: ``,
        preserveLeadingSpaces: true,
      },
      {
        bold: `true`,
        text: `Consent for Narcotic Use In Chronic Non-Cancer Pain`,
        width: `auto`,
        color: ``,
        pageBreak: `before`,
        fontSize: `18`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        alignment: `center`,
        decorationColor: ``,
        decoration: ``,
      },
      {
        bold: false,
        decorationStyle: ``,
        text: ` `,
        decoration: ``,
        width: `auto`,
        pageBreak: ``,
        color: ``,
        decorationColor: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        fontSize: 15,
      },
      {
        pageBreak: ``,
        decorationColor: ``,
        width: `auto`,
        text: `If I break this agreement my physician negative the right to drug prescribing narcotic medication for me including other treatments at Sapiens Hospital`,
        fontSize: 15,
        color: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationStyle: ``,
        alignment: `left`,
        bold: false,
      },
      {
        width: `auto`,
        decorationColor: ``,
        color: ``,
        text: ` `,
        bold: false,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        pageBreak: ``,
        decoration: ``,
        fontSize: 15,
        alignment: `left`,
      },
      {
        bold: false,
        decorationStyle: ``,
        width: `auto`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decoration: ``,
        color: ``,
        decorationColor: ``,
        text: ` I hereby AGREE to receive the treatment mentioned above.`,
        pageBreak: ``,
        alignment: `left`,
      },
      {
        preserveLeadingSpaces: true,
        fontSize: 15,
        decorationStyle: ``,
        text: ` `,
        pageBreak: ``,
        decorationColor: ``,
        bold: false,
        decoration: ``,
        alignment: `left`,
        width: `auto`,
        color: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `center`,
            pageBreak: ``,
            bold: false,
            width: 200,
            text: `Signature........................................................   `,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `center`,
            width: 80,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            text: ``,
            pageBreak: ``,
            decoration: ``,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `center`,
            bold: false,
            decorationStyle: ``,
            width: 200,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            text: `Witness 1........................................................`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            text: `(.............................................................)`,
            width: 200,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `center`,
            decorationStyle: ``,
          },
          {
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            text: ``,
            alignment: `center`,
            bold: false,
            width: 80,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            text: `(.............................................................)`,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `center`,
            width: 200,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            text: `Date........................Time..................`,
            alignment: `center`,
            width: 200,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            width: 80,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            text: `Witness 2........................................................`,
            bold: false,
            width: 200,
            preserveLeadingSpaces: true,
            alignment: `center`,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: ``,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            width: 280,
          },
          {
            fontSize: 15,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: 200,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `center`,
            pageBreak: ``,
            decoration: ``,
            text: `(.............................................................)`,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 280,
            bold: false,
            pageBreak: ``,
            decoration: ``,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            width: 200,
            fontSize: 15,
            text: `(Fingerprint/consent over telephone)`,
            alignment: `center`,
            color: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        color: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decoration: ``,
        text: ``,
        alignment: `left`,
        decorationStyle: ``,
        bold: `true`,
        width: `auto`,
        pageBreak: ` `,
        decorationColor: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            preserveLeadingSpaces: true,
            text: `Interpreter’s Statement`,
            alignment: `left`,
            color: ``,
            decoration: `underline`,
            pageBreak: ``,
            bold: `true`,
          },
        ],
      },
      {
        color: ``,
        text: `I have given a translation of Consent for Narcotic Use In Chronic Non-Cancer Pain including information that physician has explained to patient/patient’s representative.`,
        width: `auto`,
        decorationStyle: ``,
        bold: false,
        decoration: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        pageBreak: ``,
        fontSize: 15,
        alignment: `left`,
      },
      {
        decoration: ``,
        bold: false,
        fontSize: 15,
        alignment: `left`,
        width: `auto`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationColor: ``,
        text: ` `,
        color: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            width: 200,
            text: `Translate to Language.......................................`,
            bold: false,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            alignment: `center`,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            text: ``,
            alignment: `center`,
            width: 80,
            decoration: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: `Interpreter........................................................`,
            bold: false,
            decorationColor: ``,
            width: 200,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: 280,
            alignment: `center`,
            decorationStyle: ``,
            bold: false,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            alignment: `center`,
            bold: false,
            width: 200,
            text: `(.............................................................)`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        alignment: `left`,
        fontSize: 15,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        color: ``,
        bold: false,
        decorationColor: ``,
        decorationStyle: ``,
        width: `auto`,
        decoration: ``,
        text: ` `,
      },
      {
        decorationColor: ``,
        decorationStyle: ``,
        text: `Status of Signer (According to Thai Civil and Commercial Code)`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        alignment: `left`,
        pageBreak: ``,
        bold: `true`,
        color: ``,
        decoration: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            text: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            text: `[  ]`,
            fontSize: 15,
            pageBreak: ``,
            width: `auto`,
            color: ``,
            decorationColor: ``,
            bold: false,
          },
          {
            fontSize: 15,
            width: `auto`,
            text: ` 1. Patient who is major and capable of giving consent`,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decorationColor: ``,
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: `[  ]`,
            width: `auto`,
            decoration: ``,
            alignment: `center`,
            bold: false,
          },
          {
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            color: ``,
            text: ` 2. Legal husband or wife in case that the patient is not capable of giving consent (unconscious)`,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            width: `auto`,
            color: ``,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            color: ``,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            text: `[  ]`,
            alignment: `center`,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            text: ` 3. Holder of parental responsibility in case that the patient is minor (under 20 years old)`,
            pageBreak: ``,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            bold: false,
            alignment: `center`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `[  ]`,
            color: ``,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            text: ` 4. Curator in case that the patient is quasi incompetent person (adjudged by the court)`,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decoration: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
          },
          {
            alignment: `center`,
            color: ``,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            text: `[  ]`,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            width: `auto`,
            decoration: ``,
            alignment: `left`,
            text: ` 5. Guardian in case that the patient is incompetent person (adjudged by the court)`,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            fontSize: 15,
            width: 15,
            alignment: `left`,
            text: ` `,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `*For no. 2-5 , please obtain certified true copy of the patient’s representative’s identification card/ passport/document issued by governmental authority, which religion and blood type information are covered.`,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            color: ``,
          },
        ],
      },
      {
        text: ` `,
        bold: false,
        fontSize: 15,
        color: ``,
        decorationStyle: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationColor: ``,
        pageBreak: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            decoration: ``,
            text: `Relationship with patient......................................................................................................................`,
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            width: `auto`,
            text: `Identification number of the patient’s representative..................................................................`,
            pageBreak: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `Telephone number................................................................................................................................`,
            fontSize: 15,
            color: ``,
            bold: false,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: `Email........................................................................................................................................................... `,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
          },
        ],
      },
    ],
    header: props.header,
    footer: props.footer,
  };
}
