import { useState, useEffect } from "react";
import { Image } from "semantic-ui-react";
import clossSystemImage from "react-lib/assets/images/closs-system.png";

type MaintainScreenPros = {};

const styles = {
  screen: {
    display: "flex",
    height: "100dvh",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
  },
  label_title: {
    color: "red",
    fontSize: "40px",
    fontWeight: "bold",
    lineHeight: "normal",
    whiteSpace: "pre-line",
  },
  label_subtitle: {
    fontSize: "clamp(1rem, 2.5vw, 1.875rem)",
    whiteSpace: "pre-line",
    lineHeight: "normal",
    justifyContent: "center",
    display: "flex",
  },
  contentText: {
    display: "flex",
    gap: "30px",
    flexDirection: "column",
    textAlign: "center",
  },
};
export const MaintainScreen = (props: any) => {
  const [screenLandscape, setScreenLandscape] = useState<boolean>(true);

  useEffect(() => {
    if (window.innerWidth < window.innerHeight) {
      setScreenLandscape(false);
    } else {
      setScreenLandscape(true);
    }
  }, []);

  return (
    <div
      style={{
        ...styles.screen,
        flexDirection: screenLandscape ? "row" : "column",
      }}
    >
      <div style={{ padding: screenLandscape ? "0px" : "0 30px"}}>
        <Image src={ clossSystemImage || "closs-system.png" || ""} />
      </div>
      <div style={styles.contentText}>
        <div
          style={{
            ...styles.label_title,
            fontSize: "clamp(2.143rem, 3vw, 3.571rem)",
          }}
        >
          {`ขออภัยระบบปิดให้บริการ${screenLandscape?"": "\n"}แล้วในขณะนี้`}
        </div>
        <div
          style={{
            ...styles.label_subtitle,
            fontSize: "clamp(1.286rem, 2.5vw, 2.5rem)",
          }}
        >
          {
            "กรุณาติดต่อโรงพยาบาลที่ท่านใช้บริการ \nหากต้องการข้อมูลเพิ่มเติมเพื่อรับบริการ"
          }
        </div>
      </div>
    </div>
  );
};

MaintainScreen.display = "MaintainScreen";
export default MaintainScreen;
