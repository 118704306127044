import axios from "axios";
import settings from "../../../../../config/config";

// const SERVER_URL = "http://127.0.0.1:8000/"

let SERVER_URL: string;
if (settings.PENTA_ACC_EMAIL_LOGIN) {
  SERVER_URL = "https://accounts.thevcgroup.com";
} else {
  SERVER_URL = settings.API_HOST
}
const CALLBACK_URL = `${window.location.origin}/email-callback/`;
// const CALLBACK_URL = "https://ishealth-platform.web.app/email-callback/"
const APP = settings.APP_NAME;

export default class EmailLoginManager {
  register = async (email, recaptcha, first_name, last_name) => {
    const bodyFormData = new FormData();
    bodyFormData.set("email", email);
    bodyFormData.set("recaptcha", recaptcha);
    bodyFormData.set("first_name", first_name);
    bodyFormData.set("last_name", last_name);
    try {
      let ret = await axios.post(
        `${SERVER_URL}/email_auth/register/`,
        bodyFormData,
        { headers: { "Content-Type": "multipart/x-www-form-urlencoded" } }
      );
      console.log(ret);
      return ret.data;
    } catch (e) {
      console.error(e.response);
      throw e;
    }
  };

  requestLogin = async (email) => {
    const bodyFormData = new FormData();
    bodyFormData.set("email", email);
    bodyFormData.set("callback", CALLBACK_URL);
    try {
      let ret = await axios.post(
        `${SERVER_URL}/email_auth/send_login/`,
        bodyFormData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return ret.data;
    } catch (e) {
      console.error(e.response);
      throw e;
    }
  };

  getTokenFromAccount = async (email, access_token, subscription, device_id) => {

    let params =  new URLSearchParams()
    params.set("access_token", access_token)
    params.set("email", email)
    params.set("app", APP)
    params.set("device_token", JSON.stringify(subscription))
    params.set("unique_id", device_id)
    params.set("device_id", device_id)
    params.set("device_type", "webpush")

    const query = params.toString();
    try {
      let target_url
      if (settings.PENTA_ACC_EMAIL_LOGIN) {
        target_url = `${SERVER_URL}/apis/register_by_token/email_auth/?${query}`
      } else {
        target_url = `${SERVER_URL}/email_auth/get_token/?${query}`
      }
      let ret = await axios.get(
        target_url
      );
      return ret.data;
    } catch (e) {
      console.error(e.response);
      throw e;
    }
  };
}
