import UserService from "./UserService";
import { to, setStateAsync, formatDateToYYYYMMDD } from "../react-lib/utils";

export default class UserManager {
  getUserProfile = async ({ apiToken }) => {
    let headers = {};
    let params = {};
    if (apiToken) {
      headers.Authorization = `Token ${apiToken}`;
    }
    const userService = new UserService();
    const [error, response] = await to(
      userService.getUserProfile({ headers, params })
    );
    return [response, error];
  };

  updateUserProfile = async ({ apiToken, firstName, lastName, dob, phone }) => {
    let headers = {};
    if (apiToken) {
      headers.Authorization = `Token ${apiToken}`;
    }
    let data = {
      first_name: firstName,
      last_name: lastName,
      phone_no: phone,
    };
    if (dob) {
      data.dob = formatDateToYYYYMMDD(dob);
    }
    const userService = new UserService();
    const [error, response] = await to(
      userService.patchUserProfile({ headers, data })
    );
    return [response, error];
  };

  patchMyProfile = async (params) => {
    let {
      apiToken,
      dob,
      personWeight,
      personHeight,
      bloodType,
      rhType,
      congenital,
      foodAllergy,
      drugAllergy,
      gender,
      ...data
    } = params;
    const userService = new UserService();
    let options = {};

    if (dob) {
      data.dob = formatDateToYYYYMMDD(dob);
    }

    /// if need change only image 
    let extra = {}
    if (!params.image_url) {
      extra = {pre_name: "", nationality: "", gender: gender === "male" ? 1 : gender === "female" ? 2 : 0}
    }

    extra = {
      ...extra,
      ...(personWeight && { personWeight: personWeight}),
      ...(personHeight && { personHeight: personHeight}),
      ...(bloodType && { bloodType: bloodType}),
      ...(rhType && { rhType: rhType}),
      ...(congenital && { congenital: congenital}),
      ...(foodAllergy && { foodAllergy: foodAllergy}),
      ...(drugAllergy && { drugAllergy: drugAllergy}),
    }
    if (extra) {
      data.extra = JSON.stringify(extra)
    }
    const [error, response, network] = await to(
      userService.patchMyProfile({ apiToken, options, data })
    );
    return [response, error, network];
  };

  postDeleteUser = async ({apiToken, userId }) => {
    let headers = {}
    if (apiToken) {
        headers.Authorization = `Token ${apiToken}`;
    }
    let data = {
        user_id: userId,
    }
    const userService = new UserService();
    console.log(" user_id", userId, apiToken)
  
    const [error, response] = await to(userService.postDeleteUser({ headers, data }));
    return [response, error];
  }

}
