import BILService from "../services/BillService";
import { to } from "../../utils";
export default class BILManager {
  getQueueList = async ({ params } = {}) => {
    var bilQueueData = {};
    var bilQueueError = null;
    const bilService = new BILService();
    const [error, response] = await to(bilService.getQueueList({ params }));
    if (response) {
      bilQueueData = response;
    } else {
      bilQueueError = error;
    }
    return [bilQueueData, bilQueueError];
  }

  getInvoice = async ({ apiToken, patientId, status, divisionId, isTelemed } = {}) => {
    let params = {}
    if(patientId){
      params.patient = patientId
    }
    if(status){
      params.status = status
    }
    if(divisionId){
      params.division = divisionId
    }
    if (isTelemed) {
      params.is_telemed = isTelemed
    }
    const bilService = new BILService();
    const [error, response, network] = await to(bilService.getInvoice({ params, apiToken }));
    return [response, error, network];
  }

  getPendingInvoice = async ({ apiToken, isTelemed } = {}) => {
    let params = {}
   
    if (isTelemed) {
      params.is_telemed = isTelemed
    }
    const bilService = new BILService();
    const [error, response, network] = await to(bilService.getPendingInvoice({ params, apiToken }));
    return [response, error, network];
  }

  getInvoiceDetail = async ({ apiToken, invoiceId } = {}) => {
    let params = {};
    const bilService = new BILService();
    const [error, response, network] = await to(bilService.getInvoiceDetail({ params, apiToken, invoiceId }));
    return [response, error, network];
  }

  postInvoice = async ({ apiToken, encounterId, items, patientId, type, device } = {}) => {
    let params = {};
    let data = {};
    if(encounterId){
      data.encounter = encounterId;
    }
    if(items){
      data.items = items;
    }
    if(patientId){
      data.patient = patientId;
    }
    if(type){
      data.type = type;
    }
    const bilService = new BILService();
    const [error, response, network] = await to(bilService.postInvoice({ params, apiToken, data, device }));
    return [response, error, network];
  }

  getInvoiceItemByItem = async ({ apiToken, encounterId, patientId, invoiceId } = {}) => {
    let params = {};
    if(encounterId){
      params.encounter = encounterId;
    }
    if(patientId){
      params.patient = patientId;
    }
    if(invoiceId){
      params.invoice = invoiceId
    }
    const bilService = new BILService();
    const [error, response, network] = await to(bilService.getInvoiceItemByItem({ params, apiToken }));
    return [response, error, network];
  }

  postPaymentParameters = async ({ apiToken, invoiceId } = {}) => {
    let params = {}
    let data = {}
    if(invoiceId){
      data.invoice = invoiceId
    }
    const bilService = new BILService();
    const [error, response, network] = await to(bilService.postPaymentParameters({ params, data, apiToken }));
    return [response, error, network];
  }

  getReceipt = async ({ apiToken, patientId, divisionId, fromDate, isTelemed } = {}) => {
    let params = {}
    if(patientId){
      params.patient = patientId
    }
    if(divisionId){
      params.division = divisionId
    }
    if(fromDate){
      params.from_date = fromDate
    }
    if (isTelemed){
      params.is_telemed = isTelemed
    }
    const bilService = new BILService();
    const [error, response, network] = await to(bilService.getReceipt({ params, apiToken }));
    return [response, error, network];
  }

  getCompleteInvoice = async ({ apiToken, fromDate, isTelemed, hospital } = {}) => {
    let params = {}
    
    if (fromDate) {
      params.from_date = fromDate
    }
    if (isTelemed) {
      params.is_telemed = isTelemed
    }
    if (hospital){
      params.hospital = hospital
    }
    const bilService = new BILService();
    const [error, response, network] = await to(bilService.getCompleteInvoice({ params, apiToken }));
    return [response, error, network];
  }

}
