import React, { useState } from "react";

const HourRangeDropdown = (props: any) => {
  const [time, setTime] = React.useState(0);

  React.useEffect(() => {
    if (props.defaultValue && !isNaN(props.defaultValue)) {
      setTime(props.defaultValue);
    }
  }, [props.defaultValue]);

  const handleChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const createOptions = () => {
    let arr = [];
    for (let i = 0; i < 25; i++) {
      arr.push(
        <option key={i} value={i} selected={time === i}>
          {i}
        </option>
      );
    }
    return arr;
  };

  return <select onChange={handleChange}>{createOptions()}</select>;
};

HourRangeDropdown.defaultValue = {
  onChange: () => {},
}

export default React.memo(HourRangeDropdown);