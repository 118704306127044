const AddFingerPrintConsentTreatment = {
  table: {
    widths: ["20%", "30%", "50%"],
    body: [
      [
        {
          text: ` `,
          preserveLeadingSpaces: true,
          fontSize: 15,
          // border: []
        },
        {
          stack: [
            {
              text: `\t\tกรณีลงลายมือชื่อไม่ได้`,
              fontSize: 15,
              preserveLeadingSpaces: true,
            },
            {
              text: `\t\tลายพิมพ์นิ้วหัวแม่มือ`,
              fontSize: 15,
              preserveLeadingSpaces: true,
            },
            {
              text: `\t\t◌ ข้างซ้าย`,
              fontSize: 15,
              preserveLeadingSpaces: true,
            },
            {
              text: `\t\t◌ ข้างขวา`,
              fontSize: 15,
              preserveLeadingSpaces: true,
            }
          ],
          border: []
        },
        {
          stack: [
            {
              text: `ลงชื่อ......................................................แพทย์/พยาบาล`,
              fontSize: 15,
              alignment: `center`
            },
            {
              text: `(............................................................................)`,
              fontSize: 15,
              alignment: `center`
            },
            {
              text: `ตำแหน่ง............................................................`,
              fontSize: 15,
              alignment: `center`
            },
            {
              text: ` `,
              preserveLeadingSpaces: true,
              fontSize: 15,
            },
            {
              text: `ลงชื่อ...............................................พยานฝ่ายโรงพยาบาล`,
              fontSize: 15,
              alignment: `center`
            },
            {
              text: `(............................................................................)`,
              fontSize: 15,
              alignment: `center`
            },
            {
              text: `ตำแหน่ง............................................................`,
              fontSize: 15,
              alignment: `center`
            }
          ],
          border: []
        }
      ]
    ]
  },
  layout: {
    hLineStyle: function (i: any, node: any) {
      return {dash: {length: 5, space: 4}}
    },
    vLineStyle: function (i: any, node: any) {
      return {dash: {length: 5, space: 4}}
    }
  }
}

export default AddFingerPrintConsentTreatment