import React, { useState, useCallback, useEffect } from "react";
import "@natscale/react-calendar/dist/main.css";
import { Calendar } from "@natscale/react-calendar";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import "../../css/calendar.scss";
import moment from "moment";

type SelectDateProps = {
  setProp?: any;
  date?: any;
  dateDivisionSchedule: any;
  checkSelectDate?: any;
  onClickPrev?: any;
  onClickNext?: any;
} & WrappedComponentProps;

const monthsLabel = {
  0: "มกราคม",
  1: "กุมภาพันธ์",
  2: "มีนาคม",
  3: "เมษายน",
  4: "พฤษภาคม",
  5: "มิถุนายน",
  6: "กรกฎาคม",
  7: "สิงหาคม",
  8: "กันยายน",
  9: "ตุลาคม",
  10: "พฤศจิกายน",
  11: "ธันวาคม",
};

const weekDaysLabel = {
  0: "อา",
  1: "จ",
  2: "อ",
  3: "พ",
  4: "พฤ",
  5: "ศ",
  6: "ส",
};

const SelectDateInitial: Omit<SelectDateProps, "intl"> = {
  dateDivisionSchedule: [],
};

const SelectDate = (props: SelectDateProps) => {
  const [currentDate, setCurrentDate] = useState<any>(null)
  const [value, setValue] = useState();
  
  useEffect(() => {
    
    const prevButton = document.querySelector(".rc_header_nav.rc_header_nav-prev") as HTMLElement;
    const nextButton = document.querySelector(".rc_header_nav.rc_header_nav-next") as HTMLElement;
    // console.log("prevButton", prevButton, "nextButton", nextButton)
    prevButton.addEventListener("click", handleOnClickPrevious);
    nextButton.addEventListener("click", handleOnClickNext);
    return () => {
      prevButton.removeEventListener("click", handleOnClickPrevious)
      nextButton.removeEventListener("click", handleOnClickNext)
    }
  })

  useEffect(() => {
    if (props.checkSelectDate) {
      setValue(moment(props.date)?._d);
      props.setProp(
        "appointmentData.selectDate",
        moment(props.date).format("YYYY-MM-DD")
      );
    }

    let selectedDate = moment()
    if(props.date){
      selectedDate  = moment(props.date, "YYYY-MM-DD")
    }
    setCurrentDate(selectedDate)
  }, [props.date]);

  const onChange = (val: any) => {
    setValue(val);
    props.setProp(
      "appointmentData.selectDate",
      moment(val).format("YYYY-MM-DD")
    );
    setCurrentDate(moment(val))
  };

  const isDisabled = useCallback(
    (date: any) => {
      if (
        props.dateDivisionSchedule.includes(moment(date).format("YYYY-MM-DD"))
      ) {
        return false;
      } else {
        return true;
      }
    },
    [props.dateDivisionSchedule]
  );

  const handleOnClickPrevious = () => {
    // console.log("handleOnClickPrevious")
    if(props?.onClickPrev){
      let prevMonth = currentDate?.add(-1, "months")
      props.onClickPrev(prevMonth.format("M"), prevMonth.format("YYYY"))
    }
  }

  const handleOnClickNext = () => {
    // console.log("handleOnClickNext")
    if(props?.onClickNext){
      let nextMonth = currentDate?.add(1, "months")
      props.onClickNext(nextMonth.format("M"), nextMonth.format("YYYY"))
    }
  }

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Calendar
          className="calendar-custom"
          value={value}
          onChange={onChange}
          isMultiSelector={false}
          isDisabled={isDisabled}
          hideAdjacentDates={true}
          monthsLabel={monthsLabel}
          weekDaysLabel={weekDaysLabel}
          //   lockView
        />
      </div>
    </>
  );
};

SelectDate.defaultProps = SelectDateInitial;

export default React.memo(injectIntl(SelectDate));
