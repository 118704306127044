type HeaderPrintListProps = Partial<{
  createdDate: string;
  patientInfo: any;
  formName: string;
  styles: Record<string, any>;
  pageMargins: number[];
}>;

export const AddHeaderPrintListEN = (doc: any, props: HeaderPrintListProps) => {
  return {
    ...doc,
    // logo height 80 + marginHeader 16 = 96 (pageMargins must greater than Header + Header Bottom Margin)
    // margin: [left, top, right, bottom]
    pageMargins: props.pageMargins ? props.pageMargins : [38, 120, 38, 40],
    header: () => {
      return {
        table: {
          widths: ["52%", "48%"],
          body: [
            [
              // Left Side (logo and form name)
              {
                stack: [
                  {
                    image: "logo",
                    width: 300,
                    height: 80,
                    margin: [-21, -21, 0, 0],
                  },
                  {
                    text: props.formName,
                    alignment: `center`,
                    font: `Poppins`,
                    fontSize: 10,
                    bold: true,
                    margin: [0, -16, 0, 0],
                  },
                ],
                border: [true, true, false, true],
              },
              // Right Side (patient information)
              {
                stack: [
                  {
                    text: ` `,
                    preserveLeadingSpaces: true,
                    font: `PoppinsLM`,
                    fontSize: 8,
                  },
                  {
                    columns: [
                      {
                        text: `Name :`,
                        preserveLeadingSpaces: true,
                        font: `PoppinsLM`,
                        fontSize: 8,
                        bold: true,
                        width: `auto`,
                      },
                      {
                        text: ` ${props.patientInfo?.formatPatient}`,
                        preserveLeadingSpaces: true,
                        font: `KanitLM`,
                        fontSize: 8,
                        bold: true,
                        width: `auto`,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: `HN : ${props.patientInfo?.hn}`,
                        font: `PoppinsLM`,
                        fontSize: 8,
                        bold: true,
                      },
                      {
                        text: `EN/AN : ${props.patientInfo?.patientEncounter}`,
                        font: `PoppinsLM`,
                        fontSize: 8,
                        bold: true,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: `Birth date : ${props.patientInfo?.patientBirthdateEN}`,
                        font: `PoppinsLM`,
                        fontSize: 8,
                        bold: true,
                      },
                      {
                        text: `Age : ${props.patientInfo?.ageYear} years ${props.patientInfo?.ageMonth} months ${props.patientInfo?.ageDay} days`,
                        font: `PoppinsLM`,
                        fontSize: 8,
                        bold: true,
                      },
                    ],
                  },
                  {
                    text: `Gender : ${
                      props.patientInfo?.gender
                        ? props.patientInfo.gender === "F"
                          ? "Female"
                          : props.patientInfo.gender === "M"
                          ? "Male"
                          : props.patientInfo.gender
                        : "N/A"
                    }`,
                    font: `PoppinsLM`,
                    fontSize: 8,
                    bold: true,
                  },
                  {
                    text: ` `,
                    preserveLeadingSpaces: true,
                    font: `PoppinsLM`,
                    fontSize: 8,
                  },
                ],
                border: [false, true, true, true],
              },
            ],
          ],
        },
        layout: {
          paddingTop: function (i: any, node: any) {
            return 5;
          },
          paddingBottom: function (i: any, node: any) {
            return 5;
          },
          paddingLeft: function (i: any, node: any) {
            return 5;
          },
          paddingRight: function (i: any, node: any) {
            return 5;
          },
        },
        margin: [16, 16, 16, 0],
      };
    },
    footer: (currentPage: any, pageCount: any) => {
      return {
        columns: [
          {
            text: `Printed date : ${props.createdDate}`,
            alignment: `left`,
            font: `PoppinsLM`,
            fontSize: 8,
          },
          {
            text: `Page ` + currentPage.toString() + ` of ` + pageCount,
            alignment: `right`,
            font: `PoppinsLM`,
            fontSize: 8,
          },
        ],
        margin: [16, 16, 16, 16],
      };
    },
    images: {
      logo: origin + "/static/images/sapiens/logo_sapiens.png",
    },
    styles: {
      ...(props.styles || {}),
    },
  };
};
