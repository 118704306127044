const AddTablePreSedationTH = {
  unbreakable: true,
  table: {
    widths: ["20%", "20%", "60%"],
    body: [
      [
        {
          text: "รายละเอียดวิธีการใช้ยาระงับความรู้สึก",
          fontSize: 15,
          bold: true,
          colSpan: 3,
        },
        {},
        {},
      ],
      [
        {
          text: "การดมยาสลบ",
          fontSize: 15,
          border: [true, true, true, false],
        },
        {
          text: "ผลที่คาดว่าจะได้รับ",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, false],
        },
        {
          text: "วิธีการ",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, true],
        },
        {
          text: "ความเสี่ยงที่อาจเกิดขึ้น\n(แต่มิได้จำกัดอยู่เพียง)",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "การใช้ยากล่อมประสาท",
          fontSize: 15,
          border: [true, true, true, false],
        },
        {
          text: "ผลที่คาดว่าจะได้รับ",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, false],
        },
        {
          text: "วิธีการ",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, true],
        },
        {
          text: "ความเสี่ยงที่อาจเกิดขึ้น\n(แต่มิได้จำกัดอยู่เพียง)",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "บล็อกหลัง",
          fontSize: 15,
          border: [true, true, true, false],
        },
        {
          text: "ผลที่คาดว่าจะได้รับ",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, false],
        },
        {
          text: "วิธีการ",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, true],
        },
        {
          text: "ความเสี่ยงที่อาจเกิดขึ้น\n(แต่มิได้จำกัดอยู่เพียง)",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "บล็อกเส้นประสาทหลัก/ย่อย",
          fontSize: 15,
          border: [true, true, true, false],
        },
        {
          text: "ผลที่คาดว่าจะได้รับ",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, false],
        },
        {
          text: "วิธีการ",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, true],
        },
        {
          text: "ความเสี่ยงที่อาจเกิดขึ้น\n(แต่มิได้จำกัดอยู่เพียง)",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "การเฝ้าติดตามการทำงานของร่างกายอย่างใกล้ชิด",
          fontSize: 15,
          border: [true, true, true, false],
        },
        {
          text: "ผลที่คาดว่าจะได้รับ",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, false],
        },
        {
          text: "วิธีการ",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: "",
          fontSize: 15,
          border: [true, false, true, true],
        },
        {
          text: "ความเสี่ยงที่อาจเกิดขึ้น\n(แต่มิได้จำกัดอยู่เพียง)",
          fontSize: 15,
        },
        {
          text: "",
          fontSize: 15,
        },
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 15,
          colSpan: 3,
          border: [true, true, true, false],
        },
        {},
        {},
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 15,
          colSpan: 3,
          border: [true, false, true, false],
        },
        {},
        {},
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 15,
          colSpan: 3,
          border: [true, false, true, true],
        },
        {},
        {},
      ],
    ],
  },
};

export default AddTablePreSedationTH;
