import React from 'react';
import PropTypes, { number } from 'prop-types';
import { Modal, Button, TextArea, Input, Grid } from 'semantic-ui-react';
export default class OtpInput extends React.Component<any, any>{
  constructor(props: any) {
    super(props)
    this.state = {
      complete: false,
      otpValue: {},
    }
  }
  static defaultProps = {
    number: 4,
    seperator: '-',
    readOnly: false,
    hasError: false,
    space: '0px 0.2rem',
    type: 'tel'
  }
  static propTypes = {
    number: PropTypes.number.isRequired,
    seperator: PropTypes.string,
    readOnly: PropTypes.bool,
    hasError: PropTypes.bool,
    FillComplete: PropTypes.func,
    space: PropTypes.string,
  }

  blockFill = async (e: any, ith: any) => {
    if (e.target.value == '') {
      return
    }
    else {
      // @ts-ignore
      ith == this.props.number - 1 ? this[`block${ith}`].focus() : this[`block${ith + 1}`].focus()
      let otp_temp = { ...this.state.otpValue }
      otp_temp[ith] = e.target.value
      this.setState({ otpValue: otp_temp })
    }
    if (Object.keys(this.state.otpValue).length == this.props.number) this.onComplete()
  }
  blockClick = async (ith: any) => {
    // @ts-ignore
    this[`block${ith}`].focus()
  }
  backkeyUp = async (e: any, ith: any) => {
    if (e.key == 'Backspace' || e.key == 'Delete') {
      // @ts-ignore
      ith == 0 ? this[`block${ith}`].focus() : this[`block${ith - 1}`].focus()
      let otp_temp = { ...this.state.otpValue }
      delete otp_temp[ith]
      this.setState({ otpValue: otp_temp })
    }
    if (e.key == this.state.otpValue[ith]) {
      this.blockFill(e, ith)
    }
  }
  onComplete = () => {
    let otp = ''
    for (let ith = 0; ith < this.props.number; ith++) { otp = otp + this.state.otpValue[ith] }
    this.props.FillComplete(otp)
  }

  genOtpInput = () => {
    let border = this.props.hasError ? '1px solid red' : '1px solid rgba(0,0,0,0.3)'
    let blocks = []
    for (let i = 0; i < this.props.number; i++) {
      let block =
        <div key={i} style={{ alignItems: 'center', }}>
          <input
            type={this.props.type}
            maxLength={1}
            style={{
              width: '3rem', height: '3rem', textAlign: 'center', verticalAlign: 'center', margin: this.props.space, fontSize: '1.5rem',
              borderRadius: '4px', border: border,
            }}
            onChange={(e) => this.blockFill(e, i)}
            onClick={() => this.blockClick(i)}
            onKeyUp={(e) => this.backkeyUp(e, i)}
            // @ts-ignore
            ref={ref => this[`block${i}`] = ref}
            readOnly={this.props.readOnly}
          />
          {i == this.props.number - 1 ? null : <span>{this.props.seperator}</span>}
        </div>
      blocks.push(block)
    }
    return blocks
  }
  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>{this.genOtpInput()}</div>
    )
  }
}