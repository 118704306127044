export default function toPDFMakeData(props: any) {
  return {
    pageSize: `A4`,
    content: [
      {
        alignment: `center`,
        decorationStyle: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        color: ``,
        bold: `true`,
        fontSize: `18`,
        pageBreak: ``,
        text: `หนังสือแสดงความยินยอมรับการรักษาด้วยยารักษาอาการปวดชนิดเสพติดในอาการปวดเรื้อรังที่ไม่ใช่มะเร็ง`,
        decoration: ``,
        width: `auto`,
      },
      {
        color: ``,
        pageBreak: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decoration: ``,
        bold: false,
        text: ` `,
        width: `auto`,
        fontSize: 15,
        alignment: `left`,
      },
      {
        columns: [
          {
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 30,
            text: ``,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            color: ``,
            text: `ข้าพเจ้า (นาย/นาง/นางสาว) `,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            fontSize: 15,
            color: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 5,
            text: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                fontSize: 15,
                decorationStyle: ``,
                alignment: `center`,
                pageBreak: ``,
                color: ``,
                decoration: ``,
                decorationColor: ``,
                margin: [0, 0, 0, -15],
                width: `auto`,
                preserveLeadingSpaces: true,
                text: props.items?.formatPatient,
                bold: false,
              },
              {
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                width: `auto`,
                bold: false,
                decorationStyle: ``,
                text: `.............................................`,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                color: ``,
                fontSize: 15,
                alignment: `left`,
              },
            ],
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            text: `ได้รับแจ้งจากนายแพทย์/แพทย์หญิง`,
            width: `auto`,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            bold: false,
            width: 5,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                decorationColor: ``,
                decoration: ``,
                fontSize: 15,
                alignment: `center`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                color: ``,
                bold: false,
                text: props.items?.formatDoctor,
                width: `auto`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
              },
              {
                width: `auto`,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                pageBreak: ``,
                decorationColor: ``,
                alignment: `left`,
                color: ``,
                bold: false,
                text: `..............................................`,
                fontSize: 15,
                decoration: ``,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: `ว่า ข้าพเจ้ามีอาการปวดเรื้อรังที่ไม่ใช่มะเร็ง หากไม่ได้รับการรักษา หากไม่ได้รับการรักษา ข้าพเจ้าจะไม่หายจากอาการปวด และอาการปวดรุนแรงขึ้น โดยแพทย์ได้อธิบายให้เข้าใจถึงวัตถุประสงค์ เงื่อนไข ทางเลือกของการรักษา และข้าพเจ้าได้อภิปราย`,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
          },
        ],
      },
      {
        pageBreak: ``,
        decorationStyle: ``,
        decorationColor: ``,
        fontSize: 15,
        bold: false,
        width: `auto`,
        decoration: ``,
        color: ``,
        text: `กับแพทย์เกี่ยวกับผลสำเร็จ ซึ่งขึ้นอยู่กับปัจจัยจากตัวข้าพเจ้าเองและปัจจัยอื่นๆ รวมทั้งปัญหาที่อาจเกิดขึ้นในระยะฟื้นตัวจนเข้าใจ`,
        preserveLeadingSpaces: true,
        alignment: `left`,
      },
      {
        columns: [
          {
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `อย่างดี จึงขอให้ความยินยอมเข้ารับการรักษาอาการปวดด้วยยาเสพติดที่ใช้ในทางการแพทย์ ได้แก่ยา `,
          },
          {
            bold: false,
            text: `.....................................................`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            pageBreak: ``,
            width: 30,
            bold: false,
            alignment: `left`,
            text: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            color: ``,
            text: `ในการรักษาด้วยยาดังกล่าว ข้าพเจ้ารับทราบว่า ข้าพเจ้าจะต้องปฏิบัติตามข้อควรทราบและข้อควรปฏิบัติของผู้ป่วยอย่าง`,
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
          },
        ],
      },
      {
        color: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        bold: false,
        text: `เคร่งครัด ดังต่อไปนี้`,
        decoration: ``,
        width: `auto`,
        alignment: `left`,
        decorationStyle: ``,
        fontSize: 15,
      },
      {
        bold: false,
        width: `auto`,
        pageBreak: ``,
        fontSize: 15,
        decorationStyle: ``,
        alignment: `left`,
        text: ` `,
        decorationColor: ``,
        color: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
      },
      {
        decorationStyle: ``,
        text: `ข้อควรทราบ`,
        width: `auto`,
        decorationColor: ``,
        bold: `true`,
        fontSize: 15,
        pageBreak: ``,
        alignment: `center`,
        decoration: ``,
        preserveLeadingSpaces: true,
        color: ``,
      },
      {
        decorationStyle: ``,
        fontSize: 15,
        pageBreak: ``,
        bold: false,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        color: ``,
        width: `auto`,
        text: `1. ข้าพเจ้ารับทราบว่า เป้าหมายในการรักษาของข้าพเจ้าคือ ควบคุมอาการปวด โดยหากจำเป็นต้องใช้ยาฉีดชนิดเสพติด แพทย์จะใช้`,
        alignment: `left`,
      },
      {
        decorationColor: ``,
        text: `ในขนาดที่เหมาะสมเพื่อบำบัดอาการปวด`,
        alignment: `left`,
        color: ``,
        width: `auto`,
        decorationStyle: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        bold: false,
        decoration: ``,
        pageBreak: ``,
      },
      {
        pageBreak: ``,
        width: `auto`,
        decorationStyle: ``,
        color: ``,
        decoration: ``,
        decorationColor: ``,
        fontSize: 15,
        bold: false,
        preserveLeadingSpaces: true,
        text: `2. ข้าพเจ้ารับทราบว่า ทางโรงพยาบาลฯ อาจไม่อนุญาตให้เข้ารับการรักษาภาวะปวดที่ยารักษาอาการปวดชนิดเสพติด โดยมิได้`,
        alignment: `left`,
      },
      {
        preserveLeadingSpaces: true,
        decoration: ``,
        alignment: `left`,
        decorationColor: ``,
        fontSize: 15,
        pageBreak: ``,
        color: ``,
        decorationStyle: ``,
        text: `นัดหมายล่วงหน้า หากข้าพเจ้าเกิดปัญหาทางการแพทย์อื่นๆ หรือมีอาการปวดเฉียบพลันในระหว่างการรักษา ข้าพเจ้าจะแจ้ง`,
        width: `auto`,
        bold: false,
      },
      {
        color: ``,
        bold: false,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decoration: ``,
        alignment: `left`,
        pageBreak: ``,
        width: `auto`,
        decorationColor: ``,
        text: `ให้แพทย์เจ้าของไข้ทราบทันที`,
        fontSize: 15,
      },
      {
        decoration: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        alignment: `left`,
        bold: false,
        pageBreak: ``,
        fontSize: 15,
        decorationColor: ``,
        text: `3. ข้าพเจ้ารับทราบว่า อาการข้างเคียงที่อาจเกิดขึ้นได้จากการใช้ยารักษาอาการปวดชนิดเสพติด ได้แก่ คลื่นไส้ ท้องผูก เหงื่อออก ผื่นคัน ง่วงซึม เป็นต้น ซึ่งอาการง่วงซึมอาจเกิดขึ้นเมื่อเริ่มการใช้ยาหรือเพิ่มขนาดยา ข้าพเจ้าควรงดการขับขี่ยานพาหนะ หรือทำงาน`,
        color: ``,
        decorationStyle: ``,
      },
      {
        preserveLeadingSpaces: true,
        text: `ที่เกี่ยวข้องกับเครื่องจักรกลในขณะที่เกิดอาการง่วงซึมดังกล่าว`,
        decorationStyle: ``,
        decoration: ``,
        width: `auto`,
        color: ``,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        alignment: `left`,
        fontSize: 15,
      },
      {
        color: ``,
        bold: false,
        text: `4. ข้าพเจ้ารับทราบว่าการรักษาด้วยยารักษาอาการปวดชนิดเสพติด มีความเสี่ยงที่จะมีภาวะติดยา และในขณะที่ข้าพเจ้ากำลังใช้ยา`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        pageBreak: ``,
        decorationColor: ``,
        fontSize: 15,
        decoration: ``,
        alignment: `left`,
        width: `auto`,
      },
      {
        pageBreak: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decoration: ``,
        width: `auto`,
        bold: false,
        color: ``,
        text: `กลุ่มนี้อยู่ แพทย์ผู้รักษาอาจมีการทดสอบภาวะติดยา และ/หรือ ส่งต่อให้ผู้เชี่ยวชาญในการประเมินภาวะติดยาระหว่างการรักษาได้`,
        fontSize: 15,
        decorationStyle: ``,
      },
      {
        decorationStyle: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        color: ``,
        pageBreak: ``,
        text: `5. ข้าพเจ้ารับทราบว่า การใช้สารหรือวัตถุออกฤทธิ์ต่อจิตประสาท เช่น ยาระงับประสาท ยานอนหลับ แอลกอฮอล์ หรือยาที่ผิด`,
        bold: false,
        decoration: ``,
        fontSize: 15,
        width: `auto`,
        alignment: `left`,
      },
      {
        width: `auto`,
        decorationColor: ``,
        pageBreak: ``,
        bold: false,
        preserveLeadingSpaces: true,
        text: `กฎหมาย (เช่น กัญชา โคเคน เฮโรอีน หรือสารที่ทำให้เกิดภาวะประสาทหลอน) สามารถก่อให้เกิดอาการข้างเคียง หรือมีผลรบกวน`,
        decorationStyle: ``,
        fontSize: 15,
        decoration: ``,
        alignment: `left`,
        color: ``,
      },
      {
        fontSize: 15,
        width: `auto`,
        bold: false,
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
        color: ``,
        pageBreak: ``,
        alignment: `left`,
        text: `ต่อการรักษาข้าพเจ้าด้วย ข้าพเจ้าควรหยุดใช้สารดังกล่าวทั้งหมด โดยไม่จำเป็นต้องได้รับคำบอกกล่าวจากแพทย์ผู้รักษา`,
        preserveLeadingSpaces: true,
      },
      {
        preserveLeadingSpaces: true,
        alignment: `left`,
        width: `auto`,
        decoration: ``,
        color: ``,
        fontSize: 15,
        decorationColor: ``,
        decorationStyle: ``,
        pageBreak: ``,
        text: `6. หากมีความจำเป็นทางการแพทย์ ข้าพเจ้ายินยอมให้แพทย์ผุ้รักษาเปิดเผยข้อมูลในการสั่งใช้ยาของข้าพเจ้าต่อบุคลากรทางการ`,
        bold: false,
      },
      {
        fontSize: 15,
        color: ``,
        decorationColor: ``,
        pageBreak: ``,
        decoration: ``,
        decorationStyle: ``,
        text: `แพทย์อื่นตามดุลยพินิจของแพทย์ได้`,
        preserveLeadingSpaces: true,
        alignment: `left`,
        bold: false,
        width: `auto`,
      },
      {
        preserveLeadingSpaces: true,
        pageBreak: props.items?.haveHeader ? `before` : ``,
        width: `auto`,
        fontSize: 15,
        alignment: `center`,
        bold: `true`,
        decoration: ``,
        decorationStyle: ``,
        text: `ข้อการปฏิบัติของผู้ป่วย`,
        decorationColor: ``,
        color: ``,
      },
      {
        decorationColor: ``,
        width: `auto`,
        alignment: `left`,
        decorationStyle: ``,
        bold: false,
        text: `1. ข้าพเจ้าจะรับผิดชอบต่อการนัดหมายของโรงพยาบาลฯ ทุกครั้ง รวมไปถึงการส่งต่อเพื่อเข้ารับการรักษาจากผู้เชี่ยวชาญเฉพาะทาง`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
        fontSize: 15,
      },
      {
        bold: false,
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        color: ``,
        fontSize: 15,
        alignment: `left`,
        pageBreak: ``,
        text: `ต่างๆ เช่น แพทย์เวชศาสตร์ฟื้นฟู และจิตแพทย์เป็นต้น`,
      },
      {
        width: `auto`,
        decorationColor: ``,
        text: `2. ข้าพเจ้าจะแจ้งข้อมูลยาทุกตัวที่ข้าพเจ้าใช้อยู่กับแพทย์ผู้รักษาและปฏิบัติตามคำสั่งใช้ยา และแผนการรักษาอย่างเคร่งครัด หาก`,
        alignment: `left`,
        fontSize: 15,
        bold: false,
        decoration: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationStyle: ``,
        color: ``,
      },
      {
        fontSize: 15,
        bold: false,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        decoration: ``,
        text: `ข้าพเจ้าไม่อาจปฏิบัติตามแผนการรักษาได้ ข้าพเจ้าแจ้งให้แพทย์ทราบทันที`,
        alignment: `left`,
        decorationStyle: ``,
        pageBreak: ``,
      },
      {
        bold: false,
        width: `auto`,
        text: `3. ข้าพเจ้าจะต้องรับการรักษากับแพทย์เจ้าของไข้เท่านั้น ในกรณีที่มีความจำเป็นจะต้องเข้ารับการรักษาอาการปวดกับแพทย์ท่านอื่น ในครั้งต่อไปข้าพเจ้าจะต้องพบแพทย์เจ้าของไข้เท่านั้น หากข้าพเจ้าไม่มาพบแพทย์เจ้าของไข้ในครั้งต่อไป โรงพยาบาลฯ มีสิทธิ์ปฏิเสธ`,
        decorationStyle: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        pageBreak: ``,
        color: ``,
        alignment: `left`,
        decoration: ``,
      },
      {
        pageBreak: ``,
        decoration: ``,
        decorationStyle: ``,
        bold: false,
        alignment: `left`,
        text: `การรักษาข้าพเจ้าได้`,
        preserveLeadingSpaces: true,
        color: ``,
        decorationColor: ``,
        fontSize: 15,
        width: `auto`,
      },
      {
        alignment: `left`,
        preserveLeadingSpaces: true,
        text: `4. ข้าพเจ้าจะใช้ยารักษาอาการปวดชนิดเสพติดที่ได้รับจากโรงพยาบาลเท่านั้น ในขนาดและความถี่ตามคำสั่งแพทย์ผู้รักษา และจะ`,
        pageBreak: ``,
        decoration: ``,
        decorationColor: ``,
        bold: false,
        fontSize: 15,
        width: `auto`,
        color: ``,
        decorationStyle: ``,
      },
      {
        bold: false,
        alignment: `left`,
        decorationColor: ``,
        decorationStyle: ``,
        fontSize: 15,
        decoration: ``,
        text: `ไม่ใช้ยาจากแหล่งอื่น รวมทั้งจะไม่สะสมยาหรือทิ้งยา`,
        width: `auto`,
        pageBreak: ``,
        color: ``,
        preserveLeadingSpaces: true,
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        decoration: ``,
        text: `5. ข้าพเจ้าจะไม่ใช้สารเสพติด หรือยาที่ผิดกฎหมาย หรือยาของผู้อื่น`,
        fontSize: 15,
        decorationStyle: ``,
        decorationColor: ``,
        pageBreak: ``,
        color: ``,
        bold: false,
        alignment: `left`,
      },
      {
        width: `auto`,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationStyle: ``,
        pageBreak: ``,
        decorationColor: ``,
        text: `6. ข้าพเจ้าจะรับผิดชอบต่อการเก็บรักษายาที่ข้าพเจ้าได้รับอย่างปลอดภัยตลอดเวลาและข้าพเจ้าจะไม่ให้ยาแก้ปวดของข้าพเจ้าแก่ผู้อื่น`,
        bold: false,
        fontSize: 15,
        color: ``,
      },
      {
        text: ` `,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: false,
        fontSize: 15,
        pageBreak: props.items?.haveHeader ? `` : `before`,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        bold: false,
        pageBreak: ``,
        decorationStyle: ``,
        fontSize: 15,
        alignment: `left`,
        decoration: ``,
        text: `ทั้งนี้ หากข้าพเจ้าละเลยหรือไม่ปฏิบัติตามเงื่อนไขที่โรงพยาบาลกำหนดดังกล่าวข้างต้น ข้าพเจ้ายินยอมให้แพทย์ผู้รักษายกเลิก`,
        color: ``,
        decorationColor: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
      },
      {
        preserveLeadingSpaces: true,
        decorationColor: ``,
        fontSize: 15,
        decorationStyle: ``,
        pageBreak: ``,
        alignment: `left`,
        color: ``,
        width: `auto`,
        decoration: ``,
        bold: false,
        text: `การรักษาภาวะปวด และการรักษาอื่นๆ จากโรงพยาบาลเซเปี้ยนซ์ได้`,
      },
      {
        decorationStyle: ``,
        text: `ข้าพเจ้าขอแสดงความยินยอมเพื่อรับการรักษาดังกล่าวข้างต้น`,
        preserveLeadingSpaces: true,
        width: `auto`,
        decoration: ``,
        pageBreak: ``,
        fontSize: 15,
        alignment: `left`,
        color: ``,
        decorationColor: ``,
        bold: false,
      },
      {
        alignment: `left`,
        decorationColor: ``,
        text: ` `,
        width: `auto`,
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
        decorationStyle: ``,
        fontSize: 15,
        bold: false,
        pageBreak: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            width: 200,
            fontSize: 15,
            alignment: `center`,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `ลงนาม........................................................`,
            decorationColor: ``,
            bold: false,
          },
          {
            bold: false,
            width: 80,
            fontSize: 15,
            pageBreak: ``,
            text: `  `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            text: `พยาน 1 ลงนาม........................................................`,
            bold: false,
            alignment: `center`,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 200,
            decorationStyle: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 200,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `(.............................................................)`,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            alignment: `center`,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            width: 80,
            alignment: `left`,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            bold: false,
            fontSize: 15,
            text: `(.............................................................)`,
            alignment: `center`,
            width: 200,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `วันที่........................เวลา..................`,
            pageBreak: ``,
            fontSize: 15,
            width: 200,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `center`,
            bold: false,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            bold: false,
            alignment: `left`,
            width: 80,
            text: ``,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            width: 200,
            text: `พยาน 2 ลงนาม........................................................`,
            alignment: `center`,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            width: 280,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            text: `(.............................................................)`,
            decorationStyle: ``,
            width: 200,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `center`,
            pageBreak: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 280,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            text: ``,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            text: `(กรณีพิมพ์รายนิ้วมือ/ยินยอมทางโทรศัพท์) `,
            bold: false,
            alignment: `center`,
            fontSize: 15,
            width: 200,
            pageBreak: ``,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        width: `auto`,
        color: ``,
        pageBreak: ``,
        bold: false,
        alignment: `left`,
        decorationColor: ``,
        text: ` `,
        fontSize: 15,
        decoration: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            decoration: `underline`,
            pageBreak: props.items?.haveHeader ? `before` : ``,
            width: `auto`,
            bold: `true`,
            preserveLeadingSpaces: true,
            color: ``,
            text: `การแปลและให้ข้อมูลโดยผู้แปลภาษา`,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            width: 30,
            fontSize: 15,
          },
          {
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            width: `auto`,
            text: `ข้าพเจ้าได้แปลหนังสือแสดงความยินยอมรับการรักษาด้วยยารักษาอาการปวดชนิดเสพติดในอาการปวดเรื้อรังที่ไม่ใช่มะเร็ง`,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        text: `(Consent for Narcotic Use In Chronic Non-Cancer Pain) รวมทั้งข้อมูลซึ่งแพทย์ได้อธิบายให้แก่ผู้ป่วย/ตัวแทนผู้ป่วยทราบ`,
        decoration: ``,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        color: ``,
        preserveLeadingSpaces: true,
        bold: false,
        decorationColor: ``,
        decorationStyle: ``,
        width: `auto`,
      },
      {
        fontSize: 15,
        decoration: ``,
        color: ``,
        decorationColor: ``,
        alignment: `left`,
        pageBreak: ``,
        decorationStyle: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        text: ` `,
        bold: false,
      },
      {
        columns: [
          {
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `ภาษาที่แปล......................................`,
            width: 200,
            decorationStyle: ``,
            alignment: `center`,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            width: 80,
            decoration: ``,
          },
          {
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            text: `ผู้แปลลงนาม........................................................`,
            pageBreak: ``,
            color: ``,
            width: 200,
            preserveLeadingSpaces: true,
            alignment: `center`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: ``,
            decoration: ``,
            width: 280,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
          },
          {
            fontSize: 15,
            color: ``,
            bold: false,
            decorationStyle: ``,
            width: 200,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `center`,
            text: `(.............................................................)`,
          },
        ],
      },
      {
        decorationStyle: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        decorationColor: ``,
        alignment: `left`,
        color: ``,
        bold: false,
        width: `auto`,
        fontSize: 15,
        decoration: ``,
      },
      {
        pageBreak: ``,
        decorationColor: ``,
        alignment: `left`,
        decoration: ``,
        color: ``,
        bold: `true`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        fontSize: 15,
        text: `สถานะของผู้ลงนาม (ตามประมวลกฎหมายแพ่งและพาณิชย์)`,
        width: `auto`,
      },
      {
        columns: [
          {
            color: ``,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            text: `[  ]`,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `center`,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            decoration: ``,
            text: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            width: 5,
          },
          {
            decorationColor: ``,
            text: `1. ผู้ป่วย ซึ่งบรรลุนิติภาวะ และมีสติสัมปชัญญะสมบูรณ์`,
            alignment: `left`,
            bold: false,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationStyle: ``,
            text: `[  ]`,
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            alignment: `center`,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            text: ``,
            decorationColor: ``,
            width: 5,
            decoration: ``,
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            bold: false,
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `2. สามีหรือภรรยาตามกฎหมาย กรณีที่ผู้ป่วยไม่มีสติสัมปชัญญะ (ไม่รู้สึกตัว)`,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            fontSize: 15,
            text: `[  ]`,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            width: `auto`,
            alignment: `center`,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            text: `3. ผู้ใช้อำนาจปกครองกรณีผู้ป่วยอายุต่ำกว่า 20 ปี บริบูรณ์ที่ยังไม่บรรลุนิติภาวะ`,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            text: `[  ]`,
            bold: false,
            decorationColor: ``,
            alignment: `center`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            width: 5,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            text: `4. ผู้พิทักษ์ กรณีผู้ป่วยเป็นคนเสมือนไร้ความสามารถ  (ตามคำสั่งศาล)`,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            text: `[  ]`,
            alignment: `center`,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            text: ``,
            width: 5,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: `5. ผู้อนุบาล กรณีผู้ป่วยเป็นคนไร้ความสามารถ (ตามคำสั่งศาล)`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            width: `auto`,
            bold: false,
          },
        ],
      },
      {
        color: ``,
        fontSize: 15,
        bold: false,
        decoration: ``,
        alignment: `left`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        pageBreak: ``,
        decorationStyle: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            width: 18,
            pageBreak: ``,
            color: ``,
            text: ``,
          },
          {
            text: `*กรณีข้อ 2-5 ให้ขอสำเนาเอกสารบัตรประชาชน หนังสือเดินทาง เอกสารที่ออกโดยราชการจากผู้ลงนามแทน โดยปกปิดข้อมูลศาสนาและหมู่เลือด ซึ่งปรากฎบนสำเนาดังกล่าว (หากมี) พร้อมลงนามรับรองสำเนาถูกต้อง`,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
          },
        ],
      },
      {
        text: ` `,
        fontSize: 15,
        bold: false,
        decorationStyle: ``,
        width: `auto`,
        color: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decoration: ``,
        decorationColor: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            text: `ความสัมพันธ์กับผู้ป่วย......................................................................................................................`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `เลขที่บัตรประจำตัวประชาชนผู้ลงนามแทนผู้ป่วย............................................................................`,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            width: `auto`,
            fontSize: 15,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            width: `auto`,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            text: `เบอร์โทรศัพท์...................................................................................................................................`,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            text: `อีเมล................................................................................................................................................`,
            color: ``,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
    ],
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    header: props.header,
    footer: props.footer,
  };
}
