import React, { useState,CSSProperties, useRef, SyntheticEvent, useEffect } from "react";
import {
  Menu,
  Button,
  Divider,
  Form,
  Input,
  DropdownProps,
  Item,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { useCookies } from "react-cookie";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { ErrorMessage } from "../react-lib/apps/common";
import {
  Event as MobEvent,
  State as MobState,
} from "../../../../patient-lib/MobTransformInterface";
import SetProp from "react-lib/frameworks/SetProp";

interface RouterProps {
  // type for `match.params`
  shippingOder: string; // must be type `string` since value comes from the URL
}

interface MakeAppointmentProps 
extends RouteComponentProps<RouterProps, any, { shippingOder?: string }> {
  onEvent: (e: MobEvent) => any;
  divisionServiceBlockList: any ;
  channelList?: any[];
  divisionServiceBlockConfirm: any;
}

const MakeAppointment = (props: MakeAppointmentProps) => {
  const object = [{id: -1, value: -1, text: 'เภสัชกร'}]
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [dateChoices, setDateChoices] = useState([]);
  const [filterdatas, setFilterdatas] = useState([]);
  const [timeRangeChoices, setTimeRangeChoices] = useState([]);
  const [shippingOder, setShippingOder] = useState<string | number | undefined>(
    undefined
  );
  const [date, setDate] = useState<string | undefined>(undefined);
  const [time, setTime] = useState<string | undefined>(undefined);
  const [division, setDivision] = useState<number | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [channelID, setchannelID] = useState<number | undefined>(undefined);
  const [error, setError] = useState<string | null | any[] | {}>(null);

  const COLOR = {
    primary: "var(--primary-theme-color)",
    secondary: "#F2C94C",
    violet_blue: "#2D247F",
    font_white: "white",
    dark_grey: "#333333",
    orange_light: "#EB5757",
    skybule: "#EFE4F2",
    bule_tu: "#90419A" ,
    red: "#D90416",
    green: "#26A653",

  };

  const styles = {
    button_rounded: {
      background: COLOR.primary,
      color: "white",
      fontSize: "18px",
      height: "50px",
      width: "90%",
      marginBottom: "50px",
      borderRadius: "50rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    } as CSSProperties,
  }

  

  useEffect(() => {
    setIsLoading(true)
    setShippingOder(parseInt(props.match.params.shippingOder));
    const channelChannel = props.channelList?.filter((item) => item.division_name === "ห้องจ่ายยา")
      if(channelChannel){
        setchannelID(channelChannel[0].id)
      } else {
        setchannelID(undefined)
      }
    props.onEvent({
        message: "HandleSelectDivisionServiceBlock",
        params: {}
      });
  }, []);

  useEffect(() => {
    setIsLoading(false)
    console.log("chen divisionServiceBlockList ",props.divisionServiceBlockList)
    if (props.divisionServiceBlockList) {
      const dateList: Array<[]> = props.divisionServiceBlockList.dates
      if (dateList !== undefined) {
        let stateOptions:any = [];
        dateList.map((state, index) => {
          stateOptions.push({key: index, text: state, value: state})
        })
        console.log('chen stateOptions: ', stateOptions)
        if (stateOptions !== null) {
          setIsLoading(false)
          setDateChoices(stateOptions)
        }
      }
    }
  },[props.divisionServiceBlockList])

  useEffect(() => {
    setIsLoading(true)
        if (props.divisionServiceBlockConfirm !== null){
          if (channelID !== undefined){
            history.push(`/Chat/${channelID}`);
          }else {
            history.push(`/tuh-transform`);
          }
        } 
  },[props.divisionServiceBlockConfirm])

  const handleDateChange = async (
    e: SyntheticEvent,
    { value }: DropdownProps
  ) => {
    const dateList: Array<[]> = props.divisionServiceBlockList.items
    let fiterdata:any = dateList.filter( (item:any) => item.date === value as string )
    console.log('chen fiterdata: ', fiterdata)
    let Options:any = [];
        fiterdata.map((state:any, index:any ) => {
          if (index === 0){
            Options.push({key: index, text: state.label, value: state.label})
          } else {
            Options.map((item:any) => {
              if (item.text !== state.label ){
                Options.push({key: index, text: state.label, value: state.label})
              }
            })
          }
        })
        console.log('chen Options : ', Options)
        
        if (Options !== null) {
          setFilterdatas(fiterdata)
          setTimeRangeChoices(Options)
        }
    setDate(value as string);
  };

  const handleTimeChange = async (
    e: SyntheticEvent,
    { value }: DropdownProps
  ) => {
    if (value !== null){
    let fiterdata:any= filterdatas.filter( (item:any) => item.label === value as string && item.date === date )
    console.log('chen filterdatas: ', fiterdata)
      if (fiterdata !== null && fiterdata !== undefined ){
        setDivision(fiterdata[0].id);
      }
    } else {
      setDivision(null);
    }
    setTime(value as string);
  };

  const handleConfirmAppointment = async () => {
    if (division !== null && shippingOder !== undefined){
      props.onEvent({
        message: "HandlePostDivisionServiceBlock",
        params: { division: division , shipping: shippingOder }
      });
    }
    setIsLoading(true)
  };


  return (
    <div className="patient-select-appointment">
      <ErrorMessage error={error} />
      <ErrorMessage success={success} />
      <h2>
        <FormattedMessage id="appoint.select_appointment" />
      </h2>
      <h4>
        <FormattedMessage id="appoint.division" />{" : หน่วยเภสัชกร"}
      </h4>
      <Divider />
      <Form>
        <Form.Group inline>
          <Form.Field width={2}>
            <FormattedMessage id="appoint.consultant" />{" "}
            <span style={{ color: "red" }}>*</span>
          </Form.Field>
          <Form.Dropdown
            width={14}
            text='เภสัชกร'
            search
            selection
            style={{ width: "100%" }}
            options={object}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={2}>
            <FormattedMessage id="common.date" />{" "}
            <span style={{ color: "red" }}>*</span>
          </Form.Field>
          <Form.Dropdown
            width={14}
            search
            selection
            clearable
            style={{ width: "100%" }}
            onChange={handleDateChange}
            loading={isLoading}
            options={dateChoices}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={2}>
            <FormattedMessage id="common.time" />{" "}
            <span style={{ color: "red" }}>*</span>
          </Form.Field>
          <Form.Dropdown
            width={14}
            search
            selection
            clearable
            style={{ width: "100%" }}
            onChange={handleTimeChange}
            loading={isLoading}
            options={timeRangeChoices}
            value={time}
            selectOnBlur={false}
          />
        </Form.Group>
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <Button 
          primary
          content={<FormattedMessage id="common.confirm" />}
          fluid
          // onClick={handleOpenModConfirmAppointment}
          loading={isLoading}
          onClick={ () => handleConfirmAppointment()}
          disabled={isLoading || !time || !date || !division}
          style={styles.button_rounded}>ยืนยันนัดหมาย</Button>
      </div>
      {}
    </div>
  );
};


MakeAppointment.defaultProps = {
  onEvent: () => {},
  channelList:[],
  divisionServiceBlockList: [] ,
  divisionServiceBlockConfirm: null,
};

export default MakeAppointment
