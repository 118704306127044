import React from 'react';
import PropTypes from 'prop-types';
import UserService from '../../../react-lib/apis/services/UserService';
import { Button, Container, Form, Header, Icon, Image, Menu, Popup } from 'semantic-ui-react';
import { vcMessenger } from '../../compat/vc-websocket';


const smallPadding = {
  paddingTop: "8px",
  paddingBottom: "8px",
}

const transparentBGStyle = {
  backgroundColor: "transparent"
};

const zeroPXPaddingStyle = {
  padding: "0px"
};

const zeroPointFourEMPaddingStyle = {
  padding: "0.4em"
};

const fivePXPaddingStyle = {
  padding: "5px"
};

const containerInMenuStyle = {
  margin: "0px",
  alignItems: "center"
};

class MenuBar extends React.Component<any, any> {

  static propTypes: any;
  static defaultProps: any;

  constructor(props: any) {
    super(props);
    this.state = {
      ui: {
        showLogin: false,
        wsIconName: "refresh",
        wsIconColor: "blue",
        wsIconStatus: "connecting...",
        wsIconLoading: true
      }
    };

    vcMessenger.onConnectionOpen(() => {
      this.setState({
        ui: {
          wsIconName: "check",
          wsIconColor: "green",
          wsIconStatus: "connected",
          wsIconLoading: false
        }
      });
    });

    vcMessenger.onConnectionClose(() => {
      this.setState({
        ui: {
          wsIconName: "remove",
          wsIconColor: "red",
          wsIconStatus: "disconnected",
          wsIconLoading: false
        }
      });
    });

    const menuBarOnMessage = (msg: any) => {
      if (msg.source === "users") {
        this.handleUsersMessage(msg);
      } else if (msg.event === "alert") {
        // TODO: Implement alert
        alert(msg.message);
        // Util.alert(msg.message, 'yellow', _('รับทราบ'))
      }
    }
    vcMessenger.onMessage(menuBarOnMessage);

    this.fetchSessionInfo();
  }

  componentDidMount() {
    if (this.props.django && this.props.django.division) {
      this.connectWebSocket()
    }
  }

  componentDidUpdate(prevProps: any) {
    let prevDjango: any = {}
    let thisDjango: any = {}
    if(prevProps.django){
      prevDjango = prevProps.django
    }
    if(this.props.django){
      thisDjango = this.props.django
    }
    if (prevDjango.division !== thisDjango.division) {
      this.connectWebSocket()
    }
  }

  connectWebSocket = () => {
    console.log('connectWebSocket: ');
    // Need to send DJANGO via REST instead of template rendering
    if ((vcMessenger as any).isSupported) {
      // Connect to websocket
      if (!vcMessenger.isConnected()) {  // Prevent duplicate connection
        vcMessenger.connect(this.props.django && this.props.django.division && this.props.django.division.code ? this.props.django.division.code : '');
      }
    } else {
      // Websocket not supported
      this.setState({
        ui: {
          wsIconName: '',
          wsIconColor: 'grey',
          wsIconStatus: 'Not supported',
          wsIconLoading: false,
        },
      });
    }
  }


  fetchSessionInfo() {
    // BODY PARAMS 
    // inactivity_timeout
    // session_expired

    const userService = new UserService();
    userService
      // @ts-ignore
      .getSessionInfo()
      .then((res: any) => {
        const inactivityTimeout = res.data.inactivity_timeout;
        const sessionExpired = res.data.session_expired;

        if (inactivityTimeout) {
          this.setState({ ui: { showLogin: true } });
        }
        if (sessionExpired) {
          this.displayLoggedOutMessage();
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          this.displayLoggedOutMessage();
        }
      });
  }

  createLoginUrl() {
    let loginUrl =
      "/users/login/?next=" +
      encodeURIComponent(window.location.pathname + window.location.search);
    let comname = this.props.django && this.props.django.device && this.props.django.device.computer_name ? this.props.django.device.computer_name : ''
    return loginUrl + "&comname=" + comname;
    
    // console.log(' this.props.django.device.computer_name',this.props.django.device.computer_name)
    // return loginUrl + '&comname=' + this.props.django.device.computer_name;
  }

  displayLoggedOutMessage() {
    // alert('You have been logged out. Please re-login');
    window.location.href = this.createLoginUrl();
    // Util.alert('You have been logged out. Please re-login', 'red', 'close', 10000, function(){
    //     location.href = this.createLoginUrl()
    // })
  }

  handleUsersMessage(msg: any) {
    if (msg.event === "inactivity_timeout") {
      this.fetchSessionInfo();
    } else if (msg.event == "logged_out") {
      // we still need to fetch to check if our session_expired (User can login multiple sessions)
      // our session might not expired
      this.fetchSessionInfo();
    }
  }

  render() {
    return (
      <Menu size="mini">
        {this.props.showHomeButton && (
          <Menu.Item style={zeroPXPaddingStyle}>
            <a href="/">
              <Button
                // circular
                fluid
                icon={"home"}
                size={"huge"}
                className="noBoxShadow"
                style={{...transparentBGStyle, ...smallPadding}}
              />
            </a>
          </Menu.Item>
        )}

        {this.props.showAccount && (
          <Menu.Item header style={zeroPXPaddingStyle}></Menu.Item>
        )}

        {this.props.showAccount && (
          <Menu.Item>
            <Image avatar src={this.props.userImage} />
          </Menu.Item>
        )}

        {this.props.showAccount && (
          <Menu.Item style={zeroPointFourEMPaddingStyle}>
            <Form>
              <Form.Group style={{ margin: "0px", alignItems: "center" }}>
                <Form.Field>
                  {/* <Header>{"DJANGO.user.fullname"}</Header> */}
                  <Header as="h5">
                    {this.props.django &&
                    this.props.django.user &&
                    this.props.django.user.full_name
                      ? this.props.django.user.full_name
                      : ""}
                  </Header>
                </Form.Field>
                <Form.Field className="noPadding">
                  <Popup
                    position="bottom left"
                    trigger={
                      <Icon
                        size="small"
                        circular
                        inverted
                        name={this.state.ui.wsIconName}
                        loading={this.state.ui.wsIconLoading}
                        color={this.state.ui.wsIconColor}
                      />
                    }
                    content={this.state.ui.wsIconStatus}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Menu.Item>
        )}

        {this.props.showAccount && (
          <Menu.Item header>
            {this.props.django &&
            this.props.django.division &&
            this.props.django.division.code
              ? this.props.django.division.code
              : ""}
          </Menu.Item>
        )
        // <Menu.Item header>{DJANGO.division.code}</Menu.Item>
        }

        {this.props.showBackButton && (
          <Menu.Item style={fivePXPaddingStyle}>
            <Button
              circular
              fluid
              size={"large"}
              style={transparentBGStyle}
              onClick={this.props.onGoBack}
            >
              <Icon name="caret left" />
              Back
            </Button>
          </Menu.Item>
        )}

        {this.props.children}

        {this.props.showAccount && (
          <Menu.Menu position="right">
            { this.props.rightChildren && (
              <Menu.Item> 
                {this.props.rightChildren}
              </Menu.Item>
            )}
            <Menu.Item>
              <Button
                // style={{ fontSize: "0.8rem" }}
                color="red"
                onClick={() => {
                  vcMessenger.dispose();
                  let loginUrl = encodeURIComponent(this.createLoginUrl());
                  window.location.href = "/users/logout/?next=" + loginUrl;
                }}
              >
                Log Out
              </Button>
            </Menu.Item>
          </Menu.Menu>
        )}

        {/* {this.props.showAccount &&
        <Login relogin={true} showRelogin={true} open={this.state.ui.showLogin}/>
        } */}
      </Menu>
    );
  }
}

MenuBar.propTypes = {
  showHomeButton: PropTypes.bool,
  showAccount: PropTypes.bool,
  showSwitchUser: PropTypes.bool,
  showBackButton: PropTypes.bool,
  onGoBack: PropTypes.func,
  userImage: PropTypes.string,
  django: PropTypes.object,
};

MenuBar.defaultProps = {
  showHomeButton: true,
  showAccount: true,
  showSwitchUser: false,
  showBackButton: false,
  userImage: "/static/images/person.png",
  django: {},
};

export default MenuBar;
