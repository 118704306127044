export default class OfficialAccountController {
    constructor(props) {
        this.diagManager = props.diagManager;
        this.coreManager = props.coreManager;
    }

    loadDivisionProfile = async (apiToken, divisionId) => {
        let params = {
            apiToken: apiToken,
            divisionId: divisionId
        }
        const [data, error] = await this.diagManager.getDivisionProfileWithID(params);
        return [data, error]
    }

    searchDiagRuleList = async (apiToken, search, divisionId) => {
        var params = { 
            apiToken: apiToken,
            search: search,
            divisionId: divisionId,
        }
        const [data, error] = await this.diagManager.getDiagRuleList(params);
        return [data, error]
    }

    getDivision = async ({ parent, apiToken } = {}) => {
        const [data, error] = await this.coreManager.getDivision({ parent, apiToken })
        return [data, error]
    }

    getDivisionDetail = async ({ divisionId, apiToken } = {}) => {
        const [data, error] = await this.coreManager.getDivisionDetail({ divisionId, apiToken })
        return [data, error]
    }
}