export default class DiagRuleController {
    constructor(props) {
        this.diagManager = props.diagManager;
    }

    getDiagRuleList = async (apiToken) => {
        var params = { apiToken: apiToken }
        const [data, error] = await this.diagManager.getDiagRuleList(params);
        return [data, error]
    }

    searchDiagRuleList = async (apiToken, search) => {
        var params = { 
            apiToken: apiToken,
            search: search,
        }
        const [data, error] = await this.diagManager.getDiagRuleList(params);
        console.log("...... data: ", data, ", error: ", error);
        return [data, error]
    }
}