import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/CardPatientSearch.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import DGrid 1.0
import "../Common/Util.js" as Util

CardLayout {
    id: root
    signal selected(int patientID, var patientData)
    property alias targetHN : txtHN.text

    title: "ค้นหาผู้ป่วย"
    headerColor: "blue"
    toggleCard: false

    content: [
        // Patient ---------------------------------------------------------------------------------
        RestStore{
            id: rstPatient
            target: "/apis/REG/patient/"
            onFetched:{
                grdPatient.model = rstPatient
            }
        },

        Loader {
            id: ldrPatientOldName
            Modal {
                id: modPatientOldName
                onShowed: {
                    crdPatientOldName.refresh()
                }
                CardPatientOldName {
                    id: crdPatientOldName
                    patient_id: grdPatient.selectedRow.id
                    hn: grdPatient.selectedRow.hn
                    name: grdPatient.selectedRow.full_name
                    
                    hideCallback: function(){
                        ldrPatientOldName.active = false
                        modPatientOldName.hide()
                    }
                }
            }
        },

        Grid{
            Row{
                Column{
                    className: "sixteen wide"
                    Form{
                        Fields {
                            className:"inline"
                            Field{
                                label:"HN"
                                className:"two wide"
                            }
                            Field{
                                className:"four wide"
                                TextBox{
                                    id: txtHN
                                    doc_label:"HN"
                                    text: targetHN
                                    onEntered:{
                                        filterSearch()
                                    }
                                }
                            }
                            Field{
                                className:"four wide"
                                label:"ชื่อ นามสกุล (ห้ามใส่คำนำหน้า)"
                            }
                            Field{
                                className:"six wide"
                                TextBox{
                                    id: txtName
                                    doc_label:"ชื่อ นามสกุล (ห้ามใส่คำนำหน้า)"
                                    property function debouncedFilterSearch: _.debounce(filterSearch, 250)

                                    onChanged:{
                                        if (text){
                                            txtName.debouncedFilterSearch()
                                        }
                                    }

                                    onEntered:{
                                        filterSearch()
                                    }
                                }
                            }
                        }

                        Fields{
                            className:"inline"
                            Field{
                                className:"two wide"
                                label:"Citizen ID"
                            }
                            Field{
                                className:"four wide"
                                TextBox{
                                    id: passport
                                    doc_label:"Citizen ID"
                                    onEntered:{
                                        filterSearch()
                                    }
                                }
                            }
                            Field{
                                label:"สัญชาติ"
                                className:"two wide"
                            }
                            Field{
                                className:"four wide"
                                ComboBox{
                                    id: cboNation
                                    doc_label:"สัญชาติ"
                                    search: true
                                    optionTextField: "full_name"
                                    emptyItem: _('ทุกสัญชาติ')
                                    onChanged: {
                                        filterSearch()
                                    }
                                    Component.onCompleted:{
                                        nationalityRest.fetch()
                                    }
                                    RestModel{
                                        id: nationalityRest
                                        property alias items: cboNation.items
                                        url: "/apis/core/nationality/?limit=99999"
                                        queryMimeType: "application/json"
                                        onFetched:{
                                            cboNation.items = cboNation.items;
                                        }
                                    }
                                }
                            }
                            Field{
                                className:"two wide"
                                Button{
                                    text:"ค้นหา"
                                    backgroundColor: "blue"
                                    className:"fluid"
                                    onClicked:{
                                        filterSearch()
                                    }
                                }
                            }
                            Field{
                                className:"two wide"
                                Button{
                                    text:"clear"
                                    backgroundColor: "teal"
                                    className:"fluid"
                                    onClicked:{
                                        clearAll()
                                    }
                                }
                            }
                        }
                    }
                }
            }
            Row{
                Column{
                    className: "sixteen wide"
                    DGrid {
                        function renderOldName(object, value, node, options){
                            if (object.old_name){
                                var dom = document.createElement('button')
                                dom.classList.add('ui')
                                dom.classList.add('button')
                                dom.classList.add('blue')
                                dom.classList.add('icon')
                                dom.addEventListener("click", function(){
                                    ldrPatientOldName.loadAndShowModal()
                                });
                                
                                var iconDom = document.createElement('i')
                                iconDom.classList.add('search')
                                iconDom.classList.add('icon')
                                dom.appendChild(iconDom)
                                node.appendChild(dom)
                            }
                        }

                        id: grdPatient
                        doc_label:"รายการข้อมูลผู้ป่วย"
                        height: 420
                        columns:[
                            DColumn {
                                label: "HN"
                                field: "hn"
                                width: 100
                            },
                            DColumn {
                                label: "Citizen ID / Passport No."
                                field: "citizen_passport"
                                width: 150
                            },
                            DColumn {
                                label: "ชื่อ นามสกุล (ไทย)"
                                field: "full_name_th"
                                sortable: false
                                width: 200
                            },
                            DColumn {
                                label: "ชื่อ นามสกุล (อังกฤษ)"
                                field: "full_name_en"
                                sortable: false
                                width: 200
                            },
                            DColumn {
                                label: "วัน/เดือน/ปีเกิด"
                                field: "birthdate"
                                width: 100
                            },
                            DColumn {
                                label: "ชื่อมารดา"
                                field: "mother"
                                width: 200
                            },
                            DColumn {
                                label: "ที่อยู่"
                                field: "address"
                                width: 200
                            },
                            DColumn {
                                label: "เบอร์โทรศัพท์"
                                field: "tel_num"
                                width: 100
                            },
                            DButtonColumn {
                                label: "ชื่อเดิม"
                                field: "old_name"
                                renderCell: grdPatient.renderOldName
                                sortable: false
                                width: 50
                            },
                        ]
                        model: rstPatient
                        onEntered:{
                            grdSelected()
                        }
                    }
                }
            }
            Row{
                Column{
                    className: "two wide"
                    Button{
                        className: "blue fluid"
                        text: "เลือก"
                        onClicked:{
                            grdSelected()
                        }
                    }
                }
            }
        }
    ]

    function grdSelected(){
        if(Object.keys(grdPatient.selectedRow).length !== 0){
            root.selected(grdPatient.selectedRow.id, grdPatient.selectedRow)
        }
    }

    function clearSearch(){
        txtName.text = ""
        passport.text = ""
        cboNation.clear()
    }

    function clearAll(){
        txtHN.text = ""
        txtName.text = ""
        passport.text = ""
        cboNation.clear()
        grdPatient.model = []
    }

    function filterSearch(){
        var query = {}

        if (txtName.text) {
            query.name_search = txtName.text
            query.is_old_name_search = true
        }

        if (txtHN.text) {
            query.hn = txtHN.text
        }

        if (cboNation.value && cboNation.value != '') {
            query.nationality = cboNation.value
        }

        if (passport.text) {
            query.citizen_passport = passport.text
        }

        rstPatient.filter(query)
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/CardPatientSearch.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}