import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `REG/ModConfirmCoverage.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import DGrid 1.0
import QmlWeb 1.0

Modal {
    id: root
    property var patient_id
    className: "large"
    property var readOnly: false
    onShowed:{
        coverageDetail.clear()
        coverageDetail.readOnly = root.readOnly
        refreshGrid()
        sgmConfirmCoverage.loading = true
        rmdPatient.fetch().then(function() {
            sgmConfirmCoverage.loading = false
        }).catch(function() {
            sgmConfirmCoverage.loading = false
        })
    }
    onHidden: {
        coverageDetail.clear()
    }
    function refreshGrid(){
        grdPatientCoverage.model = patientCoverageStore
        patientCoverageStore.filter({
            patient: patient_id,
            order_by_priority_and_id: true
        })

        var query = {}
        if (!root.patient_id) {
            return
        }
        // query['date__gte'] = dteStart.text
        // query['date__lte'] = dteEnd.text
        query['patient'] = root.patient_id
        query['to_real_doctor_order'] = true
        rmdAppointment.query = query
        rmdAppointment.fetch()
    }
    RestModel{
        id: rmdAppointment
        url: '/apis/APP/appointment/'
        property alias items: grdAppointment.model
    }
    RestModel{
        id: rmdPatient
        url:"/apis/REG/patient/"+patient_id+"/"
        property alias hn:lblHN.text
        property alias full_name:lblName.text
        property alias birthdate:lblBirthDay.text
        property alias full_age:lblAge.text
        property alias encounter_opd_no: lblVN.text
        property alias encounter_ipd_no: lblAN.text
        property alias welfare_category: lblWelfare.text
        property alias citizen_no: lblCitizenNo.text
        property alias passport_no : lblPassportNo.text
        property alias alien_no : lblAlienNo.text
        property alias reimburse_foreign_code : lblReimburseForeignCode.text
        property var citizen_type
        onFetched: {
            fldCitizenNo.displayNone = true
            fldPassportNo.displayNone = true
            fldAlienNo.displayNone = true
            fldNonResident.displayNone = true
            fldReimburseForeignCode.displayNone = true

            if(rmdPatient.citizen_type == "T") {
                fldCitizenNo.displayNone = false
            }
            else if(rmdPatient.citizen_type == "F") {
                fldPassportNo.displayNone = false
                fldReimburseForeignCode.displayNone = false
            }
            else if(rmdPatient.citizen_type == "A") {
                fldAlienNo.displayNone = false
                fldReimburseForeignCode.displayNone = false
            }
            else if(rmdPatient.citizen_type == "N") {
                fldNonResident.displayNone = false
            }
        }
    }
    Text{
        id: patientCoverageID
        text: ""
        displayNone: true
        onTextChanged:{
            if(text === ""){
                saveBtn.displayNone = false
                editBtn.displayNone = true
                coverageDetail.confirmEnabled = false
                activeBtn.displayNone = true
                cancleBtn.displayNone = true
            }
            else{
                saveBtn.displayNone = true
                editBtn.displayNone = false
                coverageDetail.confirmEnabled = true

            }
        }
    }
    Segment {
        id: sgmConfirmCoverage
        style: "background-color: #DDDDDD"
        Link{
            doc_label:"ปุ่มปิด"
            href: "javascript:void(0)"
            text: "&#10006"
            // text: "x"

            style: {
                return{
                    position:"absolute",
                    right:"5px",
                    top:"5px",
                    color:"red",
                    fontSize:"x-large",
                    zIndex:"2"
                };
            }

            onClicked:{
                root.hide()
            }
        }
        Segment {
            className: "raised darkblue inverted"
            Form{
                inline:true
                Fields{
                    Field{
                        className:"three wide"
                        label:"HN: "
                        labelColor: "white"
                        LabelTag{
                            className: "fluid"
                            id: lblHN
                        }
                    }
                    Field{
                        className:"five wide"
                        label:"ชื่อ: "
                        labelColor: "white"
                        LabelTag{
                            className: "fluid"
                            id: lblName
                        }
                    }
                    Field{
                        className:"three wide"
                        label:"วันเกิด: "
                        labelColor: "white"
                        LabelTag{
                            className: "fluid"
                            id: lblBirthDay
                        }
                    }
                    Field{
                        className:"three wide"
                        label:"อายุ: "
                        labelColor: "white"
                        LabelTag{
                            className: "fluid"
                            id: lblAge
                        }
                    }
                    Field {
                        className: "two wide"
                        Button {
                            className: 'fluid'
                            text: 'สแกน'
                            backgroundColor: 'orange'
                            enabled: !root.readOnly
                            onClicked: {
                                ldrScanner.loadAndShowModal()
                            }
                        }
                        Loader {
                            id: ldrScanner
                            onLoaded: {
                                modScanner.patientId = patient_id
                            }
                            Common.ModScanner {
                                id: modScanner
                            }
                        }
                    }
                }
                Fields{
                    Field {
                        id: fldCitizenNo
                        displayNone: true
                        className: "five wide"
                        label: "เลขบัตรประชาชน"
                        labelColor: "white"
                        LabelTag {
                            className: "fluid"
                            id: lblCitizenNo
                            text: "-"
                        }
                    }
                    Field {
                        id: fldPassportNo
                        displayNone: true
                        className: "five wide"
                        label: "หนังสือเดินทาง"
                        labelColor: "white"
                        LabelTag {
                            className: "fluid"
                            id: lblPassportNo
                            text: "-"
                        }
                    }
                    Field {
                        id: fldAlienNo
                        displayNone: true
                        className: "five wide"
                        label: "หมายเลขใบต่างด้าว"
                        labelColor: "white"
                        LabelTag {
                            className: "fluid"
                            id: lblAlienNo
                            text: "-"
                        }
                    }
                    Field {
                        id: fldReimburseForeignCode
                        LabelTag {
                            id: lblReimburseForeignCode
                            className: "fluid"
                            text: "-"
                        }
                    }
                    Field {
                        id: fldNonResident
                        displayNone: true
                        LabelTag {
                            className: "fluid"
                            text: "ไม่มีเอกสารยืนยันตัว"
                        }
                    }

                    Field{
                        className:"four wide"
                        label:"Encounter OPD:"
                        labelColor: "white"
                        LabelTag{
                            className: "fluid"
                            id: lblVN
                            text: "-"
                        }
                    }
                    Field{
                        className:"four wide"
                        label:"Encounter IPD: "
                        labelColor: "white"
                        LabelTag{
                            className: "fluid"
                            id: lblAN
                            text: "-"
                        }
                    }
                    Field{
                        className:"four wide"
                        label:"สิทธิสวัสดิการ:"
                        labelColor: "white"
                        LabelTag{
                            className: "fluid"
                            id: lblWelfare
                            text: "-"
                        }
                    }
                }
            }
        }
        Segment {
            style: "background-color: #F3F3F3"
            className: "raised"
            Grid{
                Column{
                    className:"eight wide"
                    Text{
                        text:"รายชื่อสิทธิ์"
                        style:"font-weight:bold;"
                    }
                }
                Column{
                    className:"eight wide"
                    Text{
                        text:"นัดหมาย"
                        style:"font-weight:bold;"
                    }
                }
                Column{
                    className:"eight wide"
                    DGrid{
                        id: grdPatientCoverage
                        doc_label:"รายชื่อสิทธิ์"
                        height:210
                        columns:[
                            DColumn{ field:"coverage_name"; label:"Coverage Name"; width:28},
                            DColumn{ field:"payer_name"; label:"Payer Name"; width:35},
                            DColumn{ field:"service_type_name"; label:"Service Type"; width:18},
                            DColumn{ field:"priority"; label:"Priority"; width:12},
                            DColumn{ field:"active"; label:"สถานะ" ; width:18
                                function renderCell(object, value, node, options){
                                    var text = document.createElement("label")
                                    if(value){
                                        text.innerText = "รับรองสิทธิ์";
                                    }
                                    else{
                                        text.innerText = "ระงับสิทธิ์";
                                        text.style.color = "red";
                                    }
                                    node.appendChild(text);
                                }
                            },
                        ]
                        function renderRow(row, args, dojoUtil){
                            if(args[0].is_expired){
                                dojoUtil.domStyle.set(row,'color', 'red')
                            }
                            else{
                                dojoUtil.domStyle.set(row,'color', 'blue')
                            }
                            return row
                        }
                        RestStore{
                            id: patientCoverageStore
                            target: "/apis/core/patientcoverage/"
                        }
                        onSelected:{
                            coverageDetail.clear()
                            coverageDetail.rest.url = "/apis/core/patientcoverage/" + selectedRow.id + "/"
                            coverageDetail.rest.fetch()
                            patientCoverageID.text = selectedRow.id
                            if(selectedRow.active){
                                activeBtn.displayNone = true
                                cancleBtn.displayNone = false
                            }
                            else{
                                activeBtn.displayNone = false
                                cancleBtn.displayNone = true
                            }
                        }
                    }
                    Form{
                        Dom{tagName:"br"}
                        Fields{
                            className: "inline"
                            Field{
                                className:"seven wide"
                                Button{
                                    id: newConfirm
                                    className:"blue fluid"
                                    text:"เพิ่มสิทธิใหม่"
                                    enabled: !root.readOnly
                                    onClicked:{
                                        coverageDetail.deselectedGrid()
                                        coverageDetail.clear()
                                    }
                                }
                            }
                        }
                    }
                }
                Column{
                    className:"eight wide"
                    DGrid{
                        id: grdAppointment
                        height: 260
                        columns:[
                            DColumn{
                                field: "date"
                                label: "วันที่"
                                width: 40
                            },
                            DColumn{
                                field: "doctor_name"
                                label: "ชื่อแพทย์"
                                width: 50
                            },
                            DColumn{
                                field: "division_name"
                                label: "แผนก"
                                width: 30
                            },
                            DCustomColumn {
                                label: 'Order'
                                width: 15
                                Container {
                                    className: 'fluid'
                                    LabelTag {
                                        id: lbtLabStatus
                                        className: 'circular'
                                        backgroundColor: 'grey'
                                        link: true
                                        text: modelData.type
                                        onHovered: {
                                            if (! popTooltip.hasTargetRegistered(lbtLabStatus)) {
                                                popTooltip.target = lbtLabStatus
                                                popTooltip.text = modelData.summary_detail
                                                popTooltip.show()
                                            }
                                        }
                                    }
                                }
                            }
                            // DCustomColumn{
                            //     field:"status"
                            //     label:"สถานะ"
                            //     width:40
                            //     Button{
                            //         text: modelData.status
                            //         className:"red fluid"
                            //         displayNone: modelData.status ? false : true
                            //         onClicked:{
                            //             modMedicalList.show()
                            //         }
                            //     }
                            // }
                        ]
                        Modal{
                            id: modMedicalList
                            className:"small"
                            Segment{
                                className: "raised"
                                DGrid{
                                    columns: [
                                        DColumn{field:"code"; label:"รหัส"; width:18},
                                        DColumn{field:"name"; label:"รายการ"; width:64},
                                        DColumn{field:"price"; label:"ราคา (บาท)"; width:18},
                                        DColumn{field:"claimable"; label:"เบิกได้ (บาท)"; width:18},
                                        DColumn{field:"nonclaimable"; label:"เบิกไม่ได้ (บาท)"; width:18},
                                    ]
                                    model: [
                                        {code:"250005", name:"Complete Blood Count", claimable:"50", nonclaimable:"40", price:"90"},
                                        {code:"200068", name:"Electrolytes (Clotted Blood)",claimable:"100000", nonclaimable:"50000", price:"150000"},
                                        {code:"200152", name:"Total protein (Clotted Blood)",claimable:"10", nonclaimable:"40", price:"50"},
                                        {code:"200008", name:"Alubumin (Clotted Blood)",claimable:"20", nonclaimable:"30", price:"50"},
                                    ]
                                }
                                Dom{tagName:"br"}
                                Grid{
                                    Column{
                                        className:"five wide right floated"
                                        Button{
                                            text:"ปิด"
                                            className:"fluid red"
                                            onClicked:{
                                                modMedicalList.hide()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
        CardCoverageDetails{
            id: coverageDetail
            patient_hn: patient_id
            readOnly: root.readOnly
        }
        Form{
            Fields{
                Field{
                    className:"twelve wide"
                }
                Field{
                    className:"two wide"
                    Button{
                        id: activeBtn
                        className:"green fluid"
                        text:"รับรองสิทธิ์"
                        enabled: !root.readOnly
                        displayNone: true
                        onClicked:{
                            coverageDetail.action_type = "confirm"
                            coverageDetail.checkGroupNumber()
                        }
                    }
                    Button{
                        id: cancleBtn
                        className:"red fluid"
                        text:"ระงับสิทธิ์"
                        displayNone: true
                        enabled: !root.readOnly
                        onClicked:{
                            coverageDetail.action_type = "cancel"
                            confirmDialog.show()
                        }
                        ConfirmDialog{
                            id: confirmDialog
                            message : "ต้องการยกเลิกสิทธิ์ \"" + coverageDetail.cboCoverage_.text +
                            "\" ของผู้ป่วย: \"" + rmdPatient.full_name +
                            "\" HN: \"" + rmdPatient.hn + "\" ใช่หรือไม่"
                            confirmFunction : coverageDetail.cancelPatientCoverage
                        }
                    }
                }
                Field{
                    className:"two wide"
                    Button{
                        id: saveBtn
                        doc_label:"บันทึกสิทธิ์"
                        text:"บันทึก"
                        className:"green fluid"
                        enabled: !root.readOnly
                        onClicked:{
                            coverageDetail.action_type = "save"
                            coverageDetail.checkGroupNumber()
                        }
                    }
                    Button{
                        id: editBtn
                        text:"แก้ไข"
                        displayNone: true
                        enabled: !root.readOnly
                        className:"blue fluid"
                        onClicked:{
                            coverageDetail.action_type = "edit"
                            coverageDetail.checkGroupNumber()
                        }
                    }
                }
            }

        }
    }
}

        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/REG/ModConfirmCoverage.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}