import React from 'react';
import PropTypes from 'prop-types';
import { 
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import MenuBar from './MenuBar';

class MainLayout extends React.Component<any, any> {

  static propTypes: any;
  static defaultProps: any;

  render() {
    const {
      django,
      loading,
      loadingText,
      hideHomeButton,
      hideBackButton,
      hideSwitchUser,
      hideUserAccount,
      onGoBack,
      hideNorth,
      hideWest,
      hideMiddle,
      hideEast,
      middle,
      east,
      west,
      north,
      className,
      backgroundColor,
      middleStyle,
      northStyle,
    }: any = this.props;

    return (
      <Dimmer.Dimmable dimmed={loading} style={{height: '100%'}} className={'MainLayout ' + this.props.className}>
        <Dimmer active={loading} inverted>
          <Loader inverted size='massive'>{loadingText}</Loader>
        </Dimmer>

        <div className='layout-vertical' style={{backgroundColor}}>
          <div className='north' style={northStyle}>
            {!hideNorth && (
              <MenuBar
                // @ts-ignore
                showAccount={!hideUserAccount}
                showHomeButton={!hideHomeButton}
                showBackButton={!hideBackButton}
                showSwitchUser={!hideSwitchUser}
                django={django}
                onGoBack={onGoBack}
                rightChildren={this.props.menuBarRightChildren}
              >
                {north}
              </MenuBar>
            )}
          </div>

          <div className='layout-horizontal'>
            {(!hideWest && west) && (
              <div className='west'>
                {west}
              </div>
            )}

            {!hideMiddle && (
              <div className='middle mainlayout' style={{ ...middleStyle }}>
                <div>
                  {middle}
                </div>
              </div>
            )}

            {(!hideEast && east) && (
              <div className='east'>
                {east}
              </div>
            )}
          </div>
        </div>
      </Dimmer.Dimmable>
    )
  }
}

MainLayout.propTypes = {
  loading: PropTypes.bool,
  loadingText: PropTypes.string,

  onGoBack: PropTypes.func,

  hideHomeButton: PropTypes.bool,
  hideBackButton: PropTypes.bool,
  hideSwitchUser: PropTypes.bool,
  hideUserAccount: PropTypes.bool,

  hideNorth: PropTypes.bool,
  hideWest: PropTypes.bool,
  hideEast: PropTypes.bool,
  hideMiddle: PropTypes.bool,

  north: PropTypes.element,
  west: PropTypes.element,
  east: PropTypes.element,
  middle: PropTypes.element,

  django: PropTypes.object,

  backgroundColor: PropTypes.string,

  northStyle: PropTypes.object
};

MainLayout.defaultProps = {
  backgroundColor: "",
  hideBackButton: true,
  hideSwitchUser: true,
  loadingText: "Loading",
  onGoBack: () => {},
  django: {},
  northStyle: {}
};

export default MainLayout;