import React, { Component, useEffect, useRef, useState } from "react";
import { Tab, Loader, Dimmer } from "semantic-ui-react";
import SubPatientPayment from "react-lib/apps/IsHealth/BIL/SubPatientPayment";
import SubPatientPaymentStatus from "react-lib/apps/IsHealth/BIL/SubPatientPaymentStatus";
import { useCookies } from "react-cookie";
import moment from "moment";
import * as Util from "react-lib/utils";
import { injectIntl } from "react-intl";
interface Props {
  menuBar?: Component;
  controller: {
    getInvoice: ({
      apiToken,
      patientId,
      status
    }: {
      apiToken: string;
      patientId?: string | number;
      status?: string;
    }) => [null | { items: any[] }, null | {}, null | {}];
  };
  apiToken: string;
  patientData?: {
    id?: number | string;
  };
  onSetBackToApp?: (value) => void;
  // onNoPatientId?: () => void
}

const INVOICE_STATUS = {
  BILLED: "BILLED",
  PAID: "PAID",
  CANCELED: "CANCELED"
};

const PatientPayment = React.forwardRef((props: Props, ref) => {
  const [waitingInvoiceList, setWaitingInvoiceList] = useState([]);
  const [paidInvoiceList, setPaidInvoiceList] = useState([]);
  const [cancelInvoice, setCancelInvoice] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noPatientId, setNoPatientId] = useState(false);
  const [cookies] = useCookies([]);
  const isMounted = useRef(true);

  useEffect(() => {
    if (!props.apiToken && !cookies.apiToken) {
      props.noApiToken();
    }
    return () => (isMounted.current = false);
  }, []);

  React.useEffect(() => {
    checkBackToApp();
  }, [window.iosNative, window.MobNative]);

  const checkBackToApp = async () => {
    if (typeof window.iosNative !== "undefined") {
      try {
        window.iosNative.consoleLog("backToApp");
        const backToApp = await window.iosNative.backToApp;
        window.iosNative.consoleLog(backToApp);
        if (backToApp === "true") {
          // ios can only send string back
          window.iosNative.consoleLog("have back to app");
          props.onSetBackToApp(true);
        }
      } catch (e) {
        console.error(e);
        console.log(e.message);
      }
    } else if (typeof window.MobNative !== "undefined") {
      const backToApp = await window.MobNative.backToApp();
      if (backToApp) {
        props.onSetBackToApp(true);
      }
    }
  };

  useEffect(() => {
    if (props.patientData.id) {
      setNoPatientId(false);
      handleGetWaitingInvoice();
      var interval = setInterval(() => {
        handleGetWaitingInvoice();
      }, 16000);
      handleGetPaidInvoice();
      handleGetCancelInvoice();
    } else {
      setNoPatientId(true);
      // props.onNoPatientId()
    }
    return () => {
      clearInterval(interval);
    };
  }, [props.patientData.id, props.match.params.divisionId]);

  const handleGetWaitingInvoice = async () => {
    setIsLoading(true);
    if (!props.patientData.id) {
      setIsLoading(false);
      return;
    }
    const [res, err, net] = await props.controller.getInvoice({
      apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
      patientId: props.patientData.id,
      status: INVOICE_STATUS.BILLED,
      divisionId: props.match.params.divisionId
    });
    if (isMounted.current) {
      // res have next & prev url, currently not use :: CHERRY 13/07/2020 15.33
      setIsLoading(false);
      if (res) {
        setWaitingInvoiceList(res.items);
      } else {
        setWaitingInvoiceList([]);
      }
    }
  };

  const handleGetCancelInvoice = async () => {
    setIsLoading(true);
    if (!props.patientData.id) {
      setIsLoading(false);
      return;
    }
    const [res, err, net] = await props.controller.getInvoice({
      apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
      patientId: props.patientData.id,
      status: INVOICE_STATUS.CANCELED,
      divisionId: props.match.params.divisionId
    });
    if (isMounted.current) {
      // res have next & prev url, currently not use :: CHERRY 13/07/2020 15.33
      setIsLoading(false);
      if (res) {
        setCancelInvoice(res.items);
      } else {
        setCancelInvoice([]);
      }
    }
  };

  const handleGetPaidInvoice = async () => {
    setIsLoading(true);
    if (!props.patientData.id) {
      setNoPatientId(true);
      setIsLoading(false);
      return;
    }

    const [res, err, net] = await props.controller.getReceipt({
      apiToken: props.apiToken ? props.apiToken : cookies.apiToken,
      patientId: props.patientData.id,
      divisionId: props.match.params.divisionId,
      fromDate: Util.formatDate(moment().subtract(7, "days"))
    });
    if (isMounted.current) {
      setIsLoading(false);
      console.log({ res });
      if (res) {
        setPaidInvoiceList(res.items);
      } else {
        setPaidInvoiceList([]);
      }
    }
  };

  const panes = [
    {
      menuItem: props.intl.formatMessage({ id: "payment.pending_payment" }),
      // <FormattedMessage id="payment.pending_payment" />,
      render: () => createWaitingForPayment()
    },
    {
      menuItem: props.intl.formatMessage({ id: "payment.paid" }),
      render: () => createStatusForPayment()
    }
  ];

  const createStatusForPayment = () => {
    return [...paidInvoiceList, ...cancelInvoice]
      .sort((a,b) => (a.edited < b.edited) ? 1 : ((b.edited < a.edited) ? -1 : 0))
      .map((item, index) => {
        // return invoiceList.filter((item) => item.status_name === "PAID").map((item, index) => {
        return (
          <div className="sub-patient-payment-wrapper" key={index}>
            <SubPatientPaymentStatus detail={item} />
          </div>
        );
      });
  };

  const createWaitingForPayment = () => {
    return waitingInvoiceList.map((item, index) => {
      return (
        <div className="sub-patient-payment-wrapper" key={index}>
          <SubPatientPayment detail={item} />
        </div>
      );
    });
  };

  return (
    <div className="patient-payment">
      {props.menuBar}
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      <Dimmer.Dimmable
        className={`dimmable-area ${isLoading || noPatientId ? "" : "hidden"}`}
      >
        <Dimmer active={noPatientId}>
          {noPatientId && !isLoading ? (
            <h2>
              {props.intl.formatMessage({ id: "common.an_error_occurred" })}{" "}
              <br />{" "}
              {props.intl.formatMessage({ id: "payment.no_patient_id_error" })}
            </h2>
          ) : null}
        </Dimmer>
        <Dimmer active={isLoading} inverted>
          <Loader>Loading...</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  );
});

export default React.memo(injectIntl(PatientPayment, { forwardRef: true }));
