export default class PaymentController {
  constructor(props) {
    this.bilManager = props.bilManager;
  }

  getInvoice = async ({
    apiToken,
    patientId,
    status,
    divisionId
  } = {}) => {
    const [data, error] = await this.bilManager.getInvoice({
      apiToken,
      patientId,
      status,
      divisionId
    });
    return [data, error];
  };

  getInvoiceDetail = async ({
    apiToken,
    invoiceId
  } = {}) => {
    const [data, error] = await this.bilManager.getInvoiceDetail({
      apiToken,
      invoiceId
    });
    return [data, error];
  }

  postPaymentParameters = async ({
    apiToken,
    invoiceId
  } = {}) => {
    const [data, error] = await this.bilManager.postPaymentParameters({
      apiToken,
      invoiceId
    });
    return [data, error];
  }

  getInvoiceItemByItem = async ({
    apiToken,
    invoiceId
  } = {}) => {
    const [data, error] = await this.bilManager.getInvoiceItemByItem({
      apiToken,
      invoiceId
    });
    return [data, error];
  }

  getReceipt = async ({
    apiToken,
    patientId,
    divisionId,
    fromDate
  } = {}) => {
    const [data, error, network] = await this.bilManager.getReceipt({
      apiToken,
      patientId,
      divisionId,
      fromDate
    });
    return [data, error, network];
  }
}