import React, {
    CSSProperties,
    useRef,
    useEffect,
    useImperativeHandle,
    useState,
  } from "react";
  
  import {
    Event as MobEvent,
    State as MobState,
  } from "../patient-lib/MobTransformInterface";
  
  import { useHistory, RouteComponentProps } from "react-router-dom";

  import {
    Tab,
    Form,
    Button,
    Modal,
    Icon,
    Loader,
    TextArea,
    Image,
    Item,
    Dimmer,
  } from "semantic-ui-react";

  const icon = {
    drug: "/icon_white_medication.png",
    cancel: "/icon_cancel_white.png",
  };

  const COLOR = {
    primary: "var(--primary-theme-color)",
    secondary: "#F2C94C",
    violet_blue: "#2D247F",
    font_white: "white",
    dark_grey: "#333333",
    orange_light: "#EB5757",
    skybule: "#EFE4F2",
    bule_tu: "#90419A",
    red: "#D90416",
    green: "#26A653",
    brightOrange: "#E28B24",
  };

  const styles = {
    cardBox: {
      height: "calc(100vh - 155px)", 
      width: "100%" , 
      overflow: "scroll",
      paddingBottom: "30px",
    } as CSSProperties,
    cardLayout: {
      backgroundColor: "white",
      display: "grid",
      gridTemplateColumns: "70px auto 20px",
      alignItems: "center",
      fontSize: "18px",
      margin: "10px",
      padding: "15px",
      borderRadius: "10px",
      fontWeight: "bolder",
      boxShadow: "0px 2px 5px #888787",
    } as CSSProperties,
    iconDrug:{
        height: "50px",
        width: "50px",
        borderRadius: "25px",
        background: COLOR.bule_tu,
        justifyContent: "center",
        display: "flex",
        padding: "12px",
        alignItems: "center",
    } as CSSProperties,
  };

  type ShippingListProps = {
    onEvent: (e: MobEvent) => any;
    patientData: any ,
    shippingList: any,
    header: React.Component;
    history: any;
  };

  
  const ShippingList: React.FunctionComponent<ShippingListProps> = (
    props
  ) => {
  const history = useHistory();
  const chatListRef = useRef<any>();
  const [comfirmShipping, setComfirmShipping] = React.useState(false);
  const [checkOrder, setCheckOrder] = React.useState(false);
  const [shippingList, setShippingList] = useState<any>();
  const [deliverySecess, setDeliverySecess] = useState<[]>([]);
  const [drugDelivering, setDrugDelivering] = useState<[]>([]);
  const [texttest, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    props.onEvent({
      message: "HandleShippingOderlist",
      params: { patient: props.patientData.patient },
    });
  }, []);

  useEffect(() => {
    setIsLoading(false)
    if (props.shippingList !== null && props.shippingList !== undefined) {
      const listScessFilter:[] = props.shippingList.filter((item:any) => item.status_label === "จัดส่งสำเร็จ" )
      const listDeliveringFilter:[] = props.shippingList.filter((item:any) => item.status_label !== "จัดส่งสำเร็จ" )
      console.log('chen listScessFilter :  ', listScessFilter)
      console.log('chen listDeliveringFilter :  ', listDeliveringFilter)
      setDeliverySecess(listScessFilter)
      setDrugDelivering(listDeliveringFilter)
    }
        
  }, [props.shippingList]);

  const handleOpenDetail = (encouter:string,shippingList: string) => {
      history.push( `/tuh-transform/ConfirmShipping`, { encounter: encouter ,shipping: shippingList});
  }

  const panes = [
    { 
      menuItem: 'รอรับยา',
      render: () => <Tab.Pane attached={false}>
        <Dimmer.Dimmable>
          <Dimmer active={isLoading} inverted>
            <Loader inverted/>
          </Dimmer>
          <div style={styles.cardBox}>
            {  drugDelivering.map( (item:any,index) => {
              return(
                    <div key={index} style={styles.cardLayout} onClick = { () => handleOpenDetail(item.parcel_id,item.id)} >
                      {/* icon */}
                      <div >
                        <div style={styles.iconDrug}>
                          <Image src={icon.drug} />
                        </div>
                      </div>
                      {/* Label */}
                      <div>
                        <div >
                            {item.parcel_id? `${item.parcel_id}`:" -"}
                        </div>
                        <div >
                          <div style={{display: "flex"}}>
                              <div >{"สถานะ : "}</div> 
                              <div style={{ color: COLOR.brightOrange}}>{ "  รอรับยา"}</div> 
                          </div>
                        </div>
                      </div>
                        {/* icon next */}
                      <div>
                        <Icon name="angle right"></Icon>
                      </div>
                    </div>
                  )
                }) 
              }
          </div>
        </Dimmer.Dimmable>
      </Tab.Pane>,
    },
    {
      menuItem: 'รับยาแล้ว',
      render: () => <Tab.Pane attached={false}>
        <Dimmer.Dimmable>
          <Dimmer active={isLoading} inverted>
            <Loader inverted/>
          </Dimmer>
        <div style={styles.cardBox}>
          {  deliverySecess.map( (item:any,index) => {
            return(
                  <div key={index} style={styles.cardLayout} onClick = { () => handleOpenDetail(item.parcel_id,item.id)}>
                    {/* icon */}
                    <div >
                      <div style={styles.iconDrug}>
                        <Image src={icon.drug} />
                      </div>
                    </div>
                    {/* Label */}
                    <div>
                      <div >
                          {item.parcel_id? `${item.parcel_id}`:" -"}
                      </div>
                      <div >
                        <div style={{display: "flex"}}>
                            <div >{"สถานะ : "}</div> 
                            <div style={{ color: COLOR.brightOrange}}>{ " รับยาแล้ว"}</div> 
                        </div>
                      </div>
                    </div>
                      {/* icon next */}
                    <div>
                      <Icon name="angle right"></Icon>
                    </div>
                  </div>
                 )
              }) 
            }
          </div>
         </Dimmer.Dimmable>
  </Tab.Pane>,
    },
  ]
    return(
        <div >
        {props.header}
        <div>
          <Tab className="shippinglist"
            menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
        </div>
      );
    };

  ShippingList.defaultProps = {
    patientData: {},
    shippingList: [],
    header: {},
    history: null,
  } as ShippingListProps;
    
export default React.memo(ShippingList);



