import React, { useState, useRef, SyntheticEvent, useEffect } from "react";
import {
  Menu,
  Button,
  Divider,
  Form,
  Input,
  DropdownProps,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { QueueController, PatientAppointment, serial_to_datestr } from "./Time";
import "./Time.css";
import Cookies from "js-cookie";
import * as MODEL from "./Model";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { ErrorMessage } from "../common";
import ModConfirm from "../common/ModConfirm";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
interface RouterProps {
  // type for `match.params`
  appointId: string; // must be type `string` since value comes from the URL
}

interface PatientSelectAppointmentProps
  extends RouteComponentProps<RouterProps, any, { channelId?: string }> {
  apiToken: string;
  controller: QueueController;
  noApiToken: () => {};
  divisionId: number | string;
  intl: IntlShape;
}

const PatientSelectAppointment = (props: PatientSelectAppointmentProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [employeeChoices, setEmployeeChoices] = useState([]);
  const [dateChoices, setDateChoices] = useState([]);
  const [timeRangeChoices, setTimeRangeChoices] = useState([]);
  const [employee, setEmployee] = useState<string | number | undefined>(
    undefined
  );
  const [date, setDate] = useState<string | undefined>(undefined);
  const [time, setTime] = useState<string | undefined>(undefined);
  const [division, setDivision] = useState<number | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null | any[] | {}>(null);
  const isMounted = useRef(true);
  const [patientAppointmentId, setPatientAppointmentId] = useState<
    number | null
  >(null);
  const [patientAppointment, setPatientAppointment] =
    useState<PatientAppointment | null>(null);
  const [openModConfirmAppointment, setOpenModConfirmAppointment] =
    useState<boolean>(false);

  useEffect(() => {
    clearErrorSuccess();
    if (!props.apiToken && !Cookies.get("apiToken")) {
      props.noApiToken();
    } else {
      handleGetChoices();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    clearErrorSuccess();
    if (employee && date && time) {
      return;
    } else {
      handleGetChoices();
    }
  }, [employee, date, time, division]);

  const handleGetChoices = async () => {
    console.log(
      " handleGetChoices props.location.state.providerTypeCategory",
      props.location.state?.providerTypeCategory
    );
    if (!division) {
      return;
    }
    setIsLoading(true);
    const [res, err] = await props.controller.getAvailableDivisionServiceBlock({
      employee,
      date,
      timeRange: time,
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      division: division, //! Beware, each appointment might be different division
      providerTypeCategory: props.location.state?.providerTypeCategory
        ? "covid_vaccine"
        : null,
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setEmployeeChoices(res.employees);
        setDateChoices(res.dates);
        setTimeRangeChoices(res.time_ranges);
      }
    }
  };

  const handleEmployeeChange = async (
    e: SyntheticEvent,
    { value }: DropdownProps
  ) => {
    if (typeof value === "number" || typeof value === "string") {
      setEmployee(value);
    }
  };

  const handleDateChange = async (
    e: SyntheticEvent,
    { value }: DropdownProps
  ) => {
    setDate(value as string);
  };

  const handleTimeChange = async (
    e: SyntheticEvent,
    { value }: DropdownProps
  ) => {
    setTime(value as string);
  };

  React.useEffect(() => {
    setPatientAppointmentId(parseInt(props.match.params.appointId));
  }, [props.match.params.appointId]);

  React.useEffect(() => {
    if (patientAppointmentId) {
      clearErrorSuccess();
      handleLoadPatientAppointment();
    }
  }, [patientAppointmentId]);

  const handleLoadPatientAppointment = async () => {
    const [r, e, n] = await props.controller.getPatientAppointment({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      pk: patientAppointmentId,
    });
    if (isMounted.current) {
      if (r) {
        if (r.display_info) {
          setEmployee(r.display_info.employee);
          let date = r.display_info.start_datetime.split("T")[0];
          setDate(date);
          setTime(
            `${r.display_info.start_datetime}-${r.display_info.end_datetime}`
          );
        }
        setDivision(r.division);
        setPatientAppointment(r);
      } else {
        setPatientAppointment(null);
      }
    }
  };

  const handleConfirmAppointment = async () => {
    clearErrorSuccess();
    setIsLoading(true);
    setOpenModConfirmAppointment(false);
    const [res, err, network] =
      await props.controller.postSelectDivisionServiceBlock({
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
        patientAppointmentId: patientAppointmentId,
        division,
        timeRange: time,
        employee:
          props.location.state?.providerTypeCategory === "covid_vaccine"
            ? null
            : employee,
      });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setSuccess("สร้างนัดหมายสำเร็จ");
        if (
          props.location &&
          props.location.state &&
          props.location.state.channelId
        ) {
          setTimeout(() => {
            history.push(`/Chat/${props.location.state.channelId}/`);
          }, 1000);
        } else {
          setTimeout(() => {
            history.push("/Appointment");
          }, 1000);
        }
      } else if (err) {
        if (network.response.status === 500) {
          setError(" Error 500: เกิดข้อผิดพลาด");
        } else {
          setError(err);
        }
      }
    }
  };

  const clearErrorSuccess = () => {
    setError(null);
    setSuccess(null);
  };

  const handleOpenModConfirmAppointment = () => {
    setOpenModConfirmAppointment(true);
  };

  const handleCloseModConfirmAppointment = () => {
    setOpenModConfirmAppointment(false);
  };

  return (
    <div className="patient-select-appointment">
      <ModConfirm
        open={openModConfirmAppointment}
        titleName={<FormattedMessage id="appoint.confirm_create_appointment" />}
        alertText={<FormattedMessage id="appoint.ask_create_appointment" />}
        buttonLeft={<FormattedMessage id="common.cancel" />}
        buttonRight={<FormattedMessage id="common.confirm" />}
        onButtonLeftClick={handleCloseModConfirmAppointment}
        onButtonRightClick={handleConfirmAppointment}
      />
      <h2>
        <FormattedMessage id="appoint.select_appointment" />
      </h2>
      <h4>
        {`${props.intl.formatMessage({ id: "appoint.division" })}: 
        ${patientAppointment ? patientAppointment.division_name : ""}`}
      </h4>
      <Divider />
      <Form>
        <ErrorMessage error={error} />
        <ErrorMessage success={success} />
        <Form.Group inline>
          <Form.Field width={2}>
            <FormattedMessage id="appoint.consultant" />{" "}
            <span style={{ color: "red" }}>*</span>
          </Form.Field>
          <Form.Dropdown
            width={14}
            search
            selection
            clearable
            style={{ width: "100%" }}
            onChange={handleEmployeeChange}
            loading={isLoading}
            options={employeeChoices}
            value={employee}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={2}>
            <FormattedMessage id="common.date" />{" "}
            <span style={{ color: "red" }}>*</span>
          </Form.Field>
          <Form.Dropdown
            width={14}
            search
            selection
            clearable
            style={{ width: "100%" }}
            onChange={handleDateChange}
            loading={isLoading}
            options={dateChoices}
            value={date}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={2}>
            <FormattedMessage id="common.time" />{" "}
            <span style={{ color: "red" }}>*</span>
          </Form.Field>
          <Form.Dropdown
            width={14}
            search
            selection
            clearable
            style={{ width: "100%" }}
            onChange={handleTimeChange}
            loading={isLoading}
            options={timeRangeChoices}
            value={time}
          />
        </Form.Group>
      </Form>
      <div className="button-container">
        <Button
          primary
          content={<FormattedMessage id="common.confirm" />}
          fluid
          onClick={handleOpenModConfirmAppointment}
          loading={isLoading}
          disabled={isLoading || !time || !employee || !date}
        />
      </div>
      {}
    </div>
  );
};

PatientSelectAppointment.defaultProps = {};

export default React.memo(
  injectIntl(PatientSelectAppointment, { forwardRef: true })
);
