export default function toPDFMakeData(props: any) {
  return {
    pageOrientation: `portrait`,
    content: [
      {
        preserveLeadingSpaces: true,
        decorationColor: ``,
        pageBreak: ``,
        color: ``,
        bold: `true`,
        fontSize: `18`,
        width: `auto`,
        decorationStyle: ``,
        decoration: ``,
        alignment: `center`,
        text: `Consent Drug Screen Test`,
      },
      {
        decoration: ``,
        width: `auto`,
        fontSize: 15,
        pageBreak: ``,
        decorationStyle: ``,
        alignment: `left`,
        bold: false,
        decorationColor: ``,
        text: ` `,
        color: ``,
        preserveLeadingSpaces: true,
      },
      {
        decoration: ``,
        text: `To be completed by patient before testing:`,
        pageBreak: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decorationColor: ``,
        color: ``,
        width: `auto`,
        decorationStyle: ``,
        bold: false,
      },
      {
        columns: [
          {
            width: 30,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            fontSize: 15,
            bold: false,
            text: ``,
          },
          {
            alignment: `left`,
            fontSize: 15,
            text: `I hereby consent to urine  specimen collection and testing for below listed controlled substances by`,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
          },
        ],
      },
      {
        fontSize: 15,
        width: `auto`,
        pageBreak: ``,
        bold: false,
        decorationColor: ``,
        text: `Sapiens Medication Co.,Ltd. (“Hospital”) as follows:`,
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
        decorationStyle: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            color: ``,
            fontSize: 15,
            text: `	`,
            width: 35,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            width: `auto`,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `center`,
            bold: false,
            decorationColor: ``,
            text: `[  ]`,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            bold: false,
            color: ``,
            decorationColor: ``,
            width: 5,
            alignment: `left`,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            text: `Barbiturates`,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            color: ``,
            width: 190,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `center`,
            decorationColor: ``,
            decorationStyle: ``,
            text: `[  ]`,
            color: ``,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: 5,
            text: ``,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: `Benzodiazepines`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 35,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            text: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `[  ]`,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            alignment: `center`,
            color: ``,
            width: `auto`,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            text: ``,
            width: 5,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            text: `Cocaine`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            alignment: `left`,
          },
          {
            alignment: `left`,
            width: 208,
            pageBreak: ``,
            text: `	`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
          },
          {
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `[  ]`,
            alignment: `center`,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
          },
          {
            decoration: ``,
            width: 5,
            color: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            bold: false,
            alignment: `left`,
            width: `auto`,
            fontSize: 15,
            text: `Methadone`,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            text: ``,
            decoration: ``,
            decorationColor: ``,
            width: 35,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
          },
          {
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            text: `[  ]`,
            alignment: `center`,
            fontSize: 15,
            width: `auto`,
          },
          {
            text: ``,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            width: 5,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            text: `Cannabinoid / Tetrhydrocannabinol / Marljuana`,
            decorationColor: ``,
          },
          {
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            width: 29,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
            text: `[  ]`,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `center`,
            decoration: ``,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            width: 5,
            pageBreak: ``,
            text: ``,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `Opiates`,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 35,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
          },
          {
            fontSize: 15,
            color: ``,
            text: `[  ]`,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            width: 5,
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            text: `Amphetamines and /or Methamphetamines`,
            color: ``,
            bold: false,
            pageBreak: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
          },
          {
            width: 45,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            fontSize: 15,
          },
          {
            color: ``,
            text: `[  ]`,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            width: `auto`,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            width: 5,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            text: ``,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `Other,please specify:..................................`,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            bold: false,
            text: `Company requesting drug screen test : .................................................................................................................................`,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            text: `Company contact person : ........................................................................`,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            width: 5,
            fontSize: 15,
            text: `		`,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `Telephone : .....................................................`,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            width: 30,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            text: `I understand and agree to provide an additional urine specimen if my urine sample has been found to be `,
            alignment: `left`,
            color: ``,
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
        ],
      },
      {
        pageBreak: ``,
        decorationColor: ``,
        alignment: `left`,
        bold: false,
        width: `auto`,
        fontSize: 15,
        color: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decoration: ``,
        text: `tampered with or diluted.`,
      },
      {
        columns: [
          {
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            color: ``,
            decorationColor: ``,
            width: 30,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            text: `I hereby authorize the hospital to review and disclose my test results to the requesting company stated`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
          },
        ],
      },
      {
        pageBreak: ``,
        bold: false,
        preserveLeadingSpaces: true,
        text: ` above.`,
        color: ``,
        decorationStyle: ``,
        alignment: `left`,
        width: `auto`,
        decoration: ``,
        fontSize: 15,
        decorationColor: ``,
      },
      {
        columns: [
          {
            text: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            width: 30,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            text: `I confirm  that the list below contains a  correct and complete list  of all medications, vitamins,  herbal`,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        text: `supplements, and food supplements (prescription or over the counter) that I am currently taking or have taken within the past 30 (Thirty) days.`,
        fontSize: 15,
        alignment: `left`,
        decorationColor: ``,
        color: ``,
        width: `auto`,
        bold: false,
        decorationStyle: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
            width: `auto`,
            bold: false,
            alignment: `left`,
            text: `........................................................................................................................................................................................................ `,
            decoration: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `........................................................................................................................................................................................................ `,
            decorationColor: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            text: `........................................................................................................................................................................................................ `,
            alignment: `left`,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        color: ``,
        decorationStyle: ``,
        decoration: ``,
        width: `auto`,
        bold: false,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        fontSize: 15,
        text: ` `,
      },
      {
        columns: [
          {
            bold: false,
            decoration: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            text: `Signature......................................................`,
            decorationColor: ``,
            width: 200,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            text: ``,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            width: 80,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            text: `Witness 1........................................................`,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            alignment: `center`,
            width: 200,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: 200,
            text: `(.............................................................)`,
            color: ``,
            alignment: `center`,
            bold: false,
            decoration: ``,
          },
          {
            decoration: ``,
            width: 80,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            text: ``,
            pageBreak: ``,
          },
          {
            alignment: `center`,
            width: 200,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
            text: `(.............................................................)`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            color: ``,
            alignment: `center`,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `Date................................. Time.........................`,
            width: 200,
          },
          {
            width: 80,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            color: ``,
          },
          {
            text: `Witness 2........................................................`,
            decorationStyle: ``,
            width: 200,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            alignment: `center`,
            bold: false,
            pageBreak: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            width: 280,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
          },
          {
            alignment: `center`,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `(.............................................................)`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            width: 200,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 280,
            text: ``,
          },
          {
            text: `(Fingerprint/consent over telephone)`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `center`,
            color: ``,
            pageBreak: ``,
            bold: false,
            width: 200,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        fontSize: 15,
        pageBreak: ``,
        decorationColor: ``,
        alignment: `left`,
        text: ` `,
        decorationStyle: ``,
        decoration: ``,
        width: `auto`,
        bold: false,
        color: ``,
      },
      {
        columns: [
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: `true`,
            text: `Interpreter’s Statement`,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: `underline`,
            decorationColor: ``,
            width: `auto`,
          },
        ],
      },
      {
        fontSize: 15,
        decoration: ``,
        alignment: `left`,
        decorationColor: ``,
        text: `I have given a translation of Consent Drug Screen Test including information that physician has explained to`,
        pageBreak: ``,
        bold: false,
        preserveLeadingSpaces: true,
        color: ``,
        decorationStyle: ``,
        width: `auto`,
      },
      {
        decoration: ``,
        decorationStyle: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        width: `auto`,
        fontSize: 15,
        text: `patient/patient’s representative.`,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        color: ``,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            text: `Translate to Language.........................................`,
            color: ``,
            bold: false,
            alignment: `center`,
            decorationColor: ``,
            pageBreak: ``,
            width: 200,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            width: 80,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            alignment: `center`,
          },
          {
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            alignment: `center`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `Interpreter..............................................`,
            bold: false,
            decorationColor: ``,
            width: 200,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            width: 280,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            width: 200,
            alignment: `center`,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            text: `(.............................................................)`,
          },
        ],
      },
      {
        decorationColor: ``,
        bold: `true`,
        width: `auto`,
        alignment: `left`,
        text: `Status of Signer (According to Thai Civil and Commercial Code)`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        color: ``,
        fontSize: 15,
        pageBreak: `before`,
        decoration: ``,
      },
      {
        columns: [
          {
            text: `[  ]`,
            alignment: `center`,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            text: `1. Patient who is major and capable of giving consent`,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            decoration: ``,
            fontSize: 15,
            alignment: `center`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            text: `[  ]`,
            color: ``,
          },
          {
            fontSize: 15,
            width: 5,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
          },
          {
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            text: `2. Legal husband or wife in case that the patient is not capable of giving consent (unconscious)`,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `center`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: `[  ]`,
            bold: false,
            color: ``,
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            text: ``,
            width: 5,
            decoration: ``,
            bold: false,
          },
          {
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `3. Holder of parental responsibility in case that the patient is minor (under 20 years old)`,
            pageBreak: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `[  ]`,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            width: `auto`,
            alignment: `center`,
            fontSize: 15,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 5,
            color: ``,
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            text: `4. Curator in case that the patient is quasi incompetent person (adjudged by the court)`,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `center`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            text: `[  ]`,
          },
          {
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            text: ``,
            color: ``,
            alignment: `left`,
            width: 5,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            text: `5. Guardian in case that the patient is incompetent person (adjudged by the court)`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            text: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: 15,
            color: ``,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            text: `*For no. 2-5 , please obtain certified true copy of the patient’s representative’s identification card/`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 18,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `passport/document issued by governmental authority, which religion and blood type information are covered.`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        width: `auto`,
        decoration: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        fontSize: 15,
        decorationStyle: ``,
        decorationColor: ``,
        alignment: `left`,
        pageBreak: ``,
        color: ``,
        bold: false,
      },
      {
        columns: [
          {
            text: `Relationship with patient ...............................................................................................................`,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            text: `Identification number of the patient’s representative............................................................`,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
            text: `Telephone number..........................................................................................................................`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            decoration: ``,
            width: `auto`,
            color: ``,
            text: `Email....................................................................................................................................................`,
            pageBreak: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            pageBreak: `before`,
            preserveLeadingSpaces: true,
            bold: `true`,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            decoration: ``,
            color: ``,
            alignment: `left`,
            text: `This portion should be completed after collecting the specimen.`,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: `true`,
            alignment: `left`,
            text: `To be completed by the staff member who oversaw the specimen collection process:`,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
          },
        ],
      },
      {
        bold: false,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        fontSize: 15,
        decorationStyle: ``,
        decoration: ``,
        pageBreak: ``,
        text: `I confirm that I followed the procedures for Narcotic  Urine Sampling as below.`,
        preserveLeadingSpaces: true,
        alignment: `left`,
      },
      {
        columns: [
          {
            decorationColor: ``,
            text: `[  ]`,
            color: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            width: 5,
            decoration: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
          },
          {
            text: `I confirmed the patient’s identification with`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            fontSize: 15,
            width: `auto`,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 5,
            alignment: `left`,
            bold: false,
            text: ``,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `[  ]`,
            decorationStyle: ``,
            width: `auto`,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            width: 5,
            fontSize: 15,
            decoration: ``,
            color: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            text: `Identification card`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            color: ``,
            width: 5,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            text: `[  ]`,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            width: `auto`,
            fontSize: 15,
            color: ``,
            bold: false,
          },
          {
            text: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            width: 5,
            decorationColor: ``,
          },
          {
            text: `Passport`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            width: `auto`,
          },
          {
            width: 2,
            bold: false,
            text: ``,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            alignment: `left`,
          },
          {
            color: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            width: `auto`,
            text: `Number`,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            bold: false,
            text: `.....................................`,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            text: `[  ]`,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: ``,
            color: ``,
            width: 5,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            decoration: ``,
            bold: false,
            text: `I provided urine collection instruction to the patient and confirmed their understanding.`,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            pageBreak: ``,
            width: `auto`,
            text: `[  ]`,
            alignment: `center`,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 5,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            decoration: ``,
            color: ``,
            width: `auto`,
            alignment: `left`,
            text: `I prevented sample contamination by asking the patient to change into a patient gown and performed a pat down , turned off the water valve to the sink , and dyed the toilet water blue.`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            text: `[  ]`,
            alignment: `center`,
          },
          {
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            width: 5,
            text: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            text: `I confirm that the time for urine collection was within 3 minutes. and the patient did not flush the toilet.`,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            color: ``,
            decoration: ``,
            alignment: `left`,
            text: `[  ]`,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 5,
            fontSize: 15,
            bold: false,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            text: `The color of the urine was normal (Clear Yellow) and the temperature was`,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            width: `auto`,
            text: `..................................degrees Celsius`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: 28,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            bold: false,
          },
          {
            width: `auto`,
            alignment: `left`,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            text: `(Urine temperature must between 33-37 degrees Celsius).`,
            decorationStyle: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            bold: false,
            decorationColor: ``,
            alignment: `center`,
            pageBreak: ``,
            decorationStyle: ``,
            text: `[  ]`,
            width: `auto`,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
          },
          {
            decoration: ``,
            bold: false,
            width: 5,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            fontSize: 15,
            text: `I sealed the container in front of the patient.`,
            width: `auto`,
          },
        ],
      },
      {
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        color: ``,
        text: ` `,
        decorationColor: ``,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        width: `auto`,
        decoration: ``,
      },
      {
        columns: [
          {
            width: 170,
            alignment: `center`,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            text: `...........................................................`,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
          },
          {
            bold: false,
            alignment: `center`,
            width: 170,
            decorationColor: ``,
            decorationStyle: ``,
            text: `...........................................................`,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            text: `...........................................................`,
            width: 170,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            alignment: `center`,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            width: 170,
            bold: false,
            color: ``,
            text: `Staff’s Name `,
            alignment: `center`,
          },
          {
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            width: 170,
            alignment: `center`,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            text: `Signature`,
          },
          {
            decoration: ``,
            alignment: `center`,
            text: `Date`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            width: 170,
            color: ``,
          },
        ],
      },
      {
        text: ` `,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            text: `To be completed by patient after collecting the specimen.`,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            bold: `true`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
          },
        ],
      },
      {
        fontSize: 15,
        text: `I confirm that I have witnessed a hospital staff member to completely seal the container in which my specimen is enclosed.`,
        bold: false,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        pageBreak: ``,
        alignment: `left`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
      },
      {
        alignment: `left`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        bold: false,
        width: `auto`,
        text: ` `,
        pageBreak: ``,
        decorationColor: ``,
        color: ``,
        decoration: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            alignment: `center`,
            preserveLeadingSpaces: true,
            text: `Signature........................................................`,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            bold: false,
            width: 200,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `  `,
            width: 80,
            alignment: `left`,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `Witness 1........................................................`,
            width: 200,
            alignment: `center`,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `center`,
            color: ``,
            width: 200,
            text: `(.............................................................)`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
          },
          {
            color: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            text: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: 80,
          },
          {
            alignment: `center`,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 200,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            text: `(.............................................................)`,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            alignment: `center`,
            color: ``,
            pageBreak: ``,
            text: `Date.................................Time....................`,
            decorationStyle: ``,
            width: 200,
            decorationColor: ``,
            bold: false,
          },
          {
            decoration: ``,
            alignment: `left`,
            width: 80,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            text: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 200,
            text: `Witness 2........................................................`,
            margin: [0, 0, 0, 0],
            color: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            alignment: `center`,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            bold: false,
            width: 280,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            text: ``,
          },
          {
            fontSize: 15,
            alignment: `center`,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: 200,
            text: `(.............................................................)`,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decorationColor: ``,
            width: 280,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            bold: false,
            alignment: `center`,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 200,
            fontSize: 15,
            pageBreak: ``,
            text: `(Fingerprint/consent over telephone)`,
            decorationStyle: ``,
            color: ``,
          },
        ],
      },
    ],
    pageSize: `A4`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    header: props.header,
    footer: props.footer,
  };
}
