export function printURL(pdf_url: any) {
  var iframe = document.getElementById("vc_local_print");
  if (iframe !== null) {
    document.body.removeChild(iframe);
  }

  // recreate iframe everytime to reliably display print dialog
  iframe = document.createElement("iframe");
  iframe.id = "vc_local_print";
  iframe.style.display = "none";
  iframe.addEventListener("load", function () {
    if ((iframe as any).src === "" || (iframe as any).src === "about:blank") {
      return;
    }

    var style = document.createElement("style");
    style.setAttribute("type", "text/css");
    style.innerHTML =
      "@page{size:auto;}\n img{width:100%; height:auto;}";

    if ((iframe as any).contentDocument?.head) {
      (iframe as any).contentDocument.head.appendChild(style);
    } else if ( (iframe as any).contentDocument?.body ) {
      (iframe as any).contentDocument.body.appendChild(style);
    } 
    
    (iframe as any).contentWindow.print();
  });
  document.body.appendChild(iframe);
  (iframe as any).src = pdf_url;
}

/// Unused Code just test
function test() {
  var iframe = document.getElementById("vc_local_print");
  if (iframe !== null) {
    document.body.removeChild(iframe);
  }

  // recreate iframe everytime to reliably display print dialog
  iframe = document.createElement("iframe");
  iframe.id = "vc_local_print";
  iframe.style.display = "none";

  document.body.appendChild(iframe);
  (iframe as any).src = "http://localhost:3000/static/images/ff13e72f-2ca.jpg";
  // Pause

  var style = document.createElement("style");
  style.setAttribute("type", "text/css");
  style.innerHTML =
    "@page{size:auto; margin:0mm;}\n img{width:100%; height:auto;}";

  (iframe as any).contentDocument.head.appendChild(style);

  if ((iframe as any).src === "" || (iframe as any).src === "about:blank") {
    return;
  }
  (iframe as any).contentWindow.print();
}
