export default class ChatController {
    constructor(props) {
        this.chatManager = props.chatManager;
    }

    getChatChanel = async (apiToken, userId) => {
        var params = {
            apiToken: apiToken,
            userId: userId,
        }
        const [data, error] = await this.chatManager.getChatChannel(params);
        return [data, error]
    }

    searchChatChannel = async (apiToken, userId, search) => {
        var params = {
            apiToken: apiToken,
            userId: userId,
            search: search
        }
        const [data, error] = await this.chatManager.getChatChannel(params);
        return [data, error]
    }
    
}