
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from 'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* ['APIView'] */
/* params:  */
/* data: 'application' */
const CheckAvailableAPIView:
  {
    post: ({ params, data, apiToken, extra }:
      {
        params?: any,
        data?: any,
        apiToken?: any,
        extra?: any
      }) => any,
  } =
{
  post: async ({ params, data, apiToken, extra
  }) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }

    const result = await to(base.client.post(`${HOST}/users/api/check-available/`,
      { ...data },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default CheckAvailableAPIView

