import WasmController from "../react-lib/frameworks/WasmController";
import * as MobTransformI from "./MobTransformInterface";

export default class MobTransformController extends WasmController<
  MobTransformI.State,
  MobTransformI.Event,
  MobTransformI.Data
> {
  constructor(
    getState: () => MobTransformI.State,
    setState: (state: MobTransformI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobTransformI.DataInitial);
  }

  handleEvent = async (e: MobTransformI.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // Setup
      case "DidMount":
        MobTransformI.DidMount(this, e.params);
        return;

      case "DidUpdate":
        MobTransformI.DidUpdate(this, e.params);
        return;

      case "HandleSignIn":
        MobTransformI.LoginIssara(this, e.params);
        return

      case "HandleSignOut":
        MobTransformI.SignOutIssara(this, e.params);
        return

      case "HandleNextViewRegister":
        MobTransformI.HandleNextViewRegister(this, e.params);
        return

      case "HandleSetViewIndexRegister":
        MobTransformI.HandleSetViewIndexRegister(this, e.params);
        return

      case "HandleSetValueRegisterInfo":
        MobTransformI.HandleSetValueRegisterInfo(this, e.params);
        return

      case "HandleSetValueChangePassword":
        MobTransformI.HandleSetValueChangePassword(this, e.params);
        return

      case "HandleSetInputEmail":
        MobTransformI.HandleSetInputEmail(this, e.params);
        return

      case "HandleConfirmChangePassword":
        MobTransformI.HandleConfirmChangePassword(this, e.params);
        return

      case "HandleSetInputEmailRequest":
        MobTransformI.HandleSetInputEmailRequest(this, e.params);
        return

      case "HandleEmailRequest":
        MobTransformI.HandleEmailRequest(this, e.params);
        return

      case "HandleSetOpenModCheckEmail":
        MobTransformI.HandleSetOpenModCheckEmail(this, e.params);
        return

      case "HandleSignInByEmail":
        MobTransformI.HandleSignInByEmail(this, e.params);
        return

      case "HandleGoBackSignInEmail":
        MobTransformI.HandleGoBackSignInEmail(this, e.params);
        return

      case "HandleCloseMessage":
        MobTransformI.HandleCloseMessage(this, e.params);
        return

      case "GetPatientDetail":
        MobTransformI.GetPatientDetail(this, e.params);
        return

      case "HandleGoBackVerify":
        MobTransformI.HandleGoBackVerify(this, e.params);
        return

      case "HandleRouteChange":
        MobTransformI.HandleRouteChange(this, e.params);
        return

      case "HandleSetOpenModVerify":
        MobTransformI.HandleSetOpenModVerify(this, e.params);
        return

      case "HandleVerifyByEmail":
        MobTransformI.HandleVerifyByEmail(this, e.params);
        return

      case "HandleSetInitialInnerScreen":
        MobTransformI.HandleSetInitialInnerScreen(this, e.params);
        return

      case "UpdatePatientProfile":
        MobTransformI.UpdatePatientProfile(this, e.params);
        return

      case "HandleGoToEditProfile":
        MobTransformI.HandleGoToEditProfile(this, e.params);
        return

      case "HandleGoToEditHN":
        MobTransformI.HandleGoToEditHN(this, e.params);
        return

      case "HandleOpenLinkEKYC":
        MobTransformI.HandleOpenLinkEKYC(this, e.params);
        return

      case "HandleShippingOrder":
        MobTransformI.HandleShippingOrder(this, e.params);
        return

      case "HandleShippingOderlist":
        MobTransformI.HandleShippingOderlist(this, e.params);
        return

      case "HandleSelectDivisionServiceBlock":
        MobTransformI.HandleSelectDivisionServiceBlock(this, e.params);
        return

      case "HandlePostDivisionServiceBlock":
        MobTransformI.HandlePostDivisionServiceBlock(this, e.params);
        return
      
      case "HandleDivisionScheduleTimeList":
        MobTransformI.HandleDivisionScheduleTimeList(this, e.params);
        return

      case "HandleDoctorDivisionServiceBlockSchedule":
        MobTransformI.HandleDoctorDivisionServiceBlockSchedule(this, e.params);
        return

      case "HandleGetTimeSlotByDate":
        MobTransformI.HandleGetTimeSlotByDate(this, e.params);
        return 

      case "HandleDivisionList":
        MobTransformI.HandleDivisionList(this, e.params);
        return

      case "HandleDoctorList":
        MobTransformI.HandleDoctorList(this, e.params);
        return

      case "HandleCreateAppointment":
        MobTransformI.HandleCreateAppointment(this, e.params);
        return

      case "HandleGetAppointment":
        MobTransformI.HandleGetAppointment(this, e.params);
        return

      case "HandleUpdateAppointment":
        MobTransformI.HandleUpdateAppointment(this, e.params);
        return

      case "HandleCancelAppointment":
        MobTransformI.HandleCancelAppointment(this, e.params);
        return

      case "HandlePatientAppointmentView":
        MobTransformI.HandlePatientAppointmentView(this, e.params);
        return

      case "HandleUpdateProfileImage":
        MobTransformI.HandleUpdateProfileImage(this, e.params);
        return

      case "HandleGetUserProfile":
        MobTransformI.HandleGetUserProfile(this, e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
