import React, { useState, forwardRef, useRef, useEffect, useImperativeHandle} from "react";
import { Input, Modal } from "semantic-ui-react";
import CardDoctorSearch from "./CardDoctorSearch";
import { formatDropdown } from "../../utils";
import PropTypes from "prop-types";

const DoctorSearchBox = forwardRef ((props: any,ref) => {
  
  const [openCardDoctorSearch, setOpenCardDoctorSearch] = useState(false);
  const [doctorSearchInput , setDoctorSearchInput] = useState(props.initialValue)
  const inputRef = useRef(null);

  const [doctorListIn, setDoctorListIn] = useState([]);
  const [specialitiesListIn, setSpecialitiesListIn] = useState([]);
  const [totalPagesIn, setTotalPagesIn] = useState(null);
  const [currentPageIn, setCurrentPageIn] = useState(null);
  const [doctorListLoadingIn, setDoctorListLoadingIn] = useState(false);

    // doctorList={doctorList}
    // specialitiesList={doctorSpecialtyList}
    // getSearchDoctorList={handleGetDoctor}
    // getSpecialitiesList={handleGetDoctorSpecialty}
    // doctorListNumberOfPage={totalPages}
    // currentPage={currentPage}
    // onPageChange={handleDoctorPaginationChange}

  // Why we use useImperativeHandle , 
  // because semantic ui react Input element didn't have normal behavior like htmlTag input 
  // e.g. inputRef.current.value = '' are not effective.
  // 
  // May be I didn't select correct reference on semantic, need recheck

  useEffect( () => {
    setDoctorSearchInput(props.initialValue)
  }, [props.initialValue])

  useImperativeHandle(ref, () => ({
    focus: () => {
      (inputRef as any).current.focus();
    },
    clear: () =>{ 
      setDoctorSearchInput('')
    },
    setName: (value: any) => {
      setDoctorSearchInput(value)
    },
    openDoctorSearch: () => {
      setOpenCardDoctorSearch(true)
    }, 
    value : () =>{
      return doctorSearchInput
    }
  }));

  const handleCloseCardDoctorSearch = () => {
    setOpenCardDoctorSearch(false);
  };

  const handleOpenCardDoctorSearch = (e: any) => {
    setOpenCardDoctorSearch(true);
  };

  const handleOnChangeDoctorSearch = (e: any) => {
    setDoctorSearchInput(e.target.value)
    props.onTextChange(e.target.value)
  }

  //// Controller Implement 

  const handleOnPageChangeIn = async (doctorName: any, speciality: any, page: any) => {
    setCurrentPageIn(page)
    await getSearchDoctorListIn(doctorName, speciality, page);
  };

  const getSpecialitiesListIn = async () => {
    // TODO: Naming : Speciality
    const [
      specialityData,
      specialityError
    ] = await props.controller.getSpeciality();
  
    if (specialityData) {
      let specialitiesList = formatDropdown(specialityData, "name", "id");
      let list = specialitiesList.map(item => {
        delete item.is_active;
        delete item.display_seq;
        return item;
      });
      setSpecialitiesListIn(list as any)
    }
  }

  const getSearchDoctorListIn = async (name: any, div: any, doctorCurrentPage: any) => {

    if (!doctorCurrentPage) {
      var cPage: any = currentPageIn;
    }

    let limit = 40;
    let params: any = {
      offset: (cPage - 1) * limit,
      limit
    };
    if (name) {
      params.nameCode = name;
    }
    if (div) {
      params.specialty = div;
    }
    if (props.controller) {
      setDoctorListLoadingIn(true)
    } 

    const [
      doctorListData,
      doctorListError,
      numberOfPage
    ] = await props.controller.getDoctorList(params);
  
    console.log(' doctorListData', doctorListData)
    if ( doctorListData ) { 
      setDoctorListIn(doctorListData)
      if (numberOfPage) {
        setTotalPagesIn(numberOfPage)
      }
    }
    setDoctorListLoadingIn(false)
    // TODO: handle doctorListError
  };

  // console.log('props.fluid ',props.fluid)
  return (
    <React.Fragment>
      <Input
        disabled={props.disabled}
        id={'InputDoctorSearchBox'}
        fluid={props.fluid}
        ref={inputRef}
        value={doctorSearchInput === '' ? props.defaultValue : doctorSearchInput}
        placeholder="ไม่ระบุ"
        onChange={handleOnChangeDoctorSearch}
        action={{
          icon: "search",
          onClick: handleOpenCardDoctorSearch,
          type:'button',
        }}
      />
      <Modal open={openCardDoctorSearch} size="large">
        <CardDoctorSearch
          preData={doctorSearchInput}
          // Data
          specialitiesList={props.controller ? specialitiesListIn : props.specialitiesList}
          doctorList={props.controller ? doctorListIn : props.doctorList}
          // @ts-ignore
          doctorListLoading={props.controller ? doctorListLoadingIn : props.doctorListLoading}
          numberOfPage={props.controller ? totalPagesIn : props.doctorListNumberOfPage}
          currentPage={props.controller ? currentPageIn : props.currentPage }
          // callback
          hideCallBack={handleCloseCardDoctorSearch}
          getSpecialitiesList={props.controller ? getSpecialitiesListIn : props.getSpecialitiesList}
          getSearchDoctor={props.controller ? getSearchDoctorListIn : props.getSearchDoctorList}
          onPageChange={props.controller ?  handleOnPageChangeIn : props.onPageChange}
          onSelectDoctor={(id, doctorName) => {
            setDoctorSearchInput(doctorName)
            props.onSelectDoctor(id, doctorName);
          }}
        />
      </Modal>
    </React.Fragment>
  );
});

      // doctorList={doctorList}
              // specialitiesList={doctorSpecialtyList}
              // getSearchDoctorList={handleGetDoctor}
              // getSpecialitiesList={handleGetDoctorSpecialty}
              // doctorListNumberOfPage={totalPages}
              // currentPage={currentPage}
              // onPageChange={handleDoctorPaginationChange}

DoctorSearchBox.defaultProps = {
  controller: null,
  initialValue: '',
  defaultValue: '',
  specialitiesList: [],
  doctorList: [],
  doctorListLoading: false,
  doctorListNumberOfPage: 0,
  currentPage: 0,
  onPageChange: () => {},
  getSpecialitiesList: () => { },
  getSearchDoctorList: ()=> {},
  onSelectDoctor: () => { },
  fluid: true,
  onTextChange: () => {},
  disabled: false,
};

DoctorSearchBox.propTypes = {
  controller: PropTypes.object,
  initialValue: PropTypes.string,
  defaultValue: PropTypes.string,
  specialitiesList: PropTypes.array,
  doctorList: PropTypes.array,
  doctorListLoading: PropTypes.bool,
  doctorListNumberOfPage: PropTypes.number,
  currentPage: PropTypes.number, 
  onPageChange: PropTypes.func,
  getSpecialitiesList: PropTypes.func,
  getSearchDoctorList: PropTypes.func,
  onSelectDoctor: PropTypes.func,
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default React.memo(DoctorSearchBox);
