export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        bold: `true`,
        color: ``,
        decorationColor: ``,
        decorationStyle: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        alignment: `center`,
        fontSize: `18`,
        width: `auto`,
        text: `หนังสือแสดงความยินยอมการผ่าตัดหรือการทำหัตถการพิเศษ`,
      },
      {
        color: ``,
        text: ` `,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationStyle: ``,
        fontSize: 15,
        width: `auto`,
        alignment: `left`,
        bold: false,
        pageBreak: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            width: 30,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `ข้าพเจ้า (นาย/นาง/นางสาว) `,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            text: ` `,
            width: 5,
            decorationColor: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                decoration: ``,
                decorationStyle: ``,
                width: `auto`,
                alignment: `left`,
                preserveLeadingSpaces: true,
                fontSize: 15,
                color: ``,
                text: props.items?.formatPatient,
                pageBreak: ``,
                decorationColor: ``,
                bold: false,
              },
              {
                pageBreak: ``,
                text: `.......................................................................................................................................................`,
                bold: false,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                alignment: `left`,
                color: ``,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                width: `auto`,
                decoration: ``,
                fontSize: 15,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: `ขอแสดงความยินยอมโดยสมัครใจให้ นายแพทย์/แพทย์หญิง`,
            fontSize: 15,
            color: ``,
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            text: ``,
            width: 5,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                decoration: ``,
                color: ``,
                decorationStyle: ``,
                text: props.items?.formatDoctor,
                width: `auto`,
                fontSize: 15,
                bold: false,
                decorationColor: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                margin: [0, 0, 0, -15],
              },
              {
                decoration: ``,
                decorationStyle: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                color: ``,
                pageBreak: ``,
                bold: false,
                fontSize: 15,
                margin: [0, 0, 0, 0],
                text: `.................................................................................................................`,
                decorationColor: ``,
                alignment: `left`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            text: `และพนักงานของโรงพยาบาลที่ได้รับมอบหมายให้มีส่วนร่วมในการดูแลอาการของข้าพเจ้า ซึ่งข้าพเจ้าได้รับการอธิบายว่า....................`,
            color: ``,
            decorationStyle: ``,
            bold: false,
          },
        ],
      },
      {
        alignment: `left`,
        decorationColor: ``,
        bold: false,
        decoration: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        width: `auto`,
        text: `...................................................................................................................................................................................................................`,
        fontSize: 15,
        color: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            decoration: ``,
            fontSize: 15,
            width: 30,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: `ข้าพเจ้าเข้าใจและยินยอมให้มีการดำเนินการรักษาโดยการผ่าตัด, การรักษาโดยใช้ยา, และ/หรือการกระทำการตรวจวินิจฉัย`,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            bold: false,
            text: `ตามที่ได้มีการวางแผนไว้สำหรับข้าพเจ้าคือ : `,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            color: ``,
            pageBreak: ``,
            text: ``,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            width: 5,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                decoration: ``,
                color: ``,
                decorationStyle: ``,
                decorationColor: ``,
                bold: false,
                preserveLeadingSpaces: true,
                fontSize: 15,
                margin: [0, 0, 0, -15],
                text: props.items?.formatOperation,
                pageBreak: ``,
                width: `auto`,
                alignment: `left`,
              },
              {
                preserveLeadingSpaces: true,
                alignment: `left`,
                decoration: ``,
                width: `auto`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                color: ``,
                decorationStyle: ``,
                bold: false,
                text: `..........................................................................................................................................,`,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        stack: [
          {
            decorationColor: ``,
            text: `${props.items?.formatIcd9}   ${props.items?.formatIcd10}`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            margin: [0, 0, 0, -15],
            decoration: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            width: `auto`,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            text: `...................................................................................................................................................................................................................`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: ``,
            width: 30,
          },
          {
            text: `ข้าพเจ้าได้ทราบรายละเอียดเกี่ยวกับการผ่าตัด, การใช้ยา, และ/หรือการตรวจวินิจฉัยรวมไปถึงประโยชน์ ความเสี่ยง`,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        bold: false,
        text: `ผลข้างเคียง และ/หรือผลที่อาจจะเกิดขึ้นได้ในภายหลัง และได้อภิปรายกับแพทย์เกี่ยวกับแพทย์เกี่ยวกับผลสำเร็จ ซึ่งขึ้นอยู่`,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        alignment: `left`,
        pageBreak: ``,
        decoration: ``,
        fontSize: 15,
        decorationStyle: ``,
      },
      {
        text: `กับปัจจัยจากตัวข้าพเจ้าเองและปัจจัยอื่นๆ รวมทั้งปัญหาที่อาจเกิดขึ้นในระยะฟื้นตัว อีกทั้งทางเลือกอื่นๆ ที่เป็นไปได้ สำหรับ`,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        bold: false,
        fontSize: 15,
        decorationColor: ``,
        decoration: ``,
        width: `auto`,
        decorationStyle: ``,
        color: ``,
      },
      {
        alignment: `left`,
        decorationColor: ``,
        decoration: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        color: ``,
        fontSize: 15,
        bold: false,
        text: `กรณีของข้าพเจ้าเป็นอย่างดี`,
        pageBreak: ``,
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            width: 30,
            decoration: ``,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            color: ``,
            text: `โดยแพทย์ `,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
          },
          {
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            width: 5,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                pageBreak: ``,
                decorationColor: ``,
                bold: false,
                decoration: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                text: props.items?.doctor_name,
                width: `auto`,
                color: ``,
                decorationStyle: ``,
                alignment: `left`,
              },
              {
                decoration: ``,
                bold: false,
                preserveLeadingSpaces: true,
                width: `auto`,
                decorationStyle: ``,
                text: `....................................................................................................................................................................................`,
                fontSize: 15,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                alignment: `left`,
                color: ``,
                pageBreak: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 30,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            text: `[  ]`,
            decoration: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            width: 5,
            decorationColor: ``,
          },
          {
            decoration: ``,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `ได้รับเอกสารคำแนะนำเรื่อง `,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
          },
          {
            fontSize: 15,
            text: `.....................................................................`,
            width: `auto`,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            text: ``,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            width: 10,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            color: ``,
            text: `หมายเลข `,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            bold: false,
          },
          {
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            color: ``,
            width: 10,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            text: `......................................................`,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            width: 30,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            text: `ข้าพเจ้าขอแสดงความยินยอมให้แพทย์และ/หรือผู้ช่วยแพทย์ สามารถกระทำการรักษา/หัตถการ เพิ่มเติม/ปรับเปลี่ยนด้วย`,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            bold: false,
            width: `auto`,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        text: `เหตุผลซึ่งแสดงถึงความจำเป็นในการวินิจฉัย/การรักษาดังกล่าว อาทิ เช่น การใช้ยาระงับความรู้สึก การให้โลหิตและ/หรือผลิตภัณฑ์`,
        width: `auto`,
        bold: false,
        fontSize: 15,
        decorationColor: ``,
        alignment: `left`,
        decoration: ``,
        decorationStyle: ``,
        pageBreak: ``,
        color: ``,
      },
      {
        preserveLeadingSpaces: true,
        text: `ของโลหิต การวินิจฉัยทางรังสีวิทยาและพยาธิวิทยาการตัดชิ้นเนื้อเยื่อหรือส่วนของอวัยวะซึ่งกระทำโดยแพทย์ผู้ทำการผ่าตัด ได้รับการ`,
        bold: false,
        decorationStyle: ``,
        pageBreak: ``,
        color: ``,
        alignment: `left`,
        decoration: ``,
        decorationColor: ``,
        width: `auto`,
        fontSize: 15,
      },
      {
        decorationColor: ``,
        decoration: ``,
        width: `auto`,
        alignment: `left`,
        color: ``,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        text: `ยินยอมจากข้าพเจ้า หากการวินิจฉัย หรือการรักษาดังกล่าว กระทำไปโดยคำนึงถึงผลประโยชน์ของตัวข้าพเจ้า`,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            width: 30,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: ``,
          },
          {
            color: ``,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: `ข้าพเจ้าเข้าใจว่าไม่มีการรับประกันในผลของการวินิจฉัยต่อข้าพเจ้าในครั้งนี้แต่อย่างใด`,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            text: ``,
            width: 30,
            color: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            decoration: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `ในกรณีที่เจ้าหน้าที่บุคลากรทางการแพทย์ถูกของมีคมทิ่มตำโดยไม่เจตนา และ/หรือ มีผลให้เกิดการสัมผัสโดนเลือดของ`,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        bold: false,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        text: `ข้าพเจ้าบริเวณบาดแผลนั้นๆ ข้าพเจ้ายินยอมให้ตรวจเลือดเพื่อตรวจหาเชื้อโรคติดเชื้อ และเชื้อเอชไอวี (HIV)`,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        decoration: ``,
      },
      {
        columns: [
          {
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            width: 30,
            decoration: ``,
            fontSize: 15,
          },
          {
            decoration: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            text: `ณ ที่นี้ข้าพเจ้าแสดงความยินยอมเพื่อรับการรักษาดังกล่าวข้างต้น`,
            decorationStyle: ``,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        color: ``,
        text: ` `,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decorationColor: ``,
        bold: false,
        fontSize: 15,
        width: `auto`,
        decoration: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            color: ``,
            alignment: `center`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 200,
            decorationStyle: ``,
            decoration: ``,
            text: `ลงนาม........................................................  `,
            bold: false,
          },
          {
            color: ``,
            alignment: `left`,
            width: 80,
            text: `  `,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            color: ``,
            width: 200,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `center`,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            text: `พยาน 1 ลงนาม........................................................ `,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `center`,
            color: ``,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            text: `(.............................................................)`,
            width: 200,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: ``,
            width: 80,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            width: 200,
            text: `(.............................................................)`,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            bold: false,
          },
        ],
      },
      {
        alignment: `left`,
        decoration: ``,
        pageBreak: ``,
        bold: false,
        color: ``,
        width: `auto`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        text: ` `,
        decorationColor: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            width: 200,
            decorationColor: ``,
            text: `แพทย์ลงนาม........................................................`,
            preserveLeadingSpaces: true,
            alignment: `center`,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            text: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            width: 80,
            decorationColor: ``,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            alignment: `center`,
            decoration: ``,
            width: 200,
            text: `พยาน 2 ลงนาม........................................................`,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `center`,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            text: props.items?.doctor_name,
            fontSize: 15,
            bold: false,
            width: 200,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            width: 80,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            width: 200,
            decorationStyle: ``,
            decorationColor: ``,
            text: `(.............................................................)`,
            fontSize: 15,
            pageBreak: ``,
            alignment: `center`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `(แพทย์ผู้ให้คำอธิบาย)`,
            pageBreak: ``,
            width: 200,
            bold: false,
            alignment: `center`,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            text: ``,
            width: 80,
            decorationColor: ``,
          },
          {
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 200,
            alignment: `center`,
            decorationColor: ``,
            decoration: ``,
            text: `(กรณีพิมพ์รายนิ้วมือ/ยินยอมทางโทรศัพท์) `,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            text: `วันที่........................เวลา..................`,
            alignment: `center`,
            decorationStyle: ``,
            pageBreak: ``,
            width: 200,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
          },
        ],
      },
      {
        decorationStyle: ``,
        decoration: ``,
        bold: false,
        text: ``,
        alignment: `left`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        pageBreak: `before`,
        color: ``,
        width: `auto`,
        fontSize: 15,
      },
      {
        columns: [
          {
            decorationColor: ``,
            alignment: `left`,
            bold: `true`,
            fontSize: 15,
            decoration: `underline`,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            text: `การแปลและให้ข้อมูลโดยผู้แปลภาษา`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            pageBreak: ``,
            decoration: ``,
            text: ``,
            bold: false,
            width: 30,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            text: `ข้าพเจ้าได้แปลหนังสือแสดงความยินยอมการผ่าตัดหรือการทำหัตถการพิเศษ (Consent for Surgery or Special`,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            alignment: `left`,
          },
        ],
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        text: `Procedure) รวมทั้งข้อมูลซึ่งแพทย์ได้อธิบายให้แก่ผู้ป่วย/ผู้แทนผู้ป่วยทราบ`,
        decoration: ``,
        pageBreak: ``,
        decorationStyle: ``,
        fontSize: 15,
        decorationColor: ``,
        color: ``,
        alignment: `left`,
        bold: false,
      },
      {
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decorationColor: ``,
        color: ``,
        decoration: ``,
        alignment: `left`,
        text: ` `,
        pageBreak: ``,
        width: `auto`,
        fontSize: 15,
        bold: false,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            width: 200,
            decorationColor: ``,
            color: ``,
            text: `ภาษาที่แปล............................................`,
            alignment: `center`,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            alignment: `center`,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: 80,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            text: ` `,
          },
          {
            fontSize: 15,
            text: `ผู้แปลลงนาม........................................................ `,
            color: ``,
            width: 200,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 280,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            text: `(.............................................................)`,
            width: 200,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        width: `auto`,
        pageBreak: ``,
        text: ` `,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        alignment: `left`,
        decoration: ``,
        fontSize: 15,
        color: ``,
        decorationColor: ``,
        bold: false,
      },
      {
        fontSize: 15,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        bold: `true`,
        text: `สถานะของผู้ลงนาม (ตามประมวลกฎหมายแพ่งและพาณิชย์)`,
        decoration: ``,
        width: `auto`,
        alignment: `left`,
        color: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            alignment: `center`,
            text: `[  ]`,
            bold: false,
          },
          {
            width: 5,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            text: `1. ผู้ป่วย ซึ่งบรรลุนิติภาวะ และมีสติสัมปชัญญะสมบูรณ์`,
            color: ``,
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            text: `[  ]`,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            width: `auto`,
            alignment: `center`,
            color: ``,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            width: 5,
            decorationColor: ``,
            text: ``,
            bold: false,
            color: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: `2. สามีหรือภรรยาตามกฎหมาย กรณีที่ผู้ป่วยไม่มีสติสัมปชัญญะ (ไม่รู้สึกตัว)`,
            width: `auto`,
            decorationColor: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            text: `[  ]`,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            alignment: `center`,
          },
          {
            decorationColor: ``,
            decoration: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 5,
          },
          {
            decoration: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            text: `3. ผู้ใช้อำนาจปกครองกรณีผู้ป่วยอายุต่ำกว่า 20 ปี บริบูรณ์ที่ยังไม่บรรลุนิติภาวะ`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            pageBreak: ``,
            color: ``,
            alignment: `center`,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `[  ]`,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            alignment: `left`,
            decoration: ``,
            text: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            color: ``,
            decorationColor: ``,
            text: `4. ผู้พิทักษ์ กรณีผู้ป่วยเป็นคนเสมือนไร้ความสามารถ  (ตามคำสั่งศาล)`,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `[  ]`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            alignment: `center`,
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
          },
          {
            bold: false,
            decoration: ``,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            width: 5,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            alignment: `left`,
            text: `5. ผู้อนุบาล กรณีผู้ป่วยเป็นคนไร้ความสามารถ (ตามคำสั่งศาล)`,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            color: ``,
            fontSize: 15,
            width: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: ``,
            decoration: ``,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            text: `*กรณีข้อ 2-5 ให้ขอสำเนาเอกสารบัตรประชาชน หนังสือเดินทาง เอกสารที่ออกโดยราชการจากผู้ลงนามแทน โดยปกปิดข้อมูลศาสนาและหมู่เลือด ซึ่งปรากฎบนสำเนาดังกล่าว (หากมี) พร้อมลงนามรับรองสำเนาถูกต้อง`,
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
          },
        ],
      },
      {
        alignment: `left`,
        decorationColor: ``,
        decorationStyle: ``,
        pageBreak: ``,
        width: `auto`,
        bold: false,
        text: ` `,
        color: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            text: `ความสัมพันธ์กับผู้ป่วย.........................................................................................................................`,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            text: `เลขที่บัตรประจำตัวประชาชนผู้ลงนามแทนผู้ป่วย................................................................................ `,
            bold: false,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            color: ``,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            text: `เบอร์โทรศัพท์.......................................................................................................................................`,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decoration: ``,
            text: `อีเมล.....................................................................................................................................................`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        text: `คำแนะนำการรับโลหิตและ/หรือส่วนประกอบของโลหิต`,
        width: `auto`,
        decoration: ``,
        fontSize: `18`,
        bold: `true`,
        preserveLeadingSpaces: true,
        color: ``,
        alignment: `center`,
        pageBreak: `before`,
        decorationColor: ``,
      },
      {
        decorationStyle: ``,
        alignment: `left`,
        text: ` `,
        fontSize: 15,
        decoration: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        color: ``,
      },
      {
        color: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        decoration: ``,
        fontSize: 15,
        decorationColor: ``,
        pageBreak: ``,
        decorationStyle: ``,
        bold: `true`,
        alignment: `left`,
        text: `ข้อมูลเกี่ยวกับการรับโลหิตและ/หรือส่วนประกอบของโลหิต`,
      },
      {
        columns: [
          {
            color: ``,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            width: 30,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
          },
          {
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            text: `เนื่องจากท่านหรือบุคคลที่ท่านเป็นผู้แทน อาจมีความจำเป็นต้องได้รับโลหิต และ/หรือส่วนประกอบของโลหิตเพื่อการรักษา`,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
          },
        ],
      },
      {
        decorationColor: ``,
        decoration: ``,
        fontSize: 15,
        text: `เอกสารฉบับนี้แสดงถึงวิธีการให้และโอกาสเสี่ยงที่อาจจะเกิดขึ้นจากการได้รับโลหิต และ/หรือส่วนประกอบของโลหิต ดังนี้`,
        width: `auto`,
        alignment: `left`,
        bold: false,
        preserveLeadingSpaces: true,
        color: ``,
        pageBreak: ``,
        decorationStyle: ``,
      },
      {
        pageBreak: ``,
        fontSize: 15,
        decoration: ``,
        color: ``,
        bold: false,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        text: ` `,
        width: `auto`,
        alignment: `left`,
      },
      {
        decoration: ``,
        color: ``,
        bold: `true`,
        decorationColor: ``,
        pageBreak: ``,
        alignment: `left`,
        text: `วิธีการให้โลหิตและ/หรือส่วนประกอบของโลหิต`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        width: `auto`,
      },
      {
        columns: [
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            width: 30,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: `การให้โลหิต และหรือส่วนประกอบของโลหิต จะให้ไหลผ่านทางหลอดเลือดดำด้วยวิธีการปลอดเชื้อ และมีการตรวจสอบ`,
            alignment: `left`,
            width: `auto`,
          },
        ],
      },
      {
        width: `auto`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decoration: ``,
        text: `ความถูกต้องว่าได้ให้ถูกคนและถูกชนิด ตลอดจนมีการเฝ้าระวังอาการและความผิดปกติต่างๆ ที่อาจเกิดขึ้นได้ในระหว่างการได้รับ`,
        decorationColor: ``,
        color: ``,
        decorationStyle: ``,
        pageBreak: ``,
        alignment: `left`,
        bold: false,
      },
      {
        text: `หรือหลังการได้รัลโลหิต และส่วนประกอบของโลหิต`,
        decorationStyle: ``,
        color: ``,
        bold: false,
        decorationColor: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        width: `auto`,
        alignment: `left`,
        decoration: ``,
        pageBreak: ``,
      },
      {
        pageBreak: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        width: `auto`,
        bold: false,
        fontSize: 15,
        color: ``,
        text: ` `,
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
      },
      {
        decorationColor: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        fontSize: 15,
        color: ``,
        decorationStyle: ``,
        alignment: `left`,
        text: `โอกาสเสี่ยงจากการได้รับโลหิต และ/หรือส่วนประกอบของโลหิต`,
        pageBreak: ``,
        decoration: ``,
        bold: `true`,
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            text: ``,
            alignment: `left`,
            width: 5,
            decorationColor: ``,
          },
          {
            text: `โดยทั่วไปถือได้ว่าการได้รับโลหิตและ/หรือส่วนประกอบของโลหิต มีอัตราเสี่ยงน้อย ได้แก่`,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decoration: ``,
            width: 10,
            bold: false,
            color: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `center`,
            fontSize: 15,
            bold: false,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `•`,
            pageBreak: ``,
          },
          {
            bold: false,
            width: 5,
            fontSize: 15,
            text: ``,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            text: `ปฏิกิริยาชั่วคราวและไม่รุนแรง ได้แก่ การมีรอยช้้ำเขียว บวม และเจ็บในตำแหน่งที่แทงเข็ม นอกจากนี้อาจมีอาการ`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 20,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            text: ``,
            decoration: ``,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            bold: false,
            text: `ข้างเคียงจากส่วนประกอบของโลหิต ได้แก่ อาการปวดศีรษะ ไข้ หนาวสั่น หรือผื่นคันที่ผิวหนัง`,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decoration: ``,
            fontSize: 15,
            width: 10,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            text: `•`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            width: `auto`,
            bold: false,
            decorationColor: ``,
            color: ``,
          },
          {
            color: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            width: 5,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            text: `ปฏิกิริยารุนแรง ซึ่งอาจถึงแก่ชีวิตได้ แต่มีโอกาสเกิดขึ้นได้น้อยมาก เนื่องจากโลหิตและ/หรือส่วนประกอบของโลหิต`,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            bold: false,
            width: 10,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            color: ``,
            width: `ทุกหน่วยได้รับการตรวจสอบการเข้ากันได้กับผู้ป่วยอย่างรอบคอบแล้ว`,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            text: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            width: 10,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            pageBreak: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            alignment: `center`,
            text: `•`,
            decoration: ``,
            decorationColor: ``,
          },
          {
            width: 5,
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            width: `auto`,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `การติดเชื้อ การติดเชื้อโรคบางชนิดอาจเกิดจากการได้รับโลหิตและ/หรือส่วนประกอบของโลหิตได้ เช่น เชื้อไวรัสตับ`,
            decorationStyle: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: 20,
            pageBreak: ``,
          },
          {
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            width: `auto`,
            text: `อักเสบ มาลาเรีย เชื้อเอชไอวี เป็นต้น อย่างไรก็ดี อัตราการเกิดขึ้นได้น้อยมาก เนื่องจากโลหิตและส่วนประกอบของโลหิต`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            width: 10,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
          },
          {
            text: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: `ทุกหน่วยได้รับการตรวจกรองการติดเชื้อดังกล่าวตามวิธีมาตรฐานระดับสากลแล้ว`,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
        ],
      },
    ],
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
    pageOrientation: `portrait`,
    header: props.header,
    footer: props.footer,
  };
}
