import React from "react";
import "./LoginButton.css";
import PropTypes from "prop-types";

const LineLoginButton = React.forwardRef((props, ref) => {
  return (
    <div className="lineLoginButton" onClick={props.onClick} ref={ref} style={{ ...props.style }}>
      <img style={props.logoStyle}  alt="" />
      <span style={props.textStyle}>{props.lineLoginText}</span>
    </div>
  );
});

LineLoginButton.defaultProps = {
  onClick: () => {},
  logoStyle: {},
  textStyle: {},
  style: {},
  lineLoginText: "ล็อกอินด้วย LINE",
};

LineLoginButton.propTypes = {
  onClick: PropTypes.func,
  logoStyle: PropTypes.object,
  textStyle: PropTypes.object,
  style: PropTypes.object,
  lineLoginText: PropTypes.string
};

export default LineLoginButton;
