export default class AddressController {
  constructor(props) {
    this.coreManager = props.coreManager;
    this.prxManager = props.prxManager;
  }

  getCountryList = async ({
    apiToken
  } = {}) => {
    const [data, error] = await this.coreManager.getCountry({
      apiToken
    });
    return [data, error]
  }

  getProvinceList = async ({
    apiToken
  } = {}) => {
    const [data, error] = await this.coreManager.getProvince({
      apiToken
    });
    return [data, error]
  }

  getDistrictList = async ({
    apiToken,
    area
  } = {}) => {
    const [data, error, network] = await this.coreManager.getDistrict({
      apiToken,
      area
    });
    return [data, error, network]
  }

  getCityList = async ({
    apiToken,
    area
  } = {}) => {
    const [data, error, network] = await this.coreManager.getCity({
      apiToken,
      area
    });
    return [data, error, network]
  }

  postPatientAddress = async ({
    apiToken,
    data
  } = {}) => {
    const [res, error, network] = await this.coreManager.postPatientAddress({
      apiToken,
      data
    });
    return [res, error, network]
  }

  getPatientAddressList = async ({
    apiToken,
    patientId,
    defaultAddress
  } = {}) => {
    const [res, error, network] = await this.coreManager.getPatientAddressList({
      apiToken,
      patientId,
      defaultAddress
    });
    return [res, error, network]
  }

  getPatientAddressDetail = async ({
    apiToken,
    patientAddressId
  } = {}) => {
    const [res, error, network] = await this.coreManager.getPatientAddressDetail({
      apiToken,
      patientAddressId
    });
    return [res, error, network]
  }

  putPatientAddressDetail = async ({
    apiToken,
    patientAddressId,
    data
  } = {}) => {
    const [res, error, network] = await this.coreManager.putPatientAddressDetail({
      apiToken,
      data,
      patientAddressId
    });
    return [res, error, network]
  }

  getShippingAddress = async ({
    apiToken,
    patientId,
    encounterId
  } = {}) => {
    const [res, error, network] = await this.prxManager.getShippingAddress({
      apiToken,
      patientId,
      encounterId
    });
    return [res, error, network]
  }

  postShippingAddress = async ({
    apiToken,
    patientAddressId,
    encounterId,
    selfPickup,
    receiverName,
    receiverTel
  } = {}) => {
    const [res, error, network] = await this.prxManager.postShippingAddress({
      apiToken,
      patientAddressId,
      encounterId,
      selfPickup,
      receiverName,
      receiverTel
    });
    return [res, error, network]
  }

  deletePatientAddress = async ({
    apiToken,
    patientAddressId
  } = {}) => {
    const [res, error, network] = await this.coreManager.deletePatientAddressDetail({
      apiToken,
      patientAddressId
    });
    return [res, error, network]
  }

  getInitPatientAddress = async ({
    apiToken,
    patientId
  } = {}) => {
    const [res, error, network] = await this.prxManager.getInitPatientAddress({
      apiToken,
      patientId
    });
    return [res, error, network]
  }
}