import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

const ComboBox = React.forwardRef((props: any, ref) => {

  const [cboValue, setCboValue] = React.useState('')
  const [localOptions, setLocalOptions] = React.useState([])

  React.useImperativeHandle(ref, () => ({
    getValue: () => {
      let value = formatValueToString()
      return value
    },
    clear: () => {
      setCboValue('')
    }
  }));

  React.useEffect(() => {
    setLocalOptions(props.options)
  }, [props.options])

  React.useEffect(() => {
    formatAndSetCboValue(props.value)
  }, [props.value])

  const handleChange = (e: any, value: any) => {
    if(props.allowAdditions === false || props.multiple === false){
      props.onValueChange(value)
      return setCboValue(value)
    }
    let optionsValue = props.options.map((item: any) => item.value)
    let newOptions = [...props.options]
    for (let items of value) {
      if (!optionsValue.includes(items)) {
        newOptions.push({
          key: items,
          value: items,
          text: items
        })
      }
    }
    setLocalOptions(newOptions as any)
    setCboValue(value)
    props.onValueChange(value)
  }

  const formatAndSetCboValue = (value: any) => {
    if(props.multiple && !Array.isArray(value) && value.length > 0){
      let arr: any[] = value.split('|')
      let newOptions: any[] = [...localOptions]
      for(let item of arr){
        // @ts-ignore
        if(!localOptions.includes(item)){
          newOptions.push({
            key: item,
            value: item,
            text: item
          })
          setLocalOptions(newOptions as any)
        }
      }
      setCboValue(arr as any)
    } else {
      setCboValue(value)
    }
  }

  const formatValueToString = () => {
    if(props.multiple && Array.isArray(cboValue)){
      let strValue = cboValue.join('|')
      return strValue
    } else {
      return cboValue
    }
  }

  return (
    <Dropdown
      lazyLoad
      loading={props.loading}
      style={{ width: props.fluid ? "100%" : "auto" }}
      className={`ComboBox ${props.className} ${props.readOnly ? 'readOnly' : ''}`} 
      multiple={props.multiple}
      search={props.search}
      selection={props.selection}
      disabled={props.disabled}
      clearable={props.clearable}
      options={localOptions}
      fluid={props.fluid}
      compact={props.compact}
      value={cboValue}
      error={props.error}
      placeholder={props.placeholder}
      allowAdditions={props.allowAdditions}
      minCharacters={props.minCharacters}
      noResultsMessage={props.noResultsMessage}
      onLabelClick={props.onLabelClick}
      additionLabel='เพิ่ม '
      onChange={(e, { value }) => {
        props.onChange(e, {value})
        handleChange(e, value)
      }}
    />
  );
})

ComboBox.propTypes = {
  multiple: PropTypes.bool,
  search: PropTypes.bool,
  selection: PropTypes.bool,
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  compact: PropTypes.bool,
  options: PropTypes.array,
  error: PropTypes.bool,
  minCharacters: PropTypes.number,
  noResultsMessage: PropTypes.string,
  placeholder: PropTypes.string,
  onLabelClick: PropTypes.func,
  clearable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  loading: PropTypes.bool,
  
  allowAdditions: PropTypes.bool,
  onValueChange: PropTypes.func,
  onChange: PropTypes.func,
};

ComboBox.defaultProps = {
  multiple: false,
  search: false,
  selection: true,
  disabled: false,
  fluid: false,
  compact: false,
  error: false,
  minCharacters: 0,
  noResultsMessage: 'No results found.',
  onLabelClick: () => { },
  options: [],
  value: '',
  clearable: false,
  placeholder: '',
  loading: false,

  allowAdditions: false,
  onValueChange: () => {},
  onChange: () => {},
};

export default React.memo(ComboBox);

