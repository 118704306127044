import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, TextArea, Input, Grid } from 'semantic-ui-react';

/**
 * A modal display the settings of shift on the given division.
 */

const styles = {
  Button: {
    marginTop: '10px',
    marginBottom: '5%',
    width: '100px',
    height: '40px',
  },
};

 class AlertModal extends React.Component<any, any> {
   
  static defaultProps: any;
  static propsType: any;

  handleCloseModal = () => {
    this.props.onCloseModal();
  }

  render() {
    const {
      onCloseModal,
      alertText,
      openModal
    } = this.props;

    return (
      <Modal
        open={openModal}
        size={'small'}
        onClose={() => onCloseModal()}
        style={{width:'250px'}}
      >
      <Modal.Content style={{width: '90%'}}>
          <div style={{marginBottom:'15px', whiteSpace:'pre-wrap'}}>{alertText}</div>
          <div><Button onClick={() => onCloseModal()}>Close</Button></div>
          {/* <div align='center' style={{marginBottom:'15px', whiteSpace:'pre-wrap'}}>{alertText}</div>
          <div align='center'><Button onClick={() => onCloseModal()}>Close</Button></div> */}
      </Modal.Content>
      </Modal >
    );
  }
}

AlertModal.defaultProps = {
  onCloseModal: () => {},
  openModal: false,
  alertText: '',
}

AlertModal.propsType = {
  onCloseModal: PropTypes.func,
  openModal: PropTypes.bool,
  alertText: PropTypes.string,
}

export default React.memo(AlertModal);

