import React from "react";
import PropTypes from "prop-types";
import { TextArea } from "semantic-ui-react";

const CustomTextArea = React.forwardRef((props: any, ref) => {
  const [text, setText] = React.useState("");

  React.useImperativeHandle(ref, () => ({
    getValue: () => {
      return text;
    },
    clear: () => {
      setText("");
    }
  }));

  React.useEffect(() => {
    setText(props.value);
  }, [props.value]);

  const handleTextChange = (e: any) => {
    const { value } = e.target;
    setText(value);
    props.onChange(e, { value });
  };

  const handleKeyDown = (e: any) => {
    props.onKeyDown(e);
  };

  const handleBlur = (e: any) => {
    props.onBlur(e);
  };

  return (
    <TextArea
      testid={'textArea' + props.testid}
      value={text}
      onChange={handleTextChange}
      style={{ ...props.style }}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
    />
  );
});

CustomTextArea.defaultProps = {
  onChange: () => {},
  value: "",
  style: {},
  onKeyDown: () => {},
  onBlur: () => {},
  testid: '',
};

CustomTextArea.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  testid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default React.memo(CustomTextArea);
