import BaseService from './BaseService';
import { INF, ADMIN, BIL, PRX } from '../../configs/apis';
import * as utils from '../../utils';
import _ from '../../compat/lodashplus';

class BillService extends BaseService {
  /**
   * get ar transactions data
   * @param {object} params
   */
  getARTransactions(params) {
    return this.client
      .get(INF.AR_TRANSACTION_LIST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get bill transactions data
   * @param {object} params
   */
  getBillTransactions(params) {
    return this.client
      .get(INF.BILL_TRANSACTION_LIST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get bill transaction detail
   * @param {number} billTransactionId
   */
  getBillTransactionDetail(billTransactionId) {
    const url = INF.BILL_TRANSACTION_DETAIL({
      bill_transaction_id: billTransactionId
    });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get bill transaction summary
   * @param {object} params
   */
  getBillTransactionSummary(params) {
    return this.client
      .get(INF.BILL_TRANSACTION_SUMMARY, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get send claim transaction list
   * @param {number} arTransactionId
   */
  getSendClaimTransactions(arTransactionId) {
    return this.client
      .get(INF.SEND_CLAIM_TRANSACTION_LIST, {
        params: {
          ar_transaction_id: arTransactionId
        }
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * create ar transaction
   * @param {string} date this field is optional
   */
  createARTransaction(coveragePayerId, startDate, endDate) {
    let promise;
    if (startDate && endDate) {
      startDate = utils.formatDateToYYYYMMDD(startDate);
      endDate = utils.formatDateToYYYYMMDD(endDate);
      promise = this.client.post(INF.CREATE_AR_TRANSACTION, {
        coverage_payer_sent_claim_group: coveragePayerId,
        start_date: startDate,
        end_date: endDate
      });
    } else {
      promise = this.client.post(INF.CREATE_AR_TRANSACTION, {
        coverage_payer_sent_claim_group: coveragePayerId
      });
    }
    return promise.then(_).catch(this.throwErrorMessage);
  }
  /**
   * create bill transaction data
   * @param {string} {coverageCode}
   * @param {string} {payerCode}
   * @param {string} {patientType}
   * @param {string} {startDate}
   * @param {string} {endDate}
   */
  createSentClaimDataHIS({ coveragePayerId, startDate, endDate }) {
    const url = INF.GENERATE_SENT_CLAIM_DATA_HIS({
      coverage_payer_sent_claim_group_id: coveragePayerId,
      generate_start_date: utils.formatDateToYYYYMMDD(startDate),
      generate_end_date: utils.formatDateToYYYYMMDD(endDate)
    });
    return this.client
      .get(url)
      .then(_)
      .catch(this.throwErrorMessage);
  }
  /**
   * download a sent claim file by ar transaction id
   * @param {number} arTransactionId
   */
  downloadSentClaimFile(arTransactionId) {
    const url = INF.GENERATE_SENT_CLAIM_FILE({
      ar_transaction_id: arTransactionId
    });
    return this.client
      .get(url, { responseType: "blob" })
      .then(response => utils.downloadFile(response))
      .catch(this.throwErrorMessage);
  }
  /**
   * download a sent claim file by send claim transaction id
   * @param {number} sendClaimTransactionId
   */
  downloadSentClaimFileFromSendClaimTransaction(sendClaimTransactionId) {
    const url = INF.GENERATE_SEND_CLAIM_FILE_FROM_SEND_CLAIM_TRANSACTION({
      send_claim_transaction_id: sendClaimTransactionId
    });
    return this.client
      .get(url, { responseType: "blob" })
      .then(response => utils.downloadFile(response))
      .catch(this.throwErrorMessage);
  }
  GENERATE_SEND_CLAIM_FILE_FROM_SEND_CLAIM_TRANSACTION;
  /**
   * download a sent claim failed file by ar transaction id
   * @param {number} arTransactionId
   */
  downloadSentClaimFailedFile(arTransactionId) {
    const url = INF.GENERATE_UPDATED_SENT_CLAIM_FILE_FROM_AR({
      ar_transaction_id: arTransactionId
    });
    return this.client
      .get(url, { responseType: "blob" })
      .then(response => utils.downloadFile(response))
      .catch(this.throwErrorMessage);
  }
  /**
   * update sent claim data by ar transaction id
   * @param {number} arTransactionId
   */
  updateSentClaimData(arTransactionId) {
    return this.client
      .get(
        INF.UPDATE_SENT_CLAIM_DATA_FROM_AR({
          ar_transaction_id: arTransactionId
        })
      )
      .then(_)
      .catch(this.throwErrorMessage);
  }
  /**
   * upload a sent claim response file
   * @param {string} payerCode
   * @param {string} coverageCode
   * @param {string} patientType
   * @param {FormData} formData a form data object
   */
  uploadResponseFile({ formData }) {
    const url = INF.IMPORT_RESPONSE_FILE;
    return this.client
      .put(url, formData)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * confirm items which comes from uploadResponseFile()
   * @param {*} payerCode [URL]
   * @param {*} coverageCode [URL]
   * @param {*} patientType [URL]
   * @param {*} items [BODY] items which comes from uploadResponseFile()
   * @param {*} hospitalCode [BODY]
   * @param {*} sentNo [BODY]
   * @param {*} responseNo [BODY]
   * @param {*} responseAt [BODY]
   */
  confirmImportedResponseFile({
    /* URL */
    payerCode,
    coverageCode,
    patientType,
    /* BODY */
    items,
    hospitalCode,
    sentNo,
    responseNo,
    responseAt
  }) {
    const data = {
      items,
      hospital_code: hospitalCode,
      sent_no: sentNo,
      response_no: responseNo,
      response_at: responseAt
    };
    const url = INF.IMPORT_RESPONSE_FILE_CONFIRM;
    return this.client
      .post(url, data)
      .then(_)
      .catch(this.throwErrorMessage);
  }
  /**
   * get all base send claim
   * @param {*} params
   */
  getAllBaseSendClaim(params) {
    return this.client
      .get(INF.ALL_BASE_SEND_CLAIM_LIST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * redirect to bill transaction detail admin page
   * @param {number} billTransactionId
   */
  redirectToBillTransactionDetailAdmin(billTransactionId) {
    const url = ADMIN.BILL_TRANSACTION_DETAIL({
      bill_transaction_id: billTransactionId
    });
    window.open(url, "_blank");
  }
  /**
   * get is patient has debt
   * @param {number} patientId
   */
  getPatientHasDebt(patientId) {
    const url = BIL.PATIENT_HAS_DEBT({ patient_id: patientId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * print medical fee detail
   * @param {*} params
   */
  printMedicalFeeDetail(params) {
    return this.client
      .get(BIL.PRINT_MEDICAL_FEE_DETAIL, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get coverage payer group
   * @param {*} params
   */
  getCoveragePayerGroup(params) {
    return this.client
      .get(INF.GET_COVERAGE_PAYER_SENT_CLAIM_GROUP, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get update sent claim data every 10 sec
   * @param {*} params
   */
  getUpdateSentClaim(params) {
    return this.client
      .get(INF.GET_UPDATE_SENT_CLAIM_DATA, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * get generate sent claim data every 10 sec
   * @param {*} params
   */
  getGenerateSentClaim(params) {
    return this.client
      .get(INF.GET_GENERATE_SENT_CLAIM_DATA, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getQueueList({ params }) {
    return this.client
      .get(BIL.BIL_QUEUE_LIST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getInvoice({ params, apiToken } = {}){
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client.get(BIL.INVOICE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getPendingInvoice({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client.get(BIL.PENDING_INVOICE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getInvoiceDetail({ params, apiToken, invoiceId } = {}){
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client.get(BIL.INVOICE_DETAIL({ invoiceId }), { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postInvoice({ params, apiToken, data, device } = {}){
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (device) {
      this.client.defaults.headers["device"] = device;
    }
    return this.client.post(BIL.INVOICE, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getInvoiceItemByItem({ params, apiToken } = {}){
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client.get(BIL.INVOICEITEM_BYITEM, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postPaymentParameters({ params, data, apiToken } = {}){
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client.post(BIL.PAYMENT_PARAMETERS, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getReceipt({ params, apiToken } = {}){
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client.get(BIL.RECEIPT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getCompleteInvoice({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client.get(BIL.COMPLETE_INVOICE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  printNewMedicalFeeDetail({ params }) {
    return this.client
      .get(BIL.NEW_PRINT_MEDICAL_FEE_DETAIL, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  } 
}
export default BillService;
