import * as Model from "./Model";
// Library for handling time data

const TIMEZERO = Date.parse(new Date("2017-01-01T00:00:00Z").toString());
const DAY_ROUNDING = 1000 * 60 * 60 * 24;
const SERIAL_ROUNDING = 1000 * 60 * 15;
export const TIME_CONST = {
  UNIT_PER_WEEK: 672,
  UNIT_PER_DAY: 96
};

export interface QueueController {
  loadDivisionServiceBlock: ({}) => [{ items: [] }, {}, {}];
  listPatientAppointment: ({}) => [{ items: [] }, {}, {}];
  getPatientAppointment: ({}) => [PatientAppointment | null, {}, {}];
  getDivisionHasUser: ({}) => [{ items: [] }, {}, {}];
  loadServiceSlot: ({}) => [{ items: [] }, {}, {}];
  createServiceSlot: ({}) => [{}, {}, {}];
  updateServiceSlot: ({}) => [{ items: [] }, {}, {}];
  updatePatientAppointment: ({}) => [{}, {}, {}];
  getDoctorWorkSchedule: ({}) => [{ items: [Model.DoctorSchedule] } | null, {}, {}];
  getAvailableDivisionServiceBlock: ({}) => [{ employees: [], dates: [], time_ranges: [] } | null, {}, {}];
  postSelectDivisionServiceBlock: ({}) => [{} | null, {}, {response: { [keys: string]: any }}]
  postRequestChangeDivisionServiceBlock: ({}) => [{} | null, {} | null, {response: { [keys: string]: any }}]
}

export interface ServiceSlot {
  id: number;
  serial: number;
  status: number;
  provider: number;
  division: number;
  division_name: string;
  division_service_block: number;
}

export interface DivisionServiceBlockCompact {
  id: number;
  start_serial: number;
  end_serial: number;
  status: number;
  capacity: number;
  division: number;
  division_name: string;
  provider: number | null;
  provider_name: string;
  employee: number;
  start_datetime: string;
  end_datetime: string;
  start_datetime_iso?: string;
}

export interface DivisionServiceBlock {
  id: number;
  start_serial: number;
  end_serial: number;
  status: number;
  capacity: number;
  division: number;
  division_name: string;
  provider: number | null;
  provider_name: string;
  service_slots: ServiceSlot[];
  service_appointments: PatientAppointment[];
}

export interface DaySlotDisplay {
  serial: number;
  exists: boolean;
  id: number | null;
  status: number | null;
  division: number | null;
  division_name: string;
  divisions: Division[];
  active: boolean;
}

export interface Division {
  id: number;
  name: string;
}

export interface PatientAppointment {
  id: number;
  patient: number;
  patient_first_name: string;
  patient_last_name: string;
  division: number;
  division_name: string;
  provider: number;
  description: string;
  seq: number;
  status: number;
  estimated_at: string;
  division_service_block: number;
  display_info: DivisionServiceBlockCompact;
  estimate_service_at: string | null;
}

export const day_slots = Array.from({ length: 96 }, (v, k) => k);
export const week = Array.from({ length: 7 }, (v, k) => k);

export const get_startofday_serial = (): number => {
  const d = Date.parse(set_midnight(new Date()).toString());
  return Math.round((d - TIMEZERO) / SERIAL_ROUNDING);
};

const set_midnight = (d: Date): Date => {
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0.0);
  return d;
};

export const get_startofweek_serial = (): number => {
  const today_serial = get_startofday_serial();
  const day = new Date().getDay();
  if (day === 0) {
    return today_serial - 96 * 6;
  } else {
    return today_serial - 96 * (day - 1);
  }
};

export const week_to_days = (
  service_slots: ServiceSlot[],
  size: number
): ServiceSlot[][] => {
  if (!service_slots) return [];
  const firstChunk = service_slots.slice(0, size);
  if (!firstChunk.length) {
    return [service_slots];
  }
  return [firstChunk].concat(
    week_to_days(service_slots.slice(size, service_slots.length), size)
  );
};

export const beDateToSerial = (beDate: string) => {
  let date = beDate.split("/")
  let year = parseInt(date[2])
  let month = parseInt(date[1]) - 1
  let day = parseInt(date[0])
  let newDate = new Date(year, month, day)
  return Date.parse(newDate.toString())
}

export const serial_to_datestr = (
  serial: number,
  display: string = "all",
  options: {
    weekday?: string;
    year?: string;
    month?: string;
    day?: string;
  } = {},
  config: {
    locale?: string;
  } = { locale: "th-TH" }
): string => {
  const date = new Date(TIMEZERO + serial * 1000 * 60 * 15);
  if (display === "time") {
    return (
      date
        .getHours()
        .toString()
        .padStart(2, "0") +
      ":" +
      date
        .getMinutes()
        .toString()
        .padStart(2, "0")
    );
  } else if (display === "monthDate") {
    return date
      .toDateString()
      .split(" ")
      .slice(1, -1)
      .join(" ");
  } else if (display === "date") {
    return date.toDateString();
  } else if (display === "localeDate") {
    return date.toLocaleDateString(config.locale, options);
  } else if (display === "localeDay") {
    return date.toLocaleDateString("th-TH", { weekday: "short" });
  } else {
    return date.toString();
  }
};

export const serial_to_hour = (serial: number): number => {
  const date = new Date(TIMEZERO + serial * 1000 * 60 * 15);
  return date.getHours();
};

// Create week_slots_display
export const get_week_slots_display = (
  divisions: number[],
  service_slots: ServiceSlot[],
  weekstart_serial: number,
  division_service_blocks: DivisionServiceBlock[]
): DaySlotDisplay[][] => {
  let result = week.map((d: number, index: number) => {
    return get_day_slots_display(
      divisions,
      service_slots,
      weekstart_serial + d * 96,
      division_service_blocks
    );
  });
  return result;
};

// Create day_slots_display
export const get_day_slots_display = (
  divisions: number[],
  service_slots: ServiceSlot[],
  daystart_serial: number,
  division_service_blocks: DivisionServiceBlock[]
): DaySlotDisplay[] => {
  const service_serials = service_slots.map(s => s.serial);

  const serial_to_divisions = (serial: number) => {
    return division_service_blocks
      .map((dsb, index) => {
        return serial >= dsb.start_serial &&
          serial < dsb.end_serial &&
          dsb.provider === null
          ? { id: dsb.division, name: dsb.division_name }
          : null;
      })
      .filter(d => d != null) as Division[];
  };

  const data = day_slots.map((i, index) => {
    const exists = service_serials.includes(daystart_serial + i);
    const slot = service_slots.filter(s => s.serial === daystart_serial + i)[0];
    let activeSlot = division_service_blocks.map((item) => {
      return [item.start_serial, item.end_serial]
    })
    let today = get_startofday_serial()
    let serial = daystart_serial + i
    let active = false
    for(let item of activeSlot){
      if(serial >= item[0] && serial < item[1] && serial > today){
        active = true
      }
    }

    return {
      serial,
      exists: exists,
      id: exists ? slot.id : null,
      division: exists ? slot.division : null,
      division_name: exists ? slot.division_name : "",
      status: exists ? slot.status : null,
      divisions: serial_to_divisions(daystart_serial + i),
      active,
    };
  });

  // Filter outside instead
  // const start_index = data.findIndex((d) => d.divisions.length > 0)
  // const end_index = data[data.length-1].divisions.length > 0 ? data.length :
  //                     data.length - data.slice().reverse().findIndex((d) => d.divisions.length > 0)
  // const output = start_index === -1 ? [] :
  //                 data.map((d, index) => index >= start_index && index < end_index ? d : null)
  //                     .filter((d) => d != null) as DaySlotDisplay[]

  return data;
};
