import React, { CSSProperties } from "react";
import { Modal, Segment, Button, SemanticCOLORS, Icon } from "semantic-ui-react";
import "../../css/ModInfo.css";

type ModInfoProps = {
  color?: string,
  type?: string, 
  open?: boolean,
  onApprove?() :void,
  alertText?: string | object | null,
  btnText?: string,
  titleName?: string,
  titleColor?: string,
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen" | undefined,
  className?: string,
  closeOnDimmerClick?: boolean,
  onClose?(): void,
  duration?: number,
  buttonColor?: SemanticCOLORS | "facebook" | "google plus" | "vk" | "twitter" | "linkedin" | "instagram" | "youtube" | undefined,
  btnLoading?: boolean,
  textAlign?: string,
  blueBackground?: boolean,
  children?: React.ReactNode,
  generalBlue?: boolean,
  style?: CSSProperties;
  buttonStyle?: CSSProperties,
  closeIcon: boolean;
  backgroundColor?: string;
  centered?: boolean;
  //   color: PropTypes.oneOfType([null, PropTypes.string]),
//   type: PropTypes.string,
//   open: PropTypes.bool,
//   onApprove: PropTypes.func,
//   alertText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//   btnText: PropTypes.string,
//   titleName: PropTypes.string,
//   titleColor: PropTypes.string,
//   size: PropTypes.string,
//   className: PropTypes.string,
//   closeOnDimmerClick: PropTypes.bool,
//   onClose: PropTypes.func,
//   duration: PropTypes.number,
//   buttonColor: PropTypes.string,
//   btnLoading: PropTypes.bool,
//   textAlign: PropTypes.string,
//   blueBackground: PropTypes.bool,
}

const ModInfo = (props: ModInfoProps) => {

  let styles: any = {
    header: {
      lineHeight: "1.3em",
      ...(props.closeIcon && {
        display: "flex",
        justifyContent: "space-between",
      }),
    },
    button: {
      marginTop: "10px"
    }
  };

  let color: SemanticCOLORS | "facebook" | "google plus" | "vk" | "twitter" | "linkedin" | "instagram" | "youtube" | undefined  = undefined;
  let header = "แจ้งเตือน";

  if (props.type === TYPE.ERROR) {
    color = "red";
    header = "เกิดข้อผิดพลาด";
  } else if (props.type === TYPE.SUCCESS) {
    if (props.generalBlue) {
      styles.header["backgroundColor"] = "#006CBE";
      color = undefined;
    } else {
      color = "green";
      header = "บันทึกสำเร็จ";
    }
  } else if (props.type === TYPE.CANCEL){
    color = "red";
    header = "ยกเลิก";
  }

  if(props.color) {
    color = props.color as (SemanticCOLORS | "facebook" | "google plus" | "vk" | "twitter" | "linkedin" | "instagram" | "youtube" | undefined );
  }

  if (props.titleColor) {
    color = props.titleColor as (SemanticCOLORS | "facebook" | "google plus" | "vk" | "twitter" | "linkedin" | "instagram" | "youtube" | undefined );
  }
  if (props.titleName) {
    header = props.titleName;
  }

  if (!color && !props.backgroundColor) {
    color = "orange";
  }

  React.useEffect(() => {
    if (props.duration && props.open && props.onClose) {
      setTimeout(() => {
          props.onClose ? props.onClose() : console.error("no onClose function")
       } , props.duration);
    }
  }, [props.open, props.duration]);

  // console.log(" style", styles.header );
  
  return (
    <Modal
      open={props.open}
      size={props.size}
      className={props.className}
      closeOnDimmerClick={props.closeOnDimmerClick}
      onClose={props.onClose}
      style={props.style}
    >
      <Segment
        inverted
        className={"modHeader " + color}
        style={{ ...styles.header, backgroundColor: props.backgroundColor }}
      >
        {header}
        {props.closeIcon && (
          <Icon name="close" link={true} onClick={props.onClose} />
        )}
      </Segment>
      <Segment padded align="center" className="modContent">
        <div
          ref={(ref) =>
            !!props.centered &&
            !!ref &&
            ((
              ref.closest(".ui.page.modals.visible.active") as any
            ).style.justifyContent = "center")
          }
        >
          {props.alertText}
        </div>
        {props.children}
        <Button
          basic
          color={
            props.buttonColor
              ? props.buttonColor
              : props.generalBlue
              ? "blue"
              : (color as
                  | SemanticCOLORS
                  | "facebook"
                  | "google plus"
                  | "vk"
                  | "twitter"
                  | "linkedin"
                  | "instagram"
                  | "youtube"
                  | undefined)
          }
          className="approveButton"
          style={{ ...styles.button, ...props.buttonStyle }}
          onClick={props.onApprove}
          loading={props.btnLoading}
        >
          {props.btnText}
        </Button>
      </Segment>
    </Modal>
  );
};



const TYPE = {
  ERROR: "error",
  SUCCESS: "success",
  CANCEL: "cancel"
};

// ModInfo.propTypes = {
//   color: PropTypes.oneOfType([null, PropTypes.string]),
//   type: PropTypes.string,
//   open: PropTypes.bool,
//   onApprove: PropTypes.func,
//   alertText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//   btnText: PropTypes.string,
//   titleName: PropTypes.string,
//   titleColor: PropTypes.string,
//   size: PropTypes.string,
//   className: PropTypes.string,
//   closeOnDimmerClick: PropTypes.bool,
//   onClose: PropTypes.func,
//   duration: PropTypes.number,
//   buttonColor: PropTypes.string,
//   btnLoading: PropTypes.bool,
//   textAlign: PropTypes.string,
//   blueBackground: PropTypes.bool,
// };

ModInfo.defaultProps = {
  color: "",
  type: "",
  titleName: "",
  titleColor: "",
  blueBackground: false,
  open: false,
  duration: 0,
  buttonColor: "",
  textAlign: "",
  onApprove: () => {},
  alertText: "",
  btnText: "ตกลง",
  size: "mini",
  className: "",
  closeOnDimmerClick: true,
  onClose: () => {},
  btnLoading: false,
  style: {},
  buttonStyle: {},
  closeIcon: false,
  backgroundColor: "",
};

export default ModInfo;
