import MSGService from "../services/MSGService";
import { to } from "../../utils";
export default class MSGManager {
  getLatestChatChannel = async ({ apiToken, userId } = {}) => {
    let params = {};
    if (userId) {
      params.user_id = userId;
    }
    const msgService = new MSGService();
    const [error, response, network] = await to(
      msgService.getLatestChatChannel({ params, apiToken })
    );
    return [response, error, network];
  };
  getChatChannelMessageList = async ({
    apiToken,
    chatChannelId,
    last,
    cancelToken
  } = {}) => {
    let params = {};
    if (last) {
      params.last = last;
    }
    const msgService = new MSGService();
    const [error, response, network] = await to(
      msgService.getChatChannelMessageList({ params, apiToken, chatChannelId, cancelToken })
    );
    return [response, error, network];
  };
  getMessageFromURL = async ({ apiToken, url } = {}) => {
    let params = {};
    const msgService = new MSGService();
    const [error, response, network] = await to(
      msgService.getMessageFromURL({ params, apiToken, url })
    );
    return [response, error, network];
  };
  postChatChannelMessage = async ({
    apiToken,
    content,
    contentType,
    chatChannelId,
    contentFile,
    divisionId
  } = {}) => {
    let params = {};
    let data = {};
    if (content) {
      data.content = content;
    }
    if (contentType) {
      data.content_type = contentType;
    }
    if (contentFile) {
      data.content_file = contentFile;
    }
    const msgService = new MSGService();
    const [error, response, network] = await to(
      msgService.postChatChannelMessage({ params, apiToken, data, chatChannelId, divisionId })
    );
    return [response, error, network];
  };
  postReadMessage = async ({ apiToken, chatChannelId } = {}) => {
    let params = {};
    let data = {};
    const msgService = new MSGService();
    const [error, response, network] = await to(
      msgService.postReadMessage({ params, apiToken, data, chatChannelId })
    );
    return [response, error, network];
  };
  postUpdateSubscription = async ({ apiToken, device_id, subscription } = {}) => {
    let params = {};
    let data = {};
    if (subscription && device_id) {
      data.device_token = JSON.stringify(subscription);
      data.unique_id = device_id;
      data.device_id = device_id;
      data.device_name = device_id;
      data.device_type = 4;
      data.application = "ishealth";
    }
    const msgService = new MSGService();
    const [error, response, network] = await to(
      msgService.postUpdateSubscription({ params, apiToken, data })
    );
    return [response, error, network];
  };
}
