export default function toPDFMakeData(props: any) {
  return {
    defaultStyle: {
      font: `THSarabunNew`,
    },
    content: [
      {
        width: `auto`,
        alignment: `center`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        fontSize: `18`,
        decorationColor: ``,
        color: ``,
        decoration: ``,
        decorationStyle: ``,
        text: `หนังสือแสดงเจตนาไม่ประสงค์จะรับบริการสาธารณสุขที่เป็นเพียง`,
        bold: `true`,
      },
      {
        decoration: ``,
        fontSize: `18`,
        preserveLeadingSpaces: true,
        text: `เพื่อยืดการตายในวาระสุดท้ายของชีวิต หรือเพื่อยุติการทรมานจากการเจ็บป่วย (Living Will)`,
        pageBreak: ``,
        width: `auto`,
        bold: `true`,
        decorationColor: ``,
        decorationStyle: ``,
        color: ``,
        alignment: `center`,
      },
      {
        width: `auto`,
        decorationStyle: ``,
        decorationColor: ``,
        bold: false,
        fontSize: 15,
        alignment: `left`,
        text: ` `,
        pageBreak: ``,
        decoration: ``,
        color: ``,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            pageBreak: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            text: ` `,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            width: 403,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `เขียนที่ โรงพยาบาลเซเปี้ยนซ์`,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            alignment: `right`,
            pageBreak: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            width: 355,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            text: ``,
          },
          {
            color: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            width: `auto`,
            text: `วันที่`,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            width: 5,
            decoration: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            decorationStyle: ``,
            text: ``,
            decorationColor: ``,
          },
          {
            decoration: ``,
            color: ``,
            fontSize: 15,
            alignment: `center`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            text: props.items?.printDay,
            pageBreak: ``,
            width: `auto`,
          },
          {
            text: ``,
            bold: false,
            width: 5,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: `เดือน `,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            decoration: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            width: 5,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            color: ``,
            alignment: `left`,
          },
          {
            text: props.items?.printMonth,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
            decoration: ``,
            alignment: `center`,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            width: 5,
            color: ``,
            bold: false,
            text: ``,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: `พ.ศ. `,
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
          },
          {
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            width: 5,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
          },
          {
            text: props.items?.printYear,
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            alignment: `center`,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        decoration: ``,
        width: `auto`,
        pageBreak: ``,
        fontSize: 15,
        color: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decorationColor: ``,
        text: ` `,
        alignment: `left`,
        bold: false,
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            width: 30,
            text: ``,
            decoration: ``,
            bold: false,
          },
          {
            alignment: `left`,
            bold: false,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
            text: `ข้าพเจ้า (นาย, นาง , นางสาว)`,
          },
          {
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            width: 5,
            text: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
          },
          {
            stack: [
              {
                decorationColor: ``,
                decoration: ``,
                text: props.items?.full_name,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                color: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                width: `auto`,
                decorationStyle: ``,
                pageBreak: ``,
                bold: false,
              },
              {
                fontSize: 15,
                decorationColor: ``,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                bold: false,
                decoration: ``,
                text: `....................................................................`,
                width: `auto`,
                alignment: `left`,
                color: ``,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `อายุ`,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: 20,
            fontSize: 15,
            bold: false,
          },
          {
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 5,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            text: ``,
          },
          {
            stack: [
              {
                text: props.items?.age,
                pageBreak: ``,
                width: `auto`,
                alignment: `center`,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                color: ``,
                decoration: ``,
                bold: false,
                fontSize: 15,
                margin: [0, 0, 0, -15],
                decorationColor: ``,
              },
              {
                decorationStyle: ``,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                bold: false,
                text: `....................................................................`,
                color: ``,
                decorationColor: ``,
                width: `auto`,
                fontSize: 15,
                preserveLeadingSpaces: true,
                decoration: ``,
                pageBreak: ``,
              },
            ],
          },
          {
            width: `auto`,
            decoration: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `ปี`,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `เลขประจำตัวบัตรประชาชน `,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            text: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 5,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            bold: false,
          },
          {
            stack: [
              {
                alignment: `left`,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
                bold: false,
                decoration: ``,
                decorationColor: ``,
                fontSize: 15,
                text:
                  props.items?.citizen_no === "" ||
                  props.items?.citizen_no === "Non citizen identity"
                    ? props.items?.citizen_passport === "" ||
                      props.items?.citizen_passport === "Non citizen identity"
                      ? " "
                      : props.items?.citizen_passport
                    : props.items?.citizen_no,
                color: ``,
                pageBreak: ``,
                width: `auto`,
              },
              {
                bold: false,
                color: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                alignment: `left`,
                fontSize: 15,
                decoration: ``,
                width: `auto`,
                decorationStyle: ``,
                text: `.....................................................................................................................................................................`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decorationStyle: ``,
            text: `ที่อยู่ที่ติดต่อได้ `,
            color: ``,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            color: ``,
            width: 5,
          },
          {
            stack: [
              {
                fontSize: 15,
                margin: [0, 0, 0, -15],
                width: `auto`,
                alignment: `left`,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? `${props.items?.formAddress?.no}  ${props.items?.formAddress?.name}  ${props.items?.formAddress?.street}  ${props.items?.formAddress?.road}  ${props.items?.formAddress?.city}  ${props.items?.formAddress?.district}  ${props.items?.formAddress?.province}  ${props.items?.formAddress?.zipcode}`
                    : "  ",
                decorationStyle: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                bold: false,
                decorationColor: ``,
                color: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                width: `auto`,
                bold: false,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: `..........................................................................................................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                color: ``,
              },
            ],
          },
        ],
      },
      {
        text: `....................................................................................................................................................................................................................`,
        bold: false,
        decoration: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
        width: `auto`,
        fontSize: 15,
        decorationColor: ``,
      },
      {
        columns: [
          {
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            text: `เบอร์โทรศัพท์ `,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationColor: ``,
            width: 5,
            bold: false,
            text: ``,
          },
          {
            stack: [
              {
                bold: false,
                margin: [0, 0, 0, -15],
                width: `auto`,
                decorationStyle: ``,
                decoration: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                alignment: `left`,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? props.items?.present_address?.tel_mobile
                    : "  ",
                decorationColor: ``,
                color: ``,
                fontSize: 15,
              },
              {
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                bold: false,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                alignment: `left`,
                text: `............................................................................................................................................................................................`,
                width: `auto`,
                decoration: ``,
                pageBreak: ``,
                color: ``,
                fontSize: 15,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationStyle: ``,
            width: 10,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            text: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: `1. ขณะทำหนังสือฉบับนี้ข้าพเจ้ามีสติสัมปชัญญะสมบูรณ์ และมีความประสงค์ที่จะแสดงเจตนาที่จะขอตายอย่างสงบตามธรรมชาติ ไม่ต้องการให้มีการใช้เครื่องมือใดๆ กับข้าพเจ้า เพื่อยืดการตายออกไปโดยไม่จำเป็น และจะเป็นการสูญเปล่าตามมารตรา 12 แห่ง พระราชบัญญัติสุขภาพแห่งชาติ พ.ศ. 2550 `,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            text: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 10,
            decoration: ``,
            bold: false,
            color: ``,
            fontSize: 15,
            alignment: `left`,
          },
          {
            color: ``,
            text: `2.`,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
            decorationColor: ``,
            bold: `true`,
            text: ` เมื่อข้าพเจ้าอยู่ในวาระสุดท้ายของชีวิต`,
            alignment: `left`,
            color: ``,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            bold: false,
            text: ` หรือเมื่อข้าพเจ้าได้รับทุกข์ทรมานจากการเจ็บป่วยจากโรคที่ไม่อาจรักษาได้ข้าพเจ้าขอ`,
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            width: 10,
          },
          {
            fontSize: 15,
            text: `รับบริการสาธารณสุขที่เป็นไปเพียงยืดการตายในวาระสุดท้ายของชีวิต หรือการทรมานจากการเจ็บป่วย จึงให้ความยินยอมให้ทีม`,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            width: `auto`,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            width: 10,
            decorationStyle: ``,
            bold: false,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            text: `ผู้รักษาให้การรักษาประคับประคอง ตามที่ระบุดังนี้`,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            width: 25,
            bold: false,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            text: `2.1 การทำหัตถการเพื่อวินิจฉัยโรค ระบุ …………………….....................................`,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            width: 10,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
          },
          {
            alignment: `center`,
            decorationColor: ``,
            color: ``,
            text: `[  ]`,
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            text: ``,
            bold: false,
            pageBreak: ``,
            width: 5,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            text: `ต้องการ`,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
          },
          {
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 5,
            decorationColor: ``,
            text: ``,
            decoration: ``,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: `[  ]`,
            pageBreak: ``,
            alignment: `center`,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
            width: `auto`,
          },
          {
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            width: 5,
            decorationColor: ``,
            text: ``,
            color: ``,
            alignment: `left`,
            fontSize: 15,
          },
          {
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `ไม่ต้องการ`,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
            width: 25,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            text: `2.2 การให้อาหารทางสายยาง `,
            bold: false,
            fontSize: 15,
            width: `auto`,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            text: `		                  `,
            pageBreak: ``,
            width: 200,
          },
          {
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            width: `auto`,
            text: `[  ]`,
            decoration: ``,
            decorationColor: ``,
          },
          {
            width: 5,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            text: ` `,
          },
          {
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            text: `ต้องการ  `,
            color: ``,
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 5,
            alignment: `left`,
            fontSize: 15,
            text: ``,
            color: ``,
          },
          {
            alignment: `center`,
            preserveLeadingSpaces: true,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            text: `[  ]`,
            bold: false,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            width: 5,
            bold: false,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            text: `ไม่ต้องการ`,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            width: 25,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            text: `2.3 การให้สารน้ำ/ เลือดทางหลอดเลือดดำ  `,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            text: `              `,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            width: 149,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `center`,
            color: ``,
            decoration: ``,
            text: `[  ]`,
          },
          {
            alignment: `left`,
            width: 5,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            text: `ต้องการ  `,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            decorationColor: ``,
            width: 5,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            text: `[  ]`,
            decoration: ``,
            fontSize: 15,
            alignment: `center`,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
          },
          {
            decoration: ``,
            text: ` `,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            width: 5,
            alignment: `left`,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            text: `ไม่ต้องการ`,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 25,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            text: ``,
            color: ``,
            fontSize: 15,
            decoration: ``,
          },
          {
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            text: `2.4 การเจาะคอเพื่อใส่ท่อช่วยหายใจ  `,
            bold: false,
            decoration: ``,
            color: ``,
          },
          {
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            text: `                       `,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 174,
            color: ``,
          },
          {
            pageBreak: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            text: `[  ]`,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            alignment: `center`,
          },
          {
            text: ``,
            decorationStyle: ``,
            width: 5,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            width: `auto`,
            color: ``,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            text: `ต้องการ  `,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            width: 5,
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
          },
          {
            bold: false,
            decorationStyle: ``,
            width: `auto`,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `[  ]`,
            fontSize: 15,
            alignment: `center`,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            width: 5,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            text: `ไม่ต้องการ`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            color: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 25,
            decorationColor: ``,
          },
          {
            bold: false,
            decoration: ``,
            alignment: `left`,
            text: `2.5 การใช้เครื่องช่วยหายใจ  `,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
          },
          {
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            color: ``,
            width: 209,
            text: `                                    `,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            text: `[  ]`,
            alignment: `center`,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            decoration: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            bold: false,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            width: 5,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            text: `ต้องการ`,
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
          },
          {
            alignment: `left`,
            color: ``,
            text: ``,
            width: 5,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
          },
          {
            alignment: `center`,
            bold: false,
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            text: `[  ]`,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            color: ``,
            width: 5,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            decoration: ``,
            text: `ไม่ต้องการ`,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            width: `auto`,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            color: ``,
            text: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 25,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            alignment: `left`,
          },
          {
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            text: `2.6 การใช้ยากระตุ้นระบบไหลเวียน และยาเพิ่มความดันโลหิต  `,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: 77,
            text: ``,
            bold: false,
            alignment: `left`,
            color: ``,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            bold: false,
            text: `[  ]`,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            alignment: `center`,
          },
          {
            width: 5,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: `ต้องการ`,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            bold: false,
          },
          {
            color: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            width: 5,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
            text: `[  ]`,
            alignment: `center`,
            color: ``,
          },
          {
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            width: 5,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            text: `ไม่ต้องการ`,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            text: ``,
            width: 25,
            decoration: ``,
          },
          {
            alignment: `left`,
            fontSize: 15,
            text: `2.7 การนวดหัวใจเพื่อช่วยฟื้นคืนชีพเมื่อหัวใจหยุด`,
            color: ``,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            width: 124,
            preserveLeadingSpaces: true,
            text: `      `,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            alignment: `center`,
            decoration: ``,
            text: `[  ]`,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            width: 5,
            decoration: ``,
            color: ``,
          },
          {
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            text: `ต้องการ`,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            fontSize: 15,
          },
          {
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 5,
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            alignment: `center`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            text: `[  ]`,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            color: ``,
            decoration: ``,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            width: 5,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            bold: false,
            fontSize: 15,
            width: `auto`,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            text: `ไม่ต้องการ`,
            decorationStyle: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            width: 25,
            alignment: `left`,
            decoration: ``,
            color: ``,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            text: `2.8 อื่นๆ ระบุ ……………………………………………….................................................`,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            color: ``,
          },
          {
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            width: 10,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            alignment: `center`,
            decoration: ``,
            fontSize: 15,
            text: `[  ]`,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 5,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            text: `ต้องการ  `,
            color: ``,
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 5,
            decoration: ``,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
          },
          {
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            alignment: `center`,
            decorationColor: ``,
            text: `[  ]`,
            decoration: ``,
          },
          {
            decoration: ``,
            text: ``,
            width: 5,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            width: `auto`,
            decoration: ``,
            color: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: `ไม่ต้องการ`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            text: ``,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            width: 10,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            decoration: ``,
            text: `3. ข้าพเจ้ามีความประสงค์ที่จะได้รับการดูแลรักษา เพื่อบรรเทาอาการทุกข์ทรมาน โดยขอให้สถานพยาบาล หรือผู้ประกอบ`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            width: 10,
            decoration: ``,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            text: `วิชาชีพด้านสาธารณสุขอำนวยความสะดวกตามความเหมาะสม และความเป็นไปได้ในทางปฏิบัติ ดังต่อไปนี้`,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: ``,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            width: 10,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
            width: `auto`,
            text: `[  ]`,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            width: 10,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
          },
          {
            alignment: `left`,
            width: `auto`,
            text: `ความประสงค์ที่จะเสียชีวิตที่บ้าน`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            width: 10,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
          },
          {
            text: `[  ]`,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `center`,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            text: ``,
            color: ``,
            width: 10,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            text: `การเยียวยาจิตใจอื่น (กรุณาระบุ เช่น การสวดมนต์, การเทศนาของนักบวช เป็นต้น)`,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            width: `auto`,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            text: ` ................................................................`,
            width: `auto`,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            width: 10,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: ` `,
            bold: false,
            margin: [0, 0, 0, 0],
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            text: `…………………………………………..........………………………………………….…….…………..…..............................................................................`,
            bold: false,
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            width: 30,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
            color: ``,
            alignment: `left`,
            decoration: ``,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            text: `หากมีกรณีความไม่ชัดเจนในหนังสือแสดงเจตนาฯ ฉบับบนี้ เกิดขึ้นในช่วงที่ข้าพเจ้าไม่มีสติสัมปชัญญะ หรือไม่อาจ`,
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            text: `สื่อสารกับผู้อื่นได้ตามปกติ ข้าพเจ้ามอบหมายให้ (ชื่อ-นามสกุล)  ............................................................................................................`,
            bold: false,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            text: `ความสัมพันธ์ ..........................................................`,
            bold: false,
            width: `auto`,
          },
          {
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            text: ` (ถ้ามี) เป็นผู้แสดงเจตนาแทนเพื่อทำหน้าที่ตัดสินใจตามความประสงค์ของข้าพเจ้า`,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        decorationColor: ``,
        decorationStyle: ``,
        color: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        pageBreak: `before`,
        decoration: ``,
        text: ``,
        alignment: `left`,
        bold: false,
        width: `auto`,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            width: 30,
            decoration: ``,
          },
          {
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            text: `ข้าพเจ้าได้ทำหนังสือแสดงเจตนาต่อหน้าพยาน และทำสำเนาเอกสารมอบให้บุคคลใกล้ชิด และพยานเก็บรักษาไว้ เพื่อนำไป`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
        ],
      },
      {
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        color: ``,
        width: `auto`,
        fontSize: 15,
        text: `แสดงต่อสถานพยาบาลเมื่อข้าพเจ้าถูกนำตัวเข้ารักษาในสถานพยาบาลในครั้งแรก`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        alignment: `left`,
        decoration: ``,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            text: `หมายเหตุ`,
            fontSize: 15,
            color: ``,
            bold: `true`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            width: `auto`,
          },
          {
            width: 5,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            text: ``,
          },
          {
            width: `auto`,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            text: `การให้คำยินยอมดังกล่าวข้างต้นอาจเปลี่ยนแปลงได้ เมื่อสถานการณ์ และความต้องการของผู้ป่วย และครอบครัว`,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            bold: false,
            color: ``,
            width: 45,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            text: `เปลี่ยนแปลง และได้ปรึกษาหารือกันอีกครั้ง`,
            bold: false,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            width: 250,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            alignment: `left`,
            color: ``,
          },
          {
            color: ``,
            width: 300,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            text: `ลงชื่อ.....................................................(ผู้แสดงเจตนา)`,
            alignment: `center`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            width: 250,
            fontSize: 15,
            decoration: ``,
            text: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            bold: false,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            width: 300,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `(.............................................................)`,
            alignment: `center`,
          },
        ],
      },
      {
        decorationStyle: ``,
        decoration: ``,
        decorationColor: ``,
        bold: false,
        text: ` `,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        fontSize: 15,
        alignment: `left`,
        color: ``,
      },
      {
        columns: [
          {
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            width: 250,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            text: `ลงชื่อ...........................................(บุคคลใกล้ชิด/ญาติ)`,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            width: 300,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `center`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 250,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            text: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            bold: false,
            alignment: `center`,
            text: `(.............................................................)`,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 300,
            fontSize: 15,
            decorationStyle: ``,
          },
        ],
      },
      {
        text: ` `,
        decoration: ``,
        fontSize: 15,
        decorationStyle: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        bold: false,
        alignment: `left`,
        color: ``,
        decorationColor: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            text: ``,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            width: 250,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
          },
          {
            color: ``,
            bold: false,
            width: 300,
            decorationColor: ``,
            text: `ลงชื่อ....................................................(พยานคนที่ 1)`,
            alignment: `center`,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            color: ``,
            alignment: `left`,
            text: ``,
            width: 250,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            alignment: `center`,
            bold: false,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            text: `(.............................................................)`,
            width: 300,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
          },
        ],
      },
      {
        decorationColor: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        pageBreak: ``,
        decorationStyle: ``,
        fontSize: 15,
        bold: false,
        color: ``,
        decoration: ``,
        width: `auto`,
        text: ` `,
      },
      {
        columns: [
          {
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 250,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            decorationColor: ``,
            color: ``,
            bold: false,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            alignment: `center`,
            decoration: ``,
            fontSize: 15,
            text: `ลงชื่อ....................................................(พยานคนที่ 2)`,
            decorationStyle: ``,
            width: 300,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            width: 250,
            decorationColor: ``,
            text: ``,
            decoration: ``,
            bold: false,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            decoration: ``,
            bold: false,
            fontSize: 15,
            width: 300,
            text: `(.............................................................)`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            alignment: `center`,
            pageBreak: ``,
          },
        ],
      },
      {
        bold: false,
        decorationColor: ``,
        pageBreak: ``,
        text: ` `,
        fontSize: 15,
        width: `auto`,
        color: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationStyle: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            width: `auto`,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            bold: `true`,
            color: ``,
            decorationColor: ``,
            text: `บุคคลใกล้ชิดหรือญาติ `,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            width: 5,
            bold: false,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
            text: `ชื่อ-นามสกุล...............................................................................................................................................`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            bold: false,
            width: `auto`,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `มีความสัมพันธ์เป็น...............................................................`,
          },
          {
            width: 5,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            text: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            text: `บัตรประจำตัวประชาชนหมายเลข.....................................................`,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: `ที่อยู่ที่ติดต่อได้.................................................................................................................................................................................`,
            width: `auto`,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `หมายเลขโทรศัพท์................................................................... `,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            width: 5,
            alignment: `left`,
            bold: false,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
          },
          {
            alignment: `left`,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            text: `หมายเลขโทรศัพท์ที่ทำงาน............................................................`,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
          },
        ],
      },
      {
        alignment: `left`,
        decorationStyle: ``,
        decorationColor: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        color: ``,
        text: ` `,
        width: `auto`,
        bold: false,
        fontSize: 15,
        decoration: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `center`,
            color: ``,
            decorationStyle: ``,
            bold: `true`,
            width: `auto`,
            text: `พยานคนที่ 1`,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
          },
          {
            width: 5,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            text: ``,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            color: ``,
          },
          {
            width: `auto`,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `ชื่อ-นามสกุล.............................................................................................................................................................`,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `มีความสัมพันธ์เป็น..............................................................`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
          },
          {
            bold: false,
            text: ``,
            decorationStyle: ``,
            width: 5,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            text: `บัตรประจำตัวประชาชนหมายเลข.....................................................`,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationColor: ``,
            text: `ที่อยู่ที่ติดต่อได้.................................................................................................................................................................................`,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            text: `หมายเลขโทรศัพท์................................................................... `,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
            text: ``,
            alignment: `left`,
            width: 5,
            decoration: ``,
          },
          {
            decorationColor: ``,
            color: ``,
            bold: false,
            text: `หมายเลขโทรศัพท์ที่ทำงาน............................................................`,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        text: ` `,
        color: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        decorationColor: ``,
        decoration: ``,
        decorationStyle: ``,
        fontSize: 15,
        alignment: `left`,
        bold: false,
        pageBreak: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            text: `พยานคนที่ 2`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            bold: `true`,
          },
          {
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            width: 5,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            text: `ชื่อ-นามสกุล.............................................................................................................................................................`,
            decoration: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            text: `มีความสัมพันธ์เป็น...............................................................`,
            decorationColor: ``,
            width: `auto`,
            bold: false,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            width: 5,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            text: ``,
          },
          {
            text: `บัตรประจำตัวประชาชนหมายเลข.....................................................`,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            bold: false,
            color: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `ที่อยู่ที่ติดต่อได้.................................................................................................................................................................................`,
            pageBreak: ``,
            width: `auto`,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `หมายเลขโทรศัพท์...................................................................`,
            decoration: ``,
            fontSize: 15,
            color: ``,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 5,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
          },
          {
            bold: false,
            decoration: ``,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `หมายเลขโทรศัพท์ที่ทำงาน............................................................`,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
        ],
      },
    ],
    pageOrientation: `portrait`,
    pageSize: `A4`,
    header: props.header,
    footer: props.footer,
  };
}
