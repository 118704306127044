import DiagService from "./DiagService";
import { to, setStateAsync } from "../react-lib/utils";

export default class DiagManager {
    getDiagRuleList = async ({apiToken, search, divisionId}) => {
        let headers = {}
        let params = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        if (search) {
            params.search = search;
        }
        if (divisionId) {
            params.division = divisionId;
        }
        const diagService = new DiagService();
        const [error, response] = await to(diagService.getDiagRuleList({ headers, params }));
        return [response, error];
    }

    getPatient = async ({apiToken}) => {
        let headers = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        const diagService = new DiagService();
        const [error, response] = await to(diagService.getPatient({ headers }));
        return [response, error];
    } 

    createDiagForm = async ({apiToken, patient, diagRule, answers, triageLevel, finished}) => {
        let headers = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        let data = {
            patient: patient,
            published_diag_rule: diagRule,
            answers: answers,
            triage_level: triageLevel,
            finished: finished,
        }
        const diagService = new DiagService();
        const [error, response] = await to(diagService.postDiagForm({ headers, data }));
        return [response, error];
    }

    updateDiagForm = async ({apiToken, diagFormId, answers, finished, triageLevel, text}) => {
        let headers = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        let data = {
            id: diagFormId,
            answers: answers,
            finished: finished,
            triage_level: triageLevel,
            text: text,
        }
        console.log("updateDiagForm Request: ",JSON.stringify(data));
        const diagService = new DiagService();
        const [error, response] = await to(diagService.patchDiagFormId({ diagFormId, headers, data }));
        console.log("updateDiagForm response: ",JSON.stringify(response));
        console.log("updateDiagForm response: ",JSON.stringify(error));
        return [response, error];
    }

    getTriageLevelWithDivision = async ({apiToken, division, triageLevel}) => {
        let headers = {}
        let params = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        if (triageLevel) {
            params.triage_level = triageLevel;
        }
        const diagService = new DiagService();
        const [error, response] = await to(diagService.getTriageLevelWithDivision({ division, headers, params }));
        return [response, error];
    }

    createEncounterWithDiagForm = async ({apiToken, diagFormId}) => {
        let headers = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        let data = {
            diag_form: diagFormId,
        }
        const diagService = new DiagService();
        const [error, response] = await to(diagService.postCreateEncounterFromDiagForm({ headers, data }));
        return [response, error];
    }

    /** Division Profile */
    getDivisionProfileWithID = async ({apiToken, divisionId}) => {
        let headers = {}
        let params = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        const diagService = new DiagService();
        const [error, response] = await to(diagService.getDivisionProfileWithID({ divisionId, headers, params }));
        return [response, error];
    }

    getPublishDiagRuleDetail = async ({ apiToken, publishDiagRuleId } = {}) => {
        let headers = {}
        let params = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        const diagService = new DiagService();
        const [error, response, network] = await to(diagService.getPublishDiagRuleDetail({ publishDiagRuleId, headers, params }));
        return [response, error, network];
    }

    getProxyPatientHasDivision = async ({ apiToken } = {}) => {
        let headers = {}
        let params = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        const diagService = new DiagService();
        const [error, response, network] = await to(diagService.getProxyPatientHasDivision({ headers, params }));
        return [response, error, network];
    }

    getPublishDiagRuleCheck = async ({ apiToken, divisionId, code } = {}) => {
        let headers = {}
        let params = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        if(divisionId){
            params.division = divisionId
        }
        if(code){
            params.code = code
        }
        const diagService = new DiagService();
        const [error, response, network] = await to(diagService.getPublishDiagRuleCheck({ headers, params }));
        return [response, error, network];
    }

    getDiagFormDetail = async ({ apiToken, diagFormId } = {}) => {
        let headers = {}
        let params = {}
        if (apiToken) {
            headers.Authorization = `Token ${apiToken}`;
        }
        const diagService = new DiagService();
        const [error, response, network] = await to(diagService.getDiagFormDetail({ headers, params, diagFormId }));
        return [response, error, network];
    }
}