export default class VCAppointmentSlotController {
  constructor(props) {
    this.prxManager = props.prxManager;
  }

  getAvailableVaccineDivision = async ({ apiToken, division, year_month }) => {
    const [response, error, network] = await this.prxManager.getAvailableVaccineDivision(
      {
        apiToken,
        division,year_month
      }
    );
    return [response, error, network];
  };
}
