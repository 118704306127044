import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import _ from '../../compat/lodashplus';

export const formatComma = (val: any) => (
  !isNaN(parseFloat(val))
    ? parseFloat(val).toFixed(2).toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ',')
    : val
);

export default class PureReactTable extends React.PureComponent<any, any> {
  clickTimer: any;
  clickCount: any;

  constructor(props: any) {
    super(props)
    this.clickTimer = null;
    this.clickCount = 0;
  }

  static propTypes = {
    selectedRow: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
    page: PropTypes.number,
    pages: PropTypes.number,
    pageSize: PropTypes.number,
    onPageSizeChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onClick: PropTypes.func,
    onEnter: PropTypes.func,
    selectedClassName: PropTypes.string,
    className: PropTypes.string,
    manual: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    data: [],
    columns: [],
    selectedRow: null,
    onSelect() { },
    onEnter() { },
    selectedClassName: 'selected',
    className: '-striped -highlight',
    manual: true,
  };

  render() {

    const {
      height,
      onSelect,
      onEnter,
      selectedRow,
      data,
      getTrProps = () => ({}),
      selectedClassName,
      className,
      ...rest
    } = this.props;

    const noId = _.find(data, (item: any) => typeof item.id === 'undefined');

    if (noId) {
      console.error("Every row should have an id on data:", data)
    }

    return (
      // @ts-ignore
      <ReactTable
        manual={this.props.manual} // informs React Table that you'll be handling sorting and pagination server-side
        className={className}
        getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
          if (rowInfo && rowInfo.row) {
            const id = rowInfo.original && rowInfo.original.id
            const options = getTrProps(state, rowInfo, column, instance);
            let selectedRowId = null;

            if (selectedRow && typeof selectedRow === 'object') {
              selectedRowId = selectedRow.id;
            }
            if (selectedRow && typeof selectedRow === 'number') {
              selectedRowId = selectedRow;
            }

            return {
              ...options,
              onClick: (event: any, handleOriginal: any) => {
                if (handleOriginal) {
                  handleOriginal();
                }

                this.clickCount++;
                if (this.clickCount === 1) {
                  this.clickTimer = setTimeout(() => {
                    this.clickCount = 0;
                    // Single Click
                    onSelect(rowInfo.original);
                  }, 400)
                } else if (this.clickCount === 2) {
                  clearTimeout(this.clickTimer);
                  this.clickCount = 0;
                  // Double Click
                  onEnter(rowInfo.original);
                }
              },
              onKeyUp(event: any) {
                // TODO: handle keyboard navigation
              },
              className: id && (id === selectedRowId ? selectedClassName : '')
            };
          }
          return {};
        }}
        data={data}
        {...rest}
      />
    );
  }
}


export const alignRight = (val: any) => <div style={{ textAlign: 'right' }}>{val}</div>;
export const alignCenter = (val: any) => <div style={{ textAlign: 'center' }}>{val}</div>;
export const formatPrice = (val: any) => <div style={{ textAlign: 'right' }}>{formatComma(val)}</div>;
