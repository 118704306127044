import _ from "lodash";
import config from "../../config/config";

// change global template delimiters to {{}}
_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

//      ** how to use it **
//      if a url needs certain parameters, you have to call it like a function, e.g,
//
//      import { INF } from '../../react-lib/configs/apis'
//      INF.GENERATE_SENT_CLAIM_FILE({
//          ar_transaction_id: 1,
//      })

// console.log("hostname: ",window.location.hostname);
// console.log("host: ",window.location.host);
// console.log("port: ",window.location.port);

export var HOST = `${config.API_HOST}`;

// console.log("API HOST: ",HOST);

const STATIC_HOST = `${config.STATIC_HOST}`;
// const TAGOPTIONS_URL = `${config.TAGOPTIONS_URL}`
const ACCOUNT_THE_VC = "https://accounts.thevcgroup.com";

export const INF = {
  BILL_TRANSACTION_UPDATE_STATUS: _.template(
    "/apis/INF/bill-transaction-update-status/{{bill_transaction_id}}/"
  ),
  AR_TRANSACTION_FILTER: HOST + "/apis/INF/ar-transaction-filter/",
  BILL_TRANSACTION_LIST: HOST + "/apis/INF/bill-transaction/",
  BILL_TRANSACTION_DETAIL: _.template(
    HOST + "/apis/INF/bill-transaction/{{bill_transaction_id}}/"
  ),
  BILL_TRANSACTION_SUMMARY: HOST + "/apis/INF/bill-transaction/summary/",
  AR_TRANSACTION_LIST: HOST + "/apis/INF/ar-transaction/",
  SEND_CLAIM_TRANSACTION_LIST: HOST + "/apis/INF/send-claim-transaction/",
  ALL_BASE_SEND_CLAIM_LIST: HOST + "/apis/INF/all-base-send-claim/",
  GENERATE_SENT_CLAIM_FILE: _.template(
    HOST + "/apis/INF/generate-sent-claim-file-from-ar/{{ar_transaction_id}}/"
  ),
  GENERATE_SEND_CLAIM_FILE_FROM_SEND_CLAIM_TRANSACTION: _.template(
    HOST +
    "/apis/INF/generate-sent-claim-file-from-sent-claim-transaction/{{send_claim_transaction_id}}/"
  ),
  GENERATE_UPDATED_SENT_CLAIM_FILE_FROM_AR: _.template(
    HOST +
    "/apis/INF/generate-updated-sent-claim-file-from-ar/{{ar_transaction_id}}/"
  ),
  UPDATE_SENT_CLAIM_DATA_FROM_AR: _.template(
    HOST + "/apis/INF/do-update-sent-claim-data-from-ar/{{ar_transaction_id}}/"
  ),
  IMPORT_RESPONSE_FILE: HOST + "/apis/INF/import-response-file/",
  IMPORT_RESPONSE_FILE_CONFIRM:
    HOST + "/apis/INF/import-response-file-confirm/",
  GENERATE_SENT_CLAIM_DATA_HIS: _.template(
    HOST +
    "/apis/INF/do-generate-sent-claim-data-his-from-group/{{coverage_payer_sent_claim_group_id}}/{{generate_start_date}}/{{generate_end_date}}/"
  ),
  GET_COVERAGE_PAYER_SENT_CLAIM_GROUP:
    HOST + "/apis/INF/coverage-payer-sent-claim-group/",
  GET_UPDATE_SENT_CLAIM_DATA:
    HOST + "/apis/INF/ar-task-tracking/UPDATE_SENT_CLAIM_DATA/",
  GET_GENERATE_SENT_CLAIM_DATA:
    HOST + "/apis/INF/ar-task-tracking/GENERATE_SENT_CLAIM_DATA/",
  CREATE_AR_TRANSACTION: HOST + "/apis/INF/create-ar-transaction/",
  SENT_CLAIM_CHOICE: HOST + "/apis/INF/sent-claim-choice/",
  // IPD
  BILL_TRANSACTION_IPD: HOST + "/apis/INF/bill-transaction-ipd/",
  BILL_TRANSACTION_IPD_ID: _.template(
    HOST + "/apis/INF/bill-transaction-ipd/{{bill_transaction_ipd_id}}/"
  ),
  BILL_TRANSACTION_IPD_SUMMARY:
    HOST + "/apis/INF/bill-transaction-ipd/summary/",
  IPD_DIAGNOSIS_SENT_CLAIM: HOST + "/apis/INF/ipd-diagnosis-sent-claim/",
  IPD_PROCEDURE_SENT_CLAIM: HOST + "/apis/INF/ipd-procedure-sent-claim/",
  BILL_TRANSACTION_IPD_ITEM: HOST + "/apis/INF/bill-transaction-ipd-item/",
  GENERATE_SENT_CLAIM_DATA_IPD: _.template(
    HOST +
    "/apis/INF/do-generate-sent-claim-data-ipd-from-group/{{coverage_group_id}}/{{start_date}}/{{end_date}}/"
  ),
  UPDATE_SENT_CLAIM_DATA_IPD_FROM_AR_ID: _.template(
    HOST +
    "/apis/INF/do-update-sent-claim-data-ipd-from-ar/{{ar_transaction_id}}/"
  ),
  GENERATE_SENT_CLAIM_IPD_FILE_FROM_AR_ID: _.template(
    HOST +
    "/apis/INF/generate-sent-claim-ipd-file-from-ar/{{ar_transaction_id}}/"
  ),
  AR_TASK_TRACKING_ACTION: _.template(
    HOST + "/apis/INF/ar-task-tracking/{{action}}/"
  ),
  IMPORT_RESPONSE_FILE_IPD: HOST + "/apis/INF/import-response-file-ipd/",
  SENT_CLAIM_TRANSACTION_IPD: HOST + "/apis/INF/sent-claim-transaction-ipd/",
  ENCOUNTER_FROM_AR_ID: _.template(
    HOST + "/apis/INF/encounter-from-ar/{{ar_transaction_id}}/"
  ),
  IMPORT_AR_LAB_CATALOG_MASTER_DATA: HOST + "/apis/INF/import-ar-master-data/"
};

export const HRM = {
  EXPORT_EXCEL: HOST + "/apis/HRM/export-excel/",
  CLIENT_VIEW: HOST + "/apis/HRM/client-view/",
  SERVICE_SLOT: HOST + "/apis/HRM/service-slot/",
  // DIVISION_CONDITION:  _.template(
  //     HOST + '/apis/HRM/client-condition/?client={{division_id}}&position={{position_id}}'
  // ),
  EMPLOYEE_INFO: HOST + "/apis/HRM/employee-info/",
  CLIENT_DIVISION: HOST + "/apis/HRM/client/?content_type=division",
  PROVIDER_ASSIGN: HOST + "/apis/HRM/provider-assign/",
  PROVIDER_ASSIGN_ACTION: HOST + "/apis/HRM/provider-assign/action/",
  PROVIDER: HOST + "/apis/HRM/provider/",
  PROVIDER_INFO: _.template(HOST + "/apis/HRM/get-provider-info/{{user_id}}/"),
  PROVIDER_ASSIGN_CONFIRM: HOST + "/apis/HRM/provider-assign/confirm/",
  RESULT_CHECK_MONTHLY_CONSTRAINT: HOST + "/apis/HRM/client-validate/",
  SWAP_SHIFT: HOST + "/apis/HRM/swap-shift/",
  SWAP_SHIFT_REQUEST: HOST + "/apis/HRM/swap-shift-request/",
  SWAP_SHIFT_REQUEST_ACTION: _.template(
    HOST + "/apis/HRM/swap-shift-request/{{swap_shift_request_id}}/"
  )
};

export const CORE = {
  PATIENT_ADDRESS_DETAIL: _.template(
    HOST + "/apis/core/patient-address/{{patientAddressId}}/"
  ),
  PATIENT_ADDRESS: HOST + "/apis/core/patient-address/",
  PRODUCT: HOST + "/apis/core/product/",
  // Duplicat found below
  // ENCOUNTER_PATIENT: HOST + "/apis/core/encounter-patient/",
  // CONSTANCE: HOST + "/apis/core/constance/",
  ENCOUNTER_PATUENT_OPTIMIZED: HOST + "/apis/core/encounter-patient-optimized/",
  DOCTOR_ORDER_DETAIL: _.template(
    HOST + "/apis/core/doctor-order/detail/{{id}}/"
  ),
  ENCOUNTER_PATIENT: HOST + "/apis/core/encounter-patient/",
  CONSTANCE: HOST + "/apis/core/constance/",
  MISCELLANEOUS_ORDER_LIST_CREATE:
    HOST + "/apis/core/miscellaneous-order-list-create/",
  MISCELLANEOUS_ORDER: HOST + "/apis/core/miscellaneous-order/",
  MISCELLANEOUS_ORDER_DETAIL: _.template(
    HOST + "/apis/core/miscellaneous-order/{{miscellaneous_order_id}}/"
  ),
  MISCELLANEOUS_ORDER_PREVIEW: HOST + "/apis/core/miscellaneous-order/preview/",
  MISCELLANEOUS: HOST + "/apis/core/miscellaneous/",
  CAREER: HOST + "/apis/core/career/",
  DISTRICT: HOST + "/apis/core/district/",
  CITY: HOST + "/apis/core/city/",
  PROVINCE: HOST + "/apis/core/province/",
  COUNTRY: HOST + "/apis/core/country/",
  DOCTOR: HOST + "/apis/core/doctor/",
  RELIGION: HOST + "/apis/core/religion/",
  MODEL_CHOICE: HOST + "/apis/core/choices/",
  DIVISION: HOST + "/apis/core/division/",
  DIVISION_DETAIL: _.template(HOST + "/apis/core/division/{{divisionId}}/"),
  SPECIALITY: HOST + "/apis/core/specialty/",
  NATIONALITY: HOST + "/apis/core/nationality/?limit=99999",
  EPISODE: _.template(HOST + "/apis/core/episode/{{episode_id}}/"),
  EPISODE_LIST: HOST + "/apis/core/episode/",
  EPISODE_LATEST: HOST + "/apis/core/episode/latest/",
  EPISODE_ENCOUNTER: HOST + "/apis/core/episode-encounter/",
  ENCOUNTER: _.template(HOST + "/apis/core/encounter/{{encounter_id}}/"),
  ENCOUNTER_SEARCH: HOST + "/apis/core/encounter/search/",
  ENCOUNTER_LIST: HOST + "/apis/core/encounter/",
  ENCOUNTER_SEARCH_DETAIL: _.template(
    HOST + "/apis/core/encounter/search/{{encounter_id}}/"
  ),
  ENCOUNTER_GET_EMRS: _.template(
    HOST + "/apis/core/encounter/{{encounter_id}}/medical-record/"
  ),
  PROGRESSION_CYCLE: HOST + "/apis/core/progression-cycle/",
  PROGRESSION_CYCLE_DETAIL: _.template(
    HOST + "/apis/core/progression-cycle/{{progression_cycle_id}}/"
  ),
  PROCEDURE: HOST + "/apis/core/procedure/",
  LAST_PATIENT_ENCOUNTER: _.template(
    HOST + "/apis/core/encounter/last-patient-encounter/{{patient_id}}/"
  ),
  UPDATE_ENCOUNTER_STATUS: _.template(
    HOST + "/apis/core/encounter/update-status/{{encounter_id}}/"
  ),
  ENCOUNTER_PATIENT_ARRIVE_DETAIL: _.template(
    HOST + "/apis/core/encounter/patient-arrive/{{pk}}/"
  ),
  CHANGE_ZONE: _.template(HOST + "/apis/core/zone/{{encounter_id}}/change/"),
  BUYER_ENCOUNTER: HOST + "/apis/core/buyer-encounter/",
  LAST_IPD_MED_RECORD: HOST + "/apis/core/medical_record/",
  SEARCH_DOCTOR_OLD: _.template(
    HOST + "/apis/core/doctor/?name_code={{name_code}}"
  ),
  SEARCH_PATIENT_EN: _.template(
    HOST + "/apis/core/encounter/?number={{number}}"
  ),
  CLINICAL_TERM: HOST + "/apis/core/clinicalterm/",
  SCANNED_DOCUMENT: HOST + "/apis/core/scanned-document/",
  DOCUMENT_CATEGORY: HOST + "/apis/core/document-category/?limit=99999",
  DOCUMENT_TYPE: HOST + "/apis/core/document-type/",
  SECRET_ENCOUNTER: HOST + "/apis/core/secret-encounter/",
  CLINICAL_TERM_SET: HOST + "/apis/core/clinical-term-set/",
  BARCODE_PREFIX_LIST: HOST + "/apis/core/barcode-prefix/",
  DEFAULT_BARCODE_DETAIL: _.template(
    HOST + "/apis/core/default-barcode/{{barcode}}/"
  ),
  ENCOUNTER_STATUS: HOST + "/apis/core/encounter-status/",
  ENCOUNTER_DETAIL: _.template(HOST + "/apis/core/encounter/{{encounter_id}}/"),
  REJECTED_ORDER: HOST + "/apis/core/rejected-order/",
  CLEANING_CHOICE: HOST + "/apis/core/cleaning-choice/",
  TASK_TRACKING_RESULT: HOST + "/apis/core/task-tracking/result/",
  ICD10_ICDCODE: HOST + "/apis/core/icd10KeyUp/icdcode",
  ICD9CM_ICDCODE: HOST + "/apis/core/icd9cmKeyUp/icdcode",
  ICD10_ICDTERM: HOST + "/apis/core/icd10KeyUp/icdterm",
  ICD9CM_ICDTERM: HOST + "/apis/core/icd9cmKeyUp/icdterm",
  ICD10_MEDTERM: HOST + "/apis/core/icd10KeyUp/medterm",
  ICD9CM_MEDTERM: HOST + "/apis/core/icd9cmKeyUp/medterm",
  ICD10_SUBLEVEL: HOST + "/apis/core/icd10KeyUp/sublevel",
  ICD9CM_SUBLEVEL: HOST + "/apis/core/icd9cmKeyUp/sublevel",
  CURRENT_DOCTOR: HOST + "/apis/core/get-current-doctor/",
  SCANNED_DOCUMENT_UPDATE_SECRET:
    HOST + "apis/core/scanned-document/update-secret/",
  SCANNED_DOCUMENT_DETAIL: _.template(
    HOST + "apis/core/scanned-document/{{scanned_doc_id}}/"
  ),
  PATIENT_DIAGNOSIS_LIST: HOST + "/apis/core/patient-diagnosis-list/",
  LIST_PRE_NAME: HOST + "/apis/core/prename/"
};

export const ADMIN = {
  BILL_TRANSACTION_DETAIL: _.template(
    HOST + "/manage/INF/billtransaction/{{bill_transaction_id}}/change/"
  )
};

export const USER = {
  CERT_LOGIN: HOST + "/users/cert_login/",
  REQUEST_TOKEN: HOST + "/users/api/request_token/",
  MOBILE_REQUEST_OTP: HOST + "/users/api/mobile/request_otp/",
  MOBILE_REQUEST_TOKEN: HOST + "/users/api/mobile/request_token/",
  POSITION: HOST + "/users/apis/position/",
  SEARCH_USER: _.template(
    HOST + "/users/apis/users/?name_username={{name_username}}"
  ),
  USER_TOKENIZE: HOST + "/users/apis/users/tokenize/",
  USER_TOKENIZE_LICENSE: HOST + "/users/apis/users/tokenize-license/",
  SESSION_INFO: HOST + "/users/apis/session-info/",
  USER_PROFILE: HOST + "/users/apis/users/profile/",
  USER: HOST + "/users/apis/users/",
  USER_PERMISSION: HOST + "/users/apis/user-permission/",
  USER_GETPOSITION: HOST + "/users/apis/position/",
  USER_WITH_PATIENT_ID: _.template(
    HOST + "/users/apis/user-with-patient/{{user_id}}/"
  ),
  CHECK_PASSWORD2: HOST + "/users/apis/check_password2/",
  SET_PASSWORD: HOST + "/users/apis/users/set-password/",
}

export const ADM = {
  ADMIT_ORDER: HOST + "/apis/ADM/admit-order/",
  ADMIT_ORDER_DETAIL: _.template(
    HOST + "/apis/ADM/admit-order/{{admit_order_id}}/"
  ),
  ADMIT_ORDER_PRINT: _.template(
    HOST + "/apis/ADM/admit-order/{{admit_order_id}}/print/"
  ),
  GET_ADMIT_ORDER: HOST + "/apis/ADM/get-admit-order/",
  ESTIMATE_ADMIT: _.template(
    HOST + "/apis/ADM/admit-order/{{admit_order_id}}/process-estimate/"
  ),
  ADMIT_ORDER_ROOM_ITEM_DETAIL: _.template(
    HOST + "/apis/ADM/admit-order-room-item/{{admit_order_room_item_id}}/"
  ),
  ADMIT_ORDER_ROOM_ITEM_STATUS_CHOICE:
    HOST + "/apis/ADM/admit-order-room-item/status-choice/",
  DISCHARGE_PLANNING_SUMMARY: _.template(
    HOST + "/apis/ADM/encounter/{{encounter}}/discharge-planning-summary/"
  ),
  WARD_TYPE: HOST + "/apis/ADM/ward-type/",
  ROOM_TYPE: HOST + "/apis/ADM/room-type/",
  START_END_WARD: _.template(
    HOST + "/apis/ADM/start-end-ward/encounter/{{encounter_id}}/"
  )
};

export const ADR = {
  ADVERSE_REACTION: _.template(
    HOST +
    "/apis/ADR/adverse-reaction/?patient={{patient}}&approve_only=true&exclude_unused=true&severe_first=true"
  ),
  ADVERSE_REACTION_LIST: HOST + "/apis/ADR/adverse-reaction/",
  ADVERSE_REACTION_DETAIL: _.template(
    HOST + "/apis/ADR/adverse-reaction/{{adr_id}}/"
  )
};

export const APP = {
  REQUESTED_APPOINTMENT: HOST + "/apis/MAI/requested-appointment-list/",
  APPOINTMENT: HOST + "/apis/APP/appointment/",
  APPOINTMENT_ID: _.template(HOST + "/apis/APP/appointment/{{app_id}}/"),
  APPOINTMENT_ID_REQUEST: _.template(
    HOST + "/apis/APP/appointment/{{app_id}}/request/"
  ),
  APPOINTMENT_REQUEST: HOST + "/apis/APP/appointment/request/",
  CANCEL_APPOINTMENT: _.template(
    HOST + "/apis/APP/appointment/{{app_id}}/cancel/"
  ),
  PROVIDER_ASSIGN_APPOINTMENT: HOST + "/apis/MAI/provider-assign/appointment/",
  CONFIRM_REQUESTED_APPOINTMENT: _.template(
    HOST + "/apis/MAI/requested-appointment/{{requested_app_id}}/confirmed/"
  ),
  APPOINTMENT_ALL: HOST + "/apis/APP/appointment/all/",
  PRINT_APPOINTMENT: _.template(
    HOST + "/apis/APP/appointment/print/{{app_id}}/"
  ),
  PRINT_APPOINTMENT_WITHOUT_ID: HOST + "/apis/APP/appointment/print/",
  APPOINTMENT_ID_CONFIRM: _.template(
    HOST + "/apis/APP/appointment/{{app_id}}/confirm/"
  ),
  DOCTOR_WORK_AT_DIV: HOST + "/apis/APP/doctor-work-at-div/",
  DOCTOR_NOTE: HOST + "/apis/APP/doctor-note/",
  DOCTOR_NOTE_GROUP: HOST + "/apis/APP/doctor-note-group/",
  DOCTOR_SCHEDULE_APPOINTMENT_LIST:
    HOST + "/apis/APP/doctor-schedule/appointment/",
};

export const BLB = {
  BLOOD_GROUP_WITH_PATIENT: _.template(
    HOST + "/apis/BLB/blood-group/by-patient/{{patient_id}}/"
  ),
  BLOOD_TRANSFUSION_LIST: HOST + "/apis/BLB/blood-transfusion/"
};

export const REG = {
  PATIENT_HN: _.template(HOST + "/apis/REG/patient/hn/{{hn}}/"),
  PATIENT: _.template(HOST + "/apis/REG/patient/{{patient_id}}/"),
  SEARCH_PATIENT_HN: _.template(
    HOST + "/apis/REG/patient/?name_code={{name_code}}"
  ),
  SEARCH_PATIENT: HOST + "/apis/REG/patient/",
  SEARCH_PATIENT_NAME: _.template(
    HOST + "/apis/REG/patient/{{patient_id}}/name/"
  ),
  BLOOD_TYPE: HOST + "/apis/REG/bloodtype/"
};

export const BIL = {
  RECEIPT: HOST + "/apis/BIL/receipt/",
  PAYMENT_PARAMETERS: HOST + "/apis/BIL/payment/parameters/",
  PATIENT_HAS_DEBT: _.template(
    HOST + "/apis/BIL/patient/{{patient_id}}/has-debt/"
  ),
  PRINT_MEDICAL_FEE_DETAIL: HOST + "/apis/BIL/print-medical-fee-detail/",
  BIL_QUEUE_LIST: "/apis/BIL/opd-queue/",
  INVOICE: HOST + "/apis/BIL/invoice/",
  PENDING_INVOICE: HOST + "/apis/PRX/smartpayment/pending_invoice/",
  INVOICE_DETAIL: _.template(HOST + "/apis/BIL/invoice/{{invoiceId}}/"),
  INVOICEITEM_BYITEM: HOST + "/apis/BIL/invoiceitem/byitem/",
  NEW_PRINT_MEDICAL_FEE_DETAIL: HOST + "/apis/BIL/new-print-medical-fee-detail/",
  COMPLETE_INVOICE: HOST + "/apis/PRX/smartpayment/complete_invoice/",
};

export const TRT = {
  TREATMENT_ORDER: HOST + "/apis/TRT/treatment-order/",
  TREATMENT_ORDER_PERFORMER: HOST + "/apis/TRT/treatment-order-performer/",
  TREATMENT: HOST + "/apis/TRT/treatment/",
  TREATMENT_ORDER_ESTIMATE: HOST + "/apis/TRT/treatment-order/estimate/",
  TREATMENT_ORDER_ENCOUNTER_ARRIVE: _.template(
    HOST + "/apis/TRT/treatment-order/encounter-arrive/{{encounter_id}}/"
  )
};

export const ORM = {
  // order
  OPERATING_ORDER: HOST + "/apis/ORM/operating-order/",
  OPERATING_ORDER_BY_ID: _.template(
    HOST + "/apis/ORM/operating-order/{{operating_id}}/"
  ),
  OR_APPOINTMENT: HOST + "/apis/ORM/check-operating-appointment/",

  // Todo: deliver-operating-appointment duplicated
  DELIVER_OPERATING_APPOINTMENT:
    HOST + "/apis/ORM/deliver-operating-appointment/",
  OR_DELIVER: HOST + "/apis/ORM/deliver-operating-appointment/",

  // detail
  OPERATING_DETAIL: HOST + "/apis/ORM/operating-detail/",

  // note
  OPERAITVE_IMAGE_BY_OPERATIVE_NOTE: _.template(
    HOST + "/apis/ORM/operative-image/operative-note/{{operative_note_id}}/"
  ),

  // location
  OPERATING_LOCATION: HOST + "/apis/ORM/operating-location/",

  OPERATING_PROCEDURE_SUMMARY: HOST + "/apis/ORM/operating-procedure-summary/",

  // team
  TEAM_PRINT_OPERATIVE_NOTE: _.template(
    HOST + "/apis/ORM/team/{{team_id}}/print/"
  ),
};

export const DPO = {
  DOCTOR_CHECKOUT_PROGRESSION_CYCLE: _.template(
    HOST + "/apis/DPO/doctor-checkout-progression-cycle/{{progressionCycleId}}/"
  ),
  ENCOUNTER_OPD_CHECK_IN: _.template(
    HOST + "/apis/DPO/encounter-opd-check-in/{{encounterId}}/"
  ),
  PHYSICAL_EXAMINATION_EMR: _.template(
    HOST + "/apis/DPO/physical-examination/emr/{{emrId}}/"
  ),
  PHYSICAL_EXAM_EMR_CREATE_BY_DOCTOR:
    HOST + "/apis/DPO/physical-exam/emr/create/by-doctor/",
  PHYSICAL_EXAM_EMR_BY_DOCTOR: _.template(
    HOST + "/apis/DPO/physical-exam/emr/{{emrId}}/by-doctor/"
  ),
  PROGRESSION_CYCLE_DOCTOR_ORDERS:
    HOST + "/apis/DPO/progression-cycle-doctor-orders/",
  DIAGNOSIS: HOST + "/apis/DPO/diagnosis/",
  UPDATE_DIAGNOSIS: _.template(
    HOST + "/apis/DPO/diagnosis/{{diagnosisId}}/"
  ),
  DIAGNOSIS_TEMPLATE: HOST + "/apis/DPO/diagnosis/template/",
  GET_CLINICAL_NOTE_TYPE: HOST + "/apis/DPO/clinical-note/",
  MEDICAL_RECORD: HOST + "/apis/DPO/medical_record/",
  MEDICAL_RECORD_EMRID: _.template(
    HOST + "/apis/DPO/medical_record/{{emrId}}/"
  ),
  MEDICAL_RECORD_DIAGNOSIS: _.template(
    HOST + "/apis/DPO/medical_record/{{emrId}}/diagnosis/"
  ),
  SEARCH_TASK_HISTORY: HOST + "/apis/DPO/doctor-pending-task/",
  DOCTOR_PENDING_TASK_ORDER: HOST + "/apis/DPO/doctor-pending-task-order/",
  DOCTOR_PENDING_TASK_ORDER_EDIT: _.template(
    HOST + "/apis/DPO/doctor-pending-task-order/{{doctor_pending_id}}/"
  ),
  DOCTOR_PENDING_TASK_ORDER_WITH_EDIT:
    HOST + "/apis/DPO/doctor-pending-task/edit/",
  CANCEL_CHECKIN: _.template(
    HOST + "/apis/DPO/cancel-check-in/{{patient_emr_id}}/"
  ),
  ENCOUNTER_HISTORY: HOST + "/apis/DPO/encounter-history/",
  DOCTOR_CONSULT_ORDER: HOST + "/apis/DPO/doctor-consult-order/",
  DOCTOR_CONSULT_ORDER_ID: _.template(
    HOST + "/apis/DPO/doctor-consult-order/{{order_id}}/"
  ),
  SENSITIVE_NOTE_EDIT: _.template(
    HOST + "/apis/DPO/sensitive_note/emr/{{emr}}/"
  ),
  DOCTOR_ORDER: HOST + "/apis/DPO/doctor-order/",
  DOCTOR_ORDER_PRINT: HOST + "/apis/DPO/doctor-order-print/",
  PATIENT_QUEUE: HOST + "/apis/DPO/patient_queue/",
  DISCHARGE: _.template(HOST + "/apis/DPO/doctor-discharge-patient/{{emrId}}/"),
  DOCTOR_CERTIFICATES_TYPE: HOST + "/apis/DPO/doctor-certificates-type/",
  DOCTOR_CERTIFICATES: HOST + "/apis/DPO/doctor-certificates/",
  DOCTOR_CERTIFICATES_ID: _.template(
    HOST + "/apis/DPO/doctor-certificates/{{certId}}/"
  ),
  DIAGNOSIS_SUMMARY: HOST + "/apis/DPO/diagnosis-summary/",
  DOCTOR_CERTIFICATES_PRINTLOG:
    HOST + "/apis/DPO/doctor-certificates-printlog/",
  DOCTOR_ORDER_CANCEL: _.template(
    HOST + "/apis/DPO/doctor-order/{{order_id}}/cancel"
  ),
  MEDICAL_RECORD_GALLERY: _.template(
    HOST + "/apis/DPO/medical_record/gallery/{{emrId}}/"
  ),
  MEDICAL_RECORD_PICTURE: _.template(
    HOST + "/apis/DPO/medical_record/picture/{{pictureId}}/"
  ),
  TEMPLATE_GALLERY: HOST + "/apis/DPO/template-gallery/",
  ESTIMATE: HOST + "/apis/DPO/estimate/",
  MEDICAL_RECORD_EMR_DETAIL: _.template(
    HOST + "/apis/DPO/medical_record/emr/{{emrId}}/"
  ),
  PATIENT_HISTORY_EMR: _.template(
    HOST + "/apis/DPO/patient_history/emr/{{emrId}}/"
  ),
  SENSITIVE_NOTE: HOST + "/apis/DPO/sensitive_note/",
  SENSITIVE_NOTE_EMR: _.template(
    HOST + "/apis/DPO/sensitive_note/emr/{{emrId}}/"
  ),
  PHYSICAL_EXAM_ORGAN: HOST + "/apis/DPO/physical_exam_organ/",
  PATIENT_SCREEN_NEW: HOST + "/apis/DPO/patient_screen/new/",
  PATIENT_SCREEN_UPDATE: HOST + "/apis/DPO/patient_screen/update/"
};

export const DPI = {
  IPD_ONE_DAY: _.template(
    HOST + "/apis/DPI/ipd-one-day/emr/{{patient_emr_id}}/"
  ),
  IPD_CONTINUE: _.template(
    HOST + "/apis/DPI/ipd-continue/emr/{{patient_emr_id}}/"
  ),
  PLAN_ITEM: _.template(HOST + "/apis/core/plan-item/{{plan_id}}/"),
  CANCEL_DOCTOR_ORDER: _.template(
    HOST + "/apis/DPO/doctor-order/{{order_id}}/cancel"
  ),
  ACK_DOCTOR_ORDER: HOST + "/apis/PTM/ack-doctor-order/",
  IPD_CONTINUE_HISTORY: _.template(
    HOST + "/apis/DPI/ipd-continue-history/plan-item/{{plan_item_id}}/"
  ),
  PROGRESSION_NOTE_LIST: HOST + "/apis/DPI/progression_note/",
  PROGRESSION_NOTE: _.template(
    HOST + "/apis/DPI/progression_note/?emr={{emr}}"
  ),
  PROGRESSION_NOTE_ID: _.template(
    HOST + "/apis/DPI/progression_note/{{id}}/"
  ),
  ADMISSION_FORM: _.template(HOST + "/apis/DPI/admission-form/emr/{{emr}}/"),
  DISCHARGE_SUMMARY: _.template(
    HOST + "/apis/DPI/discharge-summary/emr/{{emr}}/"
  ),
  DIAGNOSIS: _.template(HOST + "/apis/DPI/diagnosis/emr/{{emr}}/"),
  PROCEDURE: _.template(HOST + "/apis/DPI/procedure/emr/{{emr}}/"),
  DRG: _.template(HOST + "/apis/DPI/drg/emr/{{emr}}/"),
  IPD_DOCTOR_ORDER_PRINT: HOST + "/apis/DPI/ipd-doctor-order-print/"
  // IPD_DOCTOR_ORDER_PRINT_RESULT: (
  //   HOST + '/apis/DPI/ipd-doctor-order-print/result/'
  // ),
};

export const TPD = {
  PRINT_MED_RECORD: HOST + "/apis/TPD/medication-record-print/",
  GET_DRUG_ORDER_TYPE_AVAILABLE: _.template(
    HOST + "/apis/TPD/drug-order-type-available/?encounter={{encounter_id}}"
  ),
  MED_RECORD_LIST_SUMMARY:
    HOST + "/apis/TPD/medication-record-list-summary/"
  ,
  MED_ADMIN: HOST + "/apis/TPD/medication-admin/",
  MED_ADMIN_ID: _.template(
    HOST + "/apis/TPD/medication-admin/{{medication_record_id}}/"
  ),
  MED_RECORD: HOST + "/apis/TPD/medication-record/",
  MED_RECORD_ID: _.template(
    HOST + "/apis/TPD/medication-record/{{medication_record_id}}/"
  ),
  MED_RECONCILIATION: HOST + "/apis/TPD/med-reconciliation/",
  MED_RECONCILIATION_ID: _.template(
    HOST + "/apis/TPD/med-reconciliation/{{medication_reconciliation_id}}"
  ),
  MED_RECONCILIATION_LOG: HOST + "/apis/TPD/med-reconciliation-log/",
  // TODO: Waiting API
  MED_RECORD_LIST_SUMMARY_PRINT: HOST + "/apis/TPD/medication-record-print/",
  LIST_MED_ADMIN_RECORD: HOST + "/apis/TPD/list-medication-admin-record/",
  CREATE_MED_ADMIN_RECORD: HOST + "/apis/TPD/create-medication-admin-record/",
  UPDATE_MED_ADMIN_RECORD_ID: _.template(
    HOST + "/apis/TPD/update-medication-admin-record/{{medication_record_id}}/"
  ),
  DRUG_ORDER_ITEM_ID_ADD_STD_TIME_MANUAL: _.template(
    HOST + "/apis/TPD/drug-order-item/{{drug_order_item_id}}/add/std-time-manual/"
  ),
  DRUG_ORDER_ITEM_ID_EDIT_STD_TIME_MANUAL: _.template(
    HOST + "/apis/TPD/drug-order-item/{{drug_order_item_id}}/edit/std-time-manual/"
  ),
  GET_HOME_MED_DRUG_ITEMS: _.template(
    HOST +
    "/apis/TPD/drug-order-item/?encounter={{encounterId}}&&drug_order_type=HOME_OPD&&drug_order_type=HOME_IPD"
  ),
  DRUG_ORDER_ITEM_FOR_MED_RECONCILE: HOST + "/apis/TPD/drug-order-item-for-med-reconcile/",
  DRUG_ORDER_ITEM_ID_ADMIN_DIVISION: _.template(
    HOST + "/apis/TPD/drug-order-item/{{drug_order_item_id}}/admin-division/"
  )

};

export const PTM = {
  ER_REFER_OUT_PRINT: _.template(
    HOST + "/apis/PTM/er-refer-out-print/{{formDataId}}/"
  ),
  SAFETY_CHECK_LIST_PRINT: _.template(
    HOST + "/apis/PTM/safety-check-list-print/{{formDataId}}/"
  ),
  SEEN_DOCTOR_ORDER_NOTIFICATION: _.template(
    HOST + "/apis/PTM/seen-doctor-order-notification/{{orderId}}/"
  ),
  UNACKNOWLEDGE_DOCTOR_ORDERS: HOST + "/apis/PTM/unacknowledged-doctor-orders/",
  KPI_ENCOUNTER_DELETE: _.template(
    HOST + "/apis/PTM/kpi/encounter/delete/{{kpiId}}/"
  ),
  KPI_ENCOUNTER: HOST + "/apis/PTM/kpi/encounter/",
  KPI_TEMPLATE: HOST + "/apis/PTM/kpi-template/",
  ER_SHORTCUT_DETAIL: _.template(
    HOST + "/apis/PTM/er-shortcut/{{shortcutId}}/"
  ),
  ER_SHORTCUT: HOST + "/apis/PTM/er-shortcut/",
  ER_BED_NO_ENCOUNTER: _.template(
    HOST + "/apis/PTM/er-bed-no/encounter/{{encounterId}}/"
  ),
  NURSE_NOTE_TOKEN: HOST + "/apis/PTM/get-nurse-token/",
  NURSE_NOTE_ITEM_ER: HOST + "/apis/PTM/nurse-note-item/er/",
  VITAL_SIGN_VALUE_DELETE: _.template(
    HOST + "/apis/PTM/vital-sign-value-delete/{{id}}/"
  ),
  MEASUREMENT_DETAIL: _.template(
    HOST + "/apis/PTM/measurement/{{measurementId}}/"
  ),
  ENCOUNTER_VITALSIGN_TYPE: _.template(
    HOST + "/apis/PTM/encounter-vitalsign-type/{{encounter_id}}/"
  ),
  REMOVE_ENCOUNTER_VITALSIGN_TYPE: _.template(
    HOST + "/apis/PTM/encounter-vitalsign-type/{{encounter_id}}/remove/"
  ),
  VENTILATOR_MODE: HOST + "/apis/PTM/ventilater-mode/",
  DR_ORDER: HOST + "/apis/PTM/dr-order/",
  NURSE_NOTE_DETAIL: _.template(
    HOST + "/apis/PTM/nurse-note/{{nurse_note_id}}/"
  ),
  NURSE_NOTE_ITEM_DETAIL: _.template(
    HOST + "/apis/PTM/nurse-note-item/{{nurse_note_item_id}}/"
  ),
  FORM_DATA_NURSE_NOTE: HOST + "/apis/PTM/form-data-nurse-note/",
  FORM_TYPE: HOST + "/apis/PTM/form-type/",
  NURSE_DIAG_PRINT_PHV: HOST + "/apis/PTM/nurse-diag-print/phv/",
  NURSE_DIAG_RESULT_GROUP: HOST + "/apis/PTM/nurse-diag-result-group/",
  NURSE_NOTE_LATEST: HOST + "/apis/PTM/nurse-note-latest/",
  NURSE_NOTE_ITEM: HOST + "/apis/PTM/nurse-note-item/",
  FORM_DATA: HOST + "/apis/PTM/form-data/",
  FORM_DATA_DETAIL: _.template(HOST + "/apis/PTM/form-data/{{form_data_id}}/"),
  FORM_DATA_LATEST: HOST + "/apis/PTM/form-data-latest/",
  FORM_DATA_ACTION_LOG: _.template(
    HOST + "/apis/PTM/form-data-action-log/{{form_id}}/"
  ),
  GET_EMR_SUMMARY: _.template(
    HOST +
    "/apis/PTM/opd-discharge-summary-print/?patient_id={{patientId}}&encounter_id={{encounterId}}&jasper_module=PTM&jasper_path=opd-discharge-summary&emr_summary=true"
  ),
  FORM_DATA_ENCOUNTER: _.template(
    HOST + "/apis/PTM/form-data/encounter/{{encounter}}/"
  ),
  GET_EMR_SUMMARY_PRINT: _.template(
    HOST +
    "/apis/PTM/opd-discharge-summary-print/?patient_id={{patientId}}&encounter_id={{encounterId}}&jasper_module=PTM&jasper_path=opd-discharge-summary&pdf=true"
  ),
  PRINT_MULTI_DISCIPLINARY: HOST + "/apis/PTM/multidisciplinary/print/",
  MULTI_DISCIPLINARY_ITEM: HOST + "/apis/PTM/multidisciplinary-item/",
  MULTI_DISCIPLINARY_ITEM_DETAIL: _.template(
    HOST + "/apis/PTM/multidisciplinary-item/{{multidisciplinary_id}}/"
  ),
  MEASUREMENT: HOST + "/apis/PTM/measurement/",
  MEASUREMENT_CANCEL: _.template(
    HOST + "/apis/PTM/measurement/{{measurementId}}/cancel/"
  ),
  VITAL_SIGN_TYPE: HOST + "/apis/PTM/vital-sign-type/",
  VITAL_SIGN_VALUE: HOST + "/apis/PTM/vital-sign-value/",
  LAST_HEIGHT: _.template(HOST + "/apis/PTM/latest-height/{{patientId}}/"),
  VITAL_SIGN_VALUE_CREATE_UPDATE:
    HOST + "/apis/PTM/vital-sign-value-create-update/",
  NURSE_NOTE_ITEM_PRINT: HOST + "/apis/PTM/nurse-note-item-print/",
  MULTIDISCIPLINARY_PRINT: HOST + "/apis/PTM/multidisciplinary/print/",
  DOCTOR_ORDER: HOST + "/apis/PTM/doctor-order/",
  STANDING_ORDER_TEMPLATE: HOST + "/apis/PTM/standing-order/"
};

export const IME = {
  IMAGING_EXAM_RESULT: _.template(
    HOST + "/apis/IME/list_result/?encounter={{encounterId}}"
  )
};

export const LAB = {
  INTERFACE_SUMMARY_REPORT_DETAIL: _.template(
    HOST + "/apis/LAB/interface-summary-report-detail/{{cloi}}/"
  ),
  CENTRAL_LAB_DIVISION: HOST + "/apis/LAB/central-lab-division/",
  CENTRAL_LABTEST_RESULT_FILTER:
    HOST + "/apis/LAB/central-lab-test-filter-result/",
  CENTRAL_LAB_RESULT_COMPARABLE:
    HOST + "/apis/LAB/central-lab-result-comparable/",
  CENTRAL_LAB_RESULT_LIST: HOST + "/apis/LAB/central-lab-result-list/",
};

export const HDM = {
  HDM_REQUEST_ITEM: HOST + "/apis/HDM/hd-request-item/",
  HDM_REQUEST: HOST + "/apis/HDM/hd-request/",
  REQUEST_DETAIL: _.template(HOST + "/apis/HDM/hd-request/{{req_id}}/"),
  REQUEST_ITEM_DETAIL: _.template(
    HOST + "/apis/HDM/hd-request-item/{{req_item_id}}/"
  ),
  CHECKOUT_REQUEST_ITEM: _.template(
    HOST + "/apis/HDM/hd_request_item_checkout/{{req_item_id}}/"
  ),
  PAYMENT_EDC: HOST + "/apis/HDM/hd-payment/",
  PRESCRIPTION: _.template(
    HOST + "/apis/HDM/prescription/{{prescription_id}}/"
  ),
  PRESCRIPTION_ITEM: HOST + "/apis/HDM/prescription-item/",
  PRESCRIPTION_DETAIL: _.template(
    HOST + "/apis/HDM/update-prescription/{{req_id}}/"
  ),
  CHRONICS_TIME: _.template(
    HOST + "/apis/HDM/hd-schedule-time/?reservation=true"
  ),
  RESERVATION_LIST: _.template(
    HOST + "/apis/HDM/hd-reservation/?hd_request={{req_id}}"
  ),
  RESERVATION_CREATE: _.template(HOST + "/apis/HDM/hd-reservation/{{req_id}}/"),
  PREVIEW_APPOINTMENT: _.template(HOST + "/apis/HDM/preview-appointment/"),
  HD_RECORD_BY_ITEM: _.template(
    HOST + "/apis/HDM/hd-record/by-hd-item/{{request_item_id}}/"
  ),
  EDIT_HD_RECORD: _.template(HOST + "/apis/HDM/hd-record/{{request_item_id}}/")
};

export const LRM = {
  PRINT_ANC_RECORD_BY_ENCOUNTER: _.template(
    HOST + "/apis/LRM/anc-record/by-encounter/{{encounter_id}}/print/"
  ),
  QUEUE: HOST + "/apis/LRM/queue/",
  ANC_RECORD_LIST: HOST + "/apis/LRM/anc-record-list/",
  ANC_RECORD: HOST + "/apis/LRM/anc-record/",
  PREVIOUS_PREGNANCY: HOST + "/apis/LRM/previous-pregnancy/",
  ANC_SUMMARY: _.template(HOST + "/apis/LRM/anc-summary/{{anc_summary_id}}/"),
  ANC_ITEM: HOST + "/apis/LRM/anc-item/",
  ANC_LAB: _.template(HOST + "/apis/LRM/anc-lab/{{anc_lab_id}}/"),
  FETAL_LIE: HOST + "/apis/LRM/fetal-lie/",
  LAB_INVESTIGATION_FETCH: HOST + "/apis/LRM/lab-investigation-fetch/",
  LR_LAB: HOST + "/apis/LRM/lr-lab/",
  LAB_INVESTIGATION_DETAIL: _.template(
    HOST + "/apis/LRM/lab-investigation/{{investidation_id}}/"
  ),
  BIRTH_CERTIFICATE_APPLICATION:
    HOST + "/apis/LRM/birth-certificate-application/",
  BIRTH_CERTIFICATE_APPLICATION_DETAIL: _.template(
    HOST +
    "/apis/LRM/birth-certificate-application/{{birth_certification_application_id}}/"
  ),
  BIRTH_CERTIFICATE_APPLICATION_PRINT: _.template(
    HOST +
    "/apis/LRM/birth-certificate-application/{{birth_certification_application_id}}/print/"
  ),
  OBSTETRIC_BASIC_INFO: HOST + "/apis/LRM/obstetric-basic-info/",
  OBSTETRIC_BASIC_INFO_DETAIL: _.template(
    HOST + "/apis/LRM/obstetric-basic-info/{{basic_info_id}}/"
  ),
  OBSTETRIC_PRINT: HOST + "/apis/LRM/obstetric/print/",
  OBSTETRIC_LABOR: HOST + "/apis/LRM/obstetric-labor/",
  OBSTETRIC_LABOR_DETAIL: _.template(
    HOST + "/apis/LRM/obstetric-labor/{{labor_id}}/"
  ),
  OBSTETRIC_INFANT: HOST + "/apis/LRM/obstetric-infant/",
  OBSTETRIC_INFANT_DETAIL: _.template(
    HOST + "/apis/LRM/obstetric-infant/{{infant_id}}/"
  ),
  OBSTETRIC_POST_LABOR_DETAIL: _.template(
    HOST + "/apis/LRM/obstetric-post-labor/{{post_labor_id}}/"
  ),
  OBSTETRIC_POST_LABOUR: HOST + "/apis/LRM/obstetric-post-labor/",
  OBSTETRIC_POST_LABOUR_DETAIL: _.template(
    HOST + "/apis/LRM/obstetric-post-labor/{{post_labor_id}}/"
  ),
  EDC: HOST + "/apis/LRM/edc/",
  NEWBORN: HOST + "/apis/LRM/newborn/",
  CHECK_OUT_CHOICE: HOST + "/apis/LRM/check-out-choice/",
  LR_CHECK_OUT: _.template(HOST + "/apis/LRM/lr-check-out/{{encounter_id}}/")
};

export const DFC = {
  CHECK_DOCTOR_FEE_ORDER_WITH_EMR: _.template(
    HOST + "/apis/DFC/check-doctor-fee-order/?medical_record={{emrId}}"
  )
};

export const HCU = {
  HEALTH_CHECK_UP_PROGRAM: HOST + "/apis/HCU/health-check-up-program/",
  HEALTH_CHECK_UP_PROGRAM_ORDER:
    HOST + "/apis/HCU/health-check-up-program-order/",
  HEALTH_CHECK_UP_PROGRAM_ORDER_ID: _.template(
    HOST + "/apis/HCU/health-check-up-program-order/{{order_id}}/"
  ),
  HEALTH_CHECK_UP_PROGRAM_ORDER_PRINT_ID: _.template(
    HOST + "/apis/HCU/health-check-up-program-order/print/{{order_id}}/"
  )
};

export const ICU = {
  FLOW_SHEET_DIET: HOST + "/apis/ICU/flow-sheet/diet/",
  PERIOD_WORKER_TIME_SLOT: HOST + "/apis/ICU/period-worker-time-slot/",
  GRAPHIC_SHEET_PRINT: HOST + "/apis/ICU/graphic-sheet/print/",
  FLOW_SHEET_PRINT: HOST + "/apis/ICU/flow-sheet/print/",
  MEDICATION_RECORD_ITEM: HOST + "/apis/ICU/icu-medication-record-item/",
  MEDICATION_RECORD_ITEM_ID: _.template(
    HOST + "/apis/ICU/icu-medication-record-item/{{medication_record_item_id}}/"
  ),
  EVENT_TYPE_CHOICE: HOST + "/apis/ICU/event-type-choices/",
  GET_DOCTOR_ORDER: HOST + "/apis/ICU/encounter-doctor-orders/",
  ADD_MONITOR_EVENT: _.template(
    HOST + "/apis/ICU/encounter-doctor-orders/add/encounter/{{encounter_id}}/"
  ),
  GET_MONITOR_EVENT: HOST + "/apis/ICU/graphic-sheet/monitor-events/",
  REMOVE_MONITOR_EVENT: _.template(
    HOST + "/apis/ICU/encounter-doctor-orders/remove/{{monitor_id}}/"
  ),
  ICU_INTAKE_OUTPUT_DETAIL: HOST + "/apis/ICU/icu-intake-output-detail/",
  ICU_INTAKE_OUTPUT: HOST + "/apis/ICU/icu-intake-output/",
  ICU_INTAKE_OUTPUT_FLOWSHEET: HOST + "/apis/ICU/flow-sheet/intake-output/",
  ROOM_LIST: HOST + "/apis/ICU/room-list/",
  ICU_MEDICATION_RECORD_ITEM_CHOICE: HOST + "/apis/ICU/icu-medication/",
  FLOW_SHEET_VITAL_SIGNS: HOST + "/apis/ICU/flow-sheet/vital-signs/",
  FLOW_SHEET_MEDICATIONS: HOST + "/apis/ICU/flow-sheet/medications/",
  FLOW_SHEET_VENTILATER: HOST + "/apis/ICU/flow-sheet/ventilater/",
  FLOW_SHEET_ARTERIAL: HOST + "/apis/ICU/flow-sheet/arterial/",
  FLOW_SHEET_OVERALL_BALANCE: HOST + "/apis/ICU/flow-sheet/overall-balance/",
  GRAPHIC_SHEET_OVERALL_BALANCE:
    HOST + "/apis/ICU/graphic-sheet/overall-balance/",
  INTAKE_OUTPUT_CREATE_UPDATE:
    HOST + "/apis/ICU/icu-intake-output-record-item-create-update/"
};

export const ANS = {
  // ------------------------------ Anesthesia Queue
  ANESTHESIA_QUEUE: HOST + "/apis/ANS/anesthesia-queue/",
  ANESTHESIA_QUEUE_ZONE_CHOICE:
    HOST + "/apis/ANS/anesthesia-queue-zone-choice/",

  // ------------------------------ Order
  ANESTHESIA_ORDER_ID: _.template(
    HOST + "/apis/ANS/anesthesia-order/{{ans_order_id}}/"
  ),
  ANESTHESIA_BILL_ID: _.template(
    HOST + "/apis/ANS/anesthesia-bill/{{ans_bill_id}}/"
  ),
  ANESTHESIA_CONSULT_EN_ID: _.template(
    HOST + "/apis/ANS/anesthesia-consult/{{encounter_id}}/"
  ),

  // ------------------------------ ANS Data
  INVESTIGATION_RESULT: HOST + "/apis/ANS/investigation-result/",
  ANESTHESIA_DATA: HOST + "/apis/ANS/anesthesia-data/",

  // ------------------------------ Pre Anesthetic Anesthesia Record
  PRE_ANESTHETIC_ANESTHESIA_RECORD_LIST:
    HOST + "/apis/ANS/pre-anesthetic-record/",
  PRE_ANESTHETIC_ANESTHESIA_RECORD_DETAIL: _.template(
    HOST + "/apis/ANS/pre-anesthetic-record/{{pre_ans_id}}/"
  ),
  PRE_ANESTHETIC_ANESTHESIA_RECORD_BY_PATIENT:
    HOST + "/apis/ANS/pre-anesthetic-record-by-patient/",
  PRE_ANESTHETIC_ANESTHESIA_LOG: HOST + "/apis/ANS/pre-anesthetic-log/",
  FORM_ANS_PRINT: HOST + "/apis/ANS/ans-form/print/",

  // ------------------------------ Graph
  ANESTHESIA_GRAPH: _.template(
    HOST + "/apis/ANS/anesthesia-graph/{{ans_order_id}}/"
  ),

  // ------------------------------ Agent Template
  ANESTHESIA_AGENT_TEMPLATE: HOST + "/apis/ANS/agent-template/",
  ANESTHESIA_AGENT_BY_RECORD: HOST + "/apis/ANS/anesthesia-agent/by-record/",
  ANESTHESIA_AGENT_LIST: HOST + "/apis/ANS/anesthesia-agent/",
  ANESTHESIA_AGENT_DETAIL: _.template(
    HOST + "/apis/ANS/anesthesia-agent/{{ans_agent_id}}/"
  ),

  // ------------------------------ Event & Medication
  ANESTHESIA_EVENT_MEDICATION_LIST:
    HOST + "/apis/ANS/anesthesia-event-medication/",
  ANESTHESIA_EVENT_MEDICATION_DETAIL: _.template(
    HOST + "/apis/ANS/anesthesia-event-medication/{{ans_event_med_id}}/"
  ),
  ANESTHESIA_EVENT_MEDICATION_BY_RECORD:
    HOST + "/apis/ANS/anesthesia-event-medication/by-record/",

  // ------------------------------ Monitor Template
  ANESTHESIA_MONITOR_TEMPLATE: HOST + "/apis/ANS/monitor-template/",
  ANESTHESIA_MONITOR_BY_RECORD:
    HOST + "/apis/ANS/anesthesia-monitor/by-record/",
  ANESTHESIA_MONITOR_LIST: HOST + "/apis/ANS/anesthesia-monitor/",
  ANESTHESIA_MONITOR_DETAIL: _.template(
    HOST + "/apis/ANS/anesthesia-monitor/{{ans_monitor_id}}/"
  ),

  // ------------------------------ Intake Output
  ANESTHESIA_INTAKE_OUTPUT_TEMPLATE: HOST + "/apis/ANS/intake-output-template/",
  ANESTHESIA_INTAKE_BY_RECORD: HOST + "/apis/ANS/anesthesia-intake/by-record/",
  ANESTHESIA_OUTPUT_BY_RECORD: HOST + "/apis/ANS/anesthesia-output/by-record/",
  ANESTHESIA_INTAKE_LIST: HOST + "/apis/ANS/anesthesia-intake/",
  ANESTHESIA_OUTPUT_LIST: HOST + "/apis/ANS/anesthesia-output/",
  ANESTHESIA_INTAKE_DETAIL: _.template(
    HOST + "/apis/ANS/anesthesia-intake/{{ans_intake_id}}/"
  ),
  ANESTHESIA_OUTPUT_DETAIL: _.template(
    HOST + "/apis/ANS/anesthesia-output/{{ans_output_id}}/"
  ),

  // ------------------------------ Bill
  ANESTHESIA_TEMPLATE: HOST + "/apis/ANS/anesthesia-template/",
  ANESTHESIA_TEMPLATE_ID: _.template(
    HOST + "/apis/ANS/anesthesia-template/{{ans_template_id}}/"
  ),
  ANESTHESIA_BILL: HOST + "/apis/ANS/anesthesia-bill/",
  ANESTHESIA_BILL_DETAIL: _.template(
    HOST + "/apis/ANS/anesthesia-bill/{{anesthesia_bill_id}}/"
  ),
  ANESTHESIA_BILL_LOG: _.template(
    HOST + "/apis/ANS/anesthesia-bill-action-log/{{ans_bill_id}}/"
  ),
  ANESTHESIA_BILL_PRINT_DETAIL: _.template(
    HOST + "/apis/ANS/anesthesia-bill/{{ans_bill_id}}/print/"
  )
};

export const TEC = {
  ARRIVE_TRT_ORDER: _.template(
    HOST + "/apis/TEC/treatment-order-arrive/{{treatment_order_id}}/"
  ),
  GET_SESSION_FROM_ENCOUNTER: _.template(
    HOST + "/apis/TEC/get-tec-sessions/enconter/{{encounterId}}/"
  )
};

export const MIXIN = {
  REGISTER_BY_TOKEN: ACCOUNT_THE_VC + "/apis/register_by_token/ishealth/",
  REGISTER_BY_TOKEN_FACEBOOK: "https://penta.center/users/api/auth/facebook/"
  // REGISTER_BY_TOKEN_FACEBOOK:
  //   ACCOUNT_THE_VC + "/apis/register_by_token/facebook/"
};

export const PRX = {
  RESOLVE_CHAT_CHANNEL: HOST + "/apis/PRX/resolve-chat-channel/",
  CLASSIFY_USER: HOST + "/apis/PRX/classify-user/",
  DIAG_FORM_CLASSIFY_CHOICE: HOST + "/apis/PRX/classify-choice/",
  DASHBOARD: HOST + "/apis/PRX/dashboard/",
  OA_DASHBOARD: HOST + "/apis/PRX/official-account/dashboard/",
  DASHBOARD_APPOINTMENT: HOST + "/apis/PRX/dashboard-appointment/",
  OA_DASHBOARD_APPOINTMENT: HOST + "/apis/PRX/official-account/dashboard-appointment/",
  DASHBOARD_APPOINTMENT_V2: HOST + "/apis/PRX/v2/dashboard-appointment/",
  PROXY_PATIENT_LIST: (
    HOST + "/apis/PRX/proxy-patient/"
  ),
  INIT_PATIENT_ADDRESS_PATIENT: _.template(
    HOST + "/apis/PRX/init-patient-address/patient/{{patientId}}/"
  ),
  SHIPPING_ADDRESS: HOST + "/apis/PRX/shipping-address/",
  PATIENT_QUEUE: HOST + "/apis/PRX/patient-queue/",
  APPOINTED_PATIENT_QUEUE: HOST + "/apis/PRX/appointment-patient-queue/",
  APPOINTED_PATIENT_QUEUE_V2: HOST + "/apis/PRX/v2/appointment-patient-queue/",
  ENCOUNTER_TRIAGE_DETAIL: _.template(
    HOST + "/apis/PRX/encounter-triage/{{encounterId}}/"
  ),
  REQUEST_CHANGE_DIVISION_SERVICE_BLOCK:
    HOST + "/apis/PRX/request-change-division-service-block/",
  SELECT_DIVISION_SERVICE_BLOCK:
    HOST + "/apis/PRX/select-division-service-block/",
  AVAILABLE_DIVISION_SERVICE_BLOCK:
    HOST + "/apis/PRX/available-division-service-block/",
  DOCTOR_WORK_SCHEDULE: HOST + "/apis/PRX/doctor-work-schedule/",
  ENCOUNTER_PATIENT_APPOINTMENT:
    HOST + "/apis/PRX/encounter-patient-appointment/",
  ENCOUNTER: HOST + "/apis/PRX/encounter/",
  ENCOUNTER_RECLASSIFY: _.template(
    HOST + "/apis/PRX/encounter-reclassify/{{encounterId}}/"
  ),
  DIAG_RULE: HOST + "/apis/PRX/diag-rule/",
  DIAG_RULE_DETAIL: _.template(HOST + "/apis/PRX/diag-rule/{{diagRuleId}}/"),
  DIAG_FORM: HOST + "/apis/PRX/diag-form/",
  DIAG_FORM_DETAIL: _.template(HOST + "/apis/PRX/diag-form/{{diag_form_id}}/"),
  DIAG_FORM_MONITOR: HOST + "/apis/PRX/monitor-diag-form/",
  DIVISION_HAS_USER: HOST + "/apis/PRX/division-has-user/",
  DIVISION_PROFILE: HOST + "/apis/PRX/division-profile/",
  PUBLISHED_DIAG_RULE: HOST + "/apis/PRX/published-diag-rule/",
  PUBLISHED_DIAG_RULE_DETAIL: _.template(
    HOST + "/apis/PRX/published-diag-rule/{{publishedDiagRuleId}}/"
  ),
  MASTER_TRIAGE_LEVEL_CLASSIFY: STATIC_HOST + "/master/",
  TAG_OPTIONS: HOST + "/apis/PRX/e-consent/all-tags/",
  TRIAGE_LEVEL_CLASSIFY: HOST + "/apis/PRX/classify/",
  LATEST_CHAT_CHANNEL: HOST + "/apis/PRX/latest-chat-channel/",
  CHAT_CHANNEL_MESSAGE_LIST: _.template(
    HOST + "/apis/PRX/chat-channel/{{chatChannelId}}/message/list/"
  ),
  CHAT_CHANNEL_MESSAGE_POST: _.template(
    HOST + "/apis/PRX/chat-channel/{{chatChannelId}}/message/post/"
  ),
  CHAT_CHANNEL_DETAIL: _.template(
    HOST + "/apis/PRX/chat-channel/{{chatChannelId}}/"
  ),
  ENCOUNTER_TRIAGE: HOST + "/apis/PRX/encounter-triage/",
  NURSE_NOTE_LIST: HOST + "/apis/PRX/nurse-note",
  PROGRESS_NOTE_LIST: HOST + "/apis/PRX/progression-note",
  NURSE_NOTE_ENCOUNTER: _.template(
    HOST + "/apis/PRX/nurse-note/encounter/{{encounterId}}/"
  ),
  PROXY_PATIENT: _.template(HOST + "/apis/PRX/proxy-patient/{{patientId}}/"),
  CHAT_CHANNEL: HOST + "/apis/PRX/chat-channel/",
  PROXY_PATIENT_HAS_DIVISION: HOST + "/apis/PRX/proxy-patient-has-division/",
  PROXY_PATIENT_HAS_DIVISION_ID: _.template(
    HOST + "/apis/PRX/proxy-patient-has-division/{{id}}/"
  ),
  PROXY_PATIENT_HAS_DIVISION_ID_ADMIN: _.template(
    HOST + "/apis/PRX/proxy-patient-has-division/{{id}}/admin/"
  ),
  PROXY_PATIENT_HAS_DIVISION_ADMIN: HOST + "/apis/PRX/proxy-patient-has-division/admin/",
  PATIENT: HOST + "/apis/PRX/patient/",

  CREATE_REQUEST_OA: HOST + "/apis/PRX/official-account/create/",
  LIST_OA: HOST + "/apis/PRX/official-account/",
  FOLLOW_OA: _.template(HOST + "/apis/PRX/official-account/{{id}}/"),
  GET_OA_INVITE_LINK: HOST + "/apis/PRX/official-account/invite/link/",
  CREATE_JOIN_OA_REQUEST: HOST + "/apis/PRX/official-account/invite/create/",
  LIST_JOIN_OA_REQUEST: HOST + "/apis/PRX/official-account/invite/",
  APPROVE_JOIN_OA_REQUEST: _.template(HOST + "/apis/PRX/official-account/invite/{{id}}/approve/"),
  DELETE_JOIN_OA_REQUEST: _.template(HOST + "/apis/PRX/official-account/invite/{{id}}/"),
  LIST_DIVISION_HAS_USER_OA: HOST + "/apis/PRX/official-account/user/",
  EDIT_DIVISION_HAS_USER_OA: _.template(HOST + "/apis/PRX/official-account/user/{{id}}/"),
  LIST_JOIN_OA_CHECK: HOST + "/apis/PRX/official-account/invite/me/",
  LIST_USER_ROLE_OA: HOST + "/apis/PRX/official-account/role/",
  OA_USER_PROFILE: HOST + "/apis/PRX/official-account/profile/me/",
  GET_OA_DIVISION_PROFILE: HOST + "/apis/PRX/official-account/division/",
  PRX_SCHEDULE: HOST + "/apis/PRX/schedule/",
  CREATE_UPDATE_SCHEDULE: HOST + "/apis/PRX/create-update-schedule/",
  LIST_AVAILABLE_TRAIGE_LEVEL: HOST + "/apis/PRX/official-account/triage/",
  LIST_DIVISION_CLASSIFY: HOST + "/apis/PRX/classify/",
  LIST_CHAT_CHANNEL_TRIAGE_LEVEL: _.template(HOST + "/apis/PRX/official-account/triage/{{triage_level}}/"),
  LIST_AVAILABEL_TRAIGE_LEVEL_DIAGRULE: _.template(HOST + "/apis/PRX/official-account/triage/diagrule/{{diag_rule_id}}/"),
  PATIENT_FROM_DIAGRULE: HOST + "/apis/PRX/patient-from-diag-rule/",
  PATIENT_FROM_DIAGRULE_BY_ID: _.template(HOST + "/apis/PRX/patient-from-diag-rule/{{patient_id}}/{{diag_rule_id}}/"),
  LIST_CHAT_CHANNEL_TRIAGE_LEVEL_DIAGRULE: _.template(HOST + "/apis/PRX/official-account/triage/diagrule/{{diag_rule_id}}/{{triage_level}}/"),
  LIST_VACCINE_HOSP: HOST + "/apis/PRX/division-appointment/",
  LIST_AVAILABLE_VACCINE_DIVISION: HOST + "/apis/PRX/available-vaccine-division-service-block/",
  VACCINE_SERVICE_BLOCK: HOST + "/apis/PRX/select-vaccine-division-service-block/",
  DEL_VACCINE_SERVICE_BLOCK: _.template(HOST + "/apis/PRX/cancel-vaccine-division-service-block/{{division_service_block}}/"),
  LIST_VACCINE: HOST + "/apis/PRX/vaccine/",
  LIST_VACCINE_LOT: HOST + "/apis/PRX/vaccine-lot/",
  VACCINE_NOTE: HOST + "/apis/PRX/vaccine-note/",
  VACCINE_NOTE_FIREBASE: _.template("https://firebasestorage.googleapis.com/v0/b/ishealth-vaccine-monitor-side-effects/o/{{division_id}}.json?alt=media&token=monitor-side-effect&pagination_class=page_number"),
  VACCINE_NOTE_ID: _.template(HOST + "/apis/PRX/vaccine-note/{{vaccine_id}}/"),
  PROXY_PATIENT_ALLERGY: _.template(HOST + "/apis/PRX/proxy-patient/{{patient_id}}/allergy/"),
  LIST_PATIENT_FROM_DIAG_FORM: HOST + "/apis/PRX/list-patient/from-diag-form/",
  LIST_PATIENT_FROM_PATIENT: HOST + "/apis/PRX/list-patient/from-patient/",
  PROXY_PATIENT_HAS_DIVISION_NOT_FOLLOW: HOST + "/apis/PRX/proxy-patient-has-division/not-follow/",
  PROXY_PATIENT_HAS_DIVISION_PATIENT_CHAT_ID: _.template(HOST + "/apis/PRX/proxy-patient-has-division/patient-chat/{{patient_id}}/"),
  TUH_USERS_LIST: HOST + "/apis/PRX/tuh/users_list/",
  TUH_SET_PATIENT_HN: HOST + "/apis/PRX/tuh/set-patient-hn/",
  REQUEST_BOT_MESSAGE: _.template(HOST + "/apis/PRX/chat-channel/{{chat_channel_id}}/request-bot-message/"),
  OFFICIAL_ACCOUNT_DOCTOR: HOST + "/apis/PRX/official-account/doctor/",
  V2_ENCOUNTER_TRIAGE: HOST + "/apis/PRX/v2/encounter-triage/",
  PATIENT_SHIPPING_ORDER: HOST + "/apis/PRX/patient-shipping-order/",
  PROXY_ORGANIZATION: HOST + "/apis/PRX/proxy-organization/",
  PATIENT_SHIPPING_ORDER_RECEIVED: _.template(HOST + "/apis/PRX/patient-shipping-order/{{pk}}/received/"),
};

export const MSG = {
  LATEST_CHAT_CHANNEL: HOST + "/apis/MSG/latest-chat-channel/",
  CHAT_CHANNEL_MESSAGE_LIST: _.template(
    HOST + "/apis/MSG/chat-channel/{{chatChannelId}}/message/list/"
  ),
  CHAT_CHANNEL_MESSAGE_POST: _.template(
    HOST + "/apis/MSG/chat-channel/{{chatChannelId}}/message/post/"
  ),
  CHAT_CHANNEL_MESSAGE_READ_COUNT: _.template(
    HOST + "/apis/MSG/chat-channel/{{chatChannelId}}/message/read-count/"
  ),
  DEVICE_UPDATE: _.template(HOST + "/users/api/devices/update/")
};

export const QUE = {
  SERVICE_SLOT: HOST + "/apis/QUE/service-slot/",
  SERVICE_SLOT_UPDATE: _.template(
    HOST + "/apis/QUE/service-slot-update/{{pk}}/"
  ),
  DIVISION_SERVICE_BLOCK: HOST + "/apis/QUE/division-service-block/",
  DIVISION_SERVICE_BLOCK_DETAIL:
    HOST + "/apis/QUE/division-service-block-detail/",
  PATIENT_APPOINTMENT: HOST + "/apis/QUE/patient-appointment/",
  PATIENT_APPOINTMENT_DETAIL: _.template(
    HOST + "/apis/QUE/patient-appointment-update/{{pk}}/"
  ),
  DIVISION_PROVIDER: HOST + "/apis/QUE/division-provider/",
  PATIENT_APPOINTMENT_TELEPHAR: _.template(
    HOST + "/apis/QUE/simple-patient-appointment/{{pk}}/change-time/"
  ),
  DIVISION_SERVICE_BLOCK_SUMMARY_VIEW : HOST + "/apis/QUE/division-service-block/summary/",
};
