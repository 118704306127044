export default class DiagFormController {
    constructor(props) {
        this.diagManager = props.diagManager;
    }

    getPatient = async (apiToken) => {
        var params = { apiToken: apiToken }
        const [data, error] = await this.diagManager.getPatient(params);
        return [data, error]
    }

    createDiagForm = async (apiToken, patient, diagRuleId) => {
        let params ={
            apiToken: apiToken,
            patient: patient,
            diagRule: diagRuleId,
        }
        const [data, error] = await this.diagManager.createDiagForm(params);
        return [data, error];
    }

    /**
     * params: {apiToken, diagFormId, answers, finished, triageLevel, text}
     */
    updateDiagForm = async (params) => {
        const [data, error] = await this.diagManager.updateDiagForm(params);
        return [data, error];
    }

    getTriageLeve = async (apiToken, division, triageLevel) => {
        let params = {
            apiToken: apiToken,
            division: division,
            triageLevel: triageLevel,
        }
        const [data, error] = await this.diagManager.getTriageLevelWithDivision(params);
        return [data, error];
    }

    createEncounter = async (apiToken, diagFormId) => {
        let params = {
            apiToken: apiToken,
            diagFormId: diagFormId,
        }
        const [data, error] = await this.diagManager.createEncounterWithDiagForm(params);
        return [data, error];
    }

    getPublishDiagRuleDetail = async ({ apiToken, publishDiagRuleId } = {}) => {
        const [data, error] = await this.diagManager.getPublishDiagRuleDetail({
            apiToken,
            publishDiagRuleId
        });
        return [data, error];
    }

    getProxyPatientHasDivision = async ({ apiToken } = {}) => {
        const [data, error, network] = await this.diagManager.getProxyPatientHasDivision({
            apiToken,
        });
        return [data, error, network];
    }

    getPublishDiagRuleCheck = async ({ apiToken, divisionId, code } = {}) => {
        const [data, error, network] = await this.diagManager.getPublishDiagRuleCheck({
            apiToken,
            divisionId,
            code
        });
        return [data, error, network];
    }

    getDiagFormDetail = async ({ apiToken,  diagFormId } = {}) => {
        const [data, error, network] = await this.diagManager.getDiagFormDetail({
            apiToken,
            diagFormId,
        });
        return [data, error, network];
    }
}