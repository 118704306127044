import React from "react";
import ReactDOM from "react-dom";
import MainPatient from "./patient-lib/MainPatient";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import { HashRouter, BrowserRouter } from "react-router-dom";
import MaintainScreen from "./react-lib/apps/common/MaintainScreen";
import CONFIG from "config/config";

ReactDOM.render(
  <BrowserRouter>
    {CONFIG.ENABLE_MAINTAIN ? (
      <MaintainScreen />
    ) : (
      <>
        <MainPatient />
      </>
    )}
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
