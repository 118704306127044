export const KEY_CODE = {
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  BACKSPACE: 8,
  ZERO: 48,
  NINE: 57
}

export const ENCOUNTER_TYPE = {
  OPD: "OPD",
  IPD: "IPD",
  ALL: "ALL"
};

export const USER_ROLE_TYPE = {
  DOCTOR: "DOCTOR",
  REGISTERED_NURSE: "REGISTERED_NURSE",
  TECHNICIAN_PMR: "TECHNICIAN_PMR",
};

export const HD_TYPE = {
  CHRONIC: "CHRONIC",
  ACUTE: "ACUTE"
};

export const LOADING_ANIMATION = {
  CLOSED: 0,
  OPENING: 1,
  OPENED: 2
};

export const CHECKOUT_CAUSE = {
  BY_APPROVAL: "BY_APPROVAL",
  DEAD: "DEAD",
  ADMIT: "ADMIT",
  WAIT_RESULT: "WAIT_RESULT",
  DISAPPEAR: "DISAPPEAR"
};

export const GENDER = {
  MALE: "M",
  FEMALE: "F"
}

export const GENDER_OPTIONS = [
  {
    id: "MALE",
    text: "Male",
    value: "MALE"
  },
  {
    id: "FEMALE",
    text: "Female",
    value: "FEMALE"
  }
];

export const SHORT_GENDER_OPTIONS = [
  {
    id: "MALE",
    text: "M",
    value: 1
  },
  {
    id: "FEMALE",
    text: "F",
    value: 2
  }
];

export const YES_NO = {
  "NA": "NA",
  "YES": "Yes",
  "NO": "No"
}

export const ORDER_NAME = {
  DRUG_OPD_HOME: "DrugOPDHomeOrder",
  DRUG_STAT: "DrugStatOrder",
  DRUG_ONE_DOSE: "DrugOneDoseOrder"
};

export const FORM_TYPE_CODE = {
  CARD_PATIENT_ASSESSMENT: "CardPatientAssessment",
  CARD_FALL_RISK_ASSESSMENT: "CardFallRiskAssessment",
  CARD_PAIN_ASSESSMENT: "CardPainAssessment",
  CARD_NUTRITION_ASSESSMENT: "CardNutritionAssessment",
  CARD_DISCHARGE_SUMMARY: "CardDischargeSummary",
  DISCHARGE_PLANNING: "discharge_planning",
  DISCHARGE_TEACHING: "discharge_teaching",
  DISCHARGE_NURSING: "discharge_nursing",
  CARD_REASSESSMENT: "CardReassessment",
  CARD_REASSESSMENT_PAIN_IPD: "CardReAssessmentPainIPD",
  CARD_REASSESSMENT_RISK_FALL_IPD: "CardReAssessmentRiskFallIPD",
  CARD_REASSESSMENT_SLEEP_IPD: "CardReAssessmentSleepIPD",
  CARD_PREASSESSMENT: "CardPreAssessment",
  CARD_PREASSESSMENT_IPD: "CardPreAssessmentIPD",
};

export const TRIAGE_LEVEL = {
  "1": "1 Life Threatening",
  "2": "2 Emergency",
  "3": "3 Urgency",
  "4": "4 Semi-Urgency",
  "5": "5 ทั่วไป"
}

export const ALCOHOL = {
  "NA": "NA",
  "NO": "ไม่ดื่ม",
  "YES": "ดื่ม",
  "Past": "มีประวัติการดื่ม"
}

export const TOBACCO = {
  "NA": "NA",
  "NO": "ไม่สูบ",
  "YES": "สูบ",
  "Past": "มีประวัติการสูบ"
}

export const NARCOTIC = {
  "NA": "NA",
  "NO": "ไม่เคยใช้",
  "YES": "มีประวัติการใช้",
}

export const FORM_DATA_STATUS = {
  DRAFT: "DRAFT",
  EDITED: "EDITED",
  CONFIRMED: "CONFIRMED",
  CANCELLED: "CANCELLED"
}

export const ROOM_ITEM_STATUS = {
  RESERVED: 1,
  ADMISSION_ADMITED: 2,
  WARDED: 3,
  CHECKED_OUT: 4,
  CASHIER_REQUESTED: 5,
  CASHIER_DISCHARGED: 6,
  PLAN_TRANSFERED: 7,
  TRANSFERRED: 8,
  FINISHED: 9,
  CANCELED :10,
  RESERVED_TRANSFER :11
}

export const DOCTOR_ORDER = {
  ORDER_STATUS: {
    APPOINTMENT: 1, //* เป็นคำสั่งล่วงหน้า
    PENDING: 2, //* กำลังทำคำสั่ง
    PERFORMED: 3, //* ทำคำสั่งเรียบร้อยแล้ว
    CANCEL: 4, //* คำสั่งถูกยกเลิก
    DRAFT: 5, //* คำสั่ง DRAFT
    PLANNING: 6, //* คำสั่งแบบต่อเนื่อง
    OFF: 7, //* หยุดทำคำสั่งแบบต่อเนื่อง
  },
  PAYMENT_STATUS: {
    PENDING: 1, //* รอดำเนินการ
    READY: 2,  //* พร้อมชำระเงิน
    PAID: 3, //* ชำระเงินแล้ว
  }
}

export const INTAKE_OUTPUT_TYPE = {
  ORAL: "ORAL",
  IV: "IV",
  BLOOD: "BLOOD",
  OTHER_INTAKE: "OTHER_INTAKE",
  URINE: "URINE",
  EMESIS: "EMESIS",
  STOOL_ML: "STOOL_ML",
  STOOL: "STOOL",
  OTHER_OUTPUT: "OTHER_OUTPUT",
  STOOL_CAPITAL: "Stool",
};

export const INTAKE_TYPE = [
  INTAKE_OUTPUT_TYPE.ORAL,
  INTAKE_OUTPUT_TYPE.IV,
  INTAKE_OUTPUT_TYPE.BLOOD,
  INTAKE_OUTPUT_TYPE.OTHER_INTAKE,
];

export const OUTPUT_TYPE = [
  INTAKE_OUTPUT_TYPE.URINE,
  INTAKE_OUTPUT_TYPE.EMESIS,
  INTAKE_OUTPUT_TYPE.STOOL_ML,
  INTAKE_OUTPUT_TYPE.STOOL,
  INTAKE_OUTPUT_TYPE.OTHER_OUTPUT
];

export var ENCOUNTER_STATUS_COLOR = {
  WAIT_TRIAGE: 'grey',
  ARRIVING: 'red',
  ARRIVED: 'orange',
  SCREENED: 'yellow',
  IN_QUEUE: 'olive',
  IN_EXAM: 'green',
  WAIT_LAB: 'purple',
  WAIT_TREATMENT: 'purple',
  RECEIVE_RESULT: 'blue',
  IN_TREATMENT: 'violet',
  CHECKED_OUT: 'teal',
  WAIT_PAY: 'blue',
  WAIT_DISPENSE: 'pink',
  DISCHARGED: 'black',
  WAIT_RESULT: 'brown'
}

export const TRIAGE = {
  COLOR: {
      1: 'purple',
      2: 'red',
      3: 'orange',
      4: 'yellow',
      5: 'green',
  },
  DESC: {
      1: 'Life Threatening',
      2: 'Emergency',
      3: 'Urgency',
      4: 'Semi-Urgency',
      5: 'ทั่วไป',
  }
}

export const DOCUMENT_STATUS = {
  COLOR: {
      'Draft': 'red',
      'Confirm': 'green',
  },
  LABEL: {
      'Draft': 'I',
      'Confirm': 'C',
  },
  DESC: {
      'Draft': 'Incomplete',
      'Confirm': 'Completed',
  }
}

export const DOCTOR_ORDER_TEXT = {
  "ADMIT_ORDER": "admitorder",
  "DRUG_ORDER_HOME_OPD": "drugopdhomeorder",
  "DRUG_ORDER_SELLING": "drugsellingorder",
  "DRUG_ORDER_STAT": "drugstatorder",
  "DRUG_ORDER_ONE_DOSE": "drugonedoseorder",
  "DRUG_ORDER_HOME_IPD": "drugipdhomeorder",
  "DRUG_ORDER_CONTINUE_PLAN": "drugcontinueplan",
  "DRUG_ORDER_ONE_DAY": "drugonedayorder",
  "LAB_ORDER": "centrallaborder",
  "TREATMENT_ORDER": "treatmentorder",
  "OR_ORDER": "operatingorder",
  "DOCTOR_NOTE_ORDER": "doctornoteorder",
  "IMAGING_ORDER": "imagingorder"
}

export const ER_ZONE = {
  ZONE: {
    AIIR: "AIIR",
    AIR: "AIR"
  },
  COLOR: {
    "AIIR": "#B1C0D7",
    "AIR": "#D4D7B1",
    "GENERAL": "white"
  }
}

export const LOADING_STATUS = {
  REST: "REST",
  LOADING: "LOADING",
  ERROR: "ERROR",
  LOADED: "LOADED"
}

export const DOCTOR_ORDER_ACRONYM = {
  'centrallaborder': 'L',
  'treatmentorder': 'T',
  'admitorder': 'A',
  'doctorconsultorder': 'C',
  "drugorder": "RX",
  'drugopdhomeorder': 'RX',
  'drugsellingorder': 'RX',
  'drugrefillorder': 'RX',
  'drugstatorder': 'RX',
  'drugonedoseorder': 'RX',
  'drugcontinueorder': 'RX',
  'drugcontinueissue': 'RX',
  'drugipdhomeorder': 'RX',
  'drugpickingorder': 'RX',
  "drugcontinueplan": "RX",
  "drugonedayorder": "RX",
  'drugresuscitationorder': 'RX',
  'drugscanorder': 'RX',
  'drugoperatingorder': 'RX',
  'supplyopdhomeorder': 'MS',
  'supplyopdrefillorder': 'MS',
  'supplystatorder': 'MS',
  'supplycostcenterorder': 'MS',
  'supplyipdhomeorder': 'MS',
  'supplyipdrefillorder': 'MS',
  "supplyonedayorder": "MS",
  'supplyorder': "MS",
  'imagingorder': 'X',
  'operatingorder': 'O',
  'hdrequestitem': 'H',
  'anesthesiaorder': "ANES",
  'druganesthesiaorder': "RX",
  "druginjectionorder": "RX"
}

export const DOCTOR_ORDER_BG_COLOR = {
  APPOINTMENT: 'grey',
  PENDING: 'yellow',
  PERFORMED: 'green',
  CANCEL: 'red',
  VERIFY: "green",
  DRAFT: 'grey',
  PLANNING: 'yellow',
  OFF: 'grey',
}

export const APPOINTMENT_STATUS = {
  CONFIRMED: 1,
  WAIT_CONFIRMED: 2,
  CANCELED: 3,
  POSTPONED: 4,
  FINISHED: 5,
  REQUESTED: 6,
  WAIT_PAYMENTED: 7,
}