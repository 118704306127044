import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `Common/Zoom.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import QtQml 1.0

QtObject {
    id: zoom
    property var initialZoom: 2
    property var _zoomFactor: 0.10
    property var _currentScale: 1
    property int _outerTop
    property int _outerLeft
    property bool _xOverFlowed
    property bool _yOverFlowed

    Component.onCompleted: {
        zoom.context = document.createElement('div')
        zoom.context.style.display = 'none'
        zoom.context.style.position = 'fixed'
        zoom.context.style.width = '100%'
        zoom.context.style.height = '100%'
        zoom.context.style.overflow = 'hidden'
        zoom.context.style.top = '0px'
        zoom.context.style.left = '0px'
        zoom.context.style.zIndex = 6000
        zoom.context.style.backgroundColor = 'grey'
        zoom.image = document.createElement('img')
        zoom.context.appendChild(zoom.image)
    }

    /**
     *
     * @param {string} src - image source
     * @param {event} event - event from onClick on image dom (optional)
     */
    function show(src, event) {
        zoom.context.style.display = 'flex'
        zoom.context.style.alignItems = 'center'
        zoom.context.style.justifyContent = 'center'
        zoom.image.src = src
        zoom.image.style.transform = 'scale({initialZoom})'.format(
            {initialZoom: zoom.initialZoom}
        )
        zoom._currentScale = zoom.initialZoom
        document.body.appendChild(zoom.context)
        _calculateOverflow()
        _calculateInitialPosition(event)

        zoom.context.addEventListener('mousewheel', _onWheel)
        zoom.context.addEventListener('mousemove', _onMouseMove)
        zoom.context.addEventListener('click', _onClick)
    }

    function hide() {
        zoom.context.style.display = 'none'
        zoom.image.src = ''
        zoom.image.style.backgroundPosition = 'center'
        zoom.context.removeEventListener('mousewheel', _onWheel)
        zoom.context.removeEventListener('mousemove', _onMouseMove)
        zoom.context.removeEventListener('click', _onClick)
        zoom._currentScale = 1
        document.body.removeChild(zoom.context)
    }

    function _calculateInitialPosition(event) {
        if (event instanceof MouseEvent && event.target instanceof HTMLImageElement) {
            var targetImgDimentions = event.target.getBoundingClientRect()
            var xorigin = 50
            var yorigin = 50

            // calculate the position of the cursor inside the image element (in pixels)
            var x = event.offsetX
            var y = event.offsetY

            // calculate the position of the cursor as a percentage of the total size of the image element
            var xpercent = 100 / (targetImgDimentions.width / x)
            var ypercent = 100 / (targetImgDimentions.height / y)

            _translate(xpercent, ypercent)
        }
    }

    function _onClick() {
        hide()
    }

    function _onMouseMove(event) {
        var zoomDimentions = zoom.context.getBoundingClientRect()

        // calculate the position of the cursor inside the element (in pixels)
        var x = event.clientX - zoomDimentions.left
        var y = event.clientY - zoomDimentions.top

        // calculate the position of the cursor as a percentage of the total size of the element
        var xpercent = 100 / (zoomDimentions.width / x)
        var ypercent = 100 / (zoomDimentions.height / y)

        _translate(xpercent, ypercent)
    }

    function _onWheel(event) {
        // 1 for wheel up, -1 for wheel down
        var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)))
        if (delta > 0) {
            // zoom in
            zoom._currentScale += zoom._zoomFactor
        } else {
            // zoom out
            zoom._currentScale = Math.max(0.1, zoom._currentScale - zoom._zoomFactor)
        }
        zoom.image.style.transform = 'scale({scaleFactor})'.format(
            {scaleFactor: zoom._currentScale}
        )
        _calculateOverflow()
        event.preventDefault()
    }

    function _translate(xpercent, ypercent) {
        var xorigin = 50
        var yorigin = 50

        var translateX = (xpercent - xorigin) * 2
        var translateY = (ypercent - yorigin) * 2

        if (zoom._xOverFlowed) {
            var direction = translateX > 0 ? 'right' : 'left'
            translateX = Math.abs(translateX * zoom._outerLeft) / 100
            if (direction === 'right') {
                translateX = -translateX
            }
        }
        if (zoom._yOverFlowed) {
            var direction = translateY > 0 ? 'bottom' : 'top'
            translateY = Math.abs(translateY * zoom._outerTop) / 100
            if (direction === 'bottom') {
                translateY = -translateY
            }
        }
        if (zoom._xOverFlowed || zoom._yOverFlowed) {
            translateX = zoom._xOverFlowed ? translateX : 0
            translateY = zoom._yOverFlowed ? translateY : 0
            zoom.image.style.transform = 'scale({scaleFactor}) translateX({x}px) translateY({y}px)'.format(
                {scaleFactor: zoom._currentScale, x: translateX, y: translateY}
            )
        }
    }

    function _calculateOverflow() {
        var scaledImageWidth = $(zoom.image).innerWidth() 
        var scaledImageHeight = $(zoom.image).innerHeight()
        var contextWidth = $(zoom.context).innerWidth()
        var contextHeight = $(zoom.context).innerHeight() 

        var actualImageWidth = zoom.image.offsetWidth
        var actualImageHeight = zoom.image.offsetHeight
        var widthRatio = scaledImageWidth / actualImageWidth
        var heightRatio = scaledImageHeight / actualImageHeight

        zoom._xOverFlowed = scaledImageWidth > contextWidth
        zoom._yOverFlowed = scaledImageHeight > contextHeight

        // calculate outerTop, outerLeft from scaledImage
        if (zoom._xOverFlowed && !zoom._yOverFlowed) {
            zoom._outerLeft = Math.abs(contextWidth - scaledImageWidth) / 2
        } else if (!zoom._xOverFlowed && zoom._yOverFlowed) {
            zoom._outerTop = Math.abs(contextHeight - scaledImageHeight) / 2
        } else if (zoom._xOverFlowed && zoom._yOverFlowed) {
            zoom._outerTop = Math.abs(contextHeight - scaledImageHeight) / 2
            zoom._outerLeft = Math.abs(contextWidth - scaledImageWidth) / 2
        } else if (!zoom._xOverFlowed && !zoom._yOverFlowed) {
            zoom._outerTop = 0
            zoom._outerLeft = 0
        }
        
        // translateX() and translateY() use actual pixel from the image
        // we must convert from scaled pixels to actual pixels
        if (zoom._outerTop) {
            // calculate actual outerTop
            zoom._outerTop /= heightRatio
            // add 5 paddings
            zoom._outerTop += 5
        }
        if (zoom._outerLeft) {
            // calculate actual _outerLeft
            zoom._outerLeft /= widthRatio
            // add 5 paddings
            zoom._outerLeft += 5
        }
    }
}
        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/Common/Zoom.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}