import MixInService from "../services/MixInService";
import { to } from "../../utils";
export default class MixInManager {
  getPrintAPI = async ({
    printAPI,
    patientId,
    encounterId,
    jasperModule,
    jasperPath,
    pdf,
    orderId,
    confirm,
    reason
  }: { 
    printAPI?: any,
    patientId?: any,
    encounterId?: any,
    jasperModule?: any,
    jasperPath?: any,
    pdf?: any,
    orderId?: any,
    confirm?: any,
    reason?: any
  } = {}) => {
    let params: any = {};
    if (patientId) {
      params.patient_id = patientId;
    }
    if (encounterId) {
      params.encounter_id = encounterId;
    }
    if (jasperModule) {
      params.jasper_module = jasperModule;
    }
    if (jasperPath) {
      params.jasper_path = jasperPath;
    }
    if (pdf) {
      params.pdf = pdf;
    }
    if (orderId) {
      params.order_id = orderId;
    }
    if (confirm) {
      params.confirm = confirm;
    }
    if (reason) {
      params.reason = reason;
    }
    const mixInService = new MixInService();
    const [error, response, network] = await to(
      mixInService.getPrintAPI({ printAPI, params })
    );
    return [response, error, network];
  };

  getRegisterByTokenFacebook = async ({ accessToken, subscription, device_id, app, email }: {
    accessToken: any, subscription: any, device_id: any, app: any, email: any
  }) => {
    let params: any = {};
    console.log("getRegisterByTokenFacebook... subscription: ", subscription);
    console.log("getRegisterByTokenFacebook... device_id: ", device_id);
    console.log("getRegisterByTokenFacebook... app: ", app);
    console.log("getRegisterByTokenFacebook... email: ", email);
    if (accessToken) {
      params.access_token = accessToken;
    }
    if (subscription) {
      params.device_token = JSON.stringify(subscription);
    }
    if (device_id) {
      params.unique_id = device_id;
      params.device_id = device_id;
      params.device_type = "webpush";
    }
    if (app) {
      params.app = app;
    }
    if (email) {
      params.email = email;
    }
    const mixInService = new MixInService();
    const [error, response, network] = await to(
      mixInService.getRegisterByTokenFacebook({ params })
    );
    return [response, error, network];
  }

  postRegisterByTokenFacebook = async ({ accessToken, subscription, device_id, app, email }: {
    accessToken: any, subscription: any, device_id: any, app: any, email: any
  }) => {
    let data: any = {};

    if (accessToken) {
      data.access_token = accessToken;
    }
    if (subscription) {
      data.device_token = JSON.stringify(subscription);
    }
    if (device_id) {
      data.unique_id = device_id;
      data.device_id = device_id;
      data.device_type = "webpush";
    }
    if (app) {
      data.app = app;
    }
    if (email) {
      data.email = email;
    }
    const mixInService = new MixInService();
    const [error, response, network] = await to(
      mixInService.postRegisterByTokenFacebook({ data })
    );
    return [response, error, network];
  }
}
