import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Header,
  Segment,
  Label,
  Form,
  Button,
  Icon,
  Confirm,
  Dimmer,
  Loader,
  Popup,
} from "semantic-ui-react";
import { ModalEpisodeList, ModalNewEpisode, ModOrderDetail } from "./";
import ReactTable from "react-table-6";

const styles = {
  grayColor: {
    background: "#f3f3f3",
  },
};

const CardEpisodeOfCare = (props: any) => {
  const [pageSize, setPageSize] = useState(3);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [isShowCancelDialog, setIsShowCancelDialog] = useState(false);

  const modalEpisode = useRef<any>();
  const modalNewEpisode = useRef<any>();

  // ModOrderDetail
  const [openModOrderDetail, setOpenModOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);

  const {
    isDisableSelectEpisode,
    isDisableNewEpisode,
    isDisableCancelEpisode,
    episodeType,
    getEpisodeDetail,
    getEncounterList,
    onSearchEpisode,
    onNewEpisode,
    onCancelEpisode,
    onSelectedRow,
    onEpisodeSelected,
    eocIsLoading,
    handleGetDoctorSpecialty,
    getDoctorSpecialty,
    handleSearchDoctor,
    getDoctorList,
    getSearchDoctorNumberOfPage,
    getSearchDoctorCurrentPage,
    onSearchDoctorListPageChange,
    getEOCEnEPData,
  } = props;

  const handlePageSizeChange = (value: any) => {
    setPageSize(value);
  };

  const onCancelFromEpisode = () => {
    // if (selectedRow.id) {
    //   setIsShowCancelDialog(true)
    // }
    setIsShowCancelDialog(true);
  };

  const onCreateEpisode = () => {
    (modalNewEpisode).current.openModal();
  };

  const onSelectEpisode = () => {
    (modalEpisode).current.openModal(selectedRow);
  };

  const selectRow = (data: any) => {
    setSelectedRow(data);
    onSelectedRow(data);
  };

  const handleOpenModOrderDetail = (order: any) => {
    setSelectedOrder(order);
    setOpenModOrderDetail(true);
  };

  const handleCloseModOrderDetail = () => {
    setSelectedOrder(null);
    setOpenModOrderDetail(false);
  };

  const columns = [
    { Header: "Date time", accessor: "date_time", width: 200 },
    { Header: "Encounter", accessor: "encounter", width: 150 },
    { Header: "Doctor", accessor: "doctor", width: 250 },
    { Header: "Diagnosis", accessor: "diagnosis" },
    {
      Header: "Order",
      accessor: "order",
      Cell: (props: any) => {
        const orders = props.value || [];
        return (
          <div style={{ textAlign: "left" }}>
            {orders.map((value: any, index: any) => {
              return (
                <Label
                  key={index}
                  circular
                  color={value.color}
                  onClick={(e, data) => {
                    handleOpenModOrderDetail(value);
                  }}
                >
                  {value.text}
                </Label>
              );
            })}
          </div>
        );
      },
    },
  ];

  let episodeDetail = getEpisodeDetail();
  let enEpData = getEOCEnEPData();
  let encounterList = getEncounterList();

  return (
    <Segment raised style={styles.grayColor}>

      <Dimmer active={eocIsLoading} inverted>
        <Loader active={eocIsLoading} inverted>Loading</Loader>
      </Dimmer>

      <Form>
        <Form.Group inline>
          <Form.Field >
            <Header >Episode of care</Header>
          </Form.Field>

          <Form.Group inline style={{ flex: 1, flexDirection: "row-reverse" }}>
            {!isDisableSelectEpisode && (
              <Form.Field >
                <Popup position='top right' content={"เลือก Episode ที่มีอยู่แล้วนำ Encounter นี้เข้าร่วม"} trigger={
                  <Button fluid onClick={onSelectEpisode} color="green">
                    <Icon name="file alternate" />
                    เลือก Episode
                  </Button>
                } />
              </Form.Field>
            )}

            {!isDisableNewEpisode && (
              <Form.Field >
                <Popup position='top right' content={"สร้าง Episode ใหม่แล้วนำ Encounter นี้เข้าร่วม"} trigger={
                  <Button fluid disabled={episodeDetail.id !== -1} onClick={onCreateEpisode} color="teal">
                    <Icon name="add circle" />
                    New Episode
                  </Button>
                } />
              </Form.Field>
            )}

            {!isDisableCancelEpisode && (
              <Form.Field >
                <Popup position='top right' content={"ถอน Encounter ปัจจุบันออกจาก Episode"} trigger={
                  <Button fluid disabled={episodeDetail.id === -1} onClick={onCancelFromEpisode} color="red">
                    <Icon name="remove circle" />
                    ยกเลิกระบุ Episode
                  </Button>
                } />
              </Form.Field>
            )}

            {episodeDetail.id != -1 && (<>
              <Form.Field >
                {episodeDetail.id != -1 && (
                  <Label className="fluid" size="large">
                    {episodeDetail.id} ({episodeDetail.date_time})
                  </Label>
                )}
              </Form.Field>
              <Form.Field >
                <Header as='h4'>เลข Episode, เวลา</Header>
              </Form.Field>
              <Form.Field >
                {episodeDetail.id != -1 && (
                  <Label className="fluid" size="large">
                    {enEpData?.epType}
                  </Label>
                )}
              </Form.Field>
              <Form.Field >
                <Header as='h4'>Type </Header>
              </Form.Field>
              <Form.Field  >
                {episodeDetail.id != -1 && (
                  <Label className="fluid" size="large">
                    {enEpData?.epName}
                  </Label>
                )}
              </Form.Field>
              <Form.Field >
                <Header as='h4'>ชื่อ Episode</Header>
              </Form.Field>
            </>)}

          </Form.Group>
        </Form.Group>
      </Form>

      <ReactTable
        columns={columns}
        pageSizeOptions={[3, 5, 10, 20, 50, 100]}
        data={encounterList}
        onPageSizeChange={handlePageSizeChange}
        pageSize={pageSize}
        getTrProps={(state: any, rowInfo: any) => {
          const rowData = rowInfo && rowInfo.original;
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e: any) => {
                selectRow(rowData);
              },
              className: rowData.id === (selectedRow).id ? "selected" : "",
              // style: {
              //   background: rowData.id === selectedRow.id ? '#00afec' : 'white',
              //   color: rowData.id === selectedRow.id ? 'white' : 'black'
              // }
            };
          } else {
            return {};
          }
        }}
      />

      <ModalEpisodeList
        ref={modalEpisode}
        onSearch={onSearchEpisode}
        onEpisodeSelected={onEpisodeSelected}
        // Search doctor
        getDoctorList={getDoctorList}
        getDoctorSpecialty={getDoctorSpecialty}
        getSearchDoctorNumberOfPage={getSearchDoctorNumberOfPage}
        getSearchDoctorCurrentPage={getSearchDoctorCurrentPage}
        handleGetDoctorSpecialty={handleGetDoctorSpecialty}
        handleSearchDoctor={handleSearchDoctor}
        onSearchDoctorListPageChange={onSearchDoctorListPageChange}
      />

      <ModalNewEpisode
        ref={modalNewEpisode}
        episodeType={episodeType}
        onNewEpisode={onNewEpisode}
      />

      <Confirm
        open={isShowCancelDialog}
        onCancel={() => {
          setIsShowCancelDialog(false);
        }}
        onConfirm={() => {
          setIsShowCancelDialog(false);
          onCancelEpisode(selectedRow);
        }}
        size={"tiny"}
        content={props.messageCancelEpisode}
        // style = {{width:'400px'}}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        cancelButton={"ยกเลิก"}
        confirmButton={"ตกลง"}
      />

      <ModOrderDetail
        controller={props.modOrderDetailController}
        hideCallback={handleCloseModOrderDetail}
        open={openModOrderDetail}
        orderId={selectedOrder ? (selectedOrder).id : null}
      />
    </Segment>
  );
};

CardEpisodeOfCare.defaultProps = {
  modOrderDetailController: null,
  eocIsLoading: true,
  isDisableSelectEpisode: false,
  isDisableNewEpisode: false,
  isDisableCancelEpisode: false,
  episodeType: [],
  messageCancelEpisode: 'ยืนยันการ "ยกเลิกระบุ Episode" หรือไม่?',
  onSelectedRow: (data: any) => { },
  onCancelEpisode: (data: any) => { },
  getEpisodeDetail: () => {
    return { id: -1, date_time: "" };
  },
  getEncounterList: () => {
    return [];
  },
  getDoctorSpecialty: () => {
    return [];
  },
  getDoctorList: () => {
    return [];
  },
  getSearchDoctorNumberOfPage: () => {
    return 0;
  },
  getSearchDoctorCurrentPage: () => {
    return 0;
  },
};

CardEpisodeOfCare.propTypes = {
  modOrderDetailController: PropTypes.object,
  isDisableSelectEpisode: PropTypes.bool,
  isDisableNewEpisode: PropTypes.bool,
  isDisableCancelEpisode: PropTypes.bool,
  messageCancelEpisode: PropTypes.string,
  episodeType: PropTypes.array,
  eocIsLoading: PropTypes.bool,
  /** episode detail */
  getEpisodeDetail: PropTypes.func,
  // episodeDetail: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     id: PropTypes.string,
  //     date_time: PropTypes.string
  //   })
  // ),
  getEncounterList: PropTypes.func,
  getEOCEnEPData: PropTypes.func,
  /** encounter list for show in table */
  // encounterList: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     date_time: PropTypes.string,
  //     encounter: PropTypes.string,
  //     doctor: PropTypes.string,
  //     diagnosis: PropTypes.string,
  //     order: PropTypes.array
  //   })
  // ),
  onSearchEpisode: PropTypes.func,
  onNewEpisode: PropTypes.func,
  onCancelEpisode: PropTypes.func,
  onSelectedRow: PropTypes.func,
  onEpisodeSelected: PropTypes.func,
  handleGetDoctorSpecialty: PropTypes.func,
  getDoctorSpecialty: PropTypes.func,
  handleSearchDoctor: PropTypes.func,
  getDoctorList: PropTypes.func,
  getSearchDoctorNumberOfPage: PropTypes.func,
  getSearchDoctorCurrentPage: PropTypes.func,
  onSearchDoctorListPageChange: PropTypes.func,
};

export default CardEpisodeOfCare;
