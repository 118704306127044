import React from 'react';
import { Container } from 'semantic-ui-react';

export default class PrivacyPolicy extends React.PureComponent {

  render() {
    return (
      <Container style={{margin: '50px'}}>
        <h2>ข้อกำหนดและเงื่อนไขการใช้บริการแอพพลิเคชั่น อิสเฮลท์ (IsHealth)</h2>
        <p>
          ข้อกำหนดและเงื่อนไขการใช้บริการแอพพลิเคชั่น อิสเฮลท์ (IsHealth) นี้ ทำขึ้นระหว่าง
          บริษัทมอร์คอปอเรชั่น จำกัด ซึ่งต่อไปนี้เรียกว่า “ผู้ให้บริการ” ฝ่ายหนึ่ง กับผู้ใช้บริการ ซึ่งต่อไปนี้
          เรียกว่า “ผู้ใช้บริการ” อีกฝ่ายหนึ่ง โดยบริการแอพพลิเคชั่นอิสเฮลท์ (IsHealth) นี้ จะให้บริการ
          หรือให้ความสะดวกแก่ผู้ใช้บริการ เช่น การทำแบบประเมินตนเอง ช่องทางสื่อสารเพื่อติดตามอาการ
          การจัดสร้างแบบประเมิน เป็นต้น
        </p>
        <p>
          เมื่อผู้ใช้บริการได้เข้าใช้บริการแอพพลิเคชั่นอิสเฮลท์ (IsHealth) ผู้ใช้บริการตกลงที่จะปฏิบัติตาม
          ข้อกำหนดและเงื่อนไขการใช้บริการนี้ ดังต่อไปนี้
        </p>

        <h3>1. คำจำกัดความ</h3>
        <p>
          <b>1.1 “บริการ”</b> หมายถึง บริการแอพพลิเคชั่น อิสเฮลท์ (IsHealth) ต่อไปนี้ เรียกว่า “IsHealth”
          ซึ่งเป็นบริการทางด้านสุขภาพบนโทรศัพท์เคลื่อนที่ และ/หรืออุปกรณ์สื่อสารของผู้ใช้บริการที่เชื่อมต่อด้วย
          ระบบอินเทอร์เน็ต รวมทั้งบริการประเภทอื่นๆ ที่ผู้ให้บริการจะให้บริการแก่ผู้ใช้บริการเพิ่มเติมในอนาคต
        </p>
        <p>
          <b>1.2 “ผู้ใช้บริการ”</b> หมายถึง ผู้ทำคำขอใช้บริการนี้ ไม่ว่าจะเป็นการสมัครด้วยวิธีการใดก็ตามที่ผู้ให้บริการกำหนด
        </p>
        <p>
          <b>1.3 “รหัสประจำตัว”</b> หมายถึง Login Name หรือ Username, รหัสผ่าน (Password)
          รวมถึงรหัสอื่นใดที่เกี่ยวข้องกับการเข้าใช้บริการนี้ ไม่ว่าจะเรียกด้วยชื่อใดๆ และไม่ว่าจะเป็นรหัสที่ผู้ให้บริการ
          เป็นผู้กำหนดให้หรือเป็นรหัสที่ผู้ใช้บริการเป็นผู้กำหนดเองก็ตาม ให้ถือเป็นข้อมูลส่วนตัวของผู้ใช้บริการ
          แต่เพียงผู้เดียว ซึ่งผู้ใช้บริการรับทราบและตกลงที่จะเก็บรักษาข้อมูลส่วนตัวดังกล่าวไว้เป็นความลับโดยจะต้อง
          ไม่เปิดเผยให้ผู้อื่นทราบและ/หรือยินยอมให้ผู้อื่นนำไปใช้โดยเด็ดขาด ทั้งนี้ผู้ใช้บริการตกลงยินยอมผูกพัน
          ตนเองโดยให้ถือว่าข้อมูลส่วนตัวดังกล่าว เป็นสิ่งใช้แทนตัวตนของผู้ใช้บริการ ตามข้อกำหนดและเงื่อนไข
          การใช้บริการ
        </p>

        <h3>2. บริการ</h3>
        <p>
          ผู้ใช้บริการสามารถใช้บริการนี้เพื่อการต่างๆ ดังต่อไปนี้
        </p>
        <p>2.1 บริการค้นหาและทำแบบประเมินอาการด้วยตนเอง</p>
        <p>2.2 บริการสื่อสารเพื่อปรึกษาและติดตามอาการป่วย</p>
        <p>2.3 บริการข้อมูลความรู้เรื่องโรค</p>
        <p>2.4 บริการจัดทำแบบประเมินอาการ (สำหรับโรงพยาบาลหรือบุคลากรทางการแพทย์)</p>
        <p>2.5 บริการอื่นๆ</p>

        <h3>3. การใช้บริการ</h3>
        <p>ผู้ใช้บริการรับทราบและยอมรับและตกลงกับผู้ให้บริการ ดังนี้</p>
        <p>3.1 การใช้บริการใดๆที่ทำโดยใช้รหัสประจำตัวผู้ใช้บริการให้ถือว่าเป็นการกระทำของผู้ใช้บริการเอง และมีผลผูกพันต่อผู้ใช้บริการ</p>
        <p>3.2 ผู้ใช้บริการจะรักษารหัสประจำตัวผู้ใช้บริการไว้เป็นความลับและจะไม่เปิดเผยหรือกระทำการใดๆที่อาจทำให้ผู้อื่นทราบรหัสประจำตัวดังกล่าว หากมีบุคคลอื่นล่วงรู้รหัสประจำตัวของผู้รับบริการ ผู้รับบริการต้องแจ้งให้ผู้ให้บริการทราบ เพื่อดำเนินการแก้ไขตามวิธีที่ผู้ให้บริการกำหนด</p>
        <p>3.3 ผู้ใช้บริการสามารถเปลี่ยนรหัสผ่านในการใช้บริการ ได้ด้วยตนเองโดยผ่าน แอพพลิเคชั่น IsHealth โดยดำเนินการตามวิธีการและเงื่อนไขที่ผู้ให้บริการกำหนด</p>
        <p>3.4 ผู้ใช้บริการรับทราบว่า คำศัพท์ทางเทคนิคทางการแพทย์ที่เกี่ยวกับการวินิจฉัยโรคนั้น เป็นคำศัพท์ภาษาอังกฤษและอาจยากต่อการทำความเข้าใจ ผู้ให้บริการจึงทำการแปลคำศัพท์ทางเทคนิคดังกล่าว เป็นภาษาไทยด้วยถ้อยคำที่ง่ายต่อการทำเข้าใจถึงการวินิจฉัยโรคของแพทย์เท่านั้น ซึ่งความหมายอาจไม่ตรงตามตัวอักษรของคำศัพท์ทางเทคนิคทางการแพทย์ หากผู้ใช้บริการมีข้อสงสัยเกี่ยวกับข้อมูลประวัติการรักษา โปรดติดต่อสอบถามสถานพยาบาลที่ให้บริการผู้ใช้บริการ</p>
        <p>3.5 หากมีข้อผิดพลาดประการใดเกิดขึ้นในการใช้บริการนี้ หรือผู้ใช้บริการมีข้อสงสัยเกี่ยวกับข้อมูลตน ผู้ใช้บริการจะแจ้งรายละเอียดต่าง ๆ ที่เกี่ยวข้องมายังผู้ให้บริการ เพื่อยืนยันตรวจสอบแก้ไขข้อผิดพลาดดังกล่าว</p>

        <h3>4. ความรับผิดชอบและข้อยกเว้นความรับผิดชอบผู้ให้บริการ</h3>
        <p>ผู้ให้บริการจะรับผิดชอบสำหรับความเสียที่เกิดขึ้นในกรณีที่ผู้ให้บริการกระทำโดยจงใจหรือประมาทเลินเล่อ อย่างร้ายแรง ซึ่งเป็นความเสียหายที่ตามปกติย่อมเกิดขึ้นเท่านั้น แต่ผู้ให้บริการไม่ต้องรับผิดในความเสียหายที่เกิดจากพฤติการณ์พิเศษ ทั้งนี้ ผู้ใช้บริการตกลงว่าไม่ว่ากรณีใดๆ ผู้ให้บริการไม่ต้องรับผิดชอบในความเสียหายที่เกิดจากสาเหตุต่างๆ ดังต่อไปนี้</p>
        <p>4.1 มีการใช้รหัสประจำตัวผู้ใช้บริการควบคู่กับรหัสลับแรกเข้าหรือรหัสลับส่วนตัว หรือมีการใช้ Mobile PIN หรือวิธีการอื่นใด ตามที่ผู้ให้บริการกำหนด ไม่ว่าจะเป็นการกระทำของผู้ใดก็ตาม</p>
        <p>4.2 เหตุสุดวิสัยต่างๆ หรือเหตุการณ์ที่ผู้ให้บริการไม่สามารถควบคุมได้ ซึ่งรวมถึงการที่อุปกรณ์ ระบบสื่อสาร หรือ การส่งสัญญาณเชื่อมต่อไม่ทำงา หรือการรบกวนจากไวรัสคอมพิวเตอร์ หรือการกระทำที่ไม่ชอบด้วยกฎหมาย หรือ ในกรณีที่ผู้ให้บริการปิดให้บริการเพื่อปรับปรุงหรือบำรุงรักษาระบบ อันเป็นเหตุให้ผู้ใช้บริการไม่สามารถใช้บริการนี้ได้</p>
        <p>4.3 ความล่าช้า ความผิดพลาด หรือความบกพร่องของระบบคอมพิวเตอร์ หรืออุปกรณีต่างๆของผู้ใช้บริการ ของผู้ให้บริการ หรือ ของผู้ให้บริการระบบอินเทอร์เน็ต (Internet Service Provider) หรือ เครือข่ายการสื่อสารอื่นๆ</p>
        <p>4.4 ความล่าช้าในขั้นตอนการดำเนินการของบริการนี้</p>
        <p>4.5 การที่ผู้ใช้บริการไม่ปฏิบัติตามข้อกำหนดและเงื่อนไขนี้ หรือข้อกำหนดและเงื่อนไขอื่นใดที่เกี่ยวข้อง</p>

        <h3>5. ข้อมูลการติดต่อและการส่งคำบอกกล่าว</h3>
        <p>5.1 ผู้ใช้บริการตกลงให้ผู้ให้บริการติดต่อสอบถาม เก็บ รวบรวม หรือ ใช้ข้อมูลบางประการ หรือทั้งหมดเกี่ยวกับ ผู้ใช้บริการได้ตามความจำเป็นและเหมาะสม</p>
        <p>5.2 ผู้ใช้บริการตกลงและยินยอมให้ผู้ให้บริการส่งข้อมูลบริการหรือรายการส่งเสริมการขายต่างๆ ไปยัง ไปรษณีย์อิเล็กทรอนิกส์ (E Mail) และหมายเลขโทรศัพท์มือถือของผู้ใช้บริการ</p>
        <p>5.3 หนังสือ จดหมาย คำบอกกล่าวใดๆ ของผู้ให้บริการที่จัดส่งให้แก่ผู้ใช้บริการทาง ไปรษณีย์ หรือ ไปรษณีย์อิเล็กทรอนิกส์ (E Mail) ของผู้ใช้บริการ ตามที่ได้แจ้งให้ผู้ให้บริการทราบ ให้ถือว่า เป็นการจัดส่งให้แก่ผู้ใช้บริการโดยชอบแล้ว และในกรณีที่ผู้ใช้บริการเปลี่ยนแปลงที่อยู่อยู่ หรือช่องทางการติตต่อดังกล่าว ผู้ใช้บริการจะแจ้งให้ผู้ให้บริการทราบ</p>

        <h3>6. การแก้ไขเพิ่มเติม หรือยกเลิกการให้บริการ</h3>
        <p>6.1 ผู้ให้บริการให้บริการ IsHealth ตามสภาพที่เป็นอยู่ (AS IS) และมีอยู่ (AS AVAILABLE) ผู้ให้บริการสงวนสิทธิ์ที่จะปรับปรุงและอัพเดทแอพพลิเคชั่นเป็นครั้งคราว ในเวลาใด ๆ ก็ได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า เพื่อเพิ่มประสิทธิภาพการทำงาน หรือเพิ่มประสบการณ์การใช้งานแอพพลิเคชั่นที่ดีขึ้น ซึ่งอาจเปลี่ยนแปลง จำกัด ระงับ หรือยกเลิกฟังก์ชั่น หรือบริการบางประเภท</p>
        <p>6.2 ผู้ให้บริการมีสิทธิระงับหรือยกเลิกการเข้าถึงบริการนี้ได้ทันที ไม่ว่าทั้งหมดหรือบางส่วน หากผู้ให้บริการสงสัยหรือเห็นว่า มีการใช้บริการในลักษณะที่ขัดต่อข้อกำหนดและเงื่อนไขนี้ หรือขัดต่อกฎหมายหรือความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน หรือในลักษณะที่ผิดปกติหรือมีพฤติการณ์อันน่าเชื่อว่ามีการใช้บริการอันอาจก่อให้เกิดความเสียหายแก่บุคคลใดๆ ไม่ว่าด้วยประการใด ๆ</p>
        <p>6.3 ผู้ให้บริการอาจแก้ไขเพิ่มเติมข้อกำหนดและเงื่อนไขนี้ในเวลาใดๆ ก็ได้ โดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า โดยผู้ให้บริการจะแจ้งให้ผู้ใช้บริการทราบถึงการแก้ไขเพิ่มเติมดังกล่าวผ่านทางแอพพลิเคชั่น หรือวิธีการอื่นใดที่ผู้ให้บริการเห็นสมควร การที่ผู้ใช้บริการยังคงใช้บริการนี้ต่อไป ให้ถือว่าผู้ใช้บริการรับทราบและตกลงผูกพันตามการแก้ไขเพิ่มเติมนั้น ผู้ใช้บริการมีหน้าที่ติดตามการแก้ไขเปลี่ยนแปลงดังกล่าว และจะไม่อ้างเหตุว่าไม่ทราบถึงการแก้ไขเพิ่มเติมข้อกำหนดและเงื่อนไขดังกล่าวมาเป็นเหตุในการฝ่าฝืนข้อกำหนดและเงื่อนไขนี้</p>

        <h3>7. ความสมบูรณ์ของข้อกำหนดและเงื่อนไข</h3>
        <p>ในกรณีที่ปรากฏว่ามีข้อกำหนดหรือเงื่อนไขใดในเอกสารนี้ไม่สมบูรณ์ ตกเป็นโมฆะ หรือใช้บังคับไม่ได้ ไม่ว่าด้วยประการใดๆ ให้ถือว่าข้อกำหนดหรือเงื่อนไขที่ไม่สมบูรณ์ ตกเป็นโมฆะ หรือใช้บังคับไม่ได้ดังกล่าวนั้น ไม่มีผลกระทบต่อความสมบูรณ์หรือการบังคับใช้ข้อกำหนดและเงื่อนไขอื่น ๆ ในเอกสารนี้</p>
      </Container>
    );
  }
}