import BaseService from "./BaseService";
import axios from "axios";
const post = (path, body) => {
  return fetch(`${path}`, {
    credentials: "omit",
    headers: { "content-type": "application/json;charset=UTF-8", "sec-fetch-mode": "cors" },
    body: JSON.stringify(body),
    method: "POST",
    mode: "no-cors"
  });
};
const get = path => {
  return fetch(`${path}`, {
    credentials: "omit",
    headers: { "content-type": "application/json;charset=UTF-8", "sec-fetch-mode": "cors" },
    method: "GET",
    mode: "no-cors"
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return data;
    });
};
class NotificationService extends BaseService {
  sendNotification({ pushServerSubscriptionId } = {}) {
    let url = `https://coturn.drjaysayhi.com/subscription/${pushServerSubscriptionId}`;
    return get(url);
    // return this.client
    //   .get(url)
    //   .then(this.handleResponse)
    //   .catch(this.throwErrorMessage);
  }
  sendSubscriptionToPushServer({ data } = {}) {
    console.log("const service = new NotificationService ", data);
    let url = "https://coturn.drjaysayhi.com/subscription";
    return axios(url, {
      credentials: "omit",
      headers: { "content-type": "application/json;charset=UTF-8", "sec-fetch-mode": "cors" },
      data: data,
      method: "POST"
    })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
    // return this.client
    //   .post(url, data)
    //   .then(this.handleResponse)
    //   .catch(this.throwErrorMessage);
  }
}
export default NotificationService;
