let fileFonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

let fileSapiensFonts = {
  Kanit: {
    normal: "Kanit-Regular.ttf",
    bold: "Kanit-Bold.ttf",
    italics: "Kanit-Italic.ttf",
    bolditalics: "Kanit-BoldItalic.ttf",
  },
  Poppins: {
    normal: "Poppins-Regular.ttf",
    bold: "Poppins-Bold.ttf",
    italics: "Poppins-Italic.ttf",
    bolditalics: "Poppins-BoldItalic.ttf",
  },
  KanitLM: {
    normal: "Kanit-Light.ttf",
    bold: "Kanit-Medium.ttf",
  },
  PoppinsLM: {
    normal: "Poppins-Light.ttf",
    bold: "Poppins-Medium.ttf",
  },
};

const getPdfMake = async (isSapien: boolean = false) => {
  let make = await import("pdfmake/build/pdfmake");
  let pdfMake = make.default;

  let pdfFonts = await import("react-lib/assets/fonts/pdfFonts");

  if (isSapien) {
    let sapiensFonts = await import("react-lib/assets/fonts/sapiensFonts");
    pdfMake.vfs = { ...sapiensFonts?.default, ...pdfFonts.default };
  } else {
    pdfMake.vfs = pdfFonts?.default;
  }

  pdfMake.fonts = isSapien ? { ...fileFonts, ...fileSapiensFonts } : fileFonts;
  return pdfMake;
};

export default getPdfMake;
