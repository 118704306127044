import React, { useState, useRef, useEffect } from "react";
import {
  Menu,
  Icon,
  List,
  Label,
  Image,
  Header,
  Input,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./App.css";
import personImg from "react-lib/apps/common/person.png";
import CONFIG from "../config/config";



export const ChatList = React.forwardRef((props, ref) => {
  const history = useHistory();
  const isMounted = useRef(true);
  const inputRef = useRef();
  const [cookies] = useCookies([]);

  const handleChannelSelected = (item) => () => {
    console.log(" handleChannelSelected",  item.id)
    let redirectDMRoom = CONFIG.DM_BPLUS_CHAT_CHANNELS
    console.log("CONFIG.DM_BPLUS_URL", CONFIG.DM_BPLUS_URL)
    console.log('redirectDMRoom: ', redirectDMRoom);
    console.log(" handleChannelSelected", redirectDMRoom[0])
    let redirectMomRoom = CONFIG.MOM_BPLUS_CHAT_CHANNELS
    console.log('redirectMomRoom: ', redirectMomRoom);

    if (CONFIG.DM_BPLUS_URL && redirectDMRoom?.length > 0 && item.id)  {
      if (redirectDMRoom.includes(Number(item.id))) {
        let path = CONFIG.DM_BPLUS_URL  + item.id + "/?app=Mob";
        console.log('pathDM: ', path);
        props.onRedirectedChannelSelected(path)
        return 
      }
    }

    if (CONFIG.MOM_BPLUS_URL && redirectMomRoom?.length > 0 && item.id)  {
      if (redirectMomRoom.includes(Number(item.id))) {
        let path = CONFIG.MOMNCHILD_URL  + item.id + "/?app=Mob";
        console.log('pathMOM: ', path);
        props.onRedirectedChannelSelected(path)
        return 
      }
    }
    
    let path = "/Chat/" + item.id + "/";
      if (item.chat_channel_id) {
        path = path + item.chat_channel_id + "/";
      }
      props.onChannelSelected(path);
    
  };

  const handleChannelFollow = (test)  => () => { 
    // console.log(" handleChannelFollow: ", test)
    props.onUnfollowChannelSelected(test);
  }

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      props.searchChannelList();
    }
  };

  const createChannelList = () => {
    if (!props.userId && !cookies.userId) {
      return (
        <div className="no-user-id">
          <h2>Error: No User Id</h2>
        </div>
      );
    }

    const ICON = {
      tuh: "/images/tuh/tuh-icon.png",
      // egat: "/egat-logo-chat.png",
      egat: "logo_ishealth_circle"
    };
    
    console.log(" props.channelList", props.channelList);
    console.log(" props.channelList", props.channelList);
    return props.channelList.map((item, index) => (
      <List.Item
        key={index}
        className="chat-list-item"
        onClick={handleChannelSelected(item)}
      >
        <Image
          fluid
          src={ CONFIG.COMPANY === "EGAT" ? ICON.egat : CONFIG.COMPANY === "TU" ? ICON.tuh : item.image? item.image : personImg}
          circular
          onError={(i) => (i.target.src = personImg)}
        />
        <List.Content verticalAlign={"middle"}>
          <Header size={"small"}>{item.division_name || item.name}</Header>
        </List.Content>
        {item.unread_message_count !== 0 && (
          <List.Content verticalAlign={"middle"} floated={"right"}>
            <Label data-testid="msg-noti" circular color={"red"}>
              {item.unread_message_count}
            </Label>
          </List.Content>
        )}
      </List.Item>
    ));
  };

  const createChannelFollowList = () => {
    if (!props.userId && !cookies.userId && props.channelList) {
      return <></>;
    }
    console.log(" props.channelList", props.channelList);


    let isUnfollowDM = !props.channelList.find((item) =>
      item.name.includes("diabetes")
    );
    let isUnfollowMom = !props.channelList.find((item) =>
      item.name.includes("momchild")
    );

    return (
      <>
      {(isUnfollowDM || isUnfollowMom) && 
        <span style={{fontWeight:"bold", margin: "5px", color: "#828282"}}>Recommend</span> 
      }
      {isUnfollowDM && CONFIG.DM_BPLUS_CHAT_CHANNELS?.length > 0 &&
        <List.Item
          className="chat-list-item"
          onClick={handleChannelFollow(1) }
        >
          <Image
            fluid
            src={process.env.PUBLIC_URL + '/dm.png'}
            circular
            // onError={(i) => (i.target.src = personImg)}
          />
          <List.Content verticalAlign={"middle"}>
            <Header size={"small"}>Diabetes Center</Header>
          </List.Content>
          <List.Content verticalAlign={"middle"} floated={"right"}>
            <Label data-testid="msg-noti" circular color={"blue"}>
              + follow
            </Label>
          </List.Content>
          {/* {item.unread_message_count !== 0 && (
            <List.Content verticalAlign={"middle"} floated={"right"}>
              <Label data-testid="msg-noti" circular color={"red"}>
                {item.unread_message_count}
              </Label>
            </List.Content>
          )} */}
        </List.Item>
      }

      {isUnfollowMom && CONFIG.MOM_BPLUS_CHAT_CHANNELS?.length > 0 &&
        <List.Item
          className="chat-list-item"
          onClick={ handleChannelFollow(2) }
        >
          <Image
            fluid
            src={process.env.PUBLIC_URL + '/M&C.png'}
            circular
            // onError={(i) => (i.target.src = personImg)}
          />
          <List.Content verticalAlign={"middle"}>
            <Header size={"small"}>Mom and Child Center</Header>
          </List.Content>
          <List.Content verticalAlign={"middle"} floated={"right"}>
            <Label data-testid="msg-noti" circular color={"blue"}>
              + follow
            </Label>
          </List.Content>
          {/* {item.unread_message_count !== 0 && (
            <List.Content verticalAlign={"middle"} floated={"right"}>
              <Label data-testid="msg-noti" circular color={"red"}>
                {item.unread_message_count}
              </Label>
            </List.Content>
          )} */}
        </List.Item>
      }
      </>
    );
  };

  useEffect(() => {
    if (
      props.controller &&
      (cookies.apiToken || props.apiToken) &&
      (props.userId || cookies.userId)
    ) {
      props.loadChannelList();
    }
  }, [
    props.controller,
    cookies.apiToken,
    props.apiToken,
    props.userId,
    cookies.userId,
  ]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.apiToken && !cookies.apiToken) {
      props.noApiToken();
    }
  }, [props.apiToken, cookies.apiToken]);

  console.log("Chats props:", props);

  return (
    <div className="chat-page">
      <Dimmer.Dimmable>
        <Dimmer
          data-testid="chat-list-loader"
          active={props.isLoading}
          inverted
        >
          <Loader inverted>Loading...</Loader>
        </Dimmer>
        <Menu secondary className="chat-list-search">
          <Menu.Item>
            <Input
              ref={inputRef}
              fluid
              icon={"search"}
              loading={props.isLoading}
              iconPosition={"left"}
              placeholder="Search..."
              onChange={props.onSearchChange}
              value={props.chatSearchValue}
              onKeyDown={handleInputKeyPress}
            />
          </Menu.Item>
        </Menu>
        <List celled selection verticalAlign={"middle"}>
          {createChannelList()}
          {(CONFIG.DM_BPLUS_CHAT_CHANNELS?.length > 0 || CONFIG.MOM_BPLUS_CHAT_CHANNELS?.length > 0) && createChannelFollowList()}
        </List>
      </Dimmer.Dimmable>
    </div>
  );
});

ChatList.defaultProps = {
  noApiToken: () => {},
  onChannelSelected: () => {},
  searchChannelList: () => {},
  channelList: [],
};
