export const rules = {
    12314123: {
        id: 12314123,
        name: "Test Rule 1",
        content: {
            0: {   id: 0, 
                parent_id: null, 
                label: "คำถามแรก",
                condition: "first",      
                text: '**ในช่วง 14 วันก่อนหน้า** \n\n\n\n ท่านมีประวัติเสี่ยงต่อการติดเชื้อ ข้อใดข้อหนึ่งหรือไม่ดังนี้\nข้อที่ 1 : ท่านเดินทางหรืออยู่อาศัยในพื้นที่ที่มีรายงานการระบาดต่อเนื่องของโรคCOVID-19 ',   
                type: "root",         
            },
            
            1: {   id: 1, 
                parent_id: 0,
                label: "การพบคนกลุ่มเสี่ยง",
                condition: "ไป",         
                text: "คุณได้เจอใครที่มีความเสี่ยงไหม",        
                type: "choices",        
            },
            2: {   id: 2, 
                parent_id: 1,    
                label: "self quarantine",
                condition: "เจอ",        
                text: "ผลวินิจฉัย: คุณมีความเสี่ยง",           
                type: "end",            
            },
            3: {   id: 3, 
                parent_id: 1,    
                label: "ยังไม่มีอาการ",
                condition: "ไม่เจอ",      
                text: "ผลวินิจฉัย: คุณไม่มีความเสี่ยง",         
                type: "end",            },
            4: {   id: 4, 
                parent_id: 0,    
                label: "การพบคนกลุ่มเสี่ยง",
                condition: "ไม่ได้ไป",     
                text: "คุณได้เจอใครที่มีความเสี่ยงไหม",        
                type: "choices",        
            },
            5: {   id: 5, 
                parent_id: 4,    
                label: "self quarantine",
                condition: "เจอ",        
                text: "ผลวินิจฉัย: คุณมีความเสี่ยง",           
                type: "end",            
            },
            6: {   id: 6, 
                parent_id: 4,  
                label: "ยังไม่มีอาการ",
                condition: "ไม่ได้เจอ",    
                text: "ผลวินิจฉัย: คุณไม่มีความเสี่ยง",         
                type: "end",            
            },
        }
    },
    12314124: {
        id: 12314124,
        name: "Test Rule 2",
        content: {
            0: {   id: 0, 
                parent_id: null, 
                label: "คำถามแรก",
                condition: "first",      
                text: '**ในช่วง 14 วันก่อนหน้า** \n\n![img](https://www.mor.company/wp-content/uploads/2019/11/02.png)\n\n ท่านมีประวัติเสี่ยงต่อการติดเชื้อ ข้อใดข้อหนึ่งหรือไม่ดังนี้\nข้อที่ 1 : ท่านเดินทางหรืออยู่อาศัยในพื้นที่ที่มีรายงานการระบาดต่อเนื่องของโรคCOVID-19 ใน 14 วันที่ผ่านมา (พื้นที่ระบาดระบุไว้ด้านบน)',   
                type: "root",         
            },
        }
    },
}

export const formdata = {0: false, 1: true, 2: false, 3: false, 4: false, 5: false, 6: false}

export const chatlist = [ 
    { name: "Rama", latest_message: "คุณยังไม่มีความเสี่ยง" },
    { name: "Rajvithi", latest_message: "กรุณารักษาสุขภาพ" }
]

export const oa = {
    name: "Ramathibodi",
    rules: rules,
}
